#contact-us{
	section#hero{
		@extend %theme-large-heading;
		@include background-image('/images/site/contact-us/hero-bkg.jpg', cover, center);
		color: white;
		.type-md{
			margin: 0;
		}
	}

	section#content{
		
		#where-we-build{

			@mixin param($num){
				padding: $num 0;
				p.type-contact-us-md{
					margin-bottom: $num;
					font-weight: 300;
				}
			}

			@include param(135px);
			@include tablet{@include param(120px);}
			@include phone{@include param(75px);}

			.build-card{
				border:1px solid $lighter-gray;
				margin-bottom: 30px;
				.top{
					background-color: $lightest-gray;
					@include flex-param(row, center, center);
					> *{
						width: 50%;
					}
					@include phone{
						flex-direction: column;
						> *{
							width: 100%;
						}
					}
					.title{
						padding: 30px;
						@include flex-param(column, space-between, flex-start);
						@include phone{
							align-items: center;
						}
						p:first-child{
							font-weight: 300;
						}
						p:nth-of-type(2){
							margin-top: 20px;
							@include tablet{
							    line-height: 20px;
							    text-align: left;
							}
							@include phone{
								line-height: 20px;
							}
						}
					}
					.links a{
						width: 50%;
						display: inline-block;
						float: left;
						background-color: $lighter-gray;
						color: $red;
						padding: 30px 0;
						@extend .type-sm;
						@include hoverstate($red, white, .2s);
						&:nth-child(even){
							border-left: 1px solid $lightest-gray;
						}
						&:nth-last-child(-n + 2){
							border-top: 1px solid $lightest-gray;
						}
					}
				}
				.bottom{
					padding: $lighter-gray;
					padding: 30px;
					p{
						line-height: 24px;
						text-align: left;
					}
					.description-communities{
						a{
							color: $red;
						}
					}
				}
			}
		}

	}
}