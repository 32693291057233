#error_404{
	#section_error_404{
		padding-top: 50px;
	    height: calc(100% - 75px);

	    @include background-image('/images/site/404/bkg.jpg',auto,center);
	    @include flex-param(column, center, center);

		@include inview("svg path", bottom, 1s);
		@include inview("svg polygon", bottom, 1s);
		@include inview("svg rect", right, 1.5s);
		@include inview("p", bottom, 2s);
		@include inview("a", bottom, 2.5s);

	    svg{
	    	@include size(50vh, 45vh);
	    	max-height: 500px;
	    }
	    p{
	    	margin-bottom: 40px;
	    	@include phone{
			    padding: 0 30px;
			    text-align: center;
			    line-height: 25px;
	    	}
	    }
	}
}