#floorplan-matching {

    // padding-top: 170px;
    section#hero {
        margin-top: 140px;
        // margin-bottom: 20px;
        // text-align: center;

        // img {
        //     @media screen and (max-width: 992px){
        //         max-width: 200px;
        //         object-fit: cotain;
        //     }
        // }
    }

    section#content {


        padding: 0;
        background-color: #000000;

        @media screen and (min-width: 992px) {
            padding: 0 30px;
            background-color: #ffffff;
        }

        @media screen and (min-width: 1100px) {
            padding: 0 200px 100px;
        }

        position: relative;

        .like,
        .dislike {

            display: none;
            opacity: 0;
            position: fixed;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -40%);

            @media screen and (min-width: 992px) {
                transform: translate(-50%, -25%);
            }

            z-index: -1;
            animation: fadeInOpacity 1.2s ease;
            transition: opacity 1.2s ease-in-out;

            &.active {
                display: block;
                opacity: 1;
            }

            .content {

                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 300px;
                min-height: 800px;
                background-color: #95c799;
                margin: 100px;
                border-radius: 10px;

                @media screen and (min-width: 992px) {
                    min-height: 650px;
                    margin: 1vw 20vw;
                }

                h1 {
                    font-size: 70px;
                    font-weight: bold;
                    margin-bottom: 20px;
                    color: #ffffff;
                    border: 10px solid white;
                    padding: 40px;
                    transform: rotate(-15deg);

                    @media screen and (min-width: 992px) {
                        font-size: 110px;
                    }
                }
            }
        }

        .dislike {

            .content {
                background-color: #ff7b5a;

                h1 {
                    transform: rotate(15deg);

                    @media screen and (min-width: 992px) {
                        font-size: 90px;
                    }
                }
            }
        }

        .nailed-it {
            background-color: #BA1F31;
            color: #ffffff;
            padding: 20px;
            height: 100vh;

            @media screen and (min-width: 992px) {
                padding: 60px 120px;
                height: 70vh;
            }

            h1 {
                text-align: center;
                font-size: 32px;
                margin-top: 100px;
                font-weight: bold;
                animation: slideInLeft 1.5s ease-in-out, fillBackground .3s ease;
                animation-delay: 0s, 1.5s;

                @media screen and (min-width: 992px) {
                    font-size: 60px;
                }
            }

            p {
                text-align: center;
            }

            .subtitle {
                font-style: italic;
                margin-top: 10px;
                font-size: 14px;
                animation: slideInLeft 1.5s ease-in-out;

                @media screen and (min-width: 992px) {
                    font-size: 18px;
                }
            }

            .content {
                font-size: 18px;
                margin: 30px 0;
                line-height: 160%;
                animation: slideInLeft 1.5s ease-in-out;

                @media screen and (min-width: 992px) {
                    font-size: 24px;
                }
            }

            .actions {
                padding: 10px 0;

                @media screen and (max-width: 992px) {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }

                @media screen and (min-width: 992px) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 20px;
                }

                button {
                    width: 100%;
                    animation: slideInLeft 1.5s ease-in-out;
                }
            }
        }

        #floorplan {
            .hero.hero-image {
                width: 100%;
                height: 100%;
                background-color: white;
                padding-bottom: 20px;
                text-align: center;

                img {
                    @media screen and (max-width: 992px) {
                        max-width: 200px;
                        object-fit: cotain;
                    }
                }
            }

            transition: transform 0.1s ease-in-out;

            &.swipe-right,
            &.move-right {
                transform: translateX(200%) !important;
            }

            &.swipe-left,
            &.move-left {
                transform: translateX(-200%) !important;
            }

            &.swipe-right,
            &.swipe-left,
            &.move-left,
            &.move-right {
                transition: transform 1.1s ease-in-out;
            }

            &.reset {
                transition: transform 0.1s ease;
                transform: translateX(0) !important;
            }
        }

        .card-floorplan {
            background-color: white;

            .playlist {
                @media screen and (max-width: 992px) {
                    padding: 0 20px;
                }
            }

            .card--image-mobile--content {
                background-color: black;
                display: flex;
                justify-content: space-between;
                padding: 20px;

                @media screen and (min-width: 992px) {
                    display: none;
                }


                .card--content {
                    padding: 0;
                    color: #ffffff;

                    .title-mobile {
                        font-size: 30px;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }

                    .subtitle-mobile {
                        font-size: 12px;
                        margin-bottom: 2px;
                    }

                }

                .icons {

                    display: flex;
                    gap: 15px;
                    align-items: flex-end;

                    button.btn-shadow-icon.heart {
                        background-color: #BA1F31;
                    }
                }
            }

            .card--header-image-mobile {
                // min-width: 400px;
                width: 100%;
                min-height: 36vh;
                display: block;
                position: relative;

                @media screen and (min-width: 992px) {

                    display: none;
                }

                .card-image {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .scroll-down-arrow {
                        bottom: 50px;

                        @media screen and (max-width: 992px) {
                            transform: scale(0.9);
                        }
                    }

                    transition: transform 0.1s ease-in-out;

                    &.swipe-right {
                        transform: translateX(200%) !important;
                    }

                    &.swipe-left {
                        transform: translateX(-200%) !important;
                    }

                    &.swipe-right,
                    &.swipe-left {
                        transition: transform 0.1s ease;
                    }

                    &.reset {
                        transition: transform 0.1s ease;
                        transform: translateX(0) !important;
                    }
                }

                .card--content {
                    position: absolute;
                    z-index: 2;
                    bottom: 0;
                    left: 0;
                    padding: 20px;
                    color: #ffffff;
                    width: 350px;
                    height: 150px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 3px;

                    .title-mobile {
                        font-size: 30px;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }

                    .subtitle-mobile {
                        font-size: 12px;
                        margin-bottom: 2px;
                    }
                }

                .icons {
                    position: absolute;
                    z-index: 2;
                    bottom: 20px;
                    right: 20px;
                    display: flex;
                    gap: 15px;

                    button.btn-shadow-icon.heart {
                        background-color: #BA1F31;
                    }
                }

                .shadow-image-box {
                    // background: linear-gradient(180deg, transparent 5%, #000 95%);
                    // height: 200px;
                    background-color: #000000;
                    width: 100%;
                    height: 135px;
                    position: absolute;
                    z-index: 2;
                    bottom: 0;
                    left: 0;

                }
            }

            .card--header-image {

                display: none;
                min-width: 400px;
                height: 600px;
                position: relative;

                @media screen and (min-width: 992px) {
                    min-height: 500px;
                    display: block;
                }

                @media screen and (min-width: 1600px) {
                    height: 700px;
                }

                @media screen and (min-width: 1800px) {
                    height: 800px;
                }

                @media screen and (min-width: 2000px) {
                    max-height: max-content;
                    height: auto;
                }

                img {
                    width: 100%;
                    height: 100%;
                    max-height: 600px;
                    object-fit: cover;
                    border-radius: 10px;

                    @media screen and (min-width: 1600px) {
                        max-height: 700px;
                    }

                    @media screen and (min-width: 1800px) {
                        max-height: 800px;
                    }

                    @media screen and (min-width: 2000px) {
                        max-height: max-content
                    }

                    &.fade-in {
                        opacity: 1;
                        animation-name: fadeInOpacity;
                        animation-iteration-count: 1;
                        animation-timing-function: ease-out;
                        animation-duration: 1s;
                    }

                    &.swipe-right {
                        transform: translateX(200%) !important;
                    }

                    &.swipe-left {
                        transform: translateX(-200%) !important;
                    }

                    &.swipe-right,
                    &.swipe-left {
                        transition: transform 1.2s ease-in-out;
                    }
                }

                .actions--card {
                    display: none;

                    @media screen and (min-width: 992px) {

                        display: flex;
                    }

                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translate(-50%, 40%);
                    justify-content: center;
                    align-items: center;
                    gap: 32px;
                    z-index: 1;

                    button {
                        border-radius: 10px;
                        padding: 20px;
                        min-width: 200px;
                        font-size: 20px;
                        font-weight: bold;
                        border: 3px solid #BA1F31 !important;
                        background-color: #ffffff !important;
                        color: #000000 !important;

                        .x-choose {
                            width: 16px !important;
                            height: 16px !important;
                        }

                        .heart-choose {
                            width: 19px !important;
                            height: 16px !important;
                            color: #BA1F31;
                        }
                    }

                    .clip-path-group-heart__box {

                        // background-color: #ffffff;
                        // padding: 10px;
                        // border-radius: 50%;
                        // border: 1px solid #000;
                        .house-heart,
                        .clip-path-group-heart {
                            width: 80px;
                            height: 80px;
                        }
                    }
                }

            }

            .card--content {
                padding: 20px;
                color: black;

                // margin: 20px 0;
                @media screen and (min-width: 992px) {
                    padding: 70px 60px 30px 60px;
                }

                .title {
                    display: none;
                    font-size: 36px;
                    font-weight: bold;
                    margin-bottom: 10px;

                    @media screen and (min-width: 992px) {
                        display: block;
                    }
                }

                .subtitle {
                    display: none;
                    font-size: 18px;
                    margin-bottom: 10px;

                    @media screen and (min-width: 992px) {
                        display: block;
                    }
                }

                .description {
                    margin: 20px 0;

                    p {
                        line-height: 24px;
                    }
                }

                .about {
                    margin-bottom: 20px;
                    font-size: 18px;
                    font-weight: bold;
                }
            }

            .details--info {
                display: flex;
                align-items: center;
                gap: 20px;

                @media screen and (max-width: 430px) {
                    gap: 12px;
                }

                @media screen and (max-width: 420px) {
                    gap: 12px;
                    font-size: 14px;
                }

                div {
                    display: flex;
                    align-items: center;

                    svg {
                        width: 20px;
                        height: 20px;
                        fill: #BA1F31 !important;
                        color: #BA1F31 !important;
                        margin-right: 7px;

                    }
                }
            }
        }

        .card--red {
            background-color: $red;
        }

        .mix-and-match {
            margin: 0 20px;
            max-width: 800px;
            padding: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #ffffff;
            border-radius: 10px;

            @media screen and (min-width: 992px) {
                margin: 0 auto;
            }

            .text {
                h3 {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 16px;
                }
            }

            .icon {}
        }

        .profile-container--items {
            margin-top: 20px;
            padding: 10px 0 70px 0;
            display: flex;
            flex-direction: column;
            gap: 32px;

            .card-profile {
                @media screen and (max-width: 992px) {
                    padding: 20px;
                }

                @media screen and (min-width: 992px) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }

                .slider-image {
                    width: 100%;
                    height: 100%;

                    // background-color: yellow;
                    // @media screen and (min-width: 992px){ 
                    //     min-height: 400px;
                    // }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 10px;
                    }
                }

                .text-card {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 20px;
                    color: black;

                    @media screen and (min-width: 992px) {
                        padding: 50px;
                    }

                    h4 {
                        font-size: 13px;
                        font-weight: bold;
                        margin-bottom: 10px;
                        line-height: 30px;
                        // @media screen and (min-width: 992px){ 
                        //     font-size: 26px;
                        // }
                    }

                    p {
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 36px;

                        // font-size: 16px;
                        // line-height: 26px;
                        // font-weight: normal;
                        @media screen and (min-width: 992px) {}
                    }
                }
            }
        }

        .gallery-content--items {
            h3 {
                font-size: 26px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 30px;
                color: black;
            }

            .grid--gallery {
                display: grid;
                grid-template-columns: repeat(3, 1fr);

                @media screen and (max-width: 992px) {
                    padding: 20px;
                    gap: 10px;
                }

                @media screen and (min-width: 992px) {
                    grid-template-columns: repeat(4, 1fr);
                    gap: 20px;
                }

                img {
                    background-color: cadetblue;
                    width: 100%;
                    height: 120px;
                    object-fit: cover;
                    border-radius: 10px;

                    @media screen and (min-width: 992px) {
                        height: 200px;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .action {
                padding: 20px;
                display: flex;
                justify-content: center;

                @media screen and (min-width: 992px) {
                    padding: 40px 0;
                }

                button {

                    padding: 20px;
                    border-radius: 10px;
                    text-transform: uppercase;
                    font-weight: bold;

                    @media screen and (max-width: 992px) {
                        width: 100%;
                    }

                    @media screen and (min-width: 992px) {
                        min-width: 400px;
                    }
                }
            }
        }
    }
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

/* Skeleton animation */
.skeleton::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -150px;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
    animation: loading 1.7s infinite;
}

/* Keyframes for the loading animation */
@keyframes loading {
    0% {
        left: -150px;
    }

    50% {
        left: 100%;
    }

    100% {
        left: 100%;
    }
}

.image-error,
.box-profile-error {
    display: none;
}


.modal-overlay {

    position: fixed;
    display: flex !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: center;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out;

    &.show {
        visibility: visible;
        opacity: 1;

        .asset__card {
            transform: translateY(-20px);

            @media screen and (min-width: 992px) {
                transform: translateY(50px);
            }
        }
    }

    .asset__card {
        min-width: 400px;
        height: 300px;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        transform: translateY(-60px);
        transition: transform 0.3s ease-out;

        @media screen and (min-width: 992px) {
            min-width: 600px;
            height: 500px;
        }

        div {
            // padding: 0 20px;

            // img {
            //     max-height: 300px;
            //     width: 100%;

            //     @media screen and (min-width: 992px) {
            //         max-height: 800px;
            //         max-width: 800px;
            //     }
            // }
        }

        .close-asset {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .match__card {



        margin: 200px 20px 0 20px;
        width: 100%;
        min-height: 350px;
        background-color: #BA1F31;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        transform: translateY(-60px);
        transition: transform 0.3s ease-out;
        padding: 10px 20px;

        // position: relative;
        @media screen and (max-width: 450px) {
            min-height: 300px;
        }

        @media screen and (min-width: 992px) {
            width: 500px;
            min-height: 550px;
            padding: 50px 32px;
        }

        .title {
            padding: 20px;

            @media screen and (max-width: 450px) {
                padding: 10px;
            }

            h1,
            p {
                color: #ffffff;
                text-align: center;
            }

            p {
                font-style: italic;
                font-size: 18px;

                @media screen and (max-width: 450px) {
                    font-size: 14px;
                }
            }

            h1 {
                font-size: 28px;
                font-weight: bold;
                margin-bottom: 10px;
                letter-spacing: 4px;

                @media screen and (max-width: 450px) {
                    font-size: 20px;
                }
            }
        }

        .match__image {
            position: relative;
            padding: 10px 10px 30px 10px;
            background-color: #ffffff;

            @media screen and (max-width: 450px) {
                padding: 10px 10px 20px 10px;
            }

            img {
                width: 100%;
                max-height: 232px;
                object-fit: cover;

                @media screen and (max-width: 450px) {
                    max-height: 200px;
                }

                @media screen and (min-width: 992px) {
                    // max-height: 300px;
                }
            }
        }

        .match_plan_name {
            margin-top: 20px;
            text-align: center;

            @media screen and (max-width: 450px) {
                margin-top: 10px;
            }
        }

        .actions {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 20px;

            @media screen and (min-width: 992px) {
                padding: 20px 32px;
            }

            .btn-black,
            .btn-white {
                font-size: 16px;

                @media screen and (max-width: 480px) {
                    font-size: 12px;
                    height: 40px;
                }
            }

            .btn-space {
                margin-top: 20px;

                @media screen and (max-width: 450px) {
                    margin-top: 10px;
                }
            }
        }

        .close-match {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            &:hover {
                cursor: pointer;
            }
        }

        &.hearts-container {
            // position: relative;
            // height: 300px;
            overflow: hidden;

            .heart-span {
                position: absolute;
                top: -100px;
                animation: fall 2s ease-in-out;

                .heart-choose {
                    // position: absolute;
                    // top: -50px;
                    width: 100px;
                    height: 100px;
                    color: #BA1F31;
                    opacity: 0.8;
                    // animation: fall 2s ease-in-out;

                }

            }

            .heart-span:nth-child(1) {
                left: 10%;
                animation-duration: 4s;
            }

            .heart-span:nth-child(2) {
                left: 20%;
                animation-duration: 5s;
            }

            .heart-span:nth-child(3) {
                left: 30%;
                animation-duration: 6s;
            }

            .heart-span:nth-child(4) {
                left: 40%;
                animation-duration: 4.5s;
            }

            .heart-span:nth-child(5) {
                left: 50%;
                animation-duration: 5.5s;
            }

            .heart-span:nth-child(6) {
                left: 65%;
                animation-duration: 6.5s;
            }

            .heart-span:nth-child(7) {
                left: 70%;
                animation-duration: 4.2s;
            }

            .heart-span:nth-child(8) {
                left: 80%;
                animation-duration: 5.2s;
            }

            .heart-span:nth-child(9) {
                left: 55%;
                animation-duration: 6.2s;
            }

            .heart-span:nth-child(10) {
                left: 15%;
                animation-duration: 4.8s;
            }

            .heart-span:nth-child(11) {
                left: 15%;
                animation-duration: 3s;
            }

            .heart-span:nth-child(12) {
                left: 25%;
                animation-duration: 2s;
            }

            .heart-span:nth-child(13) {
                left: 35%;
                animation-duration: 1.5s;
            }

            .heart-span:nth-child(14) {
                left: 45%;
                animation-duration: 3.5s;
            }

            .heart-span:nth-child(15) {
                left: 55%;
                animation-duration: 4.5s;
            }

            .heart-span:nth-child(16) {
                left: 65%;
                animation-duration: 1s;
            }

            .heart-span:nth-child(17) {
                left: 75%;
                animation-duration: 4.7s;
            }

            .heart-span:nth-child(18) {
                left: 80%;
                animation-duration: 2.2s;
            }

            .heart-span:nth-child(19) {
                left: 55%;
                animation-duration: 3.2s;
            }

            .heart-span:nth-child(20) {
                left: 5%;
                animation-duration: 3.8s;
            }

        }

    }

    .go-it__card {
        margin-top: 200px;

        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        transform: translateY(-60px);
        transition: transform 0.3s ease-out;
        padding: 20px;

        @media screen and (max-width: 992px) {
            width: 300px;
            min-height: 350px;
        }

        @media screen and (min-width: 992px) {
            max-width: 550px;
        }

        .title {
            padding: 15px 20px;
            animation: slideInLeft 1.5s ease-in-out;

            p {
                color: #000000;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
            }
        }


        .go-it__content--desktop {
            display: none;

            @media screen and (min-width: 992px) {
                display: block;
            }

            .heart__box {
                text-align: center;

                svg {
                    width: 50px;
                }
            }

            min-width: 450px;


            .swipe__section {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 10px;

                .heart__box {
                    animation: slideInLeft 1.5s ease-in-out;
                    text-align: center;

                    svg {
                        width: 50px;
                        height: 70px;
                    }
                }

                .left-button__section,
                .right-button__section {
                    padding: 10px;
                    animation: slideInLeft 1.5s ease-in-out;

                    .button {
                        text-align: center;

                        button {
                            min-height: 56px;
                            border: 2px solid #BA1F31;
                            background-color: #ffffff !important;
                            color: #000000 !important;
                            font-weight: bold;
                            min-width: 200px;
                            border-radius: 10px;

                            svg {
                                width: 16px !important;
                                height: 16px !important;
                                color: #BA1F31;
                            }
                        }
                    }

                    .text {
                        padding: 20px 5px;

                        p {
                            color: #000000;
                            text-align: center;
                            line-height: 24px;
                        }
                    }
                }

            }
        }

        .go-it__content {
            display: block;

            @media screen and (min-width: 992px) {
                display: none;
            }

            .swipe__section {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                padding: 10px 0;

                .left-swipe__section,
                .right-swipe__section {

                    .svg-icon {
                        animation: slideInLeft 1.5s ease-in-out;
                        position: relative;
                        width: 100%;
                        height: 125px;

                        .right-arrow-hand,
                        .left-arrow-hand {
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            top: 5px;
                        }

                        .left-arrow-hand {
                            right: 24px;
                        }

                        .right-arrow-hand {
                            z-index: 1;
                            left: 24px;
                        }

                        .green-hand,
                        .red-hand {
                            width: 80px;
                            height: 80px;
                            position: absolute;
                            top: 30px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }

                    .text {
                        color: #000000;
                        text-align: center;
                        animation: slideInLeft 1.5s ease-in-out;
                        line-height: 24px;
                    }
                }
            }

            .buttons__section {
                .icons {
                    display: flex;
                    justify-content: center;
                    gap: 20px;
                    padding: 15px 20px;

                    .heart {
                        background-color: #BA1F31;
                    }
                }


                .message {
                    color: #000000;
                    animation: slideInLeft 1.5s ease-in-out;

                    p {
                        text-align: center;
                        line-height: 24px;
                    }
                }
            }


        }

        .actions {
            padding: 20px;
            text-align: center;
            animation: slideInLeft 1.5s ease-in-out;

            button {
                min-width: 200px;
                padding: 20px 10px;
                border-radius: 10px;
                font-weight: bold;
            }
        }


    }
}

.btn-shadow-icon {
    border-radius: 50% !important;
    box-shadow: 0 2px 10px rgba(0, 0, 1px, 0.2);
    width: 56px;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .x-choose {
        width: 21px;
        height: 21px;
    }

    .heart-choose {
        width: 24px;
        height: 24px;
        color: #ffffff;
    }

}


.btn-black {
    color: #ffffff;
    background-color: #000000;
    padding: 10px 20px;
    height: 56px;
    border: none;
    border-radius: 8px !important;
    font-weight: normal !important;
}

.btn-white {
    color: #000000;
    background-color: #ffffff;
    padding: 10px 20px;
    font-weight: normal !important;
}

@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fillBackground {
    0% {
        background: linear-gradient(to left, transparent, transparent 50%, white 100%, transparent 100%);
    }

    25% {
        background: linear-gradient(to left, transparent, transparent 30%, white 60%, transparent 90%);
    }

    50% {
        background: linear-gradient(to left, transparent, transparent 15%, white 40%, transparent 100%);
    }

    75% {
        background: linear-gradient(to left, transparent, transparent 5%, white 20%, transparent 100%);
    }

    100% {
        background: transparent;
    }
}


#floorplan-no-match {

    #content {
        margin-top: 80px;

        .nailed-it {
            background-color: #BA1F31;
            color: #ffffff;
            padding: 20px;
            height: 100vh;

            @media screen and (min-width: 992px) {
                padding: 60px 120px;
                height: 80vh;
            }

            h1 {
                text-align: center;
                font-size: 32px;
                margin-top: 100px;
                font-weight: bold;
                animation: slideInLeft 1.5s ease-in-out, fillBackground .3s ease;
                animation-delay: 0s, 1.5s;

                @media screen and (min-width: 992px) {
                    font-size: 60px;
                }
            }

            p {
                text-align: center;
            }

            .subtitle {
                font-style: italic;
                margin-top: 10px;
                font-size: 14px;
                animation: slideInLeft 1.5s ease-in-out;

                @media screen and (min-width: 992px) {
                    font-size: 18px;
                }
            }

            .content {
                font-size: 18px;
                margin: 30px 0;
                line-height: 160%;
                animation: slideInLeft 1.5s ease-in-out;

                @media screen and (min-width: 992px) {
                    font-size: 24px;
                }
            }

            .actions {

                padding: 30px 0;
                display: flex;

                @media screen and (max-width: 992px) {
                    flex-direction: column;
                    gap: 20px;
                    align-items: center;
                }

                @media screen and (min-width: 992px) {
                    justify-content: center;
                    gap: 20px;
                    align-items: unset;
                }

                a {
                    width: 350px;
                    animation: slideInLeft 1.5s ease-in-out;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.btn-white:hover {
                        color: #000;
                    }

                    &.btn-black:hover {
                        color: #fff;
                    }
                }
            }
        }
    }

}

.slider-assets-modal,
.slider-ready-for-love {
    border-radius: 10px;

    .reg__card-image-slider {
        height: 100%;

    }

    .slick-prev.slick-arrow,
    .slick-next.slick-arrow {
        display: none !important;
    }

    .reg__card-image-slide-left,
    .reg__card-image-slide-right {
        // @media screen and (max-width: 992px){
        opacity: 1;
        // }
    }

    .reg__card-image-slide {
        .name {
            position: absolute;
            z-index: 3;
            left: 10px;
            top: 10px;
            color: #ffffff;
            font-weight: bold;
        }
    }
}

.slider-assets-modal,
.slider-assets-modal .reg__card-image-slider {
    height: 300px;

    @media screen and (min-width: 992px) {
        height: 500px;
    }
}

.slider-assets-modal {

    .box--principal {
        // width: auto !important;
        // min-width: 600px;

        img {
            // left: -40px;
            // left: -20px;
        }
    }

    .reg__card-image-slide-left,
    .reg__card-image-slide-right {
        top: 20vh;

        @media screen and (min-width: 992px) {
            top: 35vh;
        }
    }

    // .reg__card-image-slider img {
    //     width: 700px;
    // }
}

.slider-ready-for-love .reg__card-image-slide-left,
.slider-ready-for-love .reg__card-image-slide-right,
.slider-assets-modal .reg__card-image-slide-left,
.slider-assets-modal .reg__card-image-slide-right {
    opacity: 1 !important;
    display: block !important;
}

@keyframes fall {
    0% {
        top: -100px;
        opacity: 1;
    }

    100% {
        top: 400px;
        opacity: 0;
    }
}

.asset__container--slider {
    position: relative;
}