#my-favorites.my-design-style {
	section#content {
		margin-top: var(--headerHeight);
	}
	section#content > section {
		.you-have-not-saved-a-design-yet {
			background-color: lightgray;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 22.5px;
			height: 300px;
			padding: 30px;
			svg {
				@include size(150px);
				@include svgcolor(#fff);
			}
			p {
				color: #000;
			}
			a {
				color: $red;
				text-decoration: underline;
			}
		}
	}
}