&.coming-soon{
	//inherits two-stack theme

	#hero{
		@include background-image('/images/site/microsite/coming-soon-bkg.jpg', cover, bottom center);
		.container{
			height: calc(100% - 64px);
			p:first-child{
				margin-bottom: 50px;
				font-weight: 300;
			}
			a.btn{
				padding: 15px 60px;
			}
		}
	}

	.divider.red{
		@extend .type-lg;
		height: 64px;
	}

	#content{
		background-color: $lightest-gray;
		.col-left{
			flex: 1.5;
			padding: 100px 115px 100px 190px;
			#overview > p:first-child{
				margin-bottom: 5px;
			}
			.scrollable{
				margin-top: 60px;
				height: 500px;
				overflow: auto;
				p{
					line-height: 48px;
					margin-bottom: 20px;
					&:first-child{
						line-height: 42px;
						font-weight: 600;
					}
				}
			}
		}

		.col-right{
			color: white;
			background-color: $blue;
			flex: 1;
			.top{
				form{
					max-width: 470px;
					padding: 55px;
					input{
						margin-bottom: 15px;
					}
					input[type="submit"]{
						margin-top: 15px;
						float: right;
					}
					p{
						text-align: center;
					}
					p.type-xxxxl{
						font-weight: 300;
						line-height: 50px;
					}
					p.type-md{
						letter-spacing: .9px;
						line-height: 30px;
						margin: 20px 0;
					}
					@extend %clearfix;
				}

			}

			.bottom{
				background-color:red;
				position: relative;
				@include size(100%, 260px);
				@include background-image('/images/site/global/map-template.jpg', cover, center);
				.btn-small{
					position: absolute;
					background-color: $blue;
					color: white;
					padding: 10px 15px;
					border-radius: 0;
					bottom: 0;
					right: 0;
					@include hoverstate(darken($blue, 10%), white, .2s);
				}
			}
		}
	}
}