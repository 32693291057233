#realtor-program{
	//inherits two-stack theme

	#hero{
		@include background-image('https://d1k0mxjwfbpkpb.cloudfront.net/resources/264452/original/2.jpg', cover, bottom center);
		.container{
			h2{
				@include flex-param(row, center, center);
				img{
					width: 20vw;
					max-width: 250px;
					margin-bottom: 30px;
				}
			}
			h1{
				font-style: italic;
			}
		}
		@include phone{
			padding-top: 0;
			background-attachment: initial;

			.container{
				height: calc(100% - 110px);
			}

			.container h2{
				line-height: 25px;
				text-align: center;
			}

			.container h1{
				text-align: center;
				line-height: 30px;
			}
		}
	}

	#content{
		color: white;
		@include tablet{flex-direction : column;}
		@include phone{flex-direction : column;}
		.col-left{
			flex: 1;
			padding: 60px 125px 50px 100px;
			@include background-image('/images/site/realtor-program/realtor-content-bg.jpg', cover, bottom center);
			background-repeat: no-repeat;
			background-color: #fff;
			p{
				line-height: 56px;
				margin-bottom: 60px;
				font-weight: 300;
			}
			ul{
				padding: 0 30px;
				li{
					@extend %li-circle;
					padding-left: 20px;
					margin-bottom: 20px;
					line-height: 30px;
					max-width: 600px;
					&:after{
						top:10px;
						background-color: white;
					}
				}
			}
			@include laptop{
				padding-right: 115px;
				p{
					font-size: 22px;
					line-height: 45px;
					margin-bottom: 30px;
					font-weight: 300;
				}
				ul{
					padding-right: 0px;
				}
			}
			@include tablet{
				padding-bottom: 115px;
			}
			@include phone{
				padding: 60px 20px;
				background-position: bottom;
				background-size: 100% auto;
				p{
					font-size: 20px;
					line-height: 45px;
				}
				ul{
					padding-right: 0;
					margin-bottom: 100px;
				}
			}

		}

		.col-right{
			background-color: #a6a7a7;
		}

		.form-wrap {
			width: 415px;
			color: white;
			@include tablet{width: 100%;}
			@include phone{width: 100%;}
			> div{
				padding: 30px 45px;
				line-height: 30px;
				@include phone{
					padding: 30px 25px;
				}
			}
			.top{
				background-color: #7e7e7e;
				position: relative;
				text-align: center;
				p{
					letter-spacing: 0;
					&:nth-of-type(2){letter-spacing: 1px;}
					&:nth-of-type(3){letter-spacing: 0.5px;}
					font-weight: 600;
				}
				img{
				    position: absolute;
				    position: absolute;
				    left: -131px;
				    top: 80px;
				    width: 210px;
				    @include tablet{
				    	left: 30px;
				    }
				    @include phone{
				    	display: none;
				    }
				}
			}
			.bottom{
				padding-bottom: 60px;
				.title{
					margin-bottom: 50px;
					text-align: right;
					@include tablet{text-align: center;}
					@include phone{text-align: center;}
					p{
						margin-bottom: 10px;
						&:last-child{
							font-weight: 600;
						}
					}
				}

                &:after{
                    @extend %clearfix;
                }

                @include form-results-color(white);

                form {
                    .form-component {
                        margin-bottom: 30px;
                        border: none;

                        @include validation-white;

                        .help-block {
                            bottom: -39px;
                        }
                    }
                    input[type="submit"]{
                        float: right;
                    }
                    .btn-group{
                        margin-bottom: 30px;
                        width: 100%;
                        button{
                            padding: 10px;
                            border-radius: 5px;
                            width: 100%;
                        }
                        span{
                            background-color: transparent;
                            svg{
                                @include svgcolor($light-gray);
                            }
                        }
                    }
                    @include tablet{
                    	text-align: center;
	                    input[type="submit"]{
	                        float: none;
	                    }
                    }
                    @include phone{
                    	text-align: center;
	                    input[type="submit"]{
	                        float: none;
	                    }
                    	margin-bottom: 80px;
                    	&:after{
                    		@include psuedo-block();
                    		@include gradient(linear, (transparent, rgba(black, .3)));
                    		height: 100px;
                    		top: auto;
                    		bottom: 50px;
                    	}
                    }
                }
			}
		}
	}
}