//dark
$dark-gray: #626262;
$gray: #7f7f7f;
$nuetral-gray: #969696;
$medium-gray: #a0a0a0;
$light-gray: #a6a7a7;
$lighter-gray: #dedede;
$lightest-gray: #f5f5f5;
$cool-gray: #d0d7da;

//cool
$royal-blue: #000e84;
$medium-blue: #2a54b7;
$blue: #00a5b8;

//warm
$red: #ba1f31;
$orange: #f08804;

$faded-white: rgba(white,.5);

$green: lighten(green, 10%);

$black: #000000;

//== Media queries breakpoints
@mixin desktop-hd {
  @media only screen and (min-width: 1230px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (min-width: 768px) and (max-height: 850px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin phone {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin portrait {
  @media only screen and (orientation : portrait) {
    @content;
  }
}