//carousel
.carousel,
.carousel .carousel-inner,
.carousel .carousel-inner .item,
.carousel .carousel-inner .item .flex-caption{
	height: 100%;
	.fill{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-size: cover;
		background-position: center center;
		transform: scale(1.3);
		@include transition(all 18s ease-out);
		&.video{
			height: calc(100% - 80px);
		}
	}
	&.active .fill:not(.video){
		transform: scale(1);
	}
	.flex-caption{
		position: relative;
		z-index: 1;
		text-transform: uppercase;
	}
}

.carousel{
	background-color: black;
	position: relative;
	perspective: 2000px;
	height: 88vh;
	overflow: hidden;
	@include phone{
		height: 50vh;
	}
	.carousel-inner{
		overflow: visible;
	}
	.carousel-control{
		background-image: none;
		opacity: 1;
		cursor: pointer;
		width: 50px;
		padding-top: 110px;
		@include flex-param(column, center, center);
		@include tablet{display:none;}
		@include phone{display:none;}
		svg{
			@include size(40px);
			position: relative;
			z-index: 1;
		}
		&:after{
			content: "";
			background-color: $red;
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
		}

		&.left{@include carousel-hover(left);}
		&.right{@include carousel-hover(right);}

	}
}

%carousel-full-height{
	.carousel{
		height: 100vh;
		@include tablet{height: 60vh;}
	}

	.carousel .carousel-inner .item .flex-caption{
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    flex-direction: column;
	    h1{
	    	color: white;
	    	font-size: 70px;
	    	font-weight: 700;
	    }
	}
}

%carousel-indicator-style{
    li{
    	background-color: white;
    	border: none;
	    border-radius: 0;
	    margin-right: .5vw;
		@include phone{
			margin: 1px 5px;
		}
	    &.active{
	    	background-color: $blue;
	    }
    }
}

%square-indicator-right{
	.carousel ol.carousel-indicators{
	    left: auto;
	    right: 0;
	    text-align: right;
	    margin: 0;
	    padding-right: 1vw;
	    width: auto;
		@extend %carousel-indicator-style;
	}
}
