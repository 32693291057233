div#your-account-view {
	margin-top: var(--headerHeight);
	.container-fluid{
		@media screen and (max-width: 768px){
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

#my-favorites.your-account {

	section#hero {
		padding-left: 0; 
		padding-right:0;
		.title-matches {
			padding: 30px 20px;
			text-align: center;
			margin-top: 0 !important;
			.title-matches__text {
				margin-bottom: 0;
				font-size: 32px;
				color: #000000;
				font-weight:bold;
			}
		}
		.content-welcome {
			display: flex;
			align-items: center;
			margin-left: 10vw;
			margin-bottom: 15px;
			.content-welcome__icon {
				margin-right: 10px;
				svg {
					width: 32px;
					height: 32px; 
				}	
			}
			.content-welcome__title {
				display: flex;
				font-size: 20px;
				color: #000000;
				// font-weight: bold;
				// margin-bottom: 5px;
			}
		}
		@media screen and (max-width: 768px){
			display: none;
		}
	} 
	section#hero-mobile {
		margin-top: 110px;
		@media screen and (min-width: 769px){
			display: none;
		}
		.content-welcome {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			width: 100%;
			.content-welcome__icon {
			
				margin-right: 10px;
				svg {
					width: 32px;
					height: 32px; 
				}	
			}
			.content-welcome__hero {
				text-align: center;
				flex-grow: 1;
				.content-welcome__title {
					font-size: 24px;
					color: #000000;
					font-weight: bold;
				}
				.content-welcome__subtitle {
					font-size: 20px;
					color: #000000;
					margin-top: 10px;
				}
			}
		}
		.hero-text__box {
			padding: 12px 20px;
			border-radius: 10px;
			background: var(--Red, #BA1F31);
			h1 {
				color: var(--White, #FFF);
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: 150%; /* 30px */
				letter-spacing: 0.4px;
			}
			p {
				color: var(--White, #FFF);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%; /* 24px */
				letter-spacing: 0.32px;
			}
		}
	}
	section#content {
		padding: 75px 10vw;

		@media screen and (max-width: 480px){
			padding: 0 20px ;
		}

		> aside {
			.back {
				@include phone {
					display: block;
				}
			}
			.welcome-name {
				a {
					display: none;
				}
			}
		}
		> ul {
			display: none;
		}
		> section {
			width: 100% !important;
			padding-top: 45px;
			padding-left: 0;
			fh-form-my-favorites-manage {
				.row {
					&:nth-of-type(1) {
						display: flex;
						align-items: stretch;
						@include tablet {
							flex-direction: column;
						}
						@include phone {
							flex-direction: column;
						}
					}

					&:nth-of-type(2) {
						@include phone {
							margin-bottom: 20px;
						}
					}
				}
				.col-left {
					> div {
						@media screen and (min-width: 1024px) {
							padding-right: 30px;
						}
						@include tablet {
							padding-bottom: 30px;
						}
						@include phone {
							padding-bottom: 30px;
						}
					}
				}
				.col-right {
					border-left: 1px solid $lighter-gray;
					@include tablet {
						border-left: none;
					}
					@include phone {
						border-left: none;
					}
					> div {
						padding-left: 30px;
						@include tablet {
							padding-left: 0;
						}
						@include phone {
							padding-left: 0;
						}
					}
				}
				p {
					color: #000;
					font-size: 21px;
					margin-bottom: 30px;
				}
				.form-group {
					margin-bottom: 22.5px;

					tool-tip {
						position: absolute;
						z-index: 1;
						pointer-events: none;
						user-select: text;
						box-shadow: 0 0 10px #000;
						padding: .5rem 1rem;
					}
				}
				.form-component-checkbox {
					color: #000;
					display: flex;
					align-items: center;
					gap: 15px;
					&.sub {
						margin-left: 39px;
					}
					input.form-control {
						width: 21px;
						-webkit-box-shadow: none;
						box-shadow: none;
					}
				}
				.btn.btn-black-red {
					margin-top: 30px;
					padding-top: 7.5px;
					padding-bottom: 7.5px;
				}
			}
		}
	}
}