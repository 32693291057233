section#blog{
    background-color: #e0e0e0;
    color: black;
    width: 100%;
    margin: 0;
    padding: 0;

    .container-fluid{
        padding: 40px 50px;
        width: 100%;
        max-width: 1200px;

        .blog-btn{
            display: block;
            width: fit-content;
            text-transform: uppercase;
            font-size: 1.25rem;
            font-weight: 600;
            margin: 15px auto 25px;
        }

        #blog-container {
            a{
                display: flex;
                justify-content: center;
                align-items: center;
            }
        } 
    }

    h2{
        text-transform: uppercase;    
        text-transform: uppercase;
        text-align: center;
        font-size: 2rem;
        font-weight: 400;
    }


    .blog{
        width: 300px !important;
        height: 169px !important;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .slick-arrow{
        background-color: transparent;
        border: none;
        font-size: 25px;
        font-weight: 600;
        transform: scaleY(1.75);
        position: relative;
    }
    .slick-prev{
        left: -10px;
        bottom: -110px;
    }
    .slick-next{
        right: -98.5%;
        bottom: 95px;
    }

}