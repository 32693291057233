section#banner-promo {
  overflow: visible;
  z-index: 1;

  .banner-promo{
    background-image: none;
    background-color: $red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 25px 50px;

    @media screen and (max-width: 768px){
      padding: 15px 5px;
    }

    gap: 15px;
    width: 95%;

    @media screen and (max-width: 768px){
      width: 100%;
    }
    max-width: 1400px;
    margin: 0 auto;

    .copy > p.type-sm{
      color: white;
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;    
      @media screen and (max-width: 768px){
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

    .flex-wrap{
      padding-left: 0;
      padding-right: 0;
      display: none;
      .cta-container{
        .btn{
          color: black;
          border-radius: 0;
          padding: 10px 15px;


          &:hover{
            color: white;
          }
        }
      }
    }
  }
}