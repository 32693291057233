//global
@import 'global/functions';
@import 'global/variables';
@import 'global/reset';
@import 'global/mixins';
@import 'global/helper';
@import 'global/header';
@import 'global/footer';
@import 'global/typography';
@import 'global/cookie-banner';
@import 'global/disclaimer';

//vendor
@import 'vendor/malihu-custom-scrollbar';
@import 'vendor/slick-carousel';

//themes
@import 'themes/theme-two-stack';
@import 'themes/theme-tabbed-header';
@import 'themes/theme-detail';

//components
@import 'components/snapshot';
@import 'components/buttons';
@import 'components/forms';
@import 'components/inline-forms';
@import 'components/custom-checkbox';
@import 'components/cards';
@import 'components/carousel';
@import 'components/show-more';
@import 'components/mini-map';
@import 'components/accordian-thumbnail';
@import 'components/building-animation';
@import 'components/mortgage-calculator';
@import 'components/modal';
@import 'components/floorplan-gallery';
@import 'components/block-links';
@import 'components/banners';
@import 'large-footer/large-footer';
@import "components/hubspot-forms";

//404
@import 'error/404';

//sales center
@import 'sales-center/sales-center';

//sitemap
@import 'site-map/site-map';

//privacy policy
@import 'privacy-policy/privacy-policy';

//terms of service
@import 'terms-of-service/terms-of-service';

//Kitchen Visualizer
@import 'kitchen-visualizer/index';

//long interest list
@import 'long-interest-list/long-interest-list';

//pages
@import 'homepage/homepage';
@import 'microsite/microsite';
@import 'microsite/microsite-floorplan-detail';
@import "model-detail/model-detail";
@import "move-in-detail/move-in-detail";
@import "floorplan-detail/floorplan-detail";
@import "why-choose-fischer/why-choose-fischer";
// @import "blog/blog";
@import "energywise/energywise";
@import 'our-collections/our-collections';
@import 'our-collections/collection-details';
@import 'cincinnati-bengals/cincinnati-bengals';
@import 'floorplan-finder/floorplan-finder';
@import 'floorplan-matching/floorplan-matching';

//regional
// @import 'regional/regional';
@import 'region-revamped/region-revamped';
@import 'sales-priority-one/sales-priority-one';

//pages::design
@import 'virtual-tours/virtual-tours';
@import 'design/design-overview';
@import 'image-gallery/image-gallery';
@import 'lifestyle/lifestyle';

//pages::buying
@import 'buying-process/buying-process';
@import 'financing/financing';
@import 'prequalify/prequalify';
@import 'general-contact/general-contact';
@import 'vendor-request/vendor-request';
@import 'building-process/building-process';

//pages::own
@import 'realtor-program/realtor-program';
@import 'customer-testimonials/customer-testimonials';

//pages::about
@import 'discover-the-difference/discover-the-difference';
@import 'building-a-better-community/building-a-better-community';
@import 'join-our-team/join-our-team';
@import 'contact-us/contact-us';
@import 'vendor-bid/vendor-bid';
@import 'st-judes/st-judes';
@import 'st-louis/st-louis';

//podcast
@import 'podcast/podcast';

// my favorites
@import 'my-favorites/index';

// pages::login
@import 'login/index';

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;

  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

.disclaimer-st-louis {
  color: #000;
  font-size: 14.25px !important;
  line-height: 15px !important;
  margin-bottom: 15px !important;
}

.btn-secondary {
  background: var(--Red, #BA1F31);
  padding: 10px 20px;
  color: #ffffff;
  border: none;
  border-radius: 4px;

  &[disabled] {
    opacity: .5;
  }
}

.btn-white {
  background: #ffffff;
  padding: 10px 20px;
  color: #000000;
  border: none;
  border-radius: 8px;
  height: 56px;
  font-weight: bold;
}


// saved button
.save-button {
  --backgroundColor: #BA1F31;
  --fillColor: white;
  --outlineColor: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  // width: 48px;
  padding: 0;
  background: var(--backgroundColor);

}

.save-button .icon-heart {
  color: var(--outlineColor);
}

.save-button .svg-heart__fill {
  color: var(--fillColor);
}

.save-button--saved {
  --backgroundColor: white;
  --outlineColor: #BA1F31;

  path {
    // stroke-width: 0;
    stroke: #BA1F31;
  }
}

@mixin base-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
}

.favorite-modal {
  @include base-modal;
  background: white;
  padding: 50px 100px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #BA1F31;
}



.favorite-modal-content {
  text-align: center;
  // position: relative;
}

.favorite-modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
}



.favorite-modal.show {
  display: block;
  animation: fadeOut 2s forwards;
}

.after-login-option-modal {
  @include base-modal;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  &.show {
    display: block;
  }

  .after-login-modal-content {
    text-align: center;
    // background-color: blue;

    .after-login--box {
      margin: 80px 0;

      .after-login--box-button {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        a {
          display: flex;
          justify-content: center;
          align-items: center;

          &:first-child {
            border: 1px solid #000000;

            &:hover {
              color: #000000;
              cursor: pointer;
            }
          }

          &:last-child {
            border: 1px solid #000000;

            &:hover {
              color: #ffffff;
              cursor: pointer;
            }
          }
        }


      }
    }
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.form-control.written-disabled {
  background-color: #ecf0f1;
  opacity: 1;
}