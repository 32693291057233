#microsite {
  %section-padding {
    padding: 75px 0;
    @include phone {
      padding: 60px 0;
    }
  }

  %section-title-margin {
    margin-bottom: 80px;
    @include phone {
      margin-bottom: 40px;
    }
  }

  .section-title,
  .section-title-dark{
    font-size: 42px;

    @media screen and (max-width: 768px){
      font-size: 28px;
    }
    font-weight: 600 !important;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2.5px;
    color: black;
  }
  .section-title-dark{
    color: white;
  }
  .arrow-bouncing {
    position: fixed;
    bottom: 165px;
    right: 15px;
    z-index: 100;
    display: none !important;

    svg {
      @include keyframes(pulse) {
        0%,
        100% {
          top: -5px;
        }
        50% {
          top: 5px;
        }
      }
      @include animation("pulse 1.5s ease-in-out infinite");
      -webkit-filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.3));
      filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.3));
      position: relative;
    }
  }


  .microsite__container > #banner-promo{
    @media screen and (max-width: 768px){
      display: none;
    }
  }

  section#overview > section#banner-promo{
    @media screen and (max-width: 768px){
      display: block;
    }
  }

  .gallery-modal #slick-modal .slick-track {
    @include desktop-hd {
      height: 505px;
    }
    @include desktop {
      height: 505px;
    }
    @include tablet {
      height: 336px;
    }
    @include phone {
      height: 24.375vh;
    }
  }

  //microsite main
  @import "partials/microsite-carousel";
  @import "partials/subnav";
  @import "partials/overview";
  @import "partials/banner-promo";
  @import "partials/residences-homes";
  @import "partials/model-home";
  @import "partials/floorplans";
  @import "partials/image-gallery";
  @import "partials/local-amenities";
  @import "partials/location";
  @import "partials/contact-update";
  @import "partials/blog";

  //home show
  @import "partials/home-show-carousel";
  @import "partials/home-show-overview";
  @import "partials/home-show-explore";
  @import "partials/home-show-builders-webcam";
  @import "partials/home-show-our-partners";
  @import "partials/home-show-hours-and-directions";

  //coming soon
  @import "partials/microsite-coming-soon";

  @import "partials/disclaimer";
}

#microsite.has-announcements #microsite-carousel,
#microsite.has-announcements #home-show-carousel,
#microsite.has-announcements #hero {
  .btn-back {
    top: calc(var(--headerHeight) + 20px);
  }
  .signature-community {
    top: calc(var(--headerHeight) + 10px);
  }
  @include phone{
    .btn-back,
    .signature-community{
      margin-top: 55px;
    }
    .carousel {
      margin-top: calc(var(--headerHeight) + 50px);
    }
  }
  @media screen and (min-width: 768px) {
    .btn-back {
      top: calc(var(--headerHeight) + 30px);
    }
    .signature-community {
      top: calc(var(--headerHeight) + 10px);
    }
  }
}

#microsite.mobile-updates {
  // * { opacity: 1 !important; }

  .microsite__container {
    display: flex;
    flex-direction: column;

    @include phone {
      // #microsite-carousel {
      //   order: 0;
      // }
      // #banner-promo {
      //   order: 1;
      // }
      // #move-in-ready {
      //   order: 2;
      // }
      // #model-home {
      //   order: 3;
      // }
      // #directions-form {
      //   order: 4;
      // }
      // #floorplans {
      //   order: 5;
      // }
      // #image-gallery {
      //   order: 6;
      // }
      // #overview {
      //   order: 7;
      // }
      // #location {
      //   order: 8;
      // }
      // #contact {
      //   order: 9;
      // }
      // #contact-update {
      //   order: 10;
      // }

      #model-home {
        .card {
          background: transparent;
          border: 0;

          .card-wide {
            padding: 0 !important;
            border: 0;

            .top {
              background: white;
              border: 1px solid #ccc;
              max-width: 21em;
              margin: auto;

              .preloader {
                height: auto;
              }

              .thumb {
                height: 0;
                padding-top: 60%;
              }

              .info {
                width: 100%;
              }
            }

            .bottom {
              background: transparent;
              padding: 25px 0 0;

              img {
                margin: 25px -50px 0;
                display: block;
                width: calc(100% + 100px);
              }
            }
          }
        }
      }
    }
  }

  .microsite-subtitle {
    color: rgb(186, 31, 49);
    font-size: 400%;
    font-weight: 300;
    text-align: center;
    margin: 0.5em;
    text-transform: uppercase;
    @include tablet {
      font-size: 300%;
    }
    @include phone {
      font-size: 200%;
    }
  }

}

.tour-container,
.contact-form-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border-radius: 8px;
  padding: 24px 48px;

  h3.type-title-md{
    color: black;
    font-size: 1.25rem;
    letter-spacing: normal;
    line-height: normal;
    font-weight: 600;
  }
  
  p{
    line-height: normal;
    text-align: center;
  }
}
.tour-container{
  background-color: black;
  color: white;
  margin-bottom: 25px;

  @include phone{
    padding: 24px;

    button.btn-red-white{
      font-size: .875rem;
    }
  }

  h3{
    text-align: center;
  }
}
.contact-form-container{
  background-color: #dedede;
}
h2.type-title-md{
  color: black;
  font-size: 1.8rem;
  letter-spacing: 1px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 15px;
}
h3.type-text-md{
  font-weight: 600;
}
.contact-form-container > h3.type-text-md{
  color: black;
  text-align: center;
}

.contact-form-container > hubspot-form{
  width: 100%;
}

.contact-form-container > hubspot-form input{
  border-radius: 8px;
  margin: 0;
  text-align: center;
}

.contact-form-container > hubspot-form input::placeholder{
  color: black;
  font-weight: 600;
  font-size: .875rem;
  color: black;
  margin: 0;
  letter-spacing: normal;
  text-transform: capitalize;
}

.contact-form-container > hubspot-form input[type=submit]{
  margin: 0 auto;
  font-size: .875rem;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: normal;
  padding: 10px 64px;
  line-height: normal;

  @include phone{
    font-size: .75rem;
  }
}

.contact-form-container > hubspot-form div.input{
  margin: 10px 0;
}
button.btn.btn-schedule{
  border-radius: 8px;
  padding: 10px 64px;
}
button.btn.btn-schedule:hover,
button.btn.btn-schedule:active{
  color: black;
}
.disclosures > p{
  font-size: .5rem;
  text-align: center;
  color: black;
  padding-top: 15px;
}

.contact-form-container > hubspot-form ul.hs-error-msgs > li{
  margin-bottom: 10px !important;
  padding: 0;
  line-height: normal !important;
}

.contact-form-container > hubspot-form li.hs-form-booleancheckbox{
  line-height: normal !important;
  padding-left: 0;

  span{
    font-size: .65rem;
  }

  &::after{
    display: none;
  }
}

ul.hs-error-msgs > li::after{
  display: none;
}

.modal .modal-dialog .modal-content .modal-body{
  padding: 24px;
}

.modal-body.tour-a-model-home p.text-center{
  color: black;
  font-size: 1.8rem;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
}

.modal-body.tour-a-model-home form input[type=submit]{
  display: block;
  margin: 0 auto;
}
.tour-btn-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
}
.tour-btn-container button{
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
}
.tour-btn-container button:hover{
  color: black;
}
.modal-body .form-group input::placeholder{
  color: black;
}
.modal-body .form-group:nth-child(5){
  display: none !important;
}
.modal-body .form-group .checkbox-opt-in{
  text-align: center;
  color: black;
}

#microsite.has-announcements #microsite-carousel.microsite-carousel-coming-soon .row-top {
  padding-top: var(--headerHeight);
}
#microsite.has-announcements #microsite-carousel:not(.microsite-carousel-coming-soon),
#microsite.has-announcements #microsite-carousel.microsite-carousel-coming-soon {
  @media screen and (min-width: 768px) {
    padding-top: calc(var(--announcementHeight) + 64px);
  }
}
#microsite.has-announcements #mainHeader > .announcements{
  text-align: center;
}

.slick-microsite-modal {
  .image {
    height: 100%;
  }
}