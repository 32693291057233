
@media only screen and (min-width:  300px) { .large-footer { column-count: 1 } }
@media only screen and (min-width:  600px) { .large-footer { column-count: 2 } }
@media only screen and (min-width:  900px) { .large-footer { column-count: 3 } }
@media only screen and (min-width: 1200px) { .large-footer { column-count: 4 } }
@media only screen and (min-width: 1500px) { .large-footer { column-count: 5 } }
@media only screen and (min-width: 1800px) { .large-footer { column-count: 6 } }
@media only screen and (min-width: 2100px) { .large-footer { column-count: 7 } }
@media only screen and (min-width: 2500px) { .large-footer { column-count: 8 } }

.large-footer {
    background-color: #ba1f31;
    color: #f5f5f5;

    font-size: .9em;
    margin: auto;
    width: 100%;
}
.large-footer__region {
    width: 20em;
    padding: 1em 1em;
    break-inside: avoid;
    margin: auto;
}
.large-footer__region h4 {
    font-weight: 900;
    padding: 1em 0;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
    text-transform: uppercase;
    border-bottom: 2px ridge #ff566a;
}
.large-footer__region a {
    display: block;
    padding: .5em .25em;
    margin: 0 -.25em;
    color: #f5f5f5;
    line-height: 133%;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
    font-weight: bold;
}
.large-footer__region a:hover {
    background: #f5f5f5;
    color: #444;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.01);
}

.large-footer-gray {
    background: #444;
}
.large-footer-gray .large-footer__region h4 {
    border-bottom: 2px ridge #888;
}