section#local-amenities {
    @include inview(".map", static, 0s);
    @include inview(".info > *", right, .6s);
    .col-left,
    .col-left .info,
    .col-left:after {
        position: absolute;
        height: 100%;
        width: 40vw;
        max-width: 550px;
        padding: 60px 85px;
        top: 0;
        left: 0;
        @include desktop{
            padding: 60px;
        }
        @include tablet{
            padding: 60px 30px;
        }
        @include phone{
            height: auto;
            width: 100%;
            padding: 20px 0;
        }
    }
    .col-left .info {
        z-index: 2;
        background: white;
        p {
            font-weight: 300;
            margin-bottom: 10px;
            &:nth-of-type(2) {
                margin-bottom: 80px;
            }
        }
        li {
            @extend .type-lg;
            margin-bottom: 35px;
        }
        @include phone{
            ul{
                display: none;
                padding: 20px 20px 0;
                border-top: 1px solid #c3c3c3;
                margin-top: 20px;
                @include dropshadow(0px, 5px, 5px, 0, 0.2);
                position: absolute;
                background-color: white;
                left: 0;
                top: calc(100% - 20px);
            }
            ul li{
                font-size: 14px;
                margin-bottom: 30px;
            }
            p{
                font-size: 25px;
                margin-bottom: 5px;
                &:nth-of-type(2) {
                    font-size: 18px;
                    letter-spacing: 1.5px;
                    margin-bottom: 0;
                }
            }
        }
    }
    .col-left:after {
        content: "";
        background: -webkit-radial-gradient(56%,black,transparent, transparent);
        width: 100px;
        opacity: 0.3;
        left: auto;
        right: -40px;
        z-index: 1;
        @include phone{
            display: none;
        }
    }
    .arrow{
        display: none;
    }
    .map {
        position: relative;
        @include size(100%, 800px);
        width: 75vw;
        float: right;
        .amenities-map{
            position: relative;
            height: 100%;
        }
        .angular-google-map-container {
            height: 100%;
            position: absolute;
            width: 100%;

            .flex-container {
              padding: 15px;
              p {
                line-height: 30px;
                font-size: 15px;
                &.h5 {
                  font-size: 18px;
                  font-weight: 100;
                  margin-bottom: 15px;
                  border-bottom: 1px solid #d6d6d6;
                  padding-bottom: 10px;
                }
              }
            }
        }
        .pin {
            position: absolute;
            img {
                @include size(45px, auto);
            }
            + .popover {
                border-radius: 0;
                border: none;
                max-width: 435px;
                line-height: 30px;
                padding: 10px;
            }
        }
    }
    @include phone{
        .mobile-flex-wrap{
            @include flex-param(row, space-between, center);
            padding: 0 30px;
        }
        .arrow{
            display: inline-flex;
            svg{
                margin-left: 10px;
                @include transition(all .2s ease-in);
                @include rotate(0);
                @include svgcolor($gray);
            }
        }
        .map{        
            width: 100%;
            height: 130vw;
            padding: 0 30px 20px;
            overflow: hidden;
            position: relative;
            float: none;
            .angular-google-map-container {
                .flex-container p{
                  font-size: 14px;
                  line-height: 20px;
                }
                .flex-container p.h5 {
                  font-size: 16px;
                  line-height: 22px;
                }
            }
        }
        .info.open{
            .arrow{
                svg{
                    @include transition(all .2s ease-in);
                    @include rotate(180);
                }
            }
            ul{
                display: block;
                
                li{
                    margin-bottom: 20px;
                    float: left;
                    width: 50%;
                }
            }
        }
    }
}
