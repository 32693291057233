.theme-tabbed-header #hero-carousel{
	@extend %square-indicator-right;

	.flex-caption{
	    position: absolute;
	    color: white;
	    @include size(100%);
	    @include flex-param(column, center, center);
	}

	.bar-wrap{
		position: absolute;
	    @include size(100%, 100px);
	    z-index: 1;
	    bottom: 0;
	    background-color: rgba($gray,.7);
	    .scroll-down-arrow{
	    	bottom: 100%;
	    }
	    .scroll-down-arrow:before{
	    	background-color: rgba($gray,.7);
	    }
	}

	.carousel-indicators{
		bottom: 115px;
	}

	+ div{
	    z-index: 1;
	    position: relative;
	    margin-top: -75px;

		.nav.nav-tabs{
			overflow: hidden;
			border-bottom: transparent;
			@include flex-param(row, center, center);
			li a{
				height: 75px;
				padding: 0 60px;
				background-color: $light-gray;
				color: white;
				border-radius: 0;
				border-color: transparent;
				margin-right: 5px;
				cursor: pointer;
				text-align: center;
				@include flex-param(row, center, center);
				@include transition(all .2s ease-out);
				&:after{
					content: "";
					position: absolute;
					@include size(150%,80%);
					top: 100%;
					left: -25%;
					background-color: $lightest-gray;
				}
				@include desktop{
					padding: 0 40px;
				}
				@include tablet{
					padding: 0 18px;
				}
			}
			li.active a,
			li a:hover{
				color: $gray;
				background-color: $lightest-gray;
				@include transition(all .2s ease-in);
				@include dropshadow(0,0,45px,0,0.7);
			}

			@include phone{
				flex-direction: column;
				overflow: visible;
				position: relative;
				@include dropshadow(0px,2px,5px,0px,0.1);
				li,
				li a{
					width: 100%;
					margin: 0;
					height: 45px;
					padding: 0;
				}
				li.active{
					position: relative;
					z-index: 1;
				}
				li.active a{
					@include dropshadow(0px,0px,20px,0px,0.3);
				}
				li a{
					@include dropshadow(0px,0px,25px,0px,0.1);
				}
				li a:after{
					display: none;
				}
			}
		}

	    > .tab-content{
	    	background-color: $lightest-gray;
	    	.tab-pane{
	    		opacity: 0;
	    		@include transition(all .3s ease-out);
	    		&.active{
	    			opacity: 1;
	    			@include transition(all .3s ease-in);
	    		}
	    	}
	    }
	}
}