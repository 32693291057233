#buying-process{
	section#hero{
		background-color: $lightest-gray;
		padding-top: 100px;
		height: calc-min(140);
		> *{
			@include flex-param(row, flex-start, center);
		}
		.col-left{
			height: 100%;
			.content{
				padding-right: 500px;
				svg{
					@include size(25px);
					@include svgcolor(white);
				}
				p,
				h2{
					margin-bottom: 40px;
					&.type-md{
						line-height: 48px;
					}
					&:not(.type-md){
						font-weight: 300;
					}
				}
				h1{
					margin-bottom: 80px;
				}

				.btn.btn-blue span,
				.btn.btn-blue a{
					color: white;
					display: inline-flex;
					@include flex-param(row, center, center);
				}
				> *:not(.type-buying-process){
					opacity: 0;
				}
			}
		}

		@mixin mobileParam(){			
			height: auto;
			@include flex-param(column-reverse, flex-end, flex-end);
			.col-left{			
				height: auto;
				margin-bottom: 60px;
				.content{
					padding-right: 0;
				}
			}
		}

		@include laptop{
			.col-left{			
				@include transition(all .2s ease-out);
				@include scale(.8, left);
			}
			.col-left .content{
				padding-right: 400px;
			}
			.col-left .content h1{
				margin-bottom: 40px;
			}
		}

		@include desktop{
			.col-left .content{
				padding-right: 400px;
			}
		}

		@include tablet{
			@include mobileParam();
			padding-top: 110px;
		}
		
		@include phone{
			@include mobileParam();
			padding-top: 65px;
			> *{
				align-items: flex-end;
			}
			.col-left .content{
				p,h2{				
					margin-bottom: 20px;
					&.type-md{
						line-height: 28px;
					}
				}
				h1{
					margin-bottom: 40px;
				    font-size: 30px;
				    text-align: center;
				}
				h2{
					font-size: 20px;
					line-height: 25px;
					span{
					    display: inline-block;
					    float: left;
					    height: 40px;
					    margin-right: 10px;
					}
				}
			}
		}

		&.in .col-left .content > *:not(.type-buying-process){
			@include transition(all .2s ease-in);
			opacity: 1;
		}

		.col-right,
		.col-right .image{			
			position: absolute;
			top: 0;
			right: 0;
			@include tablet{position: relative;}
			@include phone{position: relative;}
		}

		.col-right{
			background-color: white;
			overflow: hidden;
			@include size(calc(50% - 100px), 100%);
			.image{			
			    background-size: cover;
			    background-position: right 100px;
			    background-repeat: no-repeat;
			    opacity: 0;
			    @include size(100%);
			    @include transition(all 1s ease-in);
			    @include scale(1.05);
			    &.active{
			    	@include transition(all 1s ease-out);
			    	@include scale(1);
			    	opacity: 1;
			    }
			}
			ul{
				padding-top: 100px;
				li{
					background-color: $gray;
					color: white;
					border-radius: 0 50px 50px 0;
					position: relative;
					font-size: 25px;
					@include size(130px, 60px);
					@include flex-param(row, center, center);
					font-weight: 300;
					@include hoverstate($red, white, .2s);
					&:not(:last-child){
						margin-bottom: 10px;
					}
					&.active{
						background-color: $red;
					}
				}
			}

			@mixin mobileParam($size,$height){
				width: 100%;
				height: $height;
				overflow: hidden;
				margin-bottom: 60px;
				.image{
					position: absolute;
					background-position: center;
				}
				ul{
					padding-top: 0;
				    @include scale($size,left);
				}
			}
			@include tablet{@include mobileParam(0.7,45%);}
			@include phone{
				height: 250px;
				ul{
				    width: 100%;
				    text-align: center;
				}
				ul li{
					border-radius: 25px 25px 0 0;
					width: 60px;
					height: 45px;
				    padding: 10px;
					display: inline-block;
				    text-align: center;
					&:not(:last-child){
						margin-bottom: 0px;
					}
				}
				@include mobileParam(1,250px);
			}
		}

	}
}