body#forgot-password {
    height: auto;
}

div#forgot-password-view {
    margin-top: var(--headerHeight);
}

section#container-forgot-password {
    position: relative;
    background-image: url('/images/site/login/hero-login.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 79vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(177, 177, 177, 0.70);
        z-index: 1;
    }

    .forgot-password__card {
        position: relative;
        width: 350px;
        background-color: #ffffff;
        padding: 40px 30px;
        z-index: 2;

        button {
            width: 100%;
            margin: 15px 0;
        }

        h3 {
            color: #000000;
            font-size: 24px;
            text-align: center;
            font-weight: bold;
        }

        .forgot-password-form__section {
            padding: 20px 0;

            label {
                text-align: center;
                color: #000000;
                padding: 0 0 10px 0;
            }

            .line {
                width: 100%;
                height: 1px;
                margin: 20px 0;
                background-color: var(--Medium-Gray, #868686);
            }

            .section-account {
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;

                a {
                    font-size: 16px;
                    text-decoration: underline;
                    color: var(--Black, #0C0C0C);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 32px;
                    letter-spacing: 0.32px;
                }

                p {
                    margin-bottom: 0;
                }
            }

        }
    }



}


body#reset-password {
    height: auto;
}

div#reset-password-view {
    margin-top: var(--headerHeight);
}

section#container-reset-password {
    position: relative;
    background-image: url('/images/site/login/hero-login.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 79vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(177, 177, 177, 0.70);
        z-index: 1;
    }

    .reset-password__card {
        position: relative;
        width: 350px;
        background-color: #ffffff;
        padding: 40px 30px;
        z-index: 2;

        button {
            width: 100%;
            margin: 15px 0;
        }

        h3 {
            color: #000000;
            font-size: 24px;
            text-align: center;
            font-weight: bold;
        }

        .reset-password-form__section {
            padding: 20px 0;

            label {
                text-align: center;
                color: #000000;
                padding: 10px 0;
            }

            .line {
                width: 100%;
                height: 1px;
                margin: 20px 0;
                background-color: var(--Medium-Gray, #868686);
            }

            .section-account {
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;

                a {
                    font-size: 16px;
                    text-decoration: underline;
                    color: var(--Black, #0C0C0C);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 32px;
                    letter-spacing: 0.32px;
                }

                p {
                    margin-bottom: 0;
                }
            }

        }
    }

    .login__modal-overlay {

        position: fixed;
        display: flex !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.9);
        justify-content: center;
        align-items: center;
        z-index: 200;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease-out, visibility 0.3s ease-out;

        &.show {
            visibility: visible;
            opacity: 1;

            .login__card {
                transform: translateY(50px);
            }
        }

        .login__card {
            border-radius: 8px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            transform: translateY(-60px);
            transition: transform 0.3s ease-out;

            .close-login {
                display: flex;
                width: 100%;
                justify-content: flex-end;
            }
        }
    }

}

.login__modal-overlay {

    position: fixed;
    display: flex !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: center;
    z-index: 200;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out;

    &.show {
        visibility: visible;
        opacity: 1;

        .login__card {
            transform: translateY(50px);
        }
    }

    .login__card {
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        transform: translateY(-60px);
        transition: transform 0.3s ease-out;

        .close-login {
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }
    }
}