#site-map{
	section#hero{
		height: 420px;
		background-color: $red;
	    padding-top: 100px;
	    color: white;
	    @include flex-param(row, center, center);
		.type-subtitle-xl{
			font-weight: 300;
		}
		@include phone{
			padding-top: 60px;
			height: 300px;
			text-align: center;
		}
	}
	section#content{
		padding-top: 50px;
	    background-color: $lightest-gray;

	    .type-title-xl{
	    	margin-bottom: 140px;
	    	font-weight: 300;
	    }

	    h3{
	    	margin-bottom: 20px;
	    }

	    .row > div[class^="col-"]{
	    	margin-bottom: 30px;
	    }

	    .col-count-4{
	    	column-count: 4;
	    }

	    .col-count-3{
	    	column-count: 3;
	    }

	    @include phone{
		    .col-count-4{
		    	column-count: 2;
		    }

		    .col-count-3{
		    	column-count: 1;
		    }
	    }

	    li{
	    	line-height: 30px;
	    	> ul{
	    		margin-left: 30px;
	    	}
	    }

	    a{
	    	color: $gray;
	    	@include hoverstate(transparent, $red, .2s);
	    	text-transform: capitalize;
	    }

	    .flex-wrap{
	    	justify-content: space-between;
	    }

	    .site-map-wrap > div{
	    	margin-bottom: 100px;
	    }

	}
}