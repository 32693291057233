.modal{
	.modal-dialog .modal-content{
		border-radius: 0;
		background-color: white;

		> *{
			border: none;
		}

		.modal-header{
			text-align: right;
			padding: 0;
			.btn-close{
			    border: none;
			    padding: 5px 10px;
			    background-color: darken(white, 5%);
			    border-radius: 0;
			    color: black;
			}
		}

		.modal-body{
			padding: 0 30px;
		}

		.modal-footer{
			color: black;
			padding: 15px 30px;
			@include flex-param(row, space-between, center);
			&:after,
			&:before{
				display: none;
			}
			.social{
				@include flex-param(row, space-between, center);
			}
			svg{
				@include size(20px);
				@include svghover(black, black, .2s);
				&.icon-pinterest{
					@include size(35px);
				}
				&.icon-twitter{
					margin-left: 10px;
				}
			}
		}
	}
	.modal-dialog.modal-xl{
		width: 90%;
		height: 90%;
		.modal-content,
		.modal-body{
			height: 100%;
		}
		iframe{
   			height: calc(100% - 30px);
		}
	}
}

%modal-them-base{
	color: black;
	padding: 30px 50px;
	@include phone{
		padding: 30px 35px;
	}
	&:after{
		 @extend %clearfix;
	}
	p{
		margin-bottom: 40px;
	}
	p.type-sm,
	p.type-md{
		line-height: 30px;
	}
	a{
		color: $red;
	}
	input.btn.btn-orange{
		margin-bottom: 20px;
		float: right;
	}
	input[type="radio"]{
		width: 25px;
		margin: 0 2px 0 0;
		&:not(:first-child){
			margin-left: 15px;
		}
	}
	label{
		margin-bottom: 10px;
	}
}

.modal #modal-body.vendor-signup
{
	@extend %modal-them-base;
	.flex-wrap{
		justify-content: space-between;
		margin-bottom: 25px;
	}
	.flex-wrap .form-group{
		width: 31%;
	}
}

.modal #modal-body.mortgage-calculator,
.modal #modal-body.earning-calculator,
.modal #modal-body.prequalify,
.modal #modal-body.web-view{
	padding-bottom: 30px;
	iframe{
		background-color: white;
		position: relative;
		z-index: 1;
		opacity: 1;
		width: 100%;
		height: 470px;
		@include phone{
			width: 300px;
		}
	}
}

.modal #modal-body.no-padding{
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
}

.modal .modal-header.homeowner-login{
	background-color: white;
}

.modal #modal-body.homeowner-login{
	@include homeowner-login();
	padding: 0;
	.flex-wrap{
		flex-direction: column;
	}
	.title-wrap{
		padding: 20px;
	}
	.title-wrap p.type-title-md{
		font-size: 8vw;
		letter-spacing: 0.5px;
		text-align: center;
	}
	.title-wrap p.type-sm{
		display: none;
	}
	.title-wrap .flex-wrap > *{
		margin-right: 0;
	}
	.title-wrap form{
		align-items: center;
		.password-group{
			margin-top: 20px;
			display: flex;
		    flex-direction: column;
		    width: 100%;
		    a{
		    	text-align: center;
		    	margin-bottom: 20px;
		    	color: $red;
		    }
		}
		.login-group{
			width: 120px;
			svg{
				top: 8px;
				width: 20px;
			}
			input{
				font-size: 15px;
				padding-left: 40px;
				width: auto;
			}
		}
	}
	> div{
		background-color: white;
	}
	.login-panel .col-left{
		.top,
		.mid{
			color: black;
		    padding: 20px;
		}

		.type-subtitle-md{
			line-height: 30px;
		}

		.bottom{
			padding: 20px 20px 0;
			a{
				color: $red;
			}
		}
	}
	.login-panel .col-right{
		padding: 20px;
	}
	.forgot-password > .title-wrap .flex-wrap{
		align-items: center;
	}
	.forgot-password > .title-wrap .btn.btn-gray{
		flex-direction: row-reverse;
		svg{
			margin: 0 5px 0 0;
			@include rotate(180);
		}
	}
	.forgot-password > .flex-wrap{
		flex-direction: column;
		align-items: center;
		span{
			color: black;
		}
		input:nth-child(2){
			margin: 20px 0;
		}
	}
}

.modal #modal-body.subcontractors-and-suppliers,
.modal #modal-body.land-to-sell,
.modal #modal-body.buy-now {
	@extend %modal-them-base;

    @include form-results-color(black);

    form > .flex-wrap,
    .location.flex-wrap{
        justify-content: space-between;
    }
    form > .flex-wrap{
        @include validation-white;
		margin-bottom: 30px;

        .form-group {
            margin-bottom: 20px;
        }
	}
	textarea{
		height: 110px;
		resize: none;
	}
	.checkbox-group.flex-wrap{
		align-items: center;
	}
	.location.flex-wrap > div{
		width: splitwidth(3,10);
	}
	.col-left,
	.col-right{
		width: splitwidth(2,20);
	}
	a{
		color: $red;
		@include hoverstate(transparent,black,.2s);
	}
}

.modal #modal-body.land-to-sell {
  label {
    font-size: 15px;
  }
  textarea {
    height: 70px;
  }
}


.modal #modal-body.warranty-service-question,
.modal #modal-body.compact,
.modal #modal-body.text-phone,
.modal #modal-body.regional{
	@extend %modal-them-base;
}

.modal-compact{
	max-width: 455px;

}
.modal.modal-testimonial {
  .modal-dialog .modal-content {
    background-color: #fff;
    .modal-header .btn-close {
      background-color: transparent;
      color: #000;
      font-size: 30px;
    }
    .modal-body {
      @include flex-param($dir: column, $justify: flex-start, $align: flex-start);
      gap: 22.5px;
      padding-bottom: 45px;
      padding-left: 45px;
      padding-right: 45px;
      position: relative;
      @include desktop {
        padding-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
      }
      @include tablet {
        gap: 15px;
        padding-bottom: 22.5px;
        padding-left: 22.5px;
        padding-right: 22.5px;
      }
      @include phone {
        gap: 15px;
        padding-bottom: 22.5px;
        padding-left: 22.5px;
        padding-right: 22.5px;
      }
      &:before,
      &:after {
        position: absolute;
      }
      &:before {
        content: url('data:image/svg+xml; utf8, <svg class="icons-quote-left" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" width="75" height="75" viewBox="0 0 354.9 329.8" enable-background="new 0 0 354.896 329.807" xml:space="preserve"><path fill="rgba(127, 127, 127, 0.15)" fill-rule="evenodd" clip-rule="evenodd" d="M0 138c0.7 8.9 1.3 17.9 2.1 26.8 2.3 25.6 5.4 51.1 16.4 74.6 17 36.6 44.6 63.4 79.9 82.4 4.7 2.5 9.5 4.8 14.6 7.4 10.1-16.3 20-32.3 29.5-47.7 -10-6.4-20.1-11.9-29.1-18.8 -16.3-12.5-26.6-29.4-33.7-48.5 -7.8-21.2-11.8-53.4-11.5-70.7 21 0 42.1 0 63.8 0 0-48.3 0-95.9 0-143.5C88 0 44 0 0 0 0 46 0 92 0 138zM212 0c0.1 2 0.2 4 0.2 6 0 39.5-0.4 79 0.1 118.4 0.4 29.3 2.3 58.5 9 87.2 9.9 42.1 34.8 73.8 69.7 98.1 10.6 7.4 22.2 13.3 33.7 20.1 10.5-16.9 20.4-32.9 30.1-48.6 -8.9-5.4-17.8-10-25.8-15.8 -23.3-16.9-35.5-40.8-42.2-68 -4.3-17.5-6.1-35.3-6.9-53.8 2.3-0.1 4.2-0.3 6.1-0.3 17.8 0 35.6-0.1 53.5 0 3.3 0 4.7-0.8 4.7-4.5 -0.1-45.6-0.1-91.3-0.1-136.9 0-0.7-0.2-1.3-0.3-2C300 0 256 0 212 0z"/></svg>');
        top: -15px;
        left: 45px;
        @include desktop {
          left: 30px;
        }
        @include tablet {
          left: 22.5px;
        }
        @include phone {
          left: 22.5px;
        }
      }
      &:after {
        content: url('data:image/svg+xml; utf8, <svg class="icons-quote-right" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" width="75" height="75" viewBox="0 0 354.9 329.8" enable-background="new 0 0 354.896 329.807" xml:space="preserve"><path fill="rgba(127, 127, 127, 0.15)" fill-rule="evenodd" clip-rule="evenodd" d="M354.9 138c-0.7 8.9-1.3 17.9-2.1 26.8 -2.3 25.6-5.4 51.1-16.4 74.6 -17 36.6-44.6 63.4-79.9 82.4 -4.7 2.5-9.5 4.8-14.6 7.4 -10.1-16.3-20-32.3-29.5-47.7 10-6.4 20.1-11.9 29.1-18.8 16.3-12.5 26.6-29.4 33.7-48.5 7.8-21.2 11.8-53.4 11.5-70.7 -21 0-42.1 0-63.8 0 0-48.3 0-95.9 0-143.5 44 0 88 0 132 0C354.9 46 354.9 92 354.9 138zM142.9 0c-0.1 2-0.2 4-0.2 6 0 39.5 0.4 79-0.1 118.4 -0.4 29.3-2.3 58.5-9 87.2 -9.9 42.1-34.8 73.8-69.7 98.1 -10.6 7.4-22.2 13.3-33.7 20.1C19.6 312.9 9.7 296.9 0 281.2c8.9-5.4 17.8-10 25.8-15.8 23.3-16.9 35.5-40.8 42.2-68 4.3-17.5 6.1-35.3 6.9-53.8 -2.3-0.1-4.2-0.3-6.1-0.3 -17.8 0-35.6-0.1-53.5 0 -3.3 0-4.7-0.8-4.7-4.5 0.1-45.6 0.1-91.3 0.1-136.9 0-0.7 0.2-1.3 0.3-2C54.9 0 98.9 0 142.9 0z"/></svg>');
        bottom: 30px;
        right: 45px;
        @include desktop {
          bottom: 15px;
          right: 30px;
        }
        @include tablet {
          bottom: 7.5px;
          right: 22.5px;
        }
        @include phone {
          bottom: 7.5px;
          right: 22.5px;
        }
      }
    }
  }
  p, .quote {
    color: #000;
  }
  p {
    &.author {
      font-size: 16.5px;
      @include phone { font-size: 13.5px; }
    }
  }
  .quote {
    p, span {
      font-size: 21px;
      line-height: 37.5px;
      @include desktop {
        font-size: 18px;
        line-height: 30px;
      }
      @include tablet {
        font-size: 16.5px;
        line-height: 27px;
      }
      @include phone {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
}

.modal #modal-body.regional{
	line-height: 30px;
}

.modal #modal-body.text-phone{
	padding: 0 20px 20px;

	.title .type-title-md{
		font-size: 27px;
		letter-spacing: 0;
		line-height: 35px;
		&:first-child {
		  display: none;
		}
	}

	.title .type-sm,
	.bottom-content img,
	.bottom-content p{
		display: none;
	}

	.input-group{
		width: 100%;
		.form-component:not(:last-child){
			margin-bottom: 10px;
		}
	}

	.bottom-content{
		text-align: center;
		button[type="submit"] {
		  margin-bottom: 15px;
		  .fa {
        margin-left: 15px;
        margin-right: 0;
      }
    }
	}

	.contact-info{
		display: flex;
	    border-top: 1px solid rgba(white,.2);
	    padding-top: 20px;
		p{margin: 20px 5px;}
		a{
			width: 100%;
			color: $red;
			padding: 10px 15px;
			&:hover,
			&:focus{
				color: $red;
			}
		}
	}

	form .form-component{
		margin-bottom: 20px;
	}
}

.modal #modal-body.compact{
	padding-bottom: 40px;
	p{
		margin-bottom: 30px;
	}
	.flex-wrap{
		@include flex-param(row, space-between, center);
		.btn-group{
    		flex: .7 0 auto;
		}
		button.btn{
		    padding: 11px 20px;
		    border-radius: 5px;
			span{width: 45px;}
			svg{@include size(18px);}
		}
		.dropdown-menu {
		  @include phone {
		    min-width: initial;
      }
		  li a {
		    color: $gray;
		    @include hoverstate($red, white, .2s);
      }
		}
		input,
		.submit{
			padding: 10px 20px;
		}
		.submit{
			color: $red;
			&:hover{
				color: white;
			}
		}
	}
	@include phone{	
		&.regional .btn-group.dropdown{
			width: 100%;
		}
	}
}

.modal-450 {
  width: 450px;

  @include phone {
    width: calc(100% - 20px);
  }
}

.modal #modal-body.we-are-here-to-help {
  @extend %modal-them-base;

  @include form-results-color(white);

  p {
    margin-bottom: 15px;
  }

  .call-or-text {
    background-color: $red;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: -50px;
    margin-right: -50px;
    padding-top: 15px;
    padding-bottom: 15px;

    @include phone {
      margin-left: -35px;
      margin-right: -35px;
    }

    .fa {
      margin-right: 10px;
    }
  }

  form {
    @include validation-white;

    input.btn.btn-orange {
      width: 100%;
    }
  }
}

// buy now modal
.modal #modal-body.buy-now {
	form > .flex-wrap {
	  margin-bottom: 0;
		@include phone {
			flex-wrap: wrap;
			margin-bottom: 5px;
		}
	}
	input.btn.btn-orange {
	  margin-top: 30px;
		@include phone {
			width: 100%;
		}
	}
	p {
		margin-bottom: 15px;
	}
	.col-left, .col-right {
		@include phone {
			width: 100%;
		}
	}
}

.modal .modal-dialog.modal-tour-a-model-home
{
  width: 900px;
  @include tablet {
    width: 720px;
  }
  @include phone {
    width: auto;
  }
  .modal-content {
    @include background-image('/images/site/virtual-tours/tour-a-model-home.jpg', cover, center);

    .modal-header .btn-close {
      position: absolute;
      right: 0;
      z-index: 1;
    }
    #modal-body.tour-a-model-home {
      padding-left: 270px;
      padding-right: 270px;
      @include tablet {
        padding-left: 180px;
        padding-right: 180px;
      }
      @include phone {
        padding-left: 30px;
        padding-right: 30px;
      }

      > div {
        @extend %modal-them-base;
        @include form-results-color(white);
        background-color: rgba($red,0.75);
        @include phone {
          padding-left: 15px;
          padding-right: 15px;
        }

        p {
          margin-bottom: 15px;
        }

        form {
          @include validation-white;

          .checkbox-opt-in label {
            font-size: 12px;
          }

          input.btn.btn-orange {
            margin-bottom: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
.modal .modal-dialog .modal-content{
	.modal-header.schedule-a-tour{
		background-color: black;

		button.btn-close{
			background-color: black;
			color: $light-gray;
		}
	}
	.modal-body.schedule-a-tour{
		background-color: black;
		padding-top: 0;

		h2{
			margin-bottom: 15px;
		}

		p.type-lg{
			margin-bottom: 25px;
		}

		h2, 
		p.type-lg{
			color: white;
			text-align: center;
		}

		>div{
			width: 100%;
			color: black;

			fh-form-schedule-my-tour{
				max-width: 350px;
				margin: 0 auto;
				display: block;
			}

			form{
				td.uib-day button.btn{
					padding: 5px 7px;
					margin: 0;

					&.active{
							background-color: $red;

							>span{
									color: white;
							}
					}
				}

				.datepicker-wrapper > input[type=text],
				select{
					cursor: pointer;
				}            

				.form-components .scheduled{
					margin-top: 5px;
					float: unset;

					>li:first-child{
						display: none;
					}
				
					>li:nth-child(2){
						font-size:1.2rem;
						text-align: center;
						display: block;
						>span.edit-btn{
							display: inline-block;
							font-size: .9rem;
						}
					}

					@include phone{
						font-size: 1rem;

						>span.edit-btn{
							font-size: .75rem;
						}
					}
				}

				.input-group.button-flex > div:nth-child(3) > p:last-child{
					margin-top: 8px;
				}

				.input-group.form-components{
					width: 100%;
					max-width: 350px;
					display: block;
					margin: 15px 0;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 15px;

					> .form-component{
						flex: 1;
					}

					input,
					select,
					.form-component{
						height: 45px;
						color: black;
						font-size: 1rem;
						font-weight: 400;
						text-transform: capitalize;

						@include phone{
							font-size: .875rem;
						}


						>i.fa-angle-down{
							top: 50%;
							transform: translateY(-50%);
							color: black;
						}
					}

					select:disabled{
						opacity: 1;
						cursor: not-allowed;
						font-weight: 600;
					}

					input,
					select,
					input::placeholder,
					select::placeholder,
					select > option{
						color: black;
						font-size: 1rem;
						text-align: center;
						font-weight: 600;
						border-radius: 8px !important;
						border-color: #aaa;

						@include phone{
							font-size: .875rem;
						}
					}

					select.form-control:focus{
						border-color: #999;
						box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
					}

					.uib-datepicker-popup{
						left: 50% !important;
						transform: translateX(-50%);

						@include phone{
							left: unset !important;
							transform: unset;
						}
					}

					ul.list-unstyled.scheduled{

						@include phone{
							width: 200px;
						}
						>li:first-child{
							display: none;
						}
						>li:nth-child(2){
							margin-bottom: 10px;
							font-size: 1.25rem;

							@include phone{
								font-size: 1rem;
							}

							>span.edit-btn{
								font-size: .9rem;
								font-weight: 600;
								cursor: pointer;
							}
						}
						>li{
							color: white;
						}
					}
				}

				> .input-group.form-components:nth-child(4){
					display: grid;
					grid-template-columns: 1fr 1fr;

					.form-component > input{
						text-align: left;

						&::placeholder{
							text-align: left;
						}
					}

					@include phone{
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;

						.form-component > input{
							text-align: center;

							&::placeholder{
								text-align: center;
							}
						}
					}
				}

				.input-group.button-flex{
					width: 100%;
				}

				.btn-group.dropdown{
					width: 100%;
					max-width: 350px;

					@include phone{
						max-width: 200px;
					}
				}

				button.btn-dropdown svg{
					width: 15px;
					height: 15px;
				}

				.btn-group button.btn-dropdown{
					background-color: $lighter-gray;
					color: black;
					padding: 10px;
					max-width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border-radius: 8px;
					color: black;
					font-weight: 600;

					@include phone{
						width: 100%;
						padding: 7px 10px;
					}
				}

				.dropdown button svg{
					@include phone{
						position: absolute;
						right: 10px;
					}
				}
				
				.dropdown button svg g path{
					fill: #000 !important;
				}

				.btn-wrap > p:last-child > a{
					margin-top: 15px;
				}

			}

			.btn-wrap{
				width: 100%;

				.btn{
					background-color: $red;
					color: white;
					transition: all .2s ease-in-out;
					margin: 15px auto 0;
					width: fit-content;
					border-radius: 8px;
					padding: 10px 64px;
					display: block;

					&:active,
					&:hover,
					&:focus{
						background-color: white;
						color: black;
					}
				}

				div.checkbox-opt-in{
					display: flex;
					justify-content: center;

					> label{
						color: white;
						font-size: .75rem;
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 6px;

						@include phone{
							font-size: .67rem;
						}

						&:first-child{
							margin-bottom: 7px;
						}

						input[type="checkbox"]{
							margin: 0;
							padding: 0;
							min-width: 25px;
						}
					}
				}
				p{
					text-align: center;
					
					> a { 
						color: white !important; 
					}
				}
				button.btn,
				input[type=submit]{
					font-size: .875rem;
				}
			}

			.form-results-container{
				background-color: unset;
				border: none;
			}
		}
		.tour-confirmation{
			margin: 15px auto;
			p{
				color: white;
				line-height: normal;
			}
		}
		.btn-group.dropdown{
			width: 100%;
			max-width: 350px;
			button.btn.btn-dropdown{
				width: 100%;

				svg{
					width: 15px;
					height: 15px;
				}

				svg path{
					fill: black;
				}
			}
		}
	}
}