#subnav {
	@extend %navreset;
	z-index: 100;

	@include phone{
		background-color: transparent;
		position: fixed;
		top: var(--headerHeight);
		width: 100%;
		left: 0;
		height: unset !important;
	}

	#subnav-toggle {
		text-align: center;

		ul {
			float: none;
			@include flex-param(row, center, center);

			li a {
				@extend .type-sm;
				padding: 22px;
				display: block;
				text-transform: uppercase;
				@include transition(all .2s ease-in);
				@include tablet{
					padding: 15px;
					font-size: 14px;
				}
			}

			li.active a,
			li:hover a {
				@include transition(all .2s ease-in);
				background-color: $red;
				color: white;
			}

		}
		@include phone{
			padding: 0;
			ul{			
				background: $gray;
				flex-direction: column;
				align-items: inherit;
				margin: 0;
				li a{
					padding: 8px 15px;
					color: white;
				}
			}
		}

	}

	.container-inner{
		background: white;
		width: 100%;
		@include phone{
			position: relative;
			top: 0;
			@include transition(all .3s ease-out);
			@include dropshadow(0px, 1px, 5px, 0, 0.3);
			&.down{
				@include transition(all .3s ease-in);
				top: -65px;
			}
		}
	}

	&.affixed .container-inner {
		@mixin param(){
			perspective: 2000px;
			position: fixed;
			top: calc(var(--headerHeight) - 1px);
		}
		@include desktop-hd{@include param()}
		@include desktop{@include param()}
		@include tablet{@include param()}
	}

	@include phone{
		@include navtogglestyle($gray);
		max-height: 55px;
		.navbar-toggle{
			right: 15px;
		}
		.navbar-header{
			height: 50px;
			@include flex-param(row, space-between, center);
			p{
				margin-left: 15px;
			}
			&:before,
			&:after{
				display: none;
			}
		}
	}

	#subnav-mobile{

		@media screen and (max-width: 200px){
			display: none !important;
		}

			padding: 0;
			border: 0; 
			min-height: unset;
			margin-bottom: unset;

			ul{			
				align-items: inherit;
				margin: 0;
				li a{
					padding: 15px;
					color: black;
				}
				@media screen and (max-width: 350px){
					li a {
						padding: 8px 15px;
					}
				}
				li.active a{
					@include transition(all .2s ease-in);
					background-color: white;
					color: $red;
				}
			}
		
		.nav.navbar-nav{
			display: flex;
			align-items: center;
			justify-content: center;
			top: 0;
			bottom: 0;
			min-width: 100%;
			flex-wrap: wrap;

			li{
				height: 100%;
				a{
					height: 100%;
					display: flex;
					align-items: center;
				}
			}
		}
	}
	@include phone{
		div,
		ul{
			height: 100%;
		}
	}
}