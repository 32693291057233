section#location {
  // @extend %section-padding;
  @extend %microsite-title;
  background-color: white;
  padding-top: 75px;
  @include phone {
    padding-top: 60px;
  }
  // @include inview(".type-subtitle-xl", static, 0s);
  // @include inview(".contact-info", bottom, .6s);
  // @include inview(".inline-get-fast-answers", static, .8s);
  // @include inview(".driving-directions", bottom, 1s);

  > .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    justify-items: center;
  }

  .type-subtitle-xl,
  .microsite-title {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 0.4em;
  }

  .map {
    width: 100%;
    height: 600px;

    @include phone {
      height: 60vh;
    }
  }
  .angular-google-map,
  .angular-google-map-container {
    width: 100%;
    height: 100%;
  }
  .gm-style .gm-style-iw-c {
    box-shadow: none;
    @include phone {
      min-width: 324px;
    }
    button {
      display: none !important;
    }
  }
  .gm-style .gm-style-iw-tc {
    width: 30px;
    height: 30px;
    &::after {
      background: url('/images/site/microsite/map-pin-contact.png');
      clip-path: none;
      width: 30px;
      height: 30px;
      top: -11px;
    }
  }

  .contact-popup-window {
    position: relative;
    text-align: center;
    width: 270px;
    @include phone {
      width: 210px;
    }

    .name {
      @include font($open-sans, 300);
      color: darken($gray, 21%);
      font-size: 21px;
      @include phone {
        font-size: 15px;
      }
    }
  }
}
