#prequalify{
	section#hero{
		@extend %theme-large-heading;
		@include background-image('/images/site/prequalify/hero-bkg.jpg', cover, center);
		color: white;
		.type-md{
			margin: 0;
			b{
				font-weight: 700;
				font-style: italic;
			}
		}
		@include phone{
			p{
				text-align: center;
			}
			.type-prequalify-lg{
			    font-size: 10vw;
			    line-height: 12vw;
			}
			p.type-md{
				padding: 0 3vw;
				max-width: 350px;
			}
		}
	}

	section#content{
		background-color: $lightest-gray;
		padding: 90px 0;
		label{
			margin-bottom: 10px;
			@extend .type-xs;
		}
		input{
			border: none;
		}
		.col-right{
			padding-left: 50px;
			p.type-xs{
				line-height: 26px;
			}
			input.btn{
				float: right;
				margin-top: 80px;
			}
			custom-checkbox{
				padding-top: 8px;
			}
		}

        .form-component {
            margin-bottom: 50px;

            .help-block {
                bottom: -54px;
            }

			i.fa {
				top: 100%;
			}
        }

		@include phone{
			padding: 50px 0 90px;
			.col-right{
				padding-left: 15px;
			}

			.col-right input.btn{
			    margin-top: 20px;
			    float: none;
			    width: 150px;
			    padding: 15px;
			    font-size: 16px;
			    margin-left: calc(50% - 75px);
			}
		}
	}
}