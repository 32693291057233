#microsite-floorplan-detail {
    @import "partials/subnav";
    #hero {
        overflow: initial;
        .row-top {
            height: auto;
            @include phone {
              padding-top: 110px;
            }
            .container-wrap {
                #subnav {
                    width: 100%;
                }
                .row {
                    padding: 40px 20px;
                    margin: 0;
                    .container{
                        @include flex-param(row, space-between, center);
                        &:before,
                        &:after{
                            display: none;
                        }
                    }
                    .btn-gray {
                        text-transform: uppercase;
                    }
                    .btn-group.dropdown {
                        float: right;
                        text-transform: uppercase;
                        button {
                            text-align: right;
                            background: none;
                        }
                    }
                    .btn.btn-primary.btn-dropdown{
                        width: auto;
                        float: none;
                    }
                    .btn-group.open .dropdown-toggle,
                    .btn-group.open .dropdown-toggle:focus,
                    .btn-group.open .dropdown-toggle:hover{
                        color: $gray;
                        box-shadow: none;
                    }
                    @include phone{
                        padding: 0 30px;
                        .container{
                            flex-direction: column;
                            padding: 0;
                        }
                        .btn.btn-gray{
                            padding: 10px 15px;
                        }
                        .btn.btn-gray svg{
                            margin-right: 5px;
                        }
                        .btn-group.dropdown button{
                            padding: 10px;
                            &:active{
                                box-shadow: none;
                            }
                        }
                        .btn-group.dropdown-menu{
                            top: 90%;
                        }
                    }
                }
            }
        }
    }
}
