section#image-gallery{
	@extend %section-padding;
	@extend %microsite-title;
	background-color: white;
	padding-bottom: 0;
	@include phone{
		padding-bottom: 80px;
	}
	@include inview(".type-subtitle-xl", static, 0s);
	@include inview(".container", bottom, .6s);
	.nav-controls{
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		top: -75px;
		left: 15px;
		width: calc(100% - 30px);

		.nav-buttons{
			display: flex;
			align-items: center;
			justify-content: flex-start;
	
			.img-gallery-prev::after,
			.img-gallery-prev-xs::after{
				content: ">";
				display: block;
			}

			.img-gallery-next::after,
			.img-gallery-next-xs::after{
				content: "<";
				display: block;
			}

			.gallery-btn{
				display: block;
				color: black;
				background-color: white;
				border-radius: 50%;
				height: 50px;
				width: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				margin: 5px;
			}
		}


		&.visible-xs{
			top: -200px;
			.nav-buttons{
				width: 100%;
				justify-content: space-between;
			}
		}
	}
	.nav-position{
		.nav-position-numbers{
			background-color: rgba(65,65,65,.75);
			color: white;
			text-align: center;
			padding: 10px 15px;
			letter-spacing: 2px;
			font-size: 1.25rem;
			border-radius: 20px;
			font-weight: 600;
		}

		&.visible-xs{
			position: relative;
			top: -340px;
			.nav-position-container{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				padding-right: 15px;
			}
			.nav-position-numbers{
				width: fit-content;
				font-size: 14px;
			}
		}


	}
	.carousel-row,
	.carousel-row .main-img{
		height: 420px;

		@media screen and (max-width: 1200px){
			height: 360px;
		}
	}
	.carousel-row{
		width: 95%;
		max-width: 1200px;
		margin: 0 auto;

		@media screen and (max-width: 768px){
			width: 100%;
		}
		.col-sm-6:nth-child(1){
			@media screen and (max-width: 768px){
				padding: 0;
			}
		}
		.col-sm-6:nth-child(2){
			@media screen and (max-width: 768px){
				margin-top: -35px;
			}
			> .row:nth-child(2){
				@media screen and (max-width: 768px){
					display: none;
				}
			}
		}
		.col-sm-6.visible-xs{
			padding: 0;
			top: -25%;
		}
		.slick-slide > .main-img,
		.slick-slide > .img-side {
			position: relative;
		}
		.icon-play-video {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 56.25px;
			height: auto;
			@include tablet {
				width: 37.5px;
			}
			@include phone {
				width: 37.5px;
			}
		}
		#img-gallery-carousel{
			.img-container{
				display: flex;
				align-items: center;
				justify-content: center;
				.main-img{
					min-width: 100%;
					min-height: 100%;
					object-fit: cover;

					&:hover{
						cursor: pointer;
					}
					.caption{
						width: 100%;
						display: block;
						background-color: rgba(65,65,65,.75);
						color: white;
						padding: 10px 15px;
						position: absolute;
						bottom: 0;
						text-align: center;
					}
				}
			}
			.main-img{
				background-size: 132%;
				background-position: center;
				background-repeat: no-repeat;
			}
		}

		.gallery-row{
			height: 210px;
			@media screen and (max-width: 1200px){
				height: 210px;
			}
			@media screen and (max-width: 1199px){
				height: 180px;
			}
			div{
				height: 100%;
			}
		}
	
	#img-gallery-carousel-nav1{
		padding-bottom: 10px;
	}
	#img-gallery-carousel-nav2{
		padding-top: 10px;
	}
		
	#img-gallery-carousel-nav1,
	#img-gallery-carousel-nav2,
	#img-gallery-carousel-nav3{
		position: relative;
		.img-container{
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 10px;


			@media screen and (max-width: 768px){
				padding: 0px 5px;
			}

			.img-side{
				min-width: 100%;
				min-height: 100%;
				object-fit: cover;
				&:hover{
					cursor: pointer;
				}
			}
		}
		.img-side{
			background-size: 132%;
			background-position: center;
			background-repeat: no-repeat;
		}
		.slick-list{
			margin: 0 auto;
			.slick-track{
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}		
	}
	
	#img-gallery-carousel-nav3{
		.img-container{
			padding: 0px;
			margin: 5px;
			overflow: hidden;
			height: 70px;
			opacity: .7;
			transition: transform .2s ease-in-out, opacity .2s ease-in-out;
		}
		.slick-center{
			transform: scale(1.1);
			opacity: 1;
		}
	}
	}
	.thumbnail{
		overflow: hidden;
		cursor: pointer;
		height: 180px;
		position: relative;
	    margin-left: 20px;
	    width: calc(25% - 20px);
	    perspective: 2000px;

	    @mixin param($margin){
		    &:nth-child(4n - 3){
		    	margin-left: $margin;
		    }
	    }

	    @include desktop-hd{@include param(0px);}
	    @include desktop-hd{@include param(0px);}
	    @include desktop-hd{@include param(10px);}

	    @include phone{
			width: calc(50% - 22.5px);
			margin-left: 15px;
			margin-right: 7.5px;
			&:nth-child(even){
				margin-left: 7.5px;
				margin-right: 15px;
			}
	    }
		.image,
		.overlay{
			position: absolute;
			background-size: cover;
			top: 0;
			left: 0;
			@include transition(all .2s ease-out);
			@include size(100%);
			@include scale(1);
		}

		.overlay{
			background-color: $red;
			opacity: 0;
			@include flex-param(row, center, center);
			svg{
				@include size(30px);
				@include rotateY(90);
				@include transition(all .2s ease-out .2s);
			}
		}

		&:hover{
			.image{
				@include transition(all .2s ease-in);
				@include scale(1.05);
			}
			.overlay{
				@include transition(all .2s ease-in);
				opacity: .8;
				svg{
					@include transition(all .2s ease-in .2s);
					@include rotateY(0);
				}
			}
		}
	}

	h2 {
		margin-bottom: 20px;
	}
}
.gallery-modal{
	.modal-content{
		padding: 0;
	  .modal-header{
		height: 0;
		.btn-close{
		  position: relative;
		  z-index: 100;
		  background-color: transparent;
		  color: rgba(65,65,65,.75);
		  transform: scale(1.5);
		}
	  }
	  .modal-body{
		padding: 0 !important;

		.image{ 
			position: relative;
			.caption{
				position: absolute;
				bottom: 0%;
				width: 100%;
				text-align: start;
				color: white;
				background-color: rgba(65,65,65,.75);
				padding: 10px 15px;
			}
	  	}
	  }
	  .modal-footer{
		padding: 0 !important;
	  }
	}
}
