.banner-have-a-question {
	background-color: $light-gray;
  padding: 18px;

  button, a { font-size: inherit; }

  a.privacy-link{
    color: white;
    text-decoration: underline;
  }

	@include flex-param(row, center, flex-start);

	@include phone {
		display: block;
	}

	.image {
		flex: 3 0 0;
		padding: 9px;
		border: 1px solid rgba(255, 255, 255, .6);

		@include tablet {
			display: none;
		}
		@include phone {
      display: none;
    }

		img {
		  width: 100%;
		}
	}

	.copy {
		padding-left: 15px;
		flex: 12;

		@include tablet {
		  padding-left: 0;
		}

		p.type-xxl {
		  font-size: 31.5px;
			font-style: italic;
			font-weight: 300;
			margin-bottom: 7.5px;

      @include desktop {
        font-size: 2.4vw;
      }
			@include tablet {
			  font-size: 3.3vw;
      }
      @include phone {
        font-size: 6vw;
        line-height: 30px;
      }
		}
		p.type-md {
      margin-bottom: 24px;
    }

		@include phone {
			padding-left: 3px;
			padding-right: 3px;
		}

		> div {
		  @include flex-param(row, center, flex-start);

		  @include phone {
		    flex-direction: column;
		  }
		}
  }

  .cta-container {
    font-size: 18px;
    color: white;
    display: block;

    .btn {
      border: 1px solid rgba(255, 255, 255, .6);
      padding: 9px;
      font-size: 18px;
      display: block;
      text-align: center;
    }
  }

  .banner-have-a-question__cta-container__or {
    color: white;
    font-size: 15px;
    font-style: italic;
    margin-bottom: 6px;
    margin-top: 6px;
    text-align: center;
  }

  .banner-have-a-question__cta-container__phone {
    white-space: nowrap;
  }

	.flex-wrap {
		flex-direction: column;

    .btn-ask-the-fischer-team {
      font-style: italic;

      img {
        margin-right: 10px;
      }
    }

    &.left {
      flex: 6;

      div.form-results-container {
        color: #fff;
        min-height: auto;
      }

      form {

        > .input-group {
          width: 96%;

          @include phone {
            width: 100%;
          }

          &:first-child {

            > .form-component {
              width: calc(50% - 10px);

              @include phone {
                width: 100%;
              }

              &:nth-child(odd) {
                float: left;

                @include phone {
                  float: none;
                }
              }
              &:nth-child(even) {
                float: right;

                @include phone {
                  float: none;
                }
              }
              &:nth-child(n + 1){margin-bottom: 15px;}
            }
          }
        }

        .checkbox-opt-in {
          display: inline-block;
          vertical-align: top;
          width: 69%;

          @include desktop {
            vertical-align: bottom;
            width: 60%;
          }

          @include tablet {
            vertical-align: bottom;
            width: 60%;
          }

          @include phone {
            width: 100%;
          }

          label {
            color: #fff;
            font-size: 12px;
            line-height: 15px;
            input[type="checkbox"] {
              position: relative;
              top: 3px;
            }
          }
        }

        .btn-wrap input[type="submit"],
        .btn-wrap button[type="submit"] {
          display: inline-block;
          padding: 10px 30px;
          position: relative;
          z-index: 1;
          .fa {
            margin-left: 15px;
            margin-right: 0;
          }

          @include phone {
            display: block;
            font-size: 15px;
            margin: 15px auto;
          }
        }
      }
    }
    &.right {
      flex: 3;

      @include phone {
        width: 100%;
      }

      .privacy {
        margin-top: 15px;

        a {
          color: #fff;
          font-weight: 600;
        }
      }
    }
	}

  &.combined {

    .btn {
      border: none;
    }
    .cta-container {
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, .6);
      padding: 4.5px;
    }
    .banner-have-a-question__cta-container__or {
      display: none !important;
    }

    @include phone {
      padding-left: 3px;
      padding-right: 3px;
    }

    .copy {
      @include phone {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .flex-wrap {
      .btn-bordered {
        @include phone {
          > div {
            float: left;
            &:first-child {
              border-top: 1px solid rgba(255, 255, 255, .6);
              border-bottom: 1px solid rgba(255, 255, 255, .6);
              border-left: 1px solid rgba(255, 255, 255, .6);
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              width: 33.33333333%;
              height: 107px;
            }
            &:last-child {
              width: 66.66666667%;
            }
          }
        }
      }

      .btn-ask-the-fischer-team {
        border-bottom-color: black;
        font-size: 12px;

        @include phone {
          border-left-color: white;
          text-align: left;
        }
      }

      .btn-call-or-text {
        border-top-color: darken(white, 6%);

        @include phone {
          border-left-color: darken(white, 6%);
          font-size: 12px;
        }
      }
    }
  }
  .form-disclaimer {
    color: #fff;
    @include phone {
      margin-bottom: 15px;
    }
  }

}

.banner-promo {
  background-image: url('/images/site/microsite/promo-bkg.jpg');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;

  @include dropshadow(0px,0px,30px,0,0.2);
  @include flex-param(row, center, center);

  @include tablet {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @include phone {
    display: block;
    padding-top: 1.5px;
    padding-bottom: 15px;
  }

  .image {
    flex: 4 0 0;

    @include tablet {
      display: none;
    }
    @include phone {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .copy {
    padding-left: 3vw;
    padding-right: 1.5vw;
    flex: 7;

    p {
      &.type-xxxl {
        color: rgba(255, 255, 255, .45);
        font-family: $agenda;
        letter-spacing: 3px;
        line-height: 4.5vw;
        text-shadow: 0 0 30px rgba($red, .45);
        -webkit-text-stroke-color: $red;
        position: relative;
        z-index: 0;

        &::before {
          content: attr(data-char);
          position: absolute;
          left: 0;
          width: 100%;
          z-index: -1;
          -webkit-text-stroke-color: darken($red, 10%);

          @include desktop-hd {
            -webkit-text-stroke-width: 4.5px;
          }
          @include desktop {
            -webkit-text-stroke-width: 4.5px;
          }
          @include tablet {
            -webkit-text-stroke-width: 4.5px;
          }
          @include phone {
            -webkit-text-stroke-width: 3px;
          }
        }

        @include desktop-hd {
          font-size: 3.6vw;
          margin-bottom: 7.5px;
          -webkit-text-stroke-width: 1.5px;
        }
        @include desktop {
          font-size: 37.5px;
          line-height: 45px;
          -webkit-text-stroke-width: 1.5px;
        }
        @include tablet {
          font-size: 37.5px;
          line-height: 45px;
          -webkit-text-stroke-width: 1.5px;
        }
        @include phone {
          font-size: 8.1vw;
          line-height: 45px;
          -webkit-text-stroke-width: 1.5px;
          margin-top: 15px;
          margin-bottom: 15px;
          text-align: center;
        }
      }
      &.type-sm {
        color: #000;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }

  .flex-wrap {
    flex: 5;
    flex-direction: row;
    justify-content: center;
    padding-left: 1.5vw;
    padding-right: 3vw;

    @include phone {
      padding-top: 15px;
    }

    .cta-container {
      border: 2px solid $red;
      border-radius: 6px;
      font-size: 18px;
      color: white;
      display: block;
      max-width: 378px;
      padding: 3px;

      .btn-call-or-text {
        border: 1px solid $red;
        border-radius: 3px;
        padding-top: 15px;
        padding-bottom: 15px;
        display: block;
        text-align: left;

        @include desktop-hd {
          font-size: 18px;
          padding-left: 24px;
          padding-right: 24px;
        }
        @include desktop {
          font-size: 15px;
          padding-left: 12px;
          padding-right: 12px;
        }
        @include tablet {
          font-size: 15px;
          padding-left: 12px;
          padding-right: 12px;
        }
        @include phone {
          font-size: 12px;
        }

        .banner-have-a-question__cta-container__phone {
          white-space: nowrap;
        }

        img {
          float: left;
          margin-right: 15px;

          @include desktop {
            width: 36px;
          }
          @include tablet {
            width: 36px;
          }
          @include phone {
            width: 36px;
          }
        }
      }
    }
  }
}

.regional-banner-promo {
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  max-width: 1140px;
  width: 93vw;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.lucky-banner {
  position: relative;
  display: block;
  text-decoration: none;
  max-width: 1400px;
  margin: 0 auto 30px;
  &:visited {
    color: white;
  }
}
.lucky-banner__img {
  display: block;
  width: 100%;
  height: auto;
}
.lucky-banner__content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10% 20% 20px 25%;
  font-size: 16px;
  font-size: clamp(10px, 1.5vw, 20px);
  line-height: 1.3;
  text-align: center;
  color: white;
  @include phone {
    padding: 26% 20px 73%;
    font-size: clamp(14px, 3.4vw, 30px);
  }
}
.score-exclusive-savings {
  background-color: #fff;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
  width: 93vw;
  padding: 15px 45px;
  @include desktop {
    padding: 30px;
  }
  @include tablet {
    flex-direction: column;
    gap: 15px;
    padding: 15px 30px;
  }
  @include phone {
    flex-direction: column;
    gap: 15px;
    padding: 15px;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    svg {
      width: 75px;
      height: 75px;
      @include desktop {
        width: 60px;
        height: 60px;
      }
      @include tablet {
        width: 45px;
        height: 45px;
      }
      @include phone {
        display: none;
      }
    }
    p {
      color: #ba1f31;
      font-size: 30px;
      font-style: italic;
      font-weight: 700;
      @include desktop {
        font-size: 26.25px;
      }
      @include tablet {
        font-size: 26.25px;
      }
      @include phone {
        font-size: 6vw;
        text-align: center;
      }
    }
  }
  .countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 22.5px;
    height: 100%;
    @include phone {
      gap: 7.5px;
    }
    .countdown-item {
      border: 3px solid $lighter-gray;
      border-radius: 5.625px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 7.5px;
      padding: 15px 7.5px;
      text-transform: uppercase;
      width: 105px;
      @include phone {
        font-size: 3vw;
      }
      span {
        color : #000;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 45px;
        font-weight: 700;
        text-align: center;
        @include desktop {
          font-size: 37.5px;
        }
        @include phone {
          font-size: 10.5vw;
        }
      }
    }
  }
}
