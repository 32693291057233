/* wrap */
.kv {
    padding: 65px 0;
}
@media screen and (min-width: 768px) {
    .kv {
        padding: 100px 0;
    }
}

/* hero */
.kv-hero {
    height: 300px;
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)) ,url('/images/site/kitchen-visualizer/kv-hero-bg.jpg');
    background-position: center center;
}
.kv-hero__title {
    font-size: 36px;
}
@media screen and (min-width: 768px){
    .kv-hero {
        height: 335px;
    }
    .kv-hero__title {
        font-size: 60px;
    }
}


/* intro copy */
.kv-intro {
    padding: 30px;
    line-height: 1.2;
}
.kv-intro p {
    line-height: 1.5;
    color: #8D8D8D;
}
@media screen and (min-width: 768px){
    .kv-intro {
        padding: 50px 30px;
        max-width: 1280px; 
        margin: 0 auto;
    }
    .kv-intro p {
        font-size: 20px;
    }
}

/* cards */
.kv-cards {
    padding: 20px 30px 30px;
}
.kv-card-link {
    color: #8D8D8D;
    display: block;
    margin-bottom: 30px;
    height: 100%;
}
.kv-card-link:visited {
    color: #8D8D8D;
}
.kv-card-link:hover {
    color: #8D8D8D;
}
.kv-card__title {
    display: flex;
    justify-content: center;
    padding: 15px 10px;
    font-size: 16px;
    align-items: center;
}
.kv-card__plan {
    color: #BA1F31;
}
.kv-card__collection {
    border-left: 1px solid #CCC;
    margin-left: 10px;
    padding-left: 10px;
}
.kv-card__img {
    width: 100%;
}
@media screen and (min-width: 768px){
    .kv-cards {
        padding: 20px 10px 50px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: flex-end;
    }
    .kv-card-link {
        flex: 0 0 calc(50% - 30px);
        margin: 0 15px 30px;
    }
}
@media screen and (min-width: 1200px){
    .kv-cards {
        max-width: 1280px; 
        margin: 0 auto; 
    }
    .kv-card-link {
        flex: 0 0 calc(33% - 30px);
        margin: 0 15px 30px;
    }
}