//fonts
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700';

// agenda
@font-face {
    font-family: 'agendabold';
    src: url('/fonts/ufonts.com_agenda-bold_2_-webfont.woff2') format('woff2'),
         url('/fonts/ufonts.com_agenda-bold_2_-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

// Parisienne
@font-face {
    font-family: 'Parisienne';
    src: url('/fonts/Parisienne-Regular.woff2') format('woff2'),
         url('/fonts/Parisienne-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

$open-sans: 'Open Sans', sans-serif;
$agenda: 'agendabold', sans-serif;
$parisienne: 'Parisienne', sans-serif;

@mixin font($family, $weight){
	font-family: $family;
	font-weight: $weight;
}

%open-sans{@include font($open-sans, 400);}

body{
    @extend %open-sans;
}

strong{font-weight: 600;}
b{font-weight: 700;}

.arial{font-family: Arial, sans-serif;}

//type
.type-xxs{
	font-size: 12px;
	@include desktop{font-size: 12px;}
	@include tablet{font-size: 11px;}
	@include phone{font-size: 11px;	}
}

.type-xs{
	font-size: 14px;
	@include desktop{font-size: 14px;}
	@include tablet{font-size: 13px;}
	@include phone{font-size: 13px;	}
}

.type-sm{
	font-size: 16px;
	@include desktop{font-size: 15px;}
	@include tablet{font-size: 15px;}
	@include phone{font-size: 14px;	}
}

.type-md{
	font-size: 18px;
	@include desktop{font-size: 16px;}
	@include tablet{font-size: 15px;}
	@include phone{font-size: 15px;	}
}

.type-lg{
	font-size: 20px;
	@include desktop{font-size: 20px;}
	@include tablet{font-size: 18px;}
	@include phone{font-size: 16px;	}
}

.type-xl{
	font-size: 25px;
	@include desktop{font-size: 22px;}
	@include tablet{font-size: 21px;}
	@include phone{font-size: 20px;	}
}

.type-xxl{
	font-size: 28px;
	@include desktop{font-size: 26px;}
	@include tablet{font-size: 24px;}
	@include phone{font-size: 20px;	}
}

.type-xxxl{
	font-size: 36px;
	@include desktop{font-size: 34px;}
	@include tablet{font-size: 32px;}
	@include phone{font-size: 30px;}
}

.type-xxxxl{
	font-size: 43px;
	@include desktop{font-size: 38px;}
	@include tablet{font-size: 35px;}
	@include phone{font-size: 28px;	}
}

.type-xxxxxl{
	font-size: 50px;
	@include desktop{font-size: 48px;}
	@include tablet{font-size: 45px;}
	@include phone{font-size: 40px;	}
}

//title

.type-title-xs,
.type-title-md,
.type-title-lg,
.type-title-xl,
.type-title-xxl,
.type-title-xxxl,
.type-realtor-program{
	letter-spacing: 5px;
	font-weight: 300;
}
.type-title-xs{
	font-size: 22px;
	@include desktop{font-size: 20px;}
	@include tablet{font-size: 19px;}
	@include phone{font-size: 18px;	}
}
.type-title-md{
	font-size: 34px;
	@include desktop{font-size: 32px;}
	@include tablet{font-size: 30px;}
	@include phone{font-size: 28px;	}
}

.type-title-lg{
	font-size: 52px;
	@include desktop{font-size: 50px;}
	@include tablet{font-size: 48px;}
	@include phone{font-size: 46px;	}
}

.type-title-xl{
	font-size: 60px;
	@include desktop{font-size: 55px;}
	@include tablet{font-size: 40px;}
	@include phone{font-size: 25px;	}
}


.type-title-xxl{
	font-size: 90px;
	@include desktop{font-size: 80px;}
	@include tablet{font-size: 60px;}
	@include phone{font-size: 35px;	}
}

.type-title-xxxl{
	font-size: 100px;
	@include desktop{font-size: 90px;}
	@include tablet{font-size: 70px;}
	@include phone{font-size: 50px;	}
}

.type-title-xxxxl{
	font-size: 150px;
	@include desktop{font-size: 125px;}
	@include tablet{font-size: 100px;}
	@include phone{font-size: 90px;	}
}

//subtitle

.type-subtitle-sm,
.type-subtitle-md,
.type-subtitle-lg{
	letter-spacing: 1px;
	font-weight: 600;
}

.type-subtitle-sm{
	@extend .type-lg;
}

.type-subtitle-md{
	font-size: 24px;
	@include desktop{font-size: 22px;}
	@include tablet{font-size: 20px;}
	@include phone{font-size: 18px;	}
}

.type-subtitle-lg{
	font-size: 30px;
	@include desktop{font-size: 28px;}
	@include tablet{font-size: 27px;}
	@include phone{font-size: 21px;	}
}

.type-subtitle-xl{
	font-size: 72px;
	@include desktop{font-size: 62px;}
	@include tablet{font-size: 55px;}
	@include phone{font-size: 40px;	}
}

.type-subtitle-xxl{
	font-size: 82px;
	@include desktop{font-size: 72px;}
	@include tablet{font-size: 65px;}
	@include phone{font-size: 60px;	}
}

//custom
.type-microsite-sm,
.type-regional-md{
	font-size: 40px;
	@include desktop{font-size: 30px;}
	@include tablet{font-size: 28px;}
	@include phone{font-size: 25px;	}
}

.type-blog-sm,
.type-why-choose-fischer-sm{
	font-size: 45px;
	@include desktop{font-size: 40px;}
	@include tablet{font-size: 35px;}
	@include phone{font-size: 30px;	}
}

.type-amenities-md,
.type-financing-md,
.type-design-md,
.type-floorplan-md,
.type-why-choose-fischer-md,
.type-vendor-bid-md,
.type-contact-us-md,
.type-st-judes-md{
	font-size: 48px;
	@include desktop{font-size: 45px;}
	@include tablet{font-size: 40px;}
	@include phone{font-size: 38px;	}
}

.type-prequalify-lg,
.type-vendor-bid-lg,
.type-model-detail-lg{
	font-size: 55px;
	@include desktop{font-size: 50px;}
	@include tablet{font-size: 45px;}
	@include phone{font-size: 40px;	}
}

.type-design-xl,
.type-model-detail-xl,
.type-theme-xl,
.type-microsite-xl{
	font-size: 62px;
	@include desktop{font-size: 60px;}
	@include tablet{font-size: 55px;}
	@include phone{font-size: 50px;	}
}

.type-buying-process,
.type-building-process{
	font-size: 70px;
	@include desktop{font-size: 65px;}
	@include tablet{font-size: 60px;}
	@include phone{font-size: 55px;	}
	font-weight: 300;
}

.type-realtor-program
{
	font-size: 76px;
	@include desktop{font-size: 70px;}
	@include tablet{font-size: 45px;}
	@include phone{
		font-size: 7vw;
		letter-spacing: 0;
	}
}

.card-furnished-home-community-title {
	font-size: 15px;
	font-weight: 600;
	color: #444;
	font-style: italic;
	line-height: 35px;
}

.card-furnished-home-property-type {
	font-size: 15px;
	font-weight: 600;
	color: black;
	line-height: 35px;
}