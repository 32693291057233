#microsite-carousel,
#home-show-carousel,
#hero {
	@extend %carousel-with-bottombar;

  .btn-back {
    padding: 9px;
    position: absolute;
    top: 135px;
    left: 0;
    z-index: 15;

    &::before{
      content: "\003C";
      display: inline-block;
      margin-right: 10px;
      transform: scaleY(2);
    }

    @include desktop-hd {
      padding-left: 24px;
      padding-right: 24px;
    }
    &:hover {
      background-color: $red;
    }
    span {
      &:first-child {
        padding-right: 6px;
      }
      &:last-child {
        padding-left: 6px;
      }
    }
  }
	.signature-community {
		position: absolute;
		top: 110px;
		right: 4vw;
	}
	.list-inline-buttons {
	  position: absolute;
    bottom: 105px;
    right: 10px;
    .btn {
      background-color: rgba(black,.3);
      border-color: white;
      @include desktop-hd {
        padding: 9px 24px;
      }
      @include desktop {
        padding: 9px;
      }
      @include tablet {
        padding: 9px;
      }
      @include phone {
        padding: 9px;
      }
      &:hover {
        background-color: $red;
        svg {
          border-color: blue;
        }
      }
      svg {
        @include size(25px);
        @include desktop {
          margin-right: 0;
        }
        @include tablet {
          margin-right: 0;
        }
        @include phone {
          margin-right: 0;
        }
      }
    }
	}

  .cta-right{
    right: 0;
    top: 250px;
    position: absolute;

    @include phone{
      top: calc(50% - 20px);
    }

    .get-updates {
      position: relative;
      margin-bottom: 10px;
      left: 0;
      @include carousel-right-cta($red);
      @include transition(all .3s ease-out);
      &.open{
        left: -620px;
        @include transition(all .3s ease-in);
      }
      .close-btn{
        display: none;
      }
      .content{
        @include size(620px, auto);
        position: absolute;
        top: -100px;
        left: 100%;
        padding: 30px 40px;
        background-color: $lightest-gray;
        p.type-xl {
          color: $dark-gray;
          font-style: italic;
          font-weight: 600;
          margin-bottom: 20px;
        }
        .form-component{
          border: none;
          width: calc(50% - 10px);
          margin-bottom: 10px;
          float: left;
          &:nth-child(odd){margin-right: 10px;}
          &:nth-child(even){margin-left: 10px;}
        }
        .form-componenets:after{
          @extend %clearfix;
        }

        .button-flex {
          color: white;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: row;
          flex-direction: row;
          -ms-flex-pack: end;
          justify-content: flex-end;
          -ms-flex-align: center;
          align-items: center;

          .btn-wrap {
              padding-top: 20px;
              @include flex-param(row, flex-end, center);
              .checkbox-opt-in label {
                color: $gray;
              }
              p {
                  color: $gray;
                  margin-right: 20px;
              }
          }
        }

        .form-results-container {
            h5 {
                color: black;
                font-size: 16px;
            }
        }
      }

      @include phone{
        .close-btn{
          padding: 5px;
          font-size: 25px;
          color: $light-gray;
          position: absolute;
          top: 75px;
          right: 20px;
          display: inline-block;
        }
        &.open{
          left: -100vw;
        }
        .content{
          width: 100vw;
          padding: 65px 20px 20px;
          top: -50px;
          top: -20vh;
          height: 66vh;
          max-height: 445px;
          background-color: darken($lightest-gray,5%);
          p.type-xl{
            font-size: 20px;
            line-height: 25px;
            padding-right: 20px;
          }
          .form-component{
            width: 100%;
            &:nth-child(odd){margin-right: 0;}
            &:nth-child(even){margin-left: 0;}
          }
        }
      }
    }

    .live-chat {
      @include carousel-right-cta($blue);
    }

  }
  .bottom-bar {
    .flex-wrap {
      @include phone {
        flex-direction: column;
        height: auto;
      }
      .col-left {
        @include phone {
          padding: 6px 3px;
        }
      }
      .col-right {
        @include phone {
          padding-bottom: 9px;
        }
        svg {
          @include phone {
            margin-right: 0;
          }
        }
        li {
          @include phone {
            margin-right: 0;
          }
        }
      }
    }
  }
}

#microsite-carousel {
  .carousel {
    height: 60vh;
    
    .carousel-inner {
      .slideImage {
        @include size(100%);
        position: relative;
        padding-bottom: 65%;
      }
      .slideImage img{
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
        top: auto;
        @include phone {
          top: auto;
        }
      }
    }

    .controls-wrap a::after{
      display: none;
    }


    .carousel-indicators {
      > li {
        margin-left: 5px;
        margin-right: 5px;
        width: 15px;
        height: 15px;
      }
    }

    .flex-caption{
      height: 100%;
    }
    .flex-caption p{
      text-shadow: 2px 2px black;
    }
  }

  .youtube-video-slide{
    position: initial;
    @include size(100%);
    iframe{
      @include scale(1.01);
      background-color: #000;
      height: 100%;
    }
  }

  &.fading-carousel .carousel .carousel-inner {
      .item {
          transition-property: opacity;
          position: absolute;
          top:0;
          left: 0;
          width: 100%;
          @include flex-param(column,center,center);
      }

      .item,
      .active.left,
      .active.right {
          opacity: 0;
      }

      .active,
      .next.left,
      .prev.right {
          opacity: 1;
      }

      .next,
      .prev,
      .active.left,
      .active.right {
          left: 0;
          transform: translate3d(0, 0, 0);
      }
  }

  @include tablet{
    .carousel{
      height: 430px;
    }
  }
  @include phone{
    .carousel{
      margin-top: 115px;
      height: 57vw;
    }
    .carousel .carousel-inner .flex-caption{
      padding-left: 50px;
      padding-right: 50px;
    }
    .carousel .carousel-inner .flex-caption p{
      font-size: 30px;
    }
    .signature-community{
      position: absolute;
      top: 126px;
      right: 0;
      height: 40px;
      img{
        height: 100%;
      }
    }
    .carousel-indicators{
      display: none;
    }
  }

  &.microsite-carousel-coming-soon {
    .row-top {
      background-color: $lightest-gray;
      height: 230px;
      padding-top: 110px;

      .container-fluid {
        @include flex-param(row, center, center);
        height: 100%;
        padding: 0 30px;
        &:before,
        &:after{
          display: none;
        }
        > h1 {
          margin-right: 15px;
        }
        > div {
          margin-left: 15px;
          @include desktop-hd {
            white-space: nowrap;
          }
          @include desktop {
            white-space: nowrap;
          }
        }

        .btn-white {
          border: 2px solid $blue;
          margin-left: 7.5px;
          margin-right: 7.5px;
          padding: 7.5px 15px;
          @include phone {
            display: block;
          }
          &:hover {
            border-color: $green;
          }
          &:nth-child(2) {
            @include tablet {
              margin-top: 7.5px;
            }
            @include phone {
              margin-top: 15px;
              margin-bottom: 15px;
            }
          }
        }
      }

      @include phone {
        height: auto;
        text-align: center;
        padding-top: 7.5px;
        padding-bottom: 7.5px;
        margin-top: 65px;
        .container-fluid {
          flex-direction: row;
          padding-left: 7.5px;
          padding-right: 7.5px;
        }
      }
    }

    .row-bottom {
      @include flex-param(row, initial, stretch);

      /* LEFT COLUMN */
      .col-left {
        width: 60%;
        perspective: 2000px;
        background-color: white;
        text-align: right;
        #slick-main {
          height: 584px;;
          overflow: hidden;
          max-height: 1500px;
          max-width: 2500px;
          float: right;
          width: 100%;
        }
        #slick-thumb {
          float: right;
          width: calc(100% - 60px);
          max-width: 2500px;
          @include phone {
            width: 100%;
          }
        }
      }

      @include desktop {
        .col-left {
          width: 55%;
        }
        .col-left #slick-main {
          height: 35vw;
        }
      }

      @include tablet {
        .col-left {
          width: 50%;
        }
        .col-left #slick-main {
          height: 37vw;
        }
      }

      @include phone {
        flex-direction: column;
        .col-left {
          width: 100%;
        }
        .col-left #slick-main {
          height: 75vw;
        }
        .col-left #slick-thumb {
          height: 50px;
          margin: 0;
        }
      }

      /* RIGHT COLUMN */
      .col-right {
        background-color: $blue;
        color: white;
        width: 40%;

        @include desktop {
          width: 45%;
        }
        @include tablet {
          width: 50%;
        }
        @include phone {
          width: 100%;
        }

        .top {
          .form-results-container {
            margin-top: 100px;

            .form-results-loader {
              i.fa {
                font-size: 80px;
                color: white;
              }
            }
          }

          form {
            /* max-width: 470px; */
            padding: 45px;
            overflow: hidden;
            @include tablet {
              padding: 24px;
            }
            @include phone {
              padding: 15px;
            }
            .form-component, input {
              margin-bottom: 15px;
              &[type="submit"] {
                margin-top: 15px;
              }
            }
            .type-xxxxl {
              font-weight: 300;
            }
            .type-xl {
              font-weight: 600;
              letter-spacing: .9px;
              line-height: 30px;
              margin: 20px 0;
            }
          }
          .contact-info{
            padding-top: 15px;
            clear: both;
            @include flex-param(row, center, center);
            p{
              margin: 0 10px;
            }
          }
        }
        .bottom {
          .mini-map {
            @include size(auto, 270px);
            .map {
              width: 100%;
              .btn {
                font-weight: 400;
                padding: 7.5px 18px;
                position: absolute;
                bottom: 0;
                right: 0;
                &:after {
                  content: "GET DIRECTIONS";
                }
              }
            }
          }
        }
      }
    }
  }
}

.microsite-amenities-icon-list {
    padding-left: 0;
    margin-left: -5px;
    list-style: none;

    .icon-list-item {
    		text-transform: uppercase;
  	    display: inline-block;
		    padding-right: 5px;
		    padding-left: 5px;
    }
}

.coming-soon-bottom-bar {
  .container {
    padding-top: 21px;
    padding-bottom: 21px;
  }
  h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: $red;
  }
  .btn-white {
    border: 2px solid $blue;
    margin-left: 7.5px;
    margin-right: 7.5px;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    @include phone {
      display: block;
    }
    &:hover {
      border-color: $green;
    }
    &:nth-child(2) {
      @include phone {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }
}

.flex-caption {
  &.coming-soon-caption {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    div.caption-container {
      flex: 1;
      text-align: center;
      p {
        color: white;
        text-transform: uppercase;
      }
      .btn {
          margin-top: 50px;
      }
    }
  }
}
#microsite.has-announcements #microsite-carousel.microsite-carousel-coming-soon .row-top {
  padding-top: var(--headerHeight);
}
