$card-border-color: #dedede;

//GLOBAL CARD DIRECTIVE
card, .card {
	//DEFAULT STYLE
	cursor: pointer;

	.preloader,
	.img{
		@include size(100%, 255px);

		@include laptop {
			height: 195px;
		}

		@include phone {
			height: 50vw;
		}
	}

	.preloader{
		//preloader
		background-repeat: no-repeat;
		@include background-image('/images/site/global/ajax-loader.gif', auto, center);
	}

	.img {
		background-size: cover;
		background-position: center;
		position: relative;
		z-index: 1;

		> * {
			position: absolute;
		}

		a.btn {
			bottom: 20px;
			right: 20px;
			padding: 10px 20px;
			@include flex-param(row, space-between, center);
		}

		.action-btn-group {
			bottom: 20px;
			right: 20px;
			@include flex-param(row, space-between, center);

			.action .popover {
				width: 114px;
				padding: 0;
				margin: 0;
				border-radius: 0;
				border: none;
				font-size: 14px !important;
				text-align: center;
				color: $red;
			}

			.action a {
				display: inline-block;
				width: 36px;
				height: 36px;
				padding: 6px;
				cursor: pointer;
				background: rgba(0, 0, 0, 0.3);
				border-radius: 100%;
				border: 1px solid #fff;
				transition: all 0.25s ease-in-out;

				&.video {
					padding: 7px;
				}

				&:hover {
					background-color: $red;
					border-color: $red;
				}

				svg {
					width: 100%;
					height: 100%;
				}
			}

			.action + .action {
				margin-left: 8px;
			}
		}

		.card-image-banner {
			position: absolute;
			top: -5px;
			right: -5px;
			&.fresh-savings {
			  top: 15px;
			  right: 0;
			}
			&.blitz {
			  top: 0;
			  left: 0;
			}
			&.red-bow {
			  top: 0;
			  left: 0;
			  right: auto;
			}
			&.lucky-you {
				top: 0;
				left: 0;
				right: auto;
			  }
			&.open-house {
				top: 0;
				left: 0;
				right: auto;
			}
			&.customization {
				top: 0;
				left: 0;
				right: auto;
				width: 75px;
			}
		}
		.banner-featured-community {
		  position: absolute;
		  left: 0;
		  bottom: 20px;
		}
	}
	.model-card.card-image-banner{
		position: absolute;
		top: -5px;
		right: -5px;
	}

	.title {
		padding: 15px 15px 15px 30px;
		text-align: left;

		.type-xl,
		.type-md,
		.type-sm {
			line-height: 33px;
		}

		.type-xl {
			color: $red;
		}

		.type-md {
			color: $gray;
		}

		.type-sm {
			color: $gray;
			&.price {
			  font-style: italic;
			}
			&:hover,
			&:active,
			&:focus{
				color: black;
			}
		}

		@mixin param() {
			min-height: 0;
			padding: 10px 25px;
		}

		@include laptop {
			@include param();
			.type-xl,
			.type-md,
			.type-sm {
				line-height: 25px;
			}
		}

		@include tablet {
      @include param();
      .type-xl,
      .type-md,
      .type-sm {
        line-height: 25px;
      }
    }

		@include phone {
			@include param();
			.type-xl,
      .type-md,
      .type-sm {
        line-height: 25px;
      }
		}
		.cost-to-build-new {
			font-style: italic;
		}
		.line-through {
			text-decoration: line-through;
		}
		.sale-price-new {
			font-weight: 600;
		}
		.list-price{
      display: inline-block;
      position: relative;
    }

    .list-price s {
      display: inline-block;
      line-height: 18px;
      position: relative;
        text-decoration: none;
    }

    .list-price s:after{
      content: "";
      position: absolute;
      background-color: red;
      width: 100%;
      height: 2px;
      right: 0;
      top: 50%;
      @include rotate(-5);
    }
	a.utour-cta{
		color: $red;
		font-weight: 700;
		text-transform: uppercase;
		text-decoration: none;
		font-size: 1rem;
	}
	}

	.col-wrap {
		border-top: 1px solid $lighter-gray;
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;

		.col-left,
		.col-right {
			padding: 15px;
			text-align: left;
			@include hoverstate($red, white, .2s);
			@include flex-param(row, center, center);
			flex-grow: 1;
			min-width: 50%;
			border-left: 1px solid $card-border-color;


			&:first-child {
				border-left: 0;
			}

			> div:first-child{
				transition: color .2s ease-in;
			}

			&:hover > div:first-child{
				color: white !important;
			}

			.red {
				margin-right: 5px;

				@include desktop-hd {
					margin-right: 10px;
				}

			}

			p, h2 {
				color: $gray;
				line-height: 17px;
			}

			@include phone{
				padding: 5px;
				p, h2 {
					line-height: 15px;
				}
			}

		}

	}

}

.card {
	border: none;
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: left;
	flex: 0 0 auto;

	.amenities {
		@extend .card-section-container;
		@include flex-param(row, center, center);
		min-height: 93px;
		padding: 30px 10px;

		a svg {
			margin: 0 7px;
			@include size(30px);
			@include svghover($blue, $green, .2s);

			@include tablet {
				margin: 0 5px;
				@include size(25px);
			}

		}

	}

	.snapshot {
		@extend .card-section-container;
		margin: 0;
		justify-content: center;
		padding: 20px 10px;

		@include phone{
			padding: 10px;
		}

		li {
			margin-right: 0;
			margin-left: 0;
			color: $gray;
			@extend .type-sm;
			@include phone{
				&:not(:last-child){
					margin-right: 15px;
				}
			}

			svg {
				margin-right: 10px;
				@include size(25px);
				@include phone{
					width: 20px;
				}
			}

		}

	}

	.availability {
		@extend .card-section-container;
		text-align: center;
		padding: 30px;
		color: $gray;

		span {
			color: $red;
		}

		@include laptop {
			padding: 15px 30px;
		}

		@include phone {
			padding: 15px 30px;
		}

	}

	.cta-link-group {
		@extend .card-section-container;
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
		justify-content: center;
		text-align: center;

		.cta-link {
			@extend .card-cta;
			flex-grow: 1;
			min-width: 50%;
			border-left: 1px solid $card-border-color;

			&:first-child {
				border-left: 0;
			}
			&.cta-link-model{
				min-width: 33%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				&:hover > .cta-link__label.cta-link-model__label{
					color: white;
				}
			}

			.cta-link__label.cta-link-model__label{
				color: $red;
				transition: color .1s ease-in;
			}

		}

	}

}

//WIDE STYLE
.card .card-wide,
.card.card-wide {
	.top,
	.info-top,
	.info-bottom,
	.view-details {
		@include flex-param(row, space-between, center);
	}

	.snapshot {
		border: 0;
	}

	.top {
		height: 200px;

		.thumb,
		.preloader {
			@extend %thumbnail-param;
			@include size(300px, 100%);
		}

		.info {
			flex: 1;
			height: 100%;
			@include flex-param(column, inherit, initial);

			.info-top {
				padding: 30px;
				align-items: flex-start;
			}

			.info-top .info-left p,
			.info-top .info-left h2 {
				margin-bottom: 20px;
			}

			.info-top .info-left .black {
				font-style: italic;
			}

			.info-top .info-right a {
				color: $red;
				display: block;
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}

				svg {
					@include svgcolor($gray);
					@include size(25px, 20px);
					position: relative;
					top: 5px;
					margin-right: 10px;

					&.icon-car {
						@include size(25px);
					}

				}

			}

			.info-bottom {
				border-top: 1px solid $lighter-gray;
				border-bottom: 1px solid $lighter-gray;
				overflow: hidden;
				padding-left: 30px;

				.snapshot li {
					@extend .type-sm;
				}

			}

			.view-details {
				height: 70px;
				background-color: $red;
				color: white;
				padding: 0 20px;
				@include hoverstate(black, white, .2s);
			}

		}

	}

	.bottom {
		padding: 50px;
		line-height: 36px;
		@include tablet {
		  padding: 30px;
		}
	}

	@include phone {
		.top,
		.info-top,
		.info-bottom,
		.view-details {
			flex-direction: column;
		}

		.top {
			height: auto;
		}

		.top .thumb,
		.top .preloader {
			@include size(100%, 185px);
		}

		.top .info .info-top {
			align-items: center;
		}

		.top .info .info-top .info-right {
			font-size: 14px;
		}

		.top .info .info-bottom {
			padding-left: 0;
		}

		.top .info .info-bottom .snapshot {
			padding: 20px;
			display: inline-block;

			li {
				letter-spacing: 0;
				font-size: 14px;
				width: 50%;
				float: left;
				justify-content: flex-start;
			}

			li:not(:first-child) {
				margin-left: 0;
			}

			li:nth-child(even) {
				padding-left: 20px;
			}

			li svg {
				width: 22px;
				margin-right: 5px;
			}

			&:after {
				content: '';
				clear: both;
			}

		}

		.top .info .view-details {
			width: 100%;
			height: 50px;
			justify-content: center;
		}

	}

}

//COMPACT STYLE
.card.floorplan-card {
	border: 0;
	.top {
		@include flex-param(row, flex-start, flex-start);

		> div {
			flex: 1;
		}

		.thumb {
			@extend %thumbnail-param;
			@include size(100%, 185px);
		}

		.info {
			padding: 25px 30px;
			line-height: 30px;
			text-align: left;
		}

		@include tablet {
			.thumb {
				height: 155px;
			}
		}

		@include phone {
			display: block !important;
			flex-direction: column;

			.info{
				width: 100%;
				flex-basis: 100%;
				padding: 15px 20px;
				line-height: 25px;
			}

			.thumb {
				height: 0;
				padding-top: 60%;
			}

		}
	}

	.snapshot{
		@include desktop {
			li {
				letter-spacing: 1px;
				margin-right: 5px;
			}

			li:not(:first-child) {
				margin-left: 1vw;
			}

			li svg {
				margin-right: 5px;
			}
		}

		@include phone {
			display: block;
			text-align: center;

			.snapshot__levels {
				display: none;
			}

			li {
				margin: 0.6em 7px 10px; 
				display: inline-block;
				justify-content: flex-start;
				letter-spacing: 1px;
				font-size: 13px;

				svg { 
					vertical-align: middle; 
					margin-top: -0.4em;
				}
			}
		}
	}

	.cta-link-group {
		clear: both;
	}

	.card-title {
		@extend .type-xl;
		@extend .red;
		margin-bottom: 10px;

		@include laptop {
			margin-bottom: 5px;
		}

		@include phone {
			margin-bottom: 5px;
		}

	}

	.card-subtitle {
		@extend .type-sm;
		color: black;
	}

	.card-detail {
		@extend .type-sm;
		color: grey;
	}

	.card-price {
		@extend .type-sm;
		color: black;
		font-style: italic;
	}

	.view-details {
		text-align: center;
		background-color: $lightest-gray;
		color: $blue;
		@include hoverstate($blue, white, .2s);
	}
	.card-kv {
		color: #6C6C6C;
		@media screen and (min-width: 992px) {
			transform: translateY(15px);
		}
	}

}

//SMALL STYLE
.card-xs {
	background-color: white;
	text-align: left;
	border: 1px solid $lighter-gray;

	.image {
		height: 65%;
		min-height: 150px;
		background-size: cover;
		background-position: center center;
	}

	.info {
		padding: 20px 30px;
		line-height: 20px;

		p.red {
			margin-bottom: 10px;
			line-height: 30px;
		}

	}

}

.property-type {
	color: black;
	text-transform: uppercase;
	font-weight: 600;

	&::after {
		content: " | ";
	}

	&:last-child {
		&::after {
			content: "";
		}

	}

}

.marketing-description {
  color: black;
}

/* CTA Link style for big blue-on-gray, hovers to green-on-white */
.card-cta {
	@include hoverstate($red, white, .2s);
	display: block;
	background-color: $lightest-gray;
	color: black;
	padding: 15px 0;
}

.card-section-container {
	border-top: 1px solid $card-border-color;
}