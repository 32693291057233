#my-favorites.special-offers {
	section#hero  {
		
	}
	section#content {
		margin-top: var(--headerHeight);
	}
	section#content > section {
		
	}
}