.theme-detail{
    &.has-announcements #hero .row-top {
        padding-top: var(--headerHeight);
    }
    #hero {
        .row-top {
            background-color: $lightest-gray;
            height: 230px;
            padding-top: 110px;

            .container-fluid {
                @include flex-param(row, space-between, center);
                height: 100%;
                padding: 0 30px;
                &:before,
                &:after{
                    display: none;
                }
            }

            @include phone{
                height: auto;
                text-align: center;
                padding-top: 7.5px;
                padding-bottom: 7.5px;
                margin-top: 65px;
                .container-fluid{
                    flex-direction: row;
                    padding-left: 7.5px;
                    padding-right: 7.5px;
                    a.type-xl{
                        margin-top: 20px;
                        font-size: 16px;
                        line-height: 18px;
                    }
                }
            }
        }

        .row-bottom {
            @include flex-param(row, initial, stretch);

            /* LEFT COLUMN */
            .col-left {
                width: 60%;
                perspective: 2000px;
                background-color: white;
                text-align: right;
                #slick-main{
                    height: 40vw;
                    overflow: hidden;
                    max-height: 1500px;
                    max-width: 2500px;
                    float: right;
                    width: 100%;
                }
                #slick-thumb{
                    float: right;
                    width: calc(100% - 60px);
                    max-width: 2500px;
                    @include phone{
                        width: 100%;
                    }
                }
                .btn-back {
          background-color: rgba(black,.3);
          border-color: white;
          position: absolute;
          z-index: 15;
          @include desktop-hd {
            padding: 9px 24px;
            top: 30px;
            left: 8.4%;
          }
          @include desktop {
            padding: 9px;
            top: 30px;
            left: 51px;
          }
          @include tablet {
            padding: 9px;
            top: 30px;
            left: 38px;
          }
          @include phone {
            padding: 9px;
            top: 15px;
            left: 15px;
          }
          &:hover {
            background-color: $red;
          }
        }
                .zoom{
                    $size: 40px;
                    z-index: 15;
                    margin: 20px;
                    cursor: pointer;
                    @include absolute(top,right,$size,$size);
                    svg{
                        @include size($size);
                        @include transition(all .2s ease-out);
                        @include svghover(rgba(white,.7),white,.2s);
                        &:hover{
                            @include transition(all .2s ease-in);
                            @include scale(1.2);
                        }
                    }
                }
            }

            @include desktop{
                .col-left{
                    width: 55%;
                }
                .col-left #slick-main{
                    height: 35vw;
                }
            }

            @include tablet{
                .col-left{
                    width: 50%;
                }
                .col-left #slick-main{
                    height: 37vw;
                }
            }

            @include phone{
                flex-direction: column;
                .col-left{
                    width: 100%;
                }
                .col-left #slick-main{
                    height: 75vw;
                }
                .col-left #slick-thumb{
                    height: 50px;
                    margin: 0;
                }
                .zoom{
                    display: none;
                }
            }

            /* RIGHT COLUMN */
            .col-right{
                width: 40%;
                border-left: 1px solid $lighter-gray;
                snapshot-info ul{
                    padding: 30px 30px 10px;
                    background-color: $lightest-gray;
                    li{
                        justify-content: flex-start;
                        float: left;
                        padding: 0;
                        margin: 0;
                        width: 60%;
                        &:nth-child(even){
                            width: 40%;
                        }
                    }
                    &:nth-child(even){
                        padding: 10px 30px 30px;
                        margin-bottom: 30px;
                    }
                }
                @include desktop{
                    width: 45%;
                    snapshot-info ul{
                        font-size: 18px;
                        li svg{
                            @include size(25px);
                            margin-right: 10px;
                        }
                        small{
                            font-size: 16px;
                        }
                    }
                }
                @include tablet{
                    width: 50%;
                }
                @include phone{
                    width: 100%;
                    snapshot-info {
                      display: block;
                      padding-top: 15px;
                      padding-bottom: 15px;
                      ul{
              font-size: 16px;
                          li svg{
                            @include size(25px);
                            margin-right: 10px;
                          }
                          small{
                             font-size: 13px;
                          }
                          &:nth-child(even){
                margin-bottom: 0;
              }
            }
                    }
                }

                .form-wrap {
                    width: 100%;
                    background-color: $lighter-gray;
                    padding: 20px 20px;

                    .type-xxl{
                        font-weight: 700;
                        color: white;
                        margin-bottom: 10px;
                        span.blue{
                            font-style: italic;
                        }
                    }

                    .type-xl{
                        margin-bottom: 20px;
                    }

                    form {

                        .form-component {
                            float: left;
                            width: calc(50% - 10px);
                            margin-bottom: 10px;
                            height: 40px;
                            border: none;

                            &:nth-child(even){float: right;}

                            &:after{@extend %clearfix;}
                        }

                        .button-flex {
                            color: white;
                            display: -ms-flexbox;
                            display: flex;
                            -ms-flex-direction: row;
                            flex-direction: row;
                            -ms-flex-pack: end;
                            justify-content: flex-end;
                            -ms-flex-align: center;
                            align-items: center;

                            .btn-wrap {
                                color: white;
                                @include flex-param(row, flex-end, center);
                    flex-wrap:wrap;
                    .checkbox-opt-in {
                      label {
                        color: $gray;
                        font-size: 14px;
                        line-height: 18px;
                        margin-bottom: 15px;
                      }
                    }
                                p {
                                    margin-right: 20px;
                                }
                            }
                        }

                        > *{
                            padding-right: 20px;
                        }
                    }
                    @mixin param{
                        width: 100%;
                        form > *{
                            padding-right: 0;
                        }
                        form .button-flex .btn-wrap p{
                            margin-right: 10px;
                        }
                    }
                    @include desktop{@include param}
                    @include tablet{@include param}
                    @include phone{
                        width: 100%;
                        padding: 20px 15px;
                        form .form-components{
                            padding: 0;
                        }
                        form .form-component{
                            width: 100%;
                        }
                        form .button-flex{
                            padding: 0;
                            justify-content: center;
                        }
                    }
                }
            }

        }

    }

    #content {
        background-color: $lightest-gray;
        padding: 60px 0;

        @include phone {
          padding-top: 0;
          padding-bottom: 0;
        }

        .container {
            position: relative;
            @include flex-param(row, center, flex-start);

            .col-left {
                flex: 0.9 0 0;
                padding-right: 115px;

                .flex-wrap {
                    margin-bottom: 60px;
                    align-items: center;
                    line-height: 30px;

                    @include phone {
                      justify-content: center;
                      margin-top: 15px;
                      margin-bottom: 0;
                    }

                    .icon-clock2 {
                        margin-right: 15px;
                        width: 40px;
                        min-width: 40px;
                        @include svgcolor($red);
                        @include phone {
                          width: 30px;
              min-width: 30px;
                        }
                    }

                    span.blue.type-title-xs {
                        display: block;
                        letter-spacing: 0;
                        margin-right: 20px;
                        font-weight: 800;
                        font-style: italic;
                        @include phone {
                          font-size: 15px;
                          line-height: 18px;
                          width: 60px;
                        }
                    }

                    .hours p {
                      @include phone {
                        font-size: 3.9vw;
                      }
                    }
                }

                .type-xxxl {
                    margin-bottom: 40px;
                }

                .type-md {
                    line-height: 38px;
                }

            }

            .map {
                border: 5px solid white;
            }

            @include tablet{
                .col-left{
                    padding-right: 90px;
                }
                .col-left .type-xxxl{
                    line-height: 35px;
                }
            }

            @include phone{
                flex-direction: column;
                .col-left{
                    padding-right: 0;
                    width: 100%;
                }
                .col-left .type-xxxl{
                    font-size: 25px;
                    margin-bottom: 5px;
                    text-align: center;
                }
                .col-left .type-md{
                    line-height: 30px;
                    margin-bottom: 20px;
                    text-align: center;
                }
                mini-map,
                .mini-map,
                .map{
                    width: 100%;
                    height: 250px;
                }

                mini-map{
                    margin-bottom: 80px;
                }

                .col-right {
          width: 100%;
        }
            }

        }

    }
}
