#vendor-bid{
	#vendor-bid-carousel .carousel{
		@extend %theme-small-heading;
		.fill{
			background-size: cover;
		}
	}

	#content{

		.row > div{
			text-align: center;
			padding: 135px 0;
			> p{
				margin-bottom: 75px;
			}

			> p.type-vendor-bid-md{
				font-weight: 300;
			}

			> p.type-md{
				line-height: 35px;
			}

		}

		.container .col-sm-12{
			margin-left: 15px;
			margin-right: 15px;
		}

		#bidding-documents .row > div{
			.btn-red-black{
				position: absolute;
				top: 0;
				right: 0;
				span{
					font-weight: 300;
				}
			}

			.download-list > div{
				@include flex-param(row, space-around, center);
				> *{
					flex: 1;
					text-align: center;
					padding: 15px 20px;
				}
			}

			.download-list .title{
				background-color: $light-gray;
				color: white;
			}

			.download-list .list{
				@include border(0,1,1,1,solid,$lighter-gray);
				p:first-child{
					background-color: $lightest-gray;
				}
				*:not(:last-child){
					border-right: 1px solid $lighter-gray;
				}
			}
		}

		#partnerships{
			background-color: $lightest-gray;
			color: black;
			p.type-vendor-bid-md{
				margin-bottom: 135px;
			}
			.type-sm,
			.locations{
				text-align: left;
				line-height: 36px;
				margin-bottom: 55px;
				span{
					font-weight: 600;
				}
			}
			.locations p{
				margin-bottom: 0;
			}

			.info{
				background-color: white;
				color: black;
				text-align: left;
				padding: 50px;
				margin-right: 50px;
			}
		}
	}
}