#terms-of-service{
	.text-bold{
		font-weight: 700;
	}
	#terms_of_service_section{

        h2{
            margin: 50px 0 25px;
            text-transform: uppercase;
        }

		padding-top: 250px;
		padding-bottom: 100px;
	    min-height: calc(100% - 75px);
	    background-color: $lightest-gray;

	    .type-title-xl{
	    	margin-bottom: 140px;
	    	text-align: center;
	    	font-weight: 300;
	    }
	    .type-lg{
	    	font-weight: 600;
	    }
	    p:not(:first-child){
	    	line-height: 36px;
	    	margin-bottom: 30px;
	    	padding-right: 20px;
	    }

	    @include phone{
	    	padding-top: 130px;
		    .type-title-xl{
		    	margin-bottom: 50px;
		    }
		    p:not(:first-child){
		    	margin-bottom: 20px;
		    	padding-right: 0;
		    }
	    }

		a{
			color: $red;
		}

		ul{
			padding-left: 35px;
			margin-bottom: 50px;
			li{
				list-style-type: disc;
				line-height: 36px;
			}
		}
	}
	p.type-lg{
		margin-top: 30px;
	}
}