snapshot-info .snapshot {
    @extend .type-lg;
    @include flex-param(row, flex-start, center);
    li {
        @include flex-param(row, center, center);
        letter-spacing: 2px;
        white-space: nowrap;
        &:not(:first-child) {
            margin-left: 0.5vw;
            @include tablet {
                margin-left: 1vw;
            }
            @include phone{
                margin-left: 0;
            }
        }
        svg {
            @include size(30px);
            @include svgcolor($gray);
            margin-right: 15px;
            @include phone{
                margin-right: 10px;
            }
        }
    }
}
