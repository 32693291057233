@mixin order($num){
  -webkit-order: $num;
  order: $num;
}

@mixin absolute($y,$x,$w:100%,$h:100%){
  position: absolute;
  #{$y}:0;
  #{$x}:0;
  width: $w;
  height: $h;
}

@mixin grayscale($val){
  -webkit-filter: grayscale($val);
  -moz-filter: grayscale($val);
  filter: grayscale($val);
}

@mixin border($top,$right,$bottom,$left,$line,$color){
  border-top: #{$top}px $line $color;
  border-right: #{$right}px $line $color;
  border-bottom: #{$bottom}px $line $color;
  border-left: #{$left}px $line $color;
}

@mixin item-count($item, $num){
  #{$item}:first-child:nth-last-child(#{$num}),
  #{$item}:first-child:nth-last-child(#{$num}) ~ #{$item} {
    @content
  }
}

//Navigation

@mixin bkg-strip {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 100vw;
}

@mixin navbar-flex-override($height, $bkg-color, $align) {
  height: $height !important;
  display: flex !important;
  position: relative;
  justify-content: $align;

  &:after {
    @include bkg-strip;
    background-color: $bkg-color;
    left: 0;
  }
}

@mixin navbar-header($h, $w, $bkg-color) {
  background-color: transparent;

  .navbar-header:before,
  .navbar-header:after {
    background-color: $bkg-color;
  }

  .navbar-header {
    height: $h;
    position: relative;
    width: $w;

    &:after {
      @include  bkg-strip;
      right: 0px;
    }

    .navbar-brand {
      padding: 0;
      position: relative;
      bottom: 0;
      z-index: 1;
      img{
        width: 100%;
      }
    }

  }

}

@mixin navtogglestyle($color){
  .navbar-toggle .icon-bar{
    @include transition(all .3s ease-out);
    position: relative;
    right: 0;
    background-color: $color;
  }
  .navbar-toggle:not(.collapsed){
    .icon-bar{
      right: -5px;
      width: 35px;
      height: 2px;
      @include transition(all .3s ease-in);
      &:nth-child(2){
        top: 5px;
        @include rotate(45);
      }
      &:nth-child(4){
        bottom: 5px;
        @include rotate(-45);
      }
      &:nth-child(3){
        opacity: 0;
        right: -10px;
        width: 0;
      }
    }
  }
}

@mixin homeowner-login(){
  max-width: 930px;
  @include tablet{
      width: 102vw;
      right: -77.5vw;
  }
  @include laptop{
    @include scale(.85);
    transform-origin: top;
  }
  > div:not(.active){
    display: none;
  }

  p {margin-bottom: 40px;}

  a{
    color: white;
    @include hoverstate(transparent, lighten($blue, 10%), .2s);
  }

  .type-xs{line-height: 24px;}
  .type-sm{line-height: 30px;}

  form > input,
  .password-group input{
    //height: 40px;
  }

  .password-group a{margin-top: 15px;}

  .flex-wrap > * {flex: 1;}

  .title-wrap{padding: 50px;}
  @include laptop{.title-wrap{padding: 30px;}}

  .title-wrap .flex-wrap > *{margin-right: 30px;}

  .title-wrap .flex-wrap span {
    position: relative;
    margin-right: 0;
    flex: initial;

    svg {
      position: absolute;
      top: 20px;
      left: 20px;
    }

    input {
      text-align: right;
      width: 140px;
      padding: 15px 20px;
      justify-content: flex-end;
    }

  }

  .login-panel .col-left {
    @include flex-param(column, initial, initial);
    flex: 2;

    .type-subtitle-md{
      font-weight: 300;
      line-height: 42px;
    }

    .top,
    .mid,
    .bottom{padding: 20px 40px;}
    .top {
      background-color: $light-gray;
      p:first-child{
        margin-bottom: 30px;
      }
      p:last-child{
        margin-bottom: 15px;
      }
    }
    .mid {
      background-color: $blue;
      @include flex-param(row, space-between, center);
      svg{@include scale(1.5, right)};
    }
    .bottom {background-clor: $dark-gray;}
    .bottom span{
      font-weight: 600;
      font-style: italic;
    }
    .bottom a{
      display: block;
    }

  }

  .login-panel .col-right {
    flex: 1.5;
    padding: 30px 50px 15px;
    background-color: rgba(white, .9);

    li{@extend %li-circle;}

    li,
    p{
      color: $gray;
      position: relative;
      margin-bottom: 15px;
    }

    p:first-child{
      color: $blue;
      font-weight: 700;
    }

  }

  .forgot-password{
    > .title-wrap .type-sm{
      margin-bottom: 0;
    }
    > .title-wrap .btn.btn-gray{
      @include flex-param(row, space-between, center);
      color: white;
      svg{
        margin-left: 15px;
        margin-right: 0;
        top: 0;
        left: 0;
        position: relative;
      }
    }
    > .flex-wrap{
      background-color: $light-gray;
      @include flex-param(row, center, center);
      @extend .title-wrap;
      input:nth-child(2){
        flex: 3;
        margin-right: 30px;
          height: 55px;
          border-radius: 5px;
      }
    }
  }
}

@mixin main-dropdown-menu{
  overflow: visible;
  > a:after{
    opacity: 1;
    top: 100%;
    @include transition(.3s all ease-in);
  }
  .dropdown-menu{
    margin-top: 0;
    opacity: 1;
    overflow: visible;
    @include transition(.3s all ease-in);
    li a{
      color: black;
      background-color: white;
      padding: 10px 15px;
      text-align: center;
      text-transform: uppercase;
      display: block;
      transition: color .1s ease-in;
      // @include hoverstate($red, white, .2s);
      @include tablet{
        font-size: 12px;
      }
      @include phone{
        background-color: white;
        padding: 10px;
      }
    }
    li a:hover,
    li a:active,
    li a:focus{
      background-color: white;
      color: $red;
    }
    li:not(:last-child) a{
      border-bottom: 1px solid rgba(white, .3);
    }      
    li a.fftf-menu-link{
      background-color: $red;
      color: white;
      transition: all .2s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      flex-wrap: nowrap;

      &:hover,
      &:active{
        background-color: black;
        color: white;
      }
    }
  }
}

@mixin text-shadow($param){
  -webkit-text-shadow: $param;
  -moz-text-shadow: $param;
  text-shadow: $param;
}

@mixin scale($size, $position:center){
  transform-origin: $position;
  -webkit-transform: scale($size);
  -moz-transform: scale($size);
  transform: scale($size);
}

@mixin rotate($deg, $position:center){
  transform-origin: $position;
  -webkit-transform: rotate($deg+deg);
  -moz-transform: rotate($deg+deg);
  transform: rotate($deg+deg);
}

@mixin rotateX($deg, $position:center){
  transform-origin: $position;
  -webkit-transform: rotateX($deg+deg);
  -moz-transform: rotateX($deg+deg);
  transform: rotateX($deg+deg);
}

@mixin rotateY($deg, $position:center){
  transform-origin: $position;
  -webkit-transform: rotateY($deg+deg);
  -moz-transform: rotateY($deg+deg);
  transform: rotateY($deg+deg);
}



@mixin topnav-dropdown($width, $offset){
  .dropdown-content{
    width: $width;
    right: $offset;
    position: absolute;
    height: 0;
    overflow: hidden;
    border-top: none;
    &:after{
      content: "";
      background-color: $red;
      position: absolute;
      height: 5px;
      width: 0;
      top: 0;
      right: 0;
    }
    > *,
    form{
      opacity: 0;
    }
  }

  &:not(.is-touch,.click-only):hover .dropdown-content,
  &.open .dropdown-content {
    height: auto;
    @include transition(background-color .3s ease-in .3s);
    &:after{
      width: 100%;
      @include transition(width .3s ease-in);
    }
    > *,
    form{
      opacity: 1;
      @include transition(all .3s ease-in .6s);
    }
  }
}

//Buttons

@mixin btn-link($bkg-color, $color:white, $hover-bkg: null, $hover-color: null){
  font-weight: 600;
  border-radius: 0px;
  background-color: $bkg-color;
  color: $color;
  padding: 15px 30px;
  outline: none;
  cursor: default;
  @include flex-param(row, center, center);
  @include transition(all .2s ease-out);
  display: inline-flex;
  @include phone{
    padding: 7.5px 15px;
    font-size: 12px;
  }
  svg,.fa{
    margin-right: 15px;
    @include svgcolor($color);
  }
  &:not(.no-hover):hover{
    @if ($bkg-color == white){
      color: $bkg-color;
      @include svgcolor($bkg-color);
    } @else {
      @include transition(all .2s ease-in);
      a{
        @if $hover-color {
          color: $hover-color
        } @else {
          color: $color;
        }
      }
    }
    cursor: pointer;
    @if $hover-bkg {
      background-color: $hover-bkg;
    } @else {
      background-color: $green;
    }
  }
}

//animations
@mixin inview($class, $dir, $delay, $position: relative, $rest: 0){
  #{$class}{
    @include transition(left 1s ease-out);
    position: $position;
    @if($dir != "static"){
      #{$dir}: -70px;
      @include phone{
        #{$dir}: 0px;
      }
    }
    opacity: 0;
  }

  &.in #{$class}{
    @if($dir != "static"){
      @include transition(
        opacity .7s ease-out $delay,
        $dir 1.5s ease-out $delay
      );
    }@else{
      @include transition(
        opacity .7s ease-out $delay
      );
    }
    opacity: 1;
    #{$dir}: $rest;
  }
}

@mixin keyframes($animation-name...) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin animate($animation-name, $prop){
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }

  -webkit-animation: #{$animation-name} #{$prop};
  -moz-animation: #{$animation-name} #{$prop};
  -ms-animation: #{$animation-name} #{$prop};
  -o-animation: #{$animation-name} #{$prop};
  animation: #{$animation-name} #{$prop};
}

//transitions

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin svgtransition($transition...) {
    path,
    rect,
    polygon{
      -moz-transition:    $transition;
      -o-transition:      $transition;
      -webkit-transition: $transition;
      transition:         $transition;
    }
}

//psuedo elem
@mixin psuedo-block($color:black){
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-color: $color;
  @include size(100%);
}

//crossbrowser opacity

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

//hoverstate

@mixin hoverstate($bkgcolor, $color, $speed, $imp:null){
    @include transition(all $speed ease-out);
    cursor: pointer !important;
    &:hover{
      @include transition(all $speed ease-in);
      background-color: $bkgcolor $imp;
      color: $color $imp;
      > *{
        color: $color $imp;
      }
    }
}

@mixin svghover($a, $b, $speed, $stroke: 'no-stroke'){
    path, polygon, rect, ellipse, circle, line{
      @include transition(all $speed ease-out);
      &:not(.no-fill){
        fill: $a;
      }
      @if($stroke != 'no-stroke'){
        stroke: $a;
      }
    }
    cursor: pointer !important;
    &:hover{
      path, polygon, rect, ellipse, circle, line{
        @include transition(all $speed ease-in);
        &:not(.no-fill){
          fill: $b;
        }
        @if($stroke != 'no-stroke'){
          stroke: $b;
        }
      }
    }
}


//svgs

@mixin svgcolor($color, $stroke: 'no-stroke'){
    path, polygon, rect, ellipse, circle, line{
      &:not(.no-fill){
        fill: $color;
      }
      @if($stroke != 'no-stroke'){
        stroke: $color;
      }
    }
}


@mixin svgfilter($dir, $color){
  margin: 0;
  margin-#{$dir}: 15px;
  @include svgcolor($color);
  display: inline-block;
}


//flex

@mixin flex-param($dir: row, $justify: center, $align: center, $flex: flex){
  display: $flex;
  flex-direction: $dir;
  justify-content: $justify;
  align-items: $align;
}


//carousel

@mixin carousel-hover($dir){
  &:after{
    #{$dir}: -100%;
    @include transition(all .3s ease-in);
  }
  &:hover:after{
    @include transition(all .3s ease-out);
    #{$dir}: 0%;
  }
}

//shapes
@mixin circle($rad, $color){
  width: $rad;
  height: $rad;
  border-radius: $rad;
  background-color: $color;
}

@mixin triangle($dir, $width, $height, $color){
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 $width/2 $height $width/2;
  border-color: transparent transparent $color transparent;
  @if($dir == "up"){
    @include rotate(0);
  }
  @if($dir == "right"){
    @include rotate(90);
  }
  @if($dir == "down"){
    @include rotate(180);
  }
  @if($dir == "left"){
    @include rotate(-90);
  }
}

@mixin size($w,$h:null){
  width: $w;
  height: $h;
  @if($h == null){
    height: $w;
  }
}

//cta's

@mixin carousel-right-cta($bkg-color){
  color: white;
  background-color: $bkg-color;
  @extend .type-xxs;
  @include size(105px, 80px);
  @include flex-param(column, center, center);
  @include hoverstate(darken($bkg-color, 10%), white, .3s);
  @include phone{
    @include size(50px);
  }
  > div{
    @include flex-param(column, center, center);
  }
  svg{
    @include size(30px);
    margin-bottom: 10px;
    @include phone{
      margin-bottom: 0;
    }
  }
}

@mixin background-image($url, $size, $pos){
  background-image: url($url);
  background-size: $size;
  background-position: $pos;
}

@mixin column($col...){
    -webkit-columns: $col;
    -moz-columns: $col;
    columns: $col;
}

//effects

@mixin dropshadow($x:0, $y:0, $blur:0, $spread:0, $opacity:0.1, $inset: ''){
  -webkit-box-shadow: #{$inset} $x $y $blur $spread rgba(0,0,0,$opacity);
  -moz-box-shadow: #{$inset} $x $y $blur $spread rgba(0,0,0,$opacity);
  box-shadow: #{$inset} $x $y $blur $spread rgba(0,0,0,$opacity);
}

@mixin innershadow($param, $color, $opacity){
  -webkit-box-shadow: inset $param rgba($color,$opacity);
  -moz-box-shadow: inset $param rgba($color,$opacity);
  box-shadow: inset $param rgba($color,$opacity);
}

@mixin toggleVisible($classA, $classB, $display){
  #{$classA}{
    display: $display;
  }
  #{$classB}{
    display: none;
  }
}

@mixin gradient($type, $prop){
  background: -webkiit-#{$type}-gradient($prop);
  background: -o-#{$type}-gradient($prop);
  background: -moz-#{$type}-gradient($prop);
  background: #{$type}-gradient($prop);
}

//slick slideshow
@mixin slick-control($size, $offset){
    .slick-control{
      cursor: pointer;
        svg{
            @include size($size);
            @include transition(all .2s ease-out);
            @include svgcolor($gray);
        }
        &.prev{
            left: -#{$offset};
        }
        &.next{
            right: -#{$offset};
        }
        &:hover svg{
            @include transition(all .2s ease-out);
            @include svgcolor($red);
        }
    }
}

//fader
@mixin fader($delay){
  opacity: 0;
  @include animate(fadeAnimation, .3s ease-in $delay forwards){
    to{opacity: 1;}
  };
}

@mixin invalid-form-fields {
  &.ng-submitted input.ng-invalid,
  &.ng-submitted select.ng-invalid,
  &.ng-submitted textarea.ng-invalid,
  &.ng-submitted input[type="radio"].ng-invalid + label:before,
  &.ng-submitted input[type="checkbox"].ng-invalid + label:before,
  &.ng-submitted input[type="checkbox"].ng-invalid + label {
    background: pink !important;
    border: 1px solid !important;
    border-color: red !important;
    box-shadow: none !important;
  }
}

@mixin uppercase-placeholders {
    ::-webkit-input-placeholder {
       text-transform: uppercase;
    }

    :-moz-placeholder { /* Firefox 18- */
       text-transform: uppercase;
    }

    ::-moz-placeholder {  /* Firefox 19+ */
       text-transform: uppercase;
    }

    :-ms-input-placeholder {
       text-transform: uppercase;
    }
}

@mixin validation-white {
    .help-block {
        color: white;
        .fa {
            color: white;
        }
    }
}

@mixin form-results-color($color) {
    div.form-results-loader {
       i.fa {
            color: $color;
       }
   }
}

@mixin absolute-param($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}