@mixin shared-favorites-matches-container {
	display: flex;
	justify-content: center;
	// padding: 45px 8vw;
	padding: 45px 80px;
	width: 100%;

	@media screen and (max-width: 480px) {
		padding: 50px 0;
	}
}

@mixin shared-favorites-matches-box--mobile {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;

	@media screen and (min-width: 992px) {
		display: none;
	}

	.title {
		color: #000000;
		text-align: center;

		h3,
		p {
			line-height: 150%;

			@media screen and (max-width: 768px) {
				padding: 0 20px;
			}
		}

		h3 {
			font-size: 20px;
			font-weight: 600;
		}
	}

	.image {
		width: 100%;

		img {
			width: 100%;
			height: 300px;
			object-fit: cover;

			@media screen and (min-width: 992px) {
				object-fit: contain;
			}

		}
	}

	.button {
		text-align: center;

		@media screen and (max-width: 992px) {
			padding: 0 40px;
		}

		button,
		a {
			font-weight: bold;
			height: 56px;
			min-width: 150px;
			border-radius: 10px;
			background: var(--primary-bg, #BA1F31);
			color: #ffffff;

			@media screen and (min-width: 992px) {
				min-width: 250px;
			}
		}

		a {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

@mixin shared-favorites-matches-box {
	width: 100%;
	display: grid;
	max-height: 450px;
	height: 450px;
	grid-template-columns: 1fr 1fr;
	margin: 0;
	padding: 0;
	box-sizing: border-box;

	@media screen and (max-width: 992px) {
		display: none;
		flex-direction: column-reverse;
		width: 100%;
		max-height: 100%;
	}

	div.find-your-favorites__info,
	div.find-your-favorites__image,
	div.view-your-matches__info,
	div.view-your-matches__image {
		max-height: 450px;
	}

	div.view-your-matches__info,
	div.find-your-favorites__info {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 50px 40px;
		gap: 20px;

		h3 {
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: 150%;
			letter-spacing: 0.48px;
		}

		p {
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 32px;
			letter-spacing: 0.4px;
		}

		button,
		a {
			height: 56px;
			padding: 10px 20px;
			border: none;
			border-radius: 10px;
			min-width: 300px;
			background: var(--primary-bg, #BA1F31);
			color: #ffffff;
		}

		a {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	div.view-your-matches__image,
	div.find-your-favorites__image {
		height: 100%;

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
}

@mixin shared-favorites-matches-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 60px 40px;
	// gap: 20px;
	height: auto;
	justify-content: space-between;

	h3 {
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 150%;
		letter-spacing: 0.48px;
	}

	p {
		color: #000;
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 32px;
		letter-spacing: 0.4px;
	}

	button,
	a {
		height: 56px;
		padding: 10px 20px;
		background: var(--primary-bg, #BA1F31);
		color: #ffffff;
		border: none;
		border-radius: 10px;
		min-width: 300px;
	}

	a {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}




#my-favorites.welcome {
	section#content>section {
		.box {
			h4 {
				color: #000;
				display: flex;
				align-items: center;
				gap: 7.5px;
				justify-content: space-between;
				font-size: 18px;
				margin-top: 15px;
				margin-bottom: 15px;
			}

			svg {
				@include size(24px);
				@include svgcolor($red);
			}

			p {
				color: $gray;
			}
		}
	}
}

div#my-welcome-view {
	margin-top: var(--headerHeight);

	// margin-bottom: 80px;
	.container-fluid {
		@media screen and (max-width: 768px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.welcome-text {
		margin-top: 60px;
	}
}

#my-favorites.welcome {
	section#hero {
		padding-left: 0;
		padding-right: 0;

		.title-matches {
			padding: 30px 20px;
			text-align: center;
			margin-top: 0 !important;

			.title-matches__text {
				margin-bottom: 0;
				font-size: 32px;
				color: #000000;
				font-weight: bold;
			}
		}

		.content-welcome {
			display: flex;
			align-items: center;
			margin-left: 10vw;
			margin-bottom: 15px;

			.content-welcome__icon {
				margin-right: 10px;

				svg {
					width: 32px;
					height: 32px;
				}
			}

			.content-welcome__title {
				display: flex;
				font-size: 20px;
				color: #000000;
				// font-weight: bold;
				// margin-bottom: 5px;
			}
		}

		@media screen and (max-width: 768px) {
			display: none;
		}
	}

	.hero--img {
		height: 300px;
		background-position: 50% 20%;
		background-size: cover;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		padding: 0 120px;
		gap: 20px;

		div.text {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			max-height: 300px;
			padding: 3rem;

			h1 {
				font-size: 60px;
				color: #ffffff;
			}

			h5 {
				font-size: 16px;
				color: #ffffff;
				margin-top: 30px;
			}
		}

		div.img {

			padding-top: 20px;

			img {
				width: 100%;
			}
		}

	}

	// .img-responsive {
	// 	max-height: 350px;
	// 	object-fit: cover;
	// 	object-position: 50% 20%;
	// }
	section#hero-mobile {
		margin-top: 110px;

		@media screen and (min-width: 769px) {
			display: none;
		}

		.content-header {
			display: flex;
			width: 100%;
			padding-top: 0px;
			padding-bottom: 10px;
		}

		.content-welcome {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			width: 100%;

			.content-welcome__icon {

				margin-right: 10px;

				svg {
					width: 32px;
					height: 32px;
				}
			}

			.content-welcome__hero {
				text-align: center;
				flex-grow: 1;

				.content-welcome__title {
					font-size: 24px;
					color: #000000;
					font-weight: bold;
				}

				.content-welcome__subtitle {
					font-size: 20px;
					color: #000000;
					margin-top: 10px;
				}
			}
		}

		.hero-text__box {
			display: none;
			padding: 12px 20px;
			border-radius: 10px;
			background: var(--Red, #BA1F31);

			h1 {
				color: var(--White, #FFF);
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: 150%;
				/* 30px */
				letter-spacing: 0.4px;
			}

			p {
				color: var(--White, #FFF);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				/* 24px */
				letter-spacing: 0.32px;
			}
		}
	}

	section#content {
		padding: 0 0 0 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;

		@media screen and (max-width: 768px) {
			padding-top: 0;
		}

		.incomplete-match {
			display: flex;
			justify-content: center;

			padding: 20px;

			.button {
				color: var(--red, #BA1F31);
				text-align: center;
				font-weight: bold;

				@media screen and (min-width: 992px) {
					display: flex;
					align-items: center;
					height: 56px;
					background-color: var(--red, #BA1F31);
					border-radius: 10px;
					color: white;
					padding: 10px 20px;
				}
			}
		}

		section.welcome-to-your-new-home-portal {
			padding: 0;

			@media screen and (min-width: 992px) {
				// padding: 0 8vw;
				padding: 0 80px;

			}

			img {
				height: 400px;

				@media screen and (max-width: 992px) {
					object-fit: cover;
				}

				@media screen and (min-width: 992px) {
					height: auto;
				}
			}

			h3 {
				display: none;

				@media screen and (min-width: 992px) {
					display: block;
				}
			}

			p {
				margin: 0;
				display: none;

				@media screen and (min-width: 992px) {
					display: block;
				}
			}

		}


		section.discover-your-perfect-pairings,
		section.close-call-candidates {
			.reg__cards--communities {

				grid-template-columns: repeat(1, minmax(250px, 1fr));

				@media screen and (min-width: 748px) {
					grid-template-columns: repeat(2, minmax(250px, 1fr));
				}

				@media screen and (min-width: 992px) {
					grid-template-columns: repeat(3, minmax(250px, 1fr));
				}

				@media screen and (min-width: 1560px) {
					grid-template-columns: repeat(4, minmax(250px, 1fr));
				}



			}
		}
	}

	section.take-relationship-next-level {
		position: relative;
		width: 100%;
		padding: 0;
		height: 325px;
		background-image: url('/images/site/my-favorites/Oakley_Market_Interior_Living_Room2.jpg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.8);
			z-index: 1;
		}

		.next-level__container {
			display: flex;
			width: 100%;
			height: 100%;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 2;

			h3 {
				font-size: 24px;
				color: #ffffff;
				font-weight: 400;
				line-height: 32px;
				letter-spacing: 0.4px;
				margin-bottom: 40px;
			}

			@media screen and (max-width: 480px) {
				padding: 0 20px;
				text-align: center;
			}

			.button-box {
				display: flex;
				width: 500px;
				justify-content: space-between;

				@media screen and (max-width: 480px) {
					width: 100%;
				}

				button {
					width: 100%;
				}

				button:nth-child(1) {
					margin-right: 30px;
				}

				@media screen and (max-width: 768px) {
					flex-direction: column;

					button {
						margin-bottom: 20px;
					}

					button:nth-child(1) {
						margin-right: 0;
					}
				}
			}
		}
	}

	section.ready-for-fresh-start {
		padding: 20px;

		@media screen and (min-width: 992px) {
			padding: 40px;
		}

		h4 {
			font-size: 24px;
			color: #BA1F31;
			text-align: center;
			margin: 5px 0;
		}

		p {
			color: #000000;
			text-align: center;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 32px;
			margin: 5px 0;
		}

		.box--buton {
			display: flex;
			justify-content: center;
			margin: 20px 0;

			button {
				font-weight: bold;
				height: 56px;
				min-width: 300px;
			}
		}
	}

	// test scss


	section.view-your-matches__container {
		@include shared-favorites-matches-container;

		div.view-your-matches__box--mobile {
			@include shared-favorites-matches-box--mobile;
		}

		div.view-your-matches__box {
			@include shared-favorites-matches-box;

			div.view-your-matches__info {
				@include shared-favorites-matches-info;
				background: #C2C2C2;
				color: #000000;

				h3,
				p {
					color: #000;
				}

				button,
				a {
					background: var(--primary-bg, #BA1F31);
					color: #ffffff;
				}
			}
		}
	}

	section.find-your-favorites__container {
		@include shared-favorites-matches-container;
		margin-bottom: 80px;

		div.find-your-favorites__box--mobile {
			@include shared-favorites-matches-box--mobile;

		}

		div.find-your-favorites__box {
			@include shared-favorites-matches-box;

			div.find-your-favorites__info {
				@include shared-favorites-matches-info;
				background: #000000;
				color: #000000;

				h3,
				p {
					color: #ffffff;
				}

				button,
				a {
					background: var(--primary-bg, #BA1F31);
					color: #ffffff;
				}
			}
		}
	}
}

#my-favorites.my-matches,
#my-favorites.my-favorites,
#my-favorites.welcome {
	section.ready-to-get-started {
		position: relative;
		background-color: #000000;
		color: #ffffff;
		padding: 60px 20px;

		@media screen and (min-width: 992px) {
			padding: 60px 120px;
		}

		.title {
			text-align: center;

			h3,
			h4 {
				font-style: normal;
				line-height: 150%;
				letter-spacing: 0.48px;
			}

			h3 {
				font-size: 24px;
				font-weight: 400;
			}

			h4 {
				font-size: 20px;
				font-weight: 700;
			}
		}

		.box__form {
			padding: 40px 20px 20px 20px;

			.fhFormMyFavoritesGetReady {
				.component-opt-in {
					display: flex;
					justify-content: center;
					padding: 10px 40px;
				}

				.request-tour {
					display: flex;
					justify-content: center;
					padding: 20px;

					button {
						height: 56px;
						min-width: 300px;
						font-weight: bold;

						&:hover {
							color: #ffffff;
						}
					}
				}
			}
		}

		.sales-agent {
			width: 200px;
			height: 200px;
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
}

.slider-welcome {
	max-height: 450px;
	padding-top: 450px !important;

	// margin-bottom: 60px;
	// &.slider__my-wlecome {
	// 	margin: 0 80px;
	// }

	.reg__card-image-slider {
		height: 450px;
	}

	.reg__card-image-slide {
		img {
			height: 100%;
			width: 100%;
			object-fit: cover !important;
			position: relative;

		}

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
			bottom: 0px;
			right: 0px;
			width: 100%;
			height: 100%;
			// background-color: rgba(186, 31, 49, 0.88);
			background-color: transparent;
		}

	}

	.slide__ff__hero.bg-transparent {

		&::before {
			background-color: transparent !important;
		}

	}
}

// .ff__hero::before {
//     content: "";
//     display: block;
//     position: absolute;
//     top: 0px;
//     left: 0px;
//     bottom: 0px;
//     right: 0px;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(186, 31, 49, 0.88);
// }

.slide__ff__hero {
	// padding-top: var(--headerHeight);
	// background-image: url('/images/site/floorplan-finder/ff_hero.jpg');
	background-size: cover;
	background-position: center center;
	position: relative;
	display: flex;
	width: 100%;
	margin: 0 auto;
	justify-content: center;
	gap: 25px;

	@media screen and (min-width: 350px) {
		min-height: 450px;
		height: 100%;
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(186, 31, 49, .88);
	}
}

.slide__ff__container__hero {
	// width: 100%;
	// display: flex;
	// max-width: 1100px;
	// height: 450px;

	// @media screen and (min-width: 1800px) {
	// 	max-width: 1400px;
	// }
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: minmax(0, 700px) minmax(0, 700px);
	width: 100%;
	max-width: 1200px;

	@media screen and (min-width: 360px) {
		grid-auto-rows: 200px auto;
		grid-auto-flow: row;
		justify-content: center;
	}

	@media screen and (min-width: 900px) {
		grid-auto-flow: column;
		grid-auto-rows: unset;
		justify-content: unset;
	}

	@media screen and (min-width: 1800px) {
		max-width: 1400px;
	}
}

.slide__ff__hero-copy,
.slide__ff__hero-img {
	display: flex;
	position: relative;
	z-index: 1;
	flex: 1;
}

.slide__ff__hero-copy {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 30px;
	padding: 50px 0;
	padding-top: 50px;
	padding-bottom: 50px;
	padding-left: 25px;
	padding-right: 0;

	@media screen and (min-width: 360px) {
		align-items: center;
		gap: 20px;
		padding: 30px 0 0 0;
	}

	@media screen and (min-width: 820px) {
		gap: 30px;
		padding: 50px 0;
		padding-top: 50px;
		padding-bottom: 50px;
		padding-left: 25px;
		padding-right: 0;
		align-items: flex-start;
	}

	@media screen and (min-width: 991px) {
		padding-left: 50px;
	}
}

.slide__ff__hero-img {
	justify-content: center;
	// background-image: url('/images/site/floorplan-finder/ff_hero_fg.png');
	background-size: 250px;
	background-repeat: no-repeat;
	background-position: right bottom;
	// bottom: -22px;
	margin-right: 50px;
	position: relative;
	right: 0;

	@media screen and (min-width: 320px) {
		background-size: contain;
		margin-right: 0;
		background-position: center;
	}

	@media screen and (min-width: 980px) {
		background-size: 400px;
		background-position: right bottom;
		margin-right: 50px;
	}

	@media screen and (min-width: 1200px) {
		background-position: right bottom;
	}
}

.slide__ff__hero-copy h1 {
	color: white;
	font-weight: 600;
}

@media screen and (min-width: 1200px) {
	.slide__ff__hero-copy h1 {
		font-size: 3.5rem;
	}
}

.slide__ff__hero-copy p {
	color: white;
	font-weight: 600;
	font-size: 1.1rem;
}

.slide__ff__hero-copy .btn.btn-white-black {
	border-radius: 5px;
	padding: 7px 25px;
	color: black;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 1rem;
	position: initial;

	@media screen and (min-width: 991px) {
		letter-spacing: 1.5px;
		font-size: 1.25rem;
	}
}