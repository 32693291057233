/* wrap */
#kvDetail {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 
    "header"
    "content"
    "footer";
}
#kvDetail > header {
    grid-area: header;
}
.kv-detail {
    padding: 95px 0px 0;
    padding-top: calc(var(--headerHeight));
    grid-area: content;
    display: grid;
    grid-template-rows: auto 1fr auto;
}
#kvDetail > footer {
    grid-area: footer;
}
@media screen and (min-width: 768px) {
    .kv-detail {
        padding: 140px 0px 0;
        padding-top: calc(var(--headerHeight));
    }
}

/* top */
.kv-detail__top {
    padding: 30px 30px;
    background: #f5f5f5;
}
.kv-detail__title {
        font-size: 24px;
        margin-top: 15px;
    }
@media screen and (min-width: 768px) {
    .kv-detail__top {
        padding: 30px 50px;
    }
    .kv-detail__title {
        font-size: 40px;
    }
}

/* iframe */
.kv-detail__iframe {
    width: 100%;
    height: 100%;
    min-height: 80vh;
    min-height: 80dvh;
}


/* disclaimer */
.kv-detail__disclaimer {
    padding: 30px;
    line-height: 1.2;
}
.kv-detail__disclaimer p {
    line-height: 1.5;
    color: #8D8D8D;
    font-size: 16px;
}
@media screen and (min-width: 768px){
    .kv-detail__disclaimer {
        padding: 50px;
    }
}
@media screen and (min-width: 1200px){
    .kv-detail__disclaimer {
        padding: 70px;
    }
}