building-animation{
	perspective: 1000px;
	> div{
		background-image: url('/images/site/building-a-better-community/building-fischer-red.png');
		display: inline-block;
		position: relative;
		opacity: 1;
		background-size: cover;
		&:nth-child(odd){transform-origin: bottom;}
		&:nth-child(even){transform-origin: left;}
		@include size(55px,135px);

		&.b{background-position: 0;}
		&.u{background-position: -48px;}
		&.i{width: 30px; background-position: -100px;}
		&.l{background-position: -122px;}
		&.d{background-position: -170px;}
		&.n{background-position: -245px;}
		&.g{background-position: -296px;}
		
		@include phone{
			@include size(35px,95px);
			&.u{background-position: -38px;}
			&.i{width: 15px; background-position: -75px;}
			&.l{background-position: -90px;}
			&.d{background-position: -125px;}
			&.n{background-position: -180px;}
			&.g{background-position: 35px;}
		}

	}
}