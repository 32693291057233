#financing{
	section#hero{
		@extend %theme-large-heading;
	}

	svg{
		@include size(25px);
		@include svgcolor(white);
	}

	section#content{
		@include flex-param(row, center, center);
		@include phone{
			flex-direction: column;
			padding-bottom: 80px;
		}
		> div{
			flex: 1;
			height: 480px;
			color: white;
			line-height: 72px;
			padding: 80px;
			font-weight: 300;
			@extend .type-financing-md;
			@include flex-param(column, center, flex-start);
			p,h1,h2{
				margin-bottom: 80px;
			}

			@mixin param($width, $font){
				width: $width;
				padding: 45px;
				p,h1,h2{				
					margin-bottom: 50px;
					font-size: $font;
					line-height: 55px;	
				}
				p,h1,h2,.btn{
					width: 100%;
				}
			}
			@include desktop{
				p,h1,h2{				
					margin-bottom: 55px;
					font-size: 35px;
					line-height: 55px;
					br{
						display: none;
					}
				}
			}
			@include tablet{@include param(50%, 35px);}
			@include phone{@include param(100%, 28px);}
			@include phone{
				height: 400px;
			}

			&:first-child{
				@include background-image('/images/site/financing/house.jpg',cover,center);
			}
			&:last-child{
				@include background-image('/images/site/financing/couple.jpg',cover,center);
			}
		}
	}

}