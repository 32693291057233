#customer-testimonials{
	#hero{
		height: 420px;
	    background-position: center top;
	    background-repeat: no-repeat;
	    background-size: cover;
	    padding-top: 176px;
	    color: white;
	    @include flex-param(row, center, center);
		.type-subtitle-xl{
			font-weight: 300;
		}
		@include phone{
			background-position: -420px top;
			padding-top: 142px;
			text-align: center;
		}
	}

	#testimonials-grid{
    background-color: $lightest-gray;
		padding-top: 75px;
    padding-bottom: 75px;
		@include phone{
			margin-bottom: 80px;
			padding-top: 45px;
      padding-bottom: 45px;


		}

    .testimonials {
      @include flex-param($align: stretch);
      flex-wrap: wrap;
      gap: 30px;
      @include tablet {
        gap: 15px;
      }
      @include phone {
        gap: 15px;
      }
    }
		.box-testimony {
		  border: 1px solid transparent;
		  &:nth-child(4n+1),
      &:nth-child(4n) {
        width: calc(41.66666667% - 15px);
        @include phone {
          width: 100%;
        }
      }
      &:nth-child(4n+2),
      &:nth-child(4n+3) {
        width: calc(58.33333333% - 15px);
        @include phone {
          width: 100%;
        }
      }
		  > div {
		    @include flex-param($dir: column, $justify: flex-start, $align: flex-start);
		    gap: 22.5px;
		    min-height: 364.5px;
		    position: relative;
		    @include desktop {
		      min-height: 299px;
		    }
		    @include tablet {
          gap: 15px;
          min-height: 227px;
        }
        @include phone {
          gap: 15px;
          min-height: 0;
        }
		  }
      &.black > div,
      &.white > div {
        padding: 45px;
        @include desktop {
          padding: 30px;
        }
        @include tablet {
          padding: 22.5px;
        }
        @include phone {
          padding: 22.5px;
        }
        &:before,
        &:after {
          position: absolute;
        }
        &:before {
          content: url('data:image/svg+xml; utf8, <svg class="icons-quote-left" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" width="75" height="75" viewBox="0 0 354.9 329.8" enable-background="new 0 0 354.896 329.807" xml:space="preserve"><path fill="rgba(127, 127, 127, 0.15)" fill-rule="evenodd" clip-rule="evenodd" d="M0 138c0.7 8.9 1.3 17.9 2.1 26.8 2.3 25.6 5.4 51.1 16.4 74.6 17 36.6 44.6 63.4 79.9 82.4 4.7 2.5 9.5 4.8 14.6 7.4 10.1-16.3 20-32.3 29.5-47.7 -10-6.4-20.1-11.9-29.1-18.8 -16.3-12.5-26.6-29.4-33.7-48.5 -7.8-21.2-11.8-53.4-11.5-70.7 21 0 42.1 0 63.8 0 0-48.3 0-95.9 0-143.5C88 0 44 0 0 0 0 46 0 92 0 138zM212 0c0.1 2 0.2 4 0.2 6 0 39.5-0.4 79 0.1 118.4 0.4 29.3 2.3 58.5 9 87.2 9.9 42.1 34.8 73.8 69.7 98.1 10.6 7.4 22.2 13.3 33.7 20.1 10.5-16.9 20.4-32.9 30.1-48.6 -8.9-5.4-17.8-10-25.8-15.8 -23.3-16.9-35.5-40.8-42.2-68 -4.3-17.5-6.1-35.3-6.9-53.8 2.3-0.1 4.2-0.3 6.1-0.3 17.8 0 35.6-0.1 53.5 0 3.3 0 4.7-0.8 4.7-4.5 -0.1-45.6-0.1-91.3-0.1-136.9 0-0.7-0.2-1.3-0.3-2C300 0 256 0 212 0z"/></svg>');
          top: 30px;
          left: 45px;
          @include desktop {
            top: 15px;
            left: 30px;
          }
          @include tablet {
            top: 7.5px;
            left: 22.5px;
          }
          @include phone {
            top: 7.5px;
            left: 22.5px;
          }
        }
        &:after {
          content: url('data:image/svg+xml; utf8, <svg class="icons-quote-right" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" width="75" height="75" viewBox="0 0 354.9 329.8" enable-background="new 0 0 354.896 329.807" xml:space="preserve"><path fill="rgba(127, 127, 127, 0.15)" fill-rule="evenodd" clip-rule="evenodd" d="M354.9 138c-0.7 8.9-1.3 17.9-2.1 26.8 -2.3 25.6-5.4 51.1-16.4 74.6 -17 36.6-44.6 63.4-79.9 82.4 -4.7 2.5-9.5 4.8-14.6 7.4 -10.1-16.3-20-32.3-29.5-47.7 10-6.4 20.1-11.9 29.1-18.8 16.3-12.5 26.6-29.4 33.7-48.5 7.8-21.2 11.8-53.4 11.5-70.7 -21 0-42.1 0-63.8 0 0-48.3 0-95.9 0-143.5 44 0 88 0 132 0C354.9 46 354.9 92 354.9 138zM142.9 0c-0.1 2-0.2 4-0.2 6 0 39.5 0.4 79-0.1 118.4 -0.4 29.3-2.3 58.5-9 87.2 -9.9 42.1-34.8 73.8-69.7 98.1 -10.6 7.4-22.2 13.3-33.7 20.1C19.6 312.9 9.7 296.9 0 281.2c8.9-5.4 17.8-10 25.8-15.8 23.3-16.9 35.5-40.8 42.2-68 4.3-17.5 6.1-35.3 6.9-53.8 -2.3-0.1-4.2-0.3-6.1-0.3 -17.8 0-35.6-0.1-53.5 0 -3.3 0-4.7-0.8-4.7-4.5 0.1-45.6 0.1-91.3 0.1-136.9 0-0.7 0.2-1.3 0.3-2C54.9 0 98.9 0 142.9 0z"/></svg>');
          bottom: 30px;
          right: 45px;
          @include desktop {
            bottom: 15px;
            right: 30px;
          }
          @include tablet {
            bottom: 7.5px;
            right: 22.5px;
          }
          @include phone {
            bottom: 7.5px;
            right: 22.5px;
          }
        }
        p {
          &.author {
            font-size: 19.5px;
            @include desktop { font-size: 16.5px; }
            @include tablet { font-size: 13.5px; }
            @include phone { font-size: 13.5px; }
          }
        }
      }
		  &.black {
		    background-color: #000;
		    > div {
		      justify-content: space-between;
          > div {
            @include flex-param($dir: column, $justify: flex-start, $align: flex-start);
            gap: 22.5px;
            @include tablet {
              gap: 15px;
            }
            @include phone {
              gap: 15px;
            }
          }
          p, .quote {
            color: #fff;
          }
          .quote {
            p, span {
              font-size: 24px;
              line-height: 37.5px;
              @include desktop {
                font-size: 21px;
                line-height: 30px;
              }
              @include tablet {
                font-size: 15px;
                line-height: 24px;
              }
              @include phone {
                font-size: 15px;
                line-height: 24px;
              }
            }
          }
          .btn.btn-white {
            color: #000;
            padding: 7.5px;
            @include tablet { font-size: 13.5px; }
            @include phone { font-size: 13.5px; }
            &:hover {
              background-color: $red;
              color: #fff;
            }
          }
        }
		  }
		  &.white {
		    background-color: white;
		    border-color: $lighter-gray;
		    > div {
          p, .quote {
            color: #000;
          }
          .quote {
            p, span {
              font-size: 36px;
              font-weight: 700;
              line-height: 52.5px;
              @include desktop {
                font-size: 27px;
                line-height: 41.25px;
              }
              @include tablet {
                font-size: 24px;
                line-height: 37.5px;
              }
              @include phone {
                font-size: 24px;
                line-height: 37.5px;
              }
            }
          }
        }
      }
      &.video {
        .embed-responsive-16by9 {
          width: 100%;
        }
      }
		}

		.filter{
			margin-bottom: 80px;
		}

		show-more span{
			margin: 50px 0 10px;
      color: black;
		}
	}
}