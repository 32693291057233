#my-favorites {
	
	section#hero {
		padding-left: 0; 
		padding-right:0;
		.title-matches {
			padding: 30px 20px;
			text-align: center;
			margin-top: 0 !important;
			.title-matches__text {
				margin-bottom: 0;
				font-size: 32px;
				color: #000000;
				font-weight:bold;
			}
		}
		.content-welcome {
			display: flex;
			align-items: center;
			margin-left: 10vw;
			margin-bottom: 15px;
			.content-welcome__icon {
				margin-right: 10px;
				svg {
					width: 32px;
					height: 32px; 
				}	
			}
			.content-welcome__title {
				display: flex;
				font-size: 20px;
				color: #000000;
				// font-weight: bold;
				// margin-bottom: 5px;
			}
		}
		@media screen and (max-width: 768px){
			display: none;
		}
	} 
	section#hero-mobile {
		margin-top: 110px;
		@media screen and (min-width: 769px){
			display: none;
		}
		.content-header {
			display: flex;
			width: 100%;
			padding-top: 40px;
			padding-bottom: 10px;
		}
		.content-welcome {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			width: 100%;
			.content-welcome__icon {
			
				margin-right: 10px;
				svg {
					width: 32px;
					height: 32px; 
				}	
			}
			.content-welcome__hero {
				text-align: center;
				flex-grow: 1;
				.content-welcome__title {
					font-size: 24px;
					color: #000000;
					font-weight: bold;
				}
				.content-welcome__subtitle {
					font-size: 20px;
					color: #000000;
					margin-top: 10px;
				}
			}
		}
		.hero-text__box {
			padding: 12px 20px;
			border-radius: 10px;
			background: var(--Red, #BA1F31);
			h1 {
				color: var(--White, #FFF);
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: 150%; /* 30px */
				letter-spacing: 0.4px;
			}
			p {
				color: var(--White, #FFF);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%; /* 24px */
				letter-spacing: 0.32px;
			}
		}
	}
	section#content{
		padding: 75px 15vw;
		@include flex-param(row, center, stretch);
		@include desktop {
			padding-left: 15px;
			padding-right: 15px;
		}
		@include tablet {
			padding-left: 15px;
			padding-right: 15px;
		}
		@include phone{
			flex-direction: column;
			padding-top: 15px;
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 75px;
		}
		> aside.sidebar-left {
			background-color: $lighter-gray;
			min-width: 240px;
			padding: 30px;
			@include phone {
				padding: 15px;
				width: 100%;
			}
			p, a {
				color: #000;
				line-height: 30px;
			}
			a:hover {
				color: $red;
			}
			.back {
				display: none;
				margin-bottom: 15px;
				a {
					display: flex;
					align-items: center;
					gap: 7.5px;
				}
				svg {
					@include size(18px);
					@include svgcolor(#000);
				}
			}
			.welcome-name {
				display: flex;
				font-size: 13.5px;
				font-style: italic;
				gap: 15px;
				a {
					color: $red;
					display: none;
					font-size: 12px;
					font-style: normal;
					@include phone {
						display: inline-block;
					}
				}
			}
			ul {
				display:flex;
				flex-direction: column;
				gap: 15px;
				margin-top: 30px;;
				margin-bottom: 30px;
				li a {
					display: flex;
					font-weight: bold;
					gap: 30px;
					justify-content: space-between;
				}
			}
			.your-account,
			.contact {
				font-size: 13.5px;
			}
		}
		> ul {
			border-bottom: 1px solid $lighter-gray;
			display: none;
			justify-content: space-between;
			padding-left: 15px;
			padding-right: 15px;
			width: 100%;
			@include phone {
				display: flex;
			}
			li {
				position: relative;
			}
			.active {
				&::after {
					background-color: $red;
					content: '';
					height: 3px;
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
				}
			}
			a {
				color: #000;
				display: block;
				font-size: 3vw;
				padding-top: 15px;
				padding-bottom: 15px;
			}
		}
		> section {
			// padding-left: 45px;
			// width: calc(100% - 240px);
			width: 100%;
			@include phone {
				// padding: 20px;
				width: 100%;
			}
			.hero-bkg {
				margin-bottom: 30px;
				width: 100%;
			}
			h3 {
				color: #000;
				font-size: 21px;
				margin-bottom: 15px;
			}
			p {
				margin-bottom: 30px;
				line-height: 24px;
				@include phone {
				}
			}
			.subnav {
				display: flex;
				gap: 30px;
				margin-top: 30px;
				margin-bottom: 30px;
				@include tablet {
					gap: 20px;
				}
				@include phone {
					gap: 15px;
					justify-content: center;
				}
				li {
					cursor: pointer;
					font-size: 15px;
					letter-spacing: .75px;
					padding-bottom: 3.75px;
					&.active {
						border-bottom: 1px solid $lighter-gray;
						color: #000;
						font-weight: 700;
					}
					@include phone {
						font-size: 3vw;
					}
				}
			}
			.cards {
				display: none;
				flex-wrap: wrap;
				gap: 45px;
				// justify-content: space-between;
				@include phone {
					gap: 15px;
				}
				&.active {
					display: flex;
				}
			}
			.card {
				border: 1px solid $lighter-gray;
				max-width: 450px;
				width: calc(50% - 23px);
				height: 450px;
				@include tablet {
					width: 100%;
				}
				@include phone {
					width: 100%;
				}
			}
		}
	}

	.reg__card-title {
		margin: 0;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.3;
		color: black;
		letter-spacing: 1px;
		margin-bottom: 0 !important;
		text-align: left !important;
	}
}

.sidebar-overlay {
	position: fixed;
	top: 126px;
	left: 0;
	width: 328px;
	height: 100%;
	background: #FFF;
	color: #000000;
	transform: translateX(-100%);
	transition: transform 0.3s ease;
	z-index: 1000;
	@media screen and (max-width: 768px){
		top: 110px;
	}
	&.open {
		transform: translateX(0);
	}
	.sidebar-overlay__box {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 550px;
		padding: 0 5px 0 15px;
		@media screen and (max-width: 768px){
			min-height: 450px;
		}
		
		div.back {
			display: flex;
			width: 100%;
			padding: 20px 10px;
			justify-content: flex-end;
			a.btn-back {
				font-size: 24px;
				color: var(--Text, #585858);
			}
		}
		ul li {
			
			color: #000;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: 32px; 
			letter-spacing: 0.4px;
			
			div.spacer {
				display: flex;
				width: 100%;
			} 
			a {
				display: flex;
				  justify-content: space-between;
				color: #000;
				padding: 10px 35px 10px 10px;

				svg {
					position: relative;
					top: 5px;
				}
			}
			&.active {
				color: var(--Red, #BA1F31);
				a {
					color: var(--Red, #BA1F31);
				}

				svg > path {
					stroke: var(--Red, #BA1F31);
				}
			}
		}
		div p.your-account a, 
		div p.sign-out a {
			color: var(--Text, #585858);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 32px; /* 200% */
			letter-spacing: 0.32px;
			text-transform: capitalize;
			padding-left: 10px;
		}
	}
}
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: none;
	z-index: 999;
	&.show {
		display: block;
	}
}
@import "partials/welcome";
@import "partials/my-matches";
@import "partials/my-favorites";
@import "partials/my-design-style";
@import "partials/special-offers";
@import "partials/your-account";