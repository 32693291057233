//header bkg helper
#staging section{
	padding: 200px 0 100px;
}

.white{color: white;}
.red{color: $red;}
.gray{color: $gray;}
.black{color: black;}
.blue{color: $blue;}
.red{color: $red !important;}
.light-gray{color: $light-gray;}

.bkg-red{
	background-color: $red;
}

span.read-more-toggle{
	display: none;
}

.flex-wrap{
	display: flex;
}

.sort {
	@include flex-param(row, flex-end, center);

	.type-subtitle-lg {
		margin: 0 auto 0 0;
		font-weight: 300;
	}

	a {
		color: $red;

		svg {
			@include svgcolor($red);
			@include size(20px);
			margin-right: 5px;
		}
	}

	@extend %sort-arrows;
}

.more-info{
    margin: 0 5px;
    border-radius: 20px;
    border: none;
    color: white;
    line-height: 0;
    font-size: 16px;
    @include size(20px);
	@include flex-param(column, center, center);
    display: inline-flex;
    @include hoverstate($red,white,.2s);
    + .popover{max-width:500px;}
}

%navreset{
	background-color: white;
	border: none;
	border-radius: 0;
	margin-bottom: 0;
	li{
		cursor: pointer;
	}
}

%li-circle{
	padding-left: 20px;
	position: relative;
	&:after{
		@include circle(10px, $red);
		position: absolute;
		content: "";
		top: 7px;
		left: 0;
		z-index: 1;
	}
}

%tab-list-style{
	color: $gray;
	@include hoverstate(transparent, $red, .2s);
	&.active{
		color: $red;
	}
	&:after{
		content: "|";
		color: $gray;
		margin-left: 15px;
	}
	&:last-child:after{
		content: "";
	}
}


%sort-arrows{
	ul, li{
		@include flex-param(row, space-between, center);
		&:not(:first-child){
			margin-left: 30px;
		}
	}
	li i{
		font-size: 30px;
		display: block;
		line-height: 18px;
		margin-left: 10px;
		cursor: pointer;
		color: $lighter-gray;
		@include hoverstate(transparent, $red, .2s);
	}

	.sort-item {
		@extend .type-md;
		@include flex-param(row, space-between, center);

		cursor: pointer;

		margin-right: 30px;
		&:last-child {
			margin-right: 0;
		}

		.arrows {
			display: inline;
		}

		.arrow {
			font-size: 30px;
			display: block;
			line-height: 18px;
			margin-left: 10px;
			cursor: pointer;
			color: $lighter-gray;
			@include hoverstate(transparent, $red, .2s);
			&.active {
				color: $red;
			}
		}
	}
}

%thumbnail-param{
	background-color: $lightest-gray;
	background-size: cover;
	background-position: center center;
	img{
	    position: relative;
	    top: -5px;
	    left: -5px;
	}
}

//helper theme styles
%microsite-title{
	p.type-subtitle-xl {
		text-align: center;
		font-weight: 300;
		margin-bottom: 60px;
		@include phone{
			margin-bottom: 30px;
		}
	}
}

%theme-small-heading{
	max-height: 420px;
	.fill{
		background-attachment: fixed;
		background-size: 100% auto;
	}
	.flex-caption{
		color: white;
		padding-top: 100px;
		font-weight: 300;
		@include flex-param(column, center, center);
	}
}

%theme-large-heading{
	min-height: 615px;
	background-color: $lightest-gray;
	padding-top: 100px;
	font-weight: 300;
	@include flex-param(column, center, center);
	@include phone{
		min-height: calc(100vh - 80px);
	}
	p,h1,h2{
		margin-bottom: 80px;
		&.type-md{
		    text-align: center;
		    line-height: 40px;
		    @include phone{
			    line-height: 30px;
		    }
		}
		@include phone{
			margin-bottom: 40px;
		}
	}
}

%testimonials-formatting{
	p{
		line-height: 48px;
		margin-bottom: 50px;
		padding: 0 250px;
		@include desktop{
			padding: 0 150px;
		}
		@include tablet{
			padding: 0 50px;
		}
		@include phone{
			padding: 0 10px;
			font-size: 5vw;
			line-height: 35px;
		}
	}
	i{
		margin-bottom: 50px;
		font-style: italic;
	}
}

%design-copy-formatting{
	p.type-md,
	h2.type-md{
		line-height: 42px;
		margin-top: 60px;
	}
	p.type-design-xl,
	h2.type-design-xl{
		letter-spacing: 5px;
	}
	p:not(.type-md){
		margin-bottom: 20px;
	}
}

%carousel-with-bottombar{
	position: relative;
	overflow: hidden;

	.carousel .carousel-indicator{
		@extend %carousel-indicator-style;
	}

	.carousel .carousel-inner .flex-caption{
		@include flex-param(column, center, center);
		color: white;
		font-weight: 300;
		padding-top: 50px;
		padding-left: 150px;
		padding-right: 150px;
		@include phone{
			padding-left: 20px;
			padding-right: 20px;
		}

		p {
			letter-spacing: 20px;
			text-align: center;
			opacity: 0;

			@include keyframes(kern-in) {
				0% {
					letter-spacing: 20px;
				}

				0%,
				20% {
					opacity: 0;
				}

				100% {
					opacity: 1;
					letter-spacing: 5px;
				}

			}

			@include animation(kern-in 2s ease-out 1.5s forwards);
		}

	}

	.bottom-bar {
		background-color: rgba(black, .7);
		bottom: 0;
		width: 100%;

		@include keyframes(bottomBar) {
			0%,
			80% {
				opacity: 0;
				bottom: -90px;
			}

			100% {
				opacity: 1;
				bottom: 0;
			}

		}

		@include animation(bottomBar 2s ease-out);

		.flex-wrap {
			color: white;
			height: 90px;
			@include flex-param(row, space-between, center);

			p,
			li {
				font-weight: 300;
			}

			svg {
				@include svgcolor(white);

				&.icon-tennis {
				  circle, line, path {
				    stroke: white;
				    fill: none;
          }
				}
			}

			.col-left {
				flex: 1;

				p {
					cursor: pointer;
				}

				svg {
					margin-left: 20px;
				}

			}

			.col-right {
				svg {
					@include size(25px);
					margin-right: 5px;
					position: relative;
					top: 4px;
				}

		        li {
		          margin-right: 15px;
		        }

			}
			@include phone{
				.col-left{
					padding: 0 30px;
					text-align: center;
				}
				.col-left p{
					font-size: 20px;
					line-height: 25px;
				}
			}

		}

	}
}

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

//helper animations

%pulse{
	position: relative;
	@include animation(pulseAnimate 1.5s infinite ease-in-out);
	@include keyframes(pulseAnimate){
		0%, 100%{opacity: 0.5;}
		30%, 70%{opacity: 1;}
	}
}

#icon-all{
    background-color: red;
    svg{
        @include svgcolor(white);
        @include size(30px);
        margin: 30px;
        > *[fill="none"]{
        	stroke: white;
        }
        &::after{
        	content: " (" attr(class) ") ";
        	color: white;
        }
    }
}

.hide-phone {
  @include phone {
    display: none !important;
  }
}
