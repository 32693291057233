#building-process{
	section#building-process-carousel{
		.carousel{
    		height: calc(100vh - 75px);
    		min-height: 765px;
    		@include phone{
    			min-height: 865px;
    		}
		}

		.flex-caption{
			position: absolute;
			color: white;
			padding-top: 180px;
			perspective: 2000px;
			@include size(100%);
			@include flex-param(column, flex-start, center);

			p.type-building-process,
			h1.type-building-process{
			    margin-bottom: 80px;
			    text-align: center;
			}

			@include phone{
				padding-top: calc(90px + var(--headerHeight));
				p.type-building-process,
				h1.type-building-process{
				    font-size: 35px;
				    line-height: 45px;
				    margin-bottom: 40px;
				}
			}
			.caption{
				width: 70%;
				background-color: rgba(255, 255, 255, .5);
				padding: 50px;
				text-align: left;
				opacity: 0;
				color: black;
				p,
				h2{
					opacity: 0;
				}
				p.type-xxxl,
				h2.type-xxxl{
					margin-bottom: 30px;
				}
				p.type-md{
					line-height: 40px;
				}
				@include transition(all .3s ease-in);
				@include rotateX(-90);
				&.active{
					opacity: 1;
					@include transition(all .3s ease-in .3s);
					@include rotateX(0);
					p,
					h2{
						@include transition(all .3s ease-in .6s);
						opacity: 1;
					}
				}
				@include phone{
					width: 90%;
					padding: 30px;
					p.type-xxxl,
					h2.type-xxxl{
					    font-size: 20px;
					    line-height: 28px;
					}
					p.type-md{
					    font-size: 12px;
					    line-height: 28px;
					}
				}
			}
			.custom-controls{
				margin-bottom: 50px;
				@include flex-param(row, center, center);
				svg{
					@include size(30px);
					@include svghover(white, $red, .2s);
				}
				> *{
					cursor: pointer;
				}
				ul{
					margin: 0 30px;
					li{
						@include size(30px);
						border-radius: 30px;
						background-color: white;
						color: $red;
						text-align: center;
						line-height: 30px;
						margin: 0 5px;
						white-space: nowrap;
						@include transition(all .3s ease-in);
						@include hoverstate($red, white, .2s);
						span{
							display: none;
						}
						&.active{
							background-color: $red;
							color: white;
							width: 80px;
							@include transition(all .3s ease-out);
							span{
								display: inline-block;
							}
						}
					}
				}

				@include phone{
					ul li{
						font-size: 12px;
						&.active{
							width: 30px;
							font-size: 12px;
							span{
								display: none;
							}
						}
					}
					span{
						position: relative;
						&:first-child{
							left: 15px;
						}
						&:last-child{
							right: 15px;
						}
					}
				}
			}
		}

		.controls-wrap{
			display: none;
		}
	}
}