footer{
	background-color: $black;

	.container {
	  @include phone {
	    padding-bottom: 90px;
	  }

    .flex-wrap {
      height: 75px;
      color: white;
      @include flex-param(row, space-between, center);

      @include phone {
        @include flex-param(column, space-between, center);
        padding-top: 15px;
      }

      li, a {
        @extend .type-xxs;
      }
      li {
        &:after {
          content: "|";
          position: relative;
          left: 7px;
        }
        &:first-child:after,
        &:last-child:after {
          display: none;
        }
        &:first-child {
          font-size: 3px;
          text-align: center;
          vertical-align: middle;

          @include phone {
            float: left;
          }
        }
      }
      a {
        color: white;
        @include hoverstate(transparent, $red, .2s);
        text-transform: capitalize;

        &.tos{
          text-transform: unset;
        }
      }
      @include tablet {
        a {
          line-height: 15px;
          text-align: right;
        }
      }
    }

    .equal-housing {
      display: inline-block;
      margin-bottom: 3px;

      svg {
        height: 20px;
        margin-right: 2px;
        top: 2px;
        position: relative;
      }
    }
  }
}

footer #mobile-menu{
	background-color: $red;
    position: fixed;
    height: 80px;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: none;
    @include dropshadow(0, -5px, 10px, 0, 0.3);
    @include phone{
        @include flex-param(row,center,center);
    }
    a{
    	@include flex-param(column,center,center);
    	display: inline-flex;
    	color: white;
    	width: 28%;
    	text-align: center;
    	font-size: 11px;
    	height: 100%;
        &:not(:last-child){
    	   @include hoverstate(darken($red,10%),white,.2s);
    		border-right: 1px solid rgba(white,.5);
    	}
        &:nth-of-type(2){width: 25%;}
    	&:last-child{
            width: 22%;
        }
    }
    a.active{
        background-color: darken($red,10%);
    }
    svg{
    	@include svgcolor(white);
    	@include size(35px,25px);
    	margin-bottom: 10px;
    }
}