#long-interest-list{
	#hero{
		@include background-image('/images/site/long-interest-list/hero-bkg.jpg', cover, center);
		height: 460px;
		color: white;
		padding-top: 100px;
		@include flex-param(column, center, center);
		.type-subtitle-xl{
			font-weight: 300;
			margin-bottom: 65px;
		}
	}

	#content{
		.container form.row > div{
			padding: 90px;
		}
		.container form.row > .col-left{
			background-color: $lightest-gray;
			padding-left: 0;
			input[type="radio"]{
				margin: 0;
				border: none;
				box-shadow: none;
				@include size(30px);
			}
			label{
				margin-top: 15px;
				margin-bottom: 5px;
				line-height: 25px;
				+ ul,
				+ .checkbox-group{
					margin-top: 10px;
				}
			}
			> .form-group:first-child label{
				margin-top: 0;
			}
			&:after{
				@include psuedo-block($lightest-gray);
				width: 500%;
				left: -500%;
			}

			.location{
				justify-content: space-between;			
				 > div{
					width: splitwidth(3,10);
				}
			}

			.form-group.financing,
			.form-group.home-type{
				margin-top: 50px;
			}

			.form-group.financing .flex-wrap{
				align-items: center;
				span{
					margin-right: 30px;
				}
			}

			.form-group.home-type li{
				@include flex-param(row, flex-start, center);
			}
		}
		.container form.row > .col-right{
			padding-right: 0;
			.row{
				margin-left: 0;
			}
			p.type-xl,
			.location-group{
				margin-bottom: 50px;
			}
			.location-group p{
				margin-bottom: 25px;
			}
			.location-group li{
				margin-bottom: 15px;
				text-transform: uppercase;
				align-items: center;
				input{
					width: 35px;
					border: none;
					box-shadow: none;
					margin: 0;
				}
			}
			.location-group > div{
				padding: 0;
				&.widen{
					width: 40%;
				}
			}
		}
	}
}