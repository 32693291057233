section#builders-webcam {
	@extend %section-padding;
	@include inview(".type-title-xl", static, 0s);
	@include inview(".video", bottom, .3s);
	background-color: $lightest-gray;

	.type-title-xl{
		@extend %section-title-margin;
	}
	.video{
		display: inline-block;
		border: 40px solid white;
	}
}