div#my-favorites-view {
	margin-top: var(--headerHeight);

	.container-fluid {
		@media screen and (max-width: 768px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.slider-welcome {
		@media screen and (max-width: 768px) {
			display: none !important;
		}

		&.slider__my-favorites {
			margin: 0 80px;
		}
	}
}

#my-favorites.my-favorites {
	.hero--img {
		height: 300px;
		background-position: 50% 20%;
		background-size: cover;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		padding: 0 120px;
		gap: 20px;

		@media screen and (min-width: 768px) {
			display: none;
		}

		div.text {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			max-height: 300px;
			padding: 3rem;

			h1 {
				font-size: 54px;
				color: #ffffff;
			}

			h5 {
				font-size: 16px;
				color: #ffffff;
				margin-top: 30px;
			}
		}

		div.img {

			padding-top: 20px;

			img {
				width: 100%;
			}
		}

	}

	section#content {
		padding: 75px 4vw;


		@media screen and (max-width: 480px) {
			padding: 35px 20px;
		}



		.nav__tabs--desktop {
			display: none;

			@media screen and (min-width: 992px) {
				display: flex;
				justify-content: center;
			}
		}

		.nav__tabs--mobile {
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 30px 0;

			@media screen and (min-width: 992px) {
				display: none;
			}

			.left {
				font-weight: bold;
				color: #000000;
			}

			.right {
				display: flex;
				align-items: center;
				gap: 10px;

				p {
					margin-bottom: 0;
					font-weight: bold;
					color: #000000;
				}
			}

			.dropdown-box {
				position: relative;
				display: inline-block;

				.dropdown-select__active-element {
					display: flex;
					gap: 10px;
					font-weight: bold;
					color: #000000;
					// .icon {
					// 	transform: rotate(90deg);
					// }
				}

				.dropdown__items {
					display: none;
					position: absolute;
					background-color: #f9f9f9;
					min-width: 250px;
					box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
					z-index: 1;
					top: 25px;
					right: -20px;

					.dropdown__item {
						color: black;
						padding: 20px 16px;
						text-decoration: none;
						display: block;
						border-bottom: 1px solid #C5C5C5;

						&:hover {
							cursor: pointer;
						}

						.dropdown__item-text {
							display: flex;
							gap: 20px;
							align-items: center;
						}
					}
				}

				// &:hover .dropdown__items,
				&.active .dropdown__items {
					display: block;
				}
			}

		}
	}

	section#content>section {
		h3 {
			text-align: center;

			@include phone {
				text-align: center;
			}
		}

		.section__communities.active {
			.reg__card .reg__card-content {
				.reg__card-heading .reg__card-box-title {
					display: flex;
					justify-content: space-between;
				}

				.list-unstyled.snapshot {
					display: flex;
					align-items: center;
					min-height: 40px;

					li {
						display: flex;
						align-items: center;
					}

					li svg {
						width: 25px;
						height: 25px;
					}

					li span {
						color: #000000;
						margin-left: 5px;
						margin-right: 5px;
					}

					li b {
						margin-left: 10px;
						margin-right: 5px;
						color: #000000;
					}
				}
			}
		}

		.section__floorplans.active,
		.section__communities.active,
		.section__homes.active {
			display: grid !important;
			gap: 20px;
			grid-template-columns: repeat(1, minmax(350px, 1fr));


			@media screen and (min-width: 768px) {
				grid-template-columns: repeat(2, minmax(350px, 1fr));
			}

			@media screen and (min-width: 1024px) {
				grid-template-columns: repeat(3, minmax(350px, 1fr));
			}

			@media screen and (min-width: 1540px) {
				grid-template-columns: repeat(4, minmax(300px, 1fr));
			}

			.reg__card .reg__card-image button.slick-next,
			.reg__card .reg__card-image button.slick-prev {
				display: none !important;
			}

			.sold-out {
				width: 100%;
				height: 100%;
				position: absolute;
				z-index: 10;
				color: #FFFFFF;
				border: 1px solid #C5C5C5;
				background: linear-gradient(0deg, rgba(105, 105, 105, 0.9) 0%, rgba(105, 105, 105, 0.9) 100%), url(<path-to-image>) lightgray 50% / cover no-repeat;
				// top: 30px;
				opacity: .9;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border-radius: 8px;

				.sold-out__title {

					// margin-top: 80px;
					font-size: 24px;
					font-weight: bold;
				}

				.sold-out__button {
					margin-top: 20px;

					a {
						color: #FFFFFF;
						text-decoration: underline;

						&:hover {
							cursor: pointer;
						}
					}
				}
			}

			.reg__card .reg__card-content {
				.reg__card-heading .reg__card-box-title {
					display: flex;
					justify-content: space-between;
				}
			}
		}
	}
}

.no-items {
	margin: 20px 0;
	font-size: 14px;
	font-weight: bold;
	display: flex;
	align-items: center;
	gap: 10px;

	@media screen and (min-width: 992px) {
		font-size: 18px;
	}

	span.hert-icon-text {
		svg {
			width: 20px;
			height: 20px;
			color: #BA1F31;
			// margin-right: 10px;
		}
	}
}