#design-overview{

	section#hero{
		height: 85vh;
		padding: 100px 10vw 0;
		@include flex-param(column, center, flex-start);
		@include background-image('/images/site/design/design-overview-hero-bkg.jpg', cover, center);
		.type-md{
			line-height: 48px;
		}
		p,h1,h2{
			margin-bottom: 30px;
			max-width: 550px;
			font-weight: 300;
		}
		h1{
			margin-bottom: 15px;
		}
		a.btn-blue span{
			margin: 0 5px;
		}
		@include phone{
			.type-md{
				display: none;
			}
			h1{
				margin-top: 15px;
			}
		}
	}

	section#design-style{
		$height: 350px;

		padding: 90px 0;
		text-align: center;
		background-color: $lightest-gray;
		@include inview("p", static, 0s);
		@include inview(".design-img", static, 0s);
		@include inview(".card-panel-wrap", bottom, .2s);
		@include inview(".navigate", bottom, .8s);
		.title .text-center{
			position: relative;
			.btn.btn-gray{
				position: absolute;
				right: 15px;
				top: -20px;
				@mixin param(){
					position: relative;
					top: 30px;
					right: 0px;
				}
				@include desktop{@include param();}
				@include tablet{@include param();}
				@include phone{@include param();}
			}
		}
		.title.end{
			> p{
				letter-spacing: 1px;
				font-weight: 300;
				margin-bottom: 70px;
			}
			> .text-center{
				margin-bottom: 100px;
			}
		}
		.title .design-img{
			max-width: 415px;
			margin: 20px 50px;
		}
		.title .type-xxxl{
			font-weight: 300;
			&:nth-of-type(2){
				margin-bottom: 30px;
			}
		}
		.card-panel-wrap{
		    height: $height;
		    position: relative;
		    &.result-wrap{
		    	margin: 40px 0;
		    }
		}
		@include phone{
			padding: 60px 0;
			.card-panel-wrap{
			    height: 265px;
			}
			.title .design-img{
			    width: 100%;
			    margin: 20px 0;
			}
			.title.end{
				> p,
				> .text-center{
					margin-bottom: 30px;
				}
			}
			.card-panel-wrap.result-wrap{
				height: auto;
			}
			.type-lg{
			    line-height: 25px;
			    padding: 0;
			}
			.results.show > div{
				margin-bottom: 30px;
				.card .card-xs .image{
					height: 50vw;
				}
			}
		}
		.card-panel{
			padding: 60px 0;
			cursor: pointer;
			position: absolute;
			perspective: 2000px;
			width: 100%;
			opacity: 0;
			z-index:0;
			@include scale(.6);
			@include flex-param(row, center, center);
			@include transition(all .6s ease-in);
			&.active{
				@include scale(1);
				@include transition(all .6s ease-in);
				opacity: 1;
				z-index: 1;
				> div{
					@include rotateY(0);
					&:nth-child(2){
						@include transition(all .6s ease-in);
						margin: 0 50px;
						@include phone{
							margin: 0 10px;
						}
					}
				}
			}
			> div{
				border:1px solid $light-gray;
				border-radius: 10px;
				position: relative;
				overflow: hidden;
				@include rotateY(90);
				@include size(208px);
				@include transition(all .6s ease-out);
				@include flex-param(column, center, center);
				@include phone{
					height: 150px;
					svg{
						height: 40%;
						width: 80%;
					}
				}
				&:nth-child(2){
					@include transition(all .6s ease-out);
					margin: 0 200px;
				}
				&:after{
					content: "";
					background: $blue;
					position: absolute;
					top: -275px;
					left: -275px;
					z-index: 0;
					@include size(150%);
					@include rotate(45);
					@include transition(all .3s ease-in);
					@include phone{
						@include size(200%);
					}
				}
				svg,
				span{
					@include transition(all .3s ease-in);
					@include svgcolor($nuetral-gray);
					position: relative;
					z-index: 1;
					&.icon-dinner,
					&.icon-canoe,
					&.icon-umbrella{
						path,
						line,
						circle,
						ellipse{
							@include transition(all .3s ease-in);
							stroke: $nuetral-gray;
							stroke-width: 3;
						}
					}
				}

				span{
					@extend .type-lg;
					margin-bottom: 30px;
					@include phone{
						font-size: 13px;
					}
				}
				&:hover,
				&.active{
					&:after{
						@include transition(all .3s ease-in);
						top: -50px;
						left: -50px;
						@include phone{
							top: -60px;
						}
					}
					svg,
					span{
						@include transition(all .3s ease-in);
						@include svgcolor(white);
						color: white;
						&.icon-dinner,
						&.icon-canoe,
						&.icon-umbrella{
							path,
							line,
							circle,
							ellipse{
								@include transition(all .3s ease-in);
								stroke: white;
							}
						}
					}
				}
			}
		}
		.results{
			opacity: 0;
			position: relative;
			@include transition(all .3s ease-in, .2s);
			.carousel-control{
				background-image: none;
				width: 5%;
				z-index: 5;
				
				@mixin param($pos){
					&.left{left: $pos;}
					&.right{right: $pos;}
				}
				@include param(-80px);
				@include desktop{@include param(-5vw);}

				svg{
					top: calc(50% - 25px);
					left: calc(50% - 25px);
					position: absolute;
					@include size(50px);
					@include transition(all .2s ease-out);
					@include svgcolor($light-gray);
				}
				&:hover svg{
					cursor: pointer;
					@include transition(all .2s ease-in);
					@include svgcolor($gray);
				}
			}
			
			slick{
				max-height: 350px;
				overflow: hidden;
				.card-xs .image{height: 165px;}
			}

			&.show{
				opacity: 1;
				z-index: 1;
				@include transition(all .3s ease-in);
			}
		}
		.error{
			font-style: italic;
			margin-bottom: 20px;
			opacity: 0;
			&.show{
				@extend %pulse;
			}
		}
		.navigate{
			@include flex-param(row, center, center);
			span{
				font-style: italic;
				font-weight: 600;
			}
			.btn{
				margin-left: 40px;
			}
			@include toggleVisible('.btn-blue','.btn-red', inline-block);
			&.result{
				@include toggleVisible('.btn-red','.btn-blue', inline-block);
			}
		}
	}

	section#design-team{
		background-color: $blue;
		color: white;
		padding: 110px 0;
		background-repeat: no-repeat;
		@include inview(".container", right, 0s);
		.container{
			z-index: 5;
		}
		p{
			z-index: 5;
			position: relative;
		}
		&:before{
			content: "";
			@include background-image('/images/site/design/design0overview-woman.jpg', cover, center right);
			@include size(100%);
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
		}
		&:after{
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
			@include size(50%,100%);
			@include gradient(linear, (90deg, $blue 70%, rgba($blue,0)));
		}
		&.in{
			@include transition(all 10s ease-out);
		}
		@extend %design-copy-formatting;
		@include phone{
			.type-design-md,
			.type-design-xl{
				text-align: center;
			}
			&:after{			
				width: 100%;
				opacity: 0.9;
				@include gradient(linear, (90deg, $blue 70%, $blue));
			}
		}
	}

	section#bsb-design{
		padding: 105px 0;
		background-color: $lightest-gray;
		.container{
			@include flex-param(row, flex-start, flex-start);
			> .col-left{
				@extend %design-copy-formatting;
				img{
					width: 100%;
				}
				padding-right: 50px;
			}
			.col-right{
				.thumbnails li{
					@include size(175px);
					background-size: cover;
					position: relative;
					overflow: hidden;
					cursor: pointer;
					&:not(:last-child){
						margin-bottom: 45px;
					}
					div{
						background-color: rgba($blue,0.9);
						color: white;
						padding: 5px;
						position: absolute;
						top: calc(100% - 35px);
						height: 35px;
						padding-top: 10px;
						@include size(100%);
						@include flex-param(column, flex-start, center);
						@include transition(all .2s ease-in);
						&:after{
							margin-top: 5px;
							font-size: 50px;
							content: "+";
							opacity: 0;
							@include transition(all 5s ease-out, .2s);
							@include rotateY(90);
						}
						@include phone{
							font-size: 13px;
						}
					}
					&:hover,
					&.debug{
						div{						
							top: 0;
							padding-top: calc(50% - 25px);
							@include transition(all .2s ease-out);
							&:after{
								opacity: 1;
								@include transition(all .1s ease-out);
								@include rotateY(0);
							}
						}
					}
				}
			}
			.inline-modal{
				position: absolute;
				z-index: -1;
				left: 0;
				top: 0;
				padding: 50px;
				perspective: 2000px;
				@include size(100%);
				@include transition(all 0s ease-in, 4s);
				&.overflow .row{height: 100%;}
				.row{
					background-color: white;
					padding: 85px;
					display: flex;
					@include dropshadow(0, 0, 15px);
					@include rotateX(-90);
					@include transition(all .3s ease-out);
					p{
						margin-bottom: 30px;
						&.margin-min{
							margin-bottom: 15px;
						}
						&.type-sm{
							line-height: 34px;
						}
					}
					hr{
						margin-bottom: 30px;
					}
					.bio img{
						padding: 5px;
						border: 1px solid $light-gray;
					}
					.col-left{
						padding: 0;
					}
					.col-left img{
						width: 100%;
					}
					.col-right{
						padding-left: 40px;
						img{
							width: 100%;
						}
						.bio{
							height: 100%;
							overflow-x: hidden;
							overflow-y: auto;
						}
						.bio .row{
							padding-left: 0;
							padding-right: 20px;
							height: auto;
						}
					}
					@include tablet{
						padding: 45px;
						p.type-subtitle-lg{
							font-size: 24px;
						}
						.col-right .bio .row{
							flex-direction: column;
						}
						.col-right img{
							margin-bottom: 30px;
						}
						.col-right p:nth-child(1),
						.col-right p:nth-child(2){
							text-align: center;
						}
						.col-right p:nth-child(2){
							font-size: 16px;
						}
					}
					.close{
						font-size: 40px;
						position: absolute;
						top: 20px;
						right: 30px;
						font-weight: 300;
					}
				}
				&.open{
					z-index: 1;
					@include transition(all 0s ease-out);
					.row{					
						@include rotateX(0);
						@include transition(all .3s ease-in);
					}
				}
				&.open-remove{
					.row{					
						opacity: 0;
						@include transition(all .2s ease-in);
					}
				}
			}
			@include phone{
				flex-direction: column;
				.col-left{
					margin-bottom: 40px;
				}
				.col-left p.type-md{
					margin-top: 30px;
					line-height: 35px;
				}
				.col-right{
					width: 100%;
					.thumbnails li{
						width: calc(50% - 10px);
						float: left;
						&:nth-child(odd){
							margin-right: 20px;
						}
					}
				}
				.inline-modal{
					padding: 25px;
					height: calc(100% - 80px);
				}
				.inline-modal p.type-subtitle-lg{
					font-size: 18px;
					line-height: 24px;
				    text-align: center;
				    border-bottom: 1px solid $light-gray;
				    padding-bottom: 25px;
				}
				.inline-modal > .row{
					padding: 80px 20px;
				}
				.inline-modal > .row .col-left{
					display: none;
					width: 30vw;
				}
				.inline-modal > .row .col-right{
					padding: 0;
				}

				.inline-modal .bio .row{
					padding: 20px;
					flex-direction: column;
					> div:first-child{
						text-align: center;
						width: 100%;
					}
					> div:first-child img{
						width: 75%;
						margin-bottom: 20px;
					}
				}
			}
		}
	}
}