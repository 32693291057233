.kv-top {
    margin: 30px 15px 0;
    @media screen and (min-width: 768px) {
        margin-left: 0;
        margin-right: 0;
    }
}
.kv-top__link {
    display: block !important;
}
.kv-top__text {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    @media screen and (min-width: 1200px) {
        font-size: 18px;
    }
    svg {
        transform: rotate(180deg);
        margin-left: 10px;
    }
}
.kb-top__img {
    width: 100%;
    max-width: 320px;
}

.kv-floorplan {
    background: white;
    text-align: center;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.kv-floorplan__link {
    display: inline-grid;
    position: relative;
    color: white;
}
floorplan-gallery ~ .kv-floorplan {
    margin-top: -50px;
    padding-bottom: 50px;
    @media screen and (min-width: 768px) {
        margin-top: -100px;
        padding-bottom: 100px;
    }
}
.kv-floorplan__link:visited {
    color: white;
}
.kv-floorplan__img {
    display: block;
    grid-column: 1;
    grid-row: 1 / span 2;
}
.kv-floorplan__text {
    text-align: center;
    grid-column: 1;
    grid-row: 2;
    align-items: end;
    background: rgba(0, 0, 0, 0.5);
    padding: 15px;
    font-size: 20px;
    color: white;
}
.kv-floorplan__text svg path {
    fill: white !important;
}
@media screen and (min-width: 768px) {
    .kv-floorplan__text {
        font-size: 32px;
        padding: 30px;
    }
}