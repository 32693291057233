.mini-map {
	@include size(445px, 350px);

	position: relative;

	.map {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 445px;

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -67px 0 0 -23px;
		}

		@include transition(all .3s ease-out);

		@include background-image('/images/site/global/map-template.jpg', cover, center);

		.btn {
			float: right;
			&:after {
				content: "EXPAND MAP";
			}
		}

		&.expand {
			@include transition(all .3s ease-in);
			width: 100%;

			.btn {
				background-color: $red;
				&:after {
					content: "SHRINK MAP";
				}
			}
		}
	}
}