#energywise{
	section#hero{
		@extend %theme-large-heading;
		.type-prequalify-lg {
			line-height: 75px;
			margin-top: 120px;
			@include desktop {
				font-size: 45px;
			}
			@include phone {
				font-size: 6vw;
				line-height: 10.5vw;
				margin-top: 75px;
			}
		}
		.logo{
			position: relative;
			// margin-top: 120px;
			margin-bottom: 70px;
			img{position: relative;opacity: 0;}
			.energy{
				left: 15px;
				top: -50px;
				@include animate(energyAnimation, .6s ease-out 1s forwards){
					to{top: 0px; opacity: 1;}
				};
			}
			.wise{
				right: 15px;
				top: 50px;
				@include animate(wiseAnimation, .6s ease-out 1s forwards){
					to{top: 0px; opacity: 1;}
				};
			}
			.leaf{
				position: absolute;
				left: 85px;
				top: -20px;
				transform-origin: bottom;
				@include rotateY(90);
				@include animate(leafAnimation, .3s ease-in 1.6s forwards){
					to{opacity: 1; @include rotateX(0);}
				};
			}
			@include phone{
			    width: 590px;
			    transform: scale(.5);
			    margin-top: 0;
			    margin-bottom: 10px;
			}

		}
		#energy-star-link{
			color: $red;
		}
		.hers{
			margin-bottom: 200px;
			.title{
			    background: darken($lighter-gray,5%);
			    color: white;
			    padding: 30px 50px;
			    border-radius: 10px 10px 0 0;
			    text-align: center;
			    margin: 0 50px;
				opacity: 0;
				@include rotateX(90);
				@include animate(hersTitle, .3s ease-in 2s forwards){
					to{opacity: 1; @include rotateX(0);}
				}
				@include phone{
				    margin: 0;
				    padding: 20px 18vw 20px 15px;
				    border-radius: 10px;
				    font-size: 4vw;
				    line-height: 5vw;
				}
			}
			ul{
				@include gradient(linear,(90deg, red, darken(yellow,5%), lighten(green,10%))); 
				@include flex-param(row, space-around, center);
				padding: 20px;
				border-radius: 20px;
				color: white;
				opacity: 0;
				@include animate(hersUl, .3s ease-in 2.3s forwards){
					to{opacity: 1;}
				}
				li{
					position: relative;
					div{
						position: absolute;
						color: $gray;
						border: 1px solid $gray;
					    padding: 15px;
						top: 75px;
					    width: 185px;
					    display: inline-block;
						left: 300%;
						opacity: 0;
						&:after{
							content: "";
							position: absolute;
							bottom: calc(100% + 1px);
							left: calc(50% - 15px);
						}
						@mixin param($dir, $amount){
							to{
				    			#{$dir}: $amount;
								opacity: 1;
							}
						}
						@mixin desktopAnimation(){						
							&.tag-1{
								&:after{@include triangle('up', 30px, 15px, mix(red,yellow,50%));}
								@include animate(tagOne, .4s ease-out 2.6s forwards){
									@include param(left, -75.5px);
								}
							}
							&.tag-2{
								&:after{@include triangle('up', 30px, 15px, mix(orange,yellow,50%));}
								@include animate(tagTwo, .4s ease-out 3s forwards){
									@include param(left, -75.5px);
								}
							}
							&.tag-3{
								&:after{@include triangle('up', 30px, 15px, mix(yellow,green,70%));}
							    position: absolute;
							    width: auto;
							    display: block;
							    left: 200%;
							    padding: 0;
							    border: none;
							    opacity: 0;
								margin-left: 48px;
								@include animate(tagThree, .4s ease-out 3.4s forwards){
									@include param(left, -75%);
								}
								p{
								    position: absolute;
								    left: calc(100% + 20px);
								    width: 400px;
								    height: 100%;
								    top: 0;
								    line-height: 25px;
								    text-align: left;
								    //display: flex;
								    //align-items: center;
								}
							}
						}
						@include desktop-hd{@include desktopAnimation()}
						@include desktop{@include desktopAnimation()}
						@include tablet{@include desktopAnimation()}
						@include phone{						
							&.tag-1{
								&:after{@include triangle('right', 30px, 15px, mix(red,yellow,50%));}
								@include animate(tagOne, .4s ease-out 2.6s forwards){
									@include param(right, 70px);
								}
							}
							&.tag-2{
								&:after{@include triangle('right', 30px, 15px, mix(orange,yellow,50%));}
								@include animate(tagTwo, .4s ease-out 3s forwards){
									@include param(right, 70px);
								}
							}
							&.tag-3{
								&:after{@include triangle('right', 30px, 15px, mix(yellow,green,70%));}
							    position: absolute;
							    width: auto;
							    display: inline-block;
							    padding: 0;
							    border: none;
							    opacity: 0;
							    top: 0px;
								margin-top: 28px;
								@include animate(tagThree, .4s ease-out 3.4s forwards){
									@include param(right, 70px);
								}
								img{
								    position: relative;
								    left: 2px;
								}
								p{
								    position: absolute;
								    line-height: 25px;
								    align-items: center;
								    padding: 0;
								    left: auto;
								    right: -10px;
								    width: 65vw;
								    height: auto;
								    top: calc(100% + 45px);
								}
							}
						}
					}
				}
				@include tablet{
					li div{
						width: 100px;
						margin-left: 50px;
					}
					li div.tag-3{
				    	margin-left: -15px;
				    	p{
				    		width: 200px;
				    		text-align: left;
				    	}
				    	br{
				    		display: none;
				    	}
					}
				}
				@include phone{
					position: relative;
					flex-direction: column;
					max-width: 45px;
					float: right;
					top: -40px;
					right: 2vw;
					border-radius: 15px;
					@include gradient(linear,(-180deg, red, darken(yellow,5%), lighten(green,10%))); 
					li div{
						left: auto;
						right: 300px;
						top: -15px;
					}
					li:not(:last-child){
						margin-bottom: 20px;
					}
					li div:after{
					    bottom: calc(50% - 8px);
					    left: calc(100% - 7px);
					}
				}
			}
		}

		p.copy{
			@include fader($delay: 2s);
		}

		.btn.btn-red,
		p.type-xs{
			@include fader($delay: 4s);
		}

		.btn.btn-red,
		p.type-xs{
			margin-bottom: 70px;
		}

		a h2{
			margin-bottom: 0;
		}

		p.type-xs{
			line-height: 28px;
		}
		.img-responsive-custom {
		  max-width: 100%;
		}
	}

	section#content{
		@include flex-param(row, center, center);
		@include phone{
			flex-direction: column;
		}
		svg{
			@include size(25px);
			@include svgcolor(white);
		}
		> div{
			flex: 1;
			height: 480px;
			color: white;
			line-height: 72px;
			padding: 80px;
			@extend .type-financing-md;
			font-weight: 300;
			@include flex-param(column, center, flex-start);
			h2{
				margin-bottom: 5vh;
			}
			@include tablet{
				padding: 45px;
				height: 380px;
				h2{				
					font-size: 25px;
					line-height: 45px;
				}
			}
			@include phone{
				padding: 30px;
				height: 350px;
			    width: 100%;
			    align-items: center;
				h2{
				    font-size: 30px;
				    line-height: 45px;
				}
			}
			&:first-child{
				@include background-image('/images/site/energywise/frame.jpg',cover,center);
			}
			&:last-child{
				@include background-image('/images/site/energywise/trees.jpg',cover,center);
			}
		}
	}

	@include phone{
		section#inline-form.inline-energywyse{
			padding-bottom: 80px;
		}
	}
	.form-container{
		width: 100%;
		margin: 0;
		background-color: #e0e0e0;
		position: relative;
		padding: 24px 48px;
	
		@include phone{
			padding: 24px;
		}
		.submitted-message{
			padding: 100px 0;
			text-align: center;
		}
	
		form{
			display: flex;
			flex-wrap: wrap;
	
			fieldset,
			.hs_submit{
				flex-basis: 100%;
				.hs-button{
					border-radius: 5px;
				}
			}
	
			textarea{
				height: auto;
				max-height: 2.625rem;
				overflow: hidden;
				resize: none;
				padding: 13px 15px 5px;
				white-space: nowrap;
	
				&::placeholder{
					color: black;
					font-weight: 300;
					letter-spacing: 1.5px;
					white-space: nowrap;
				}
			}
	
			input[type="checkbox"]{
				min-width: 25px;
			}
	
			@media screen and (min-width: 769px) and (max-width: 1024px){
				input::placeholder,
				textarea::placeholder,
				select{
					font-size: .75rem;
				}
	
				textarea{
					padding: 15px 15px 5px;
				}
			}
	
			@media screen and (max-width: 768px) {
				.input > input.hs-input {
					width: 100% !important;
				}
			}
		}
		.hs-form{
			width: 80%;
			max-width: 1200px;
			margin: 0 auto;
	
			@media screen and (max-width: 768px){
				width: 100%;
			}
		}
		.sales-agent{
			height: 220px;
			width: 200px;
			position:absolute;
			bottom: 0;
			right: 0;
			&:not(.show-agent){
				display: none;
			}
			@media screen and (min-width: 301px) and (max-width: 425px){
				background-size: contain;
				background-repeat: no-repeat;
				width: 180px;
				height: 180px;
			}
			@media screen and (max-width: 300px){
				display: none;
			}
		}
		h3{
			font-size: 1.5rem;
			font-weight: 400;
			text-align: center;
			margin-bottom: 10px;
			color: black;
	
			a{
				font-size: 1.5rem;
				color: #ba1f31;
				font-weight: 700;
				font-style: italic;
			}
		}
				
		@media screen and (min-width: 451px) and (max-width: 768px){
			padding-top: 32px;
			padding-bottom: 48px;
		}
	
		@media screen and (max-width: 450px){
			padding-top: 32px;
			padding-bottom: 32px;
			padding-left: 24px;
			padding-right: 24px;
			width: 100%;
		}
	
		&.show-agent .hs-form form{
			@media screen and (max-width: 768px) {
				.hs_opt_in{
					width: calc(100% - 125px);
					padding-top: 32px;
	
					.input{
						margin: 0 15px;
					}
				}
				.hs_submit .actions{
					width: calc(100% - 125px);
				}
			}
			@media screen and (max-width: 425px){
				.hs_opt_in{
					width: calc(100% - 150px);
					padding-top: 15px;
	
				}
			}
			@media screen and (max-width: 300px){
				.sales-agent{
					display: none;
				}
				.hs_opt_in{
					width: 100%;
				}
				.hs_submit .actions{
					width: 100%;
				}
			}
		}
	}
}