.theme-two-stack{
	#hero{		
		background-attachment: fixed;
		padding-top: 100px;
		color: white;
		height: 100%;
		.container{
			height: calc(100% - 110px);
			position: relative;
			width: 100%;
			@include flex-param(column, center, center);
		}
		.divider{
			background-color: white;
			height: 110px;
			font-weight: 600;
			text-align: center;
			@include flex-param(row, center, center);
		}
	}

	#content{
		@include flex-param(row, center, stretch);
	}
}