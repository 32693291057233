#home-show-carousel{
  position: relative;
	.fill {
    background-position: center;
    position: relative;
    height: 100vh;
  }
  .fill:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(black, 0.2);
  }
  .flex-caption{
    margin: 40px 0;
    color: white;
    text-shadow: 0px 3px 10px black;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 5px;
    text-align: center;
    @include phone {
      line-height: 30px;
    }
    @include flex-param( column );
    @include absolute-param($left: 20px, $right:20px);
    .type-title-md {
      font-weight: 600;
    }
  }
	.bottom-bar{
		position: absolute;
		color: white;
		padding: 30px;
		background-color: transparent;
		text-align: right;
		img{margin-left: 30px;}
		p{
      line-height: 44px;
      &:last-child{font-weight: 300;}
      @include phone {
        line-height: 30px;
        &:last-child{font-weight: 600;}
      }
    }
		@include flex-param(row, flex-end, center);
	}
}