section#overview {
  .container-inner{
  @media screen and (min-width: 768px){
    .container-fluid{
      width: 95%;
      max-width: 1400px;
    }
  }
    ul{
      display: none;
    }
  }
	background-color: white;
	@include inview(".container-fluid", left, 0s);
	@include inview(".img-right", right, .6s);
	@include inview(".inline-new-phase", bottom, .8s);

  @include phone{
    width: 100%;
  }

	.container-inner {
		@extend %section-padding;
    padding: 50px 0;
		position: relative;


		.img-right {
			@include background-image('/images/site/microsite/living-space.jpg', cover, center);
			position: absolute;
			top: 0;
			right: 0;
			width: 40%;
			height: 100%;
      @include phone{
        display: none;
      }

		}

		a{
			@include flex-param(row, center, center);
			display: inline-flex;
			svg{margin: 0 0 0 20px;}
		}

    h1 > span{
      display: block;
    }

		.type-xl {
			margin-bottom: 20px;
      color: black;
      font-style: italic;
      font-weight: 300;
      font-size: 22px;

      @media screen and (max-width: 768px){
        font-size: 18px;
      }
		}

    .tagline{
      font-style: normal;
    }

		.type-title-xl {
			margin-bottom: 20px;
      font-weight: 400;
      letter-spacing: 2px;
      color: black;
      @media screen and (max-width: 768px){
        font-size: 40px;
      }
    }

    .type-md {
      line-height: 45px;
      margin-bottom: 45px;
    }

    .type-lg.blurb{
      color: black;
      font-size: 1.1rem;
      line-height: normal;
      p{
        margin: 25px 0;
      }
    }

		ul {
			display: block;
    }

    ul li {
      @extend %li-circle;
      margin-bottom: 20px;
      line-height: 30px;
      color: black;
      &:after{
        top: 10px;
        background-color: black;
      }
    }

    @include desktop-hd{
      ul li {
  			line-height: 35px;
        &:after{
          top: 12px;
        }
      }
    }

    @include tablet{
      .type-xl {
        margin-bottom: 10px;
      }

      .type-title-xl {
        margin-bottom: 40px;
      }
    }

    @include phone{
      .type-xl {
        margin-bottom: 10px;
      }

      .type-xl,
      .type-title-xl{
        text-align: left;
      }

      .type-md {
        margin-bottom: 0;
      }
    }

    p.next-tour{
      color: white;
      font-size: 1rem;
      text-align: center;
      line-height: 1.35rem;

      span.tour-time{
        font-weight: 600;
        text-decoration: underline;

        >i{
          font-size: 1.25rem;
        }
      }
    }


    &.overview-coming-soon {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: stretch;
      align-items: stretch;
      @include phone{
        -ms-flex-direction: column;
        flex-direction: column;
      }
      .img-right {
        height: 45vw;
        &.first {
          top: 180px;
        }
        &.second {
          top: calc(45vw + 195px);
        }
      }
    }

    .container-fluid{
      .container-fluid{
        width: 100%;
        padding-left: 0;
        @include phone{
          padding-right: 0;
        }

        .col-sm-12{
          padding-left: 0;
          @include phone{
            padding-right: 0;

          }
        }
      }

      #banner-promo{
        margin: 15px auto 30px;
      }

      .panel{
        border: none;
        box-shadow: none;

        .panel-heading{
          background-color: white;
          padding: 15px;

          &::after{
            content: "";
            width: 100%;
            height: 1px;
            background-color: $light-gray;
            display: block;
            position: relative;
            top: 17.5px;
          }

          h4{
            display: inline-block;
            font-size: 18px;
            letter-spacing: 1px;
            width: 100%;
            
            .accordion-toggle{
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              &::after{
                content: "";
                position: relative;
                top: 0;
                display: inline-block;
                width: 10px;
                height: 10px;
                border-left: 3px solid $red;
                border-bottom: 3px solid $red;
                transition: transform .2s ease-in-out, top .2s ease-in-out;
                transform: rotate(-45deg);
                margin-left: 5px;  
              }
            }
          }
        }
        .panel-collapse{
          padding: 15px;
        }
        .panel-collapse.in{
        }
      }      
      .panel.panel-open{
        .panel-heading{
          .accordion-toggle::after{
            transform: rotate(135deg);
            top: 3px;
          }
        }
        .panel-body{
          border-top: none;
          color: black;
          font-size: 18px;
          line-height: normal;

          a,
          b{
            color: black !important;
            font-weight: 700 !important;
          }

          p{
            margin-bottom: 15px !important;
          }

          @media screen and (max-width: 768px){
            font-size: 16px;
          }
        }
      }

      .form-container{
        background-color: $lighter-gray;
        color: black;
        padding: 40px 25px;

        .form-header{
          font-size: 1.375rem;
          letter-spacing: 1px;
        }

        .form-instructions{
          margin-bottom: 15px;
        }

        .form-header,
        .form-instructions{
          text-align: center;
          line-height: normal;
        }


        form{
            max-width: 100%;

            ul > li{
              line-height: normal;
              &::after{
                display: none;
              }
            }

            .hs-form-booleancheckbox-display span{
              line-height: .85rem;
            }
            .hs-form-field{
              width: 100%;
            }
        }

        .inline-get-fast-answers{
          height: fit-content;
          background-color: $lighter-gray;
  
          .image{
            display: none;
          }
  
          .copy{
            display: none;
          }
  
          .flex-wrap form{
            flex-direction: column;
            padding-bottom: 0;

            .input-group{
              width: 100%;

              input{
                border-radius: 0 !important;
                border: none;
                &::placeholder{
                  color: black;
                  text-transform: uppercase;
                  font-weight: 300;
                  letter-spacing: .5px;
                  font-size: 14px;
                }
              }
            }
              .form-disclaimer{
                font-size: 10.5px;
                line-height: normal;
                text-align: justify;
                a{
                  color: black !important;
                }
              }
            

            .btn-wrap{
              margin-left: 0;

              p:last-child{
                text-align: center;

                .privacy-link{
                  color: black;
                  text-align: center;
                }
              }
              button{
                padding: 0 15px;
              }
            }
          }
        }
      }

      div.container.mobile-video-container{
        padding: 0;
      }
      section.promo-video{
        margin: 50px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 25px;
        flex-direction: column;

        @include phone{
          margin: 50px auto 0;
        }

        div.promo-video-container{
          width: 100%;
          max-width: 768px;

          span{
            color: black;
            display: block;
            text-align: center;
            letter-spacing: .5px;
            margin-bottom: 25px;
          }
    
          div.video-thumb{
            width: 100%;
            max-width: 750px;
            height: 70vh;
            max-height: 500px;
            background-size: 132%;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;

            @include phone{
              max-height: 300px;
            }
    
            &::before{
              content: "";
              display: block;
              position: relative;
              background-color: rgba(0,0,0,.5);
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
            }

            span{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              min-width: 32px;
              width: 15vw;
              max-width: 128px;
              z-index: 1;
            }
          }
        }
      }
    }
    .visible-xs section.promo-video{
      margin-top: 0;
      margin-bottom: 30px;
      .video-thumb > span{
        transform: translateX(-50%) !important;
      }
    }
	}
}
&:not(.home-show) section#overview{
  a,
  b{
    color: black !important;
    font-weight: 700 !important;
  }
  .accordion-toggle{
    font-weight: normal !important;
  }
  .form-btn{
    text-transform: uppercase !important;
    font-size: 1.125rem !important;
    color: white !important;
    display: block !important;
    width: fit-content !important;
    margin: 0 auto !important;
  }
}