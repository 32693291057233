#st-judes{

	#st-judes-carousel{
		@extend %carousel-with-bottombar;
		.bottom-bar{
			position: absolute;
		}
		.bottom-bar .row.flex-wrap{
			height: 175px;		
			img{
				margin-bottom: 165px;
			}
			.col-right{
				text-align: right;
				span{
					letter-spacing: 0;
					padding-left: 10px;
				}
				*{
					line-height: 35px;
				}
			}
		}
		@include tablet{
			.carousel{
				height: 60vh;
			}
			.bottom-bar .row.flex-wrap{
				height: 145px;
			}
			.bottom-bar .row.flex-wrap img{			
			    margin-bottom: 90px;
			    max-width: 270px;
			}
		}
		@include phone{
			.carousel{
				height: calc(100vh - 80px);
			}
			.carousel .fill{
				height: calc(100% - 240px);
				margin-top: 50px;
			}
			.bottom-bar{
			    height: 190px;
			    padding-top: 15px;
			}
			.bottom-bar .row.flex-wrap{
				height: auto;
				flex-direction: column;
			}
			.bottom-bar .row.flex-wrap img{			
			    margin-bottom: 0;
			    max-width: 100px;
			}
			.bottom-bar .row.flex-wrap p.type-subtitle-lg{
				font-size: 18px;
				line-height: 20px;
			}
			.col-right p{
				text-align: center;
			}
		}
	}
	
	#content{

		p.type-st-judes-md{
			font-weight: 300;
			color: $red;
			line-height: 72px;
			@include phone{
			    line-height: 35px;
			    font-size: 30px;
			}
		}

		#overview{
			background-color: $lightest-gray;
			padding: 120px 0;
			p.type-sm{line-height: 45px;}
			p.type-subtitle-md{line-height: 52px;}
			p.type-md{line-height: 34px;}
			p:not(:last-child){
				margin-bottom: 30px;
			}
			.col-left{
				padding-right: 50px;
			}
			.col-right .white-bkg{
				background-color: white;
				padding: 30px;
				text-align: center;
				p.type-md{
					display: inline-block;
					width: 70%;
				}
				a{
					line-height: 30px;
				}
			}
			@include tablet{
				padding: 60px 0;
			}
			@include phone{
				padding: 30px 0;
				.col-left{
					padding-right: 0;
					margin-bottom: 20px;
				}
			}
		}

		#time-lapse-video{
			@include inview("p", bottom, 0s);
			@include inview("iframe", bottom, .3s);
			padding: 115px 0;
			iframe{
				margin-top: 95px;
			}
			@include tablet{
				padding: 55px 0;
				iframe{
					margin-top: 45px;
				}
			}
			@include phone{
				padding: 30px 0;
				iframe{
					margin-top: 20px;
				    width: 100%;
				    height: 60vw;
				}
			}

		}

		#partner-vendors{
			@include inview("p", bottom, 0s);
			@include inview("img", bottom, .6s);
			@include inview("ul", bottom, 1.5s);
			background-color: $lightest-gray;
			padding: 135px 0;
			p{
				margin-bottom: 75px;
			}
			p.type-md{
				display: inline-block;
				line-height: 36px;
				max-width: 600px;
			}
			p.type-subtitle-md{
				letter-spacing: 1px;
			}
			.flex-wrap{			
				justify-content: center;
				align-items: center;
				&.vendors{
					justify-content: space-between;
					align-items: flex-start;
				}
				&:last-child{
					margin-bottom: 75px;
				}
				img{
					margin: 30px;
				}
			}
			li{
				@extend .type-sm;
				text-align: left;
				line-height: 48px;
			}
			@include tablet{
				padding: 60px 0;
				p,
				.flex-wrap:last-child{
					margin-bottom: 35px;
				}
				.flex-wrap img{
				    margin: 15px;
				    width: 75%;
				}
				.flex-wrap.vendors{
					flex-wrap: wrap;
				}
				.flex-wrap.vendors ul{
				    width: 50%;
				    padding-left: 75px;
				}
			}
			@include phone{
				padding: 30px 0 120px 0;
				li,
				p.type-subtitle-md{
					text-align: center;
					line-height: 25px;
				}
				p,
				.flex-wrap:last-child{
					margin-bottom: 20px;
				}
				.flex-wrap img{
				    margin: 10px;
				    width: 75%;
				}
				.flex-wrap.vendors{
					flex-wrap: wrap;
				}
				.flex-wrap.vendors ul{
				    width: 100%;
				}
			}
		}
	}

}