section#explore {
	@extend %section-padding;
	@include inview(".type-title-xl", static, 0s);
	@include inview("ul", bottom, .3s);
	@include inview("#slick-floorplan", bottom, .5s);

	.type-title-xl{
		@extend %section-title-margin;
	}

	#slick-floorplan .item{
		position: relative;
		opacity: 0;
		@include transition(all .2s ease-in);
		&.slick-active {
			opacity: 1;
			@include transition(all .2s ease-in);
		}
		svg{
			@include svgcolor($blue);
			@include size(30px);
		}
		.pin{
			position: absolute;
		}
		.popover{
			border-radius: 0;
			max-width: 500px;
		}
		.popover-content{
			padding: 5px;
		}
	}

	li{
		@extend %tab-list-style;
		margin-bottom: 30px;
		@include phone {
			display: block;
			padding: 20px 0;
			margin-bottom: 0px;
			&:after {display: none}
			&:not(:last-child){border-bottom: 1px solid lightgray}
		}
	}

	#slick-floorplan .slick-list {
		overflow: visible;
	}

	#slick-floorplan .pin {
		cursor: pointer;
	}

	#slick-floorplan .pin + .popover {
		@include size( 600px, 285px );
		@include phone {
			@include size( 300px, 143px );
		}
	}

}