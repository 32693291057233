#homepage{
	// .announcements {
    // 	@include phone {
    //   		display: none;
    // 	}
  	// }

	header #main-nav .navbar-header {
		@include phone {
			background-color: unset;
			background-image: none;
			transition: background-color 1s ease;
  		}
  	}

	.navbar-header.dark-scroll{
		background-color: #000000 !important;
	}

  	#homepage-carousel{
		@extend %carousel-full-height;

		.carousel{
			height: 100vh;
		}

		overflow-y: visible;
        overflow-x: hidden;
        
		&:before {
		  background-color: rgba(0,0,0,0.25);
		  content: '';
		  position: absolute;
		  top: 0;
		  bottom: 0;
		  left: 0;
		  right: 0;
		  z-index: 10;
		}

		video{
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    height: 100%;
			width: 100%;
			object-fit: cover;
			// @include tablet{left: -22%;} 
		    // @include phone{ left: -100%; }
		}

		.homepage-hero-container{
			height: 100vh;
			min-width: 100%;
		}

		.homepage-hero-video{
			height: 100%;
			overflow-x: hidden;
		}

		.fallback-hero-wide {
			min-height: 100%;
			max-height: 100vh;
			min-width: 100%;
			max-width: unset;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
            
            @include phone{
                height: 100%;
            }
		}

		.fallback-hero-vertical{
			min-height: 100%;
			min-width: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.item .flex-caption{
			transform: rotateY(90deg);
			opacity: 0;
			padding-top: 50px;
			@include phone{
				padding-top: 0;
			}
			p{
				color: white;
			}
			p.type-md{
				line-height: 48px;
				@include tablet{
					line-height: 35px;
				}
				@include phone{
					line-height: 25px;
					font-size: 13px;
				}
			}
			a{
				display: flex;
				svg{
					display: none;
					margin-right: 0;
					height: 20px;
					width: 20px;
				}
			}
			.anchor-wrap{
				padding-top: 30px;
			}
		}

		@include phone{
			.item .fill{
				background-size: cover;
				background-position: center;
			}
			.item .flex-caption{
				padding-top: 150px;
				.btn.btn-blue{
					background-color: rgba(black, .7);
					border: 2px solid $blue;
					.icon-search{
						display: inline-block;
						margin-right: 10px;
						@include size(12px);
					}
				}
			}
		}

		.item.slide-distinct-style .flex-caption p.type-md{
			padding: 0 30vw;
			@include tablet{ 
				padding: 0 10vw;
			}
			@include phone{
				padding: 0;
			}
		}

		.item:not(.slide-st-judes) .flex-caption p.type-md{
			margin: 50px;
			@include phone{
				margin: 20px;
			}
		}

		.item.active:not(.slide-decorated-model-homes) .flex-caption{
			@include transition(all .6s ease-in .1s);
			transform: rotateY(0deg);
			opacity: 1;
		}

		.item.slide-happy-homeowners svg.icon-smile{@include svgfilter(right, white);}
		.item.slide-move-in-ready svg.icon-search{@include svgfilter(right, white);}
		.item.slide-decorated-model-homes svg.icon-search{@include svgfilter(right, $blue);}
		.item.slide-st-judes svg.icon-circle-arrow-right{@include svgfilter(left, $red);}

		.item.slide-decorated-model-homes{
			.flex-caption{
				width: 100%;
				padding: 100px 100px;
				background-color: $blue;
				transform: rotateY(0deg);
				opacity: 1;
				@include desktop{min-width: 505px;}
				@include tablet{
					padding: 200px 0 100px;
				}
				> * {
					opacity: 0;
					a:hover svg{
						@include svgcolor(white);
					}
				}
				p:nth-child(1){
					@extend .type-title-xxxl;
				}
				p:nth-of-type(2){
					@extend .type-title-md;
				    line-height: 50px;
				    @include tablet{
				    	margin-top: 20px;
				    	font-size: 20px;
				    	line-height: 30px;
				    }
				    @include phone{
				    	line-height: 30px;
				    	font-size: 25px;
				    }
				}
				p:nth-of-type(3){
					padding: 0;
					@include tablet{margin: 20px 50px;}
				}
			}
			&.active .flex-caption{
				@include transition(all .6s ease-in);
				width: 40%;
				opacity: 1;
				@include tablet{width:50%;}
				@include phone{
					width: 100%;
					padding: 20px;
				}
				> *{
					@include transition(all .3s ease-in .8s);
					opacity: 1;
				}
			}
		}

		.item.slide-st-judes{
			.flex-caption{
				padding-top: 0;
			}
			&:after{
				content: "";
				background-color: rgba(darken($red,20%),.7);
				@include absolute(bottom, left, 100%, 175px);
				@include tablet{height: 145px;}
			}
			img{
				margin-bottom: 0px;
				@include tablet{
					width: 200px;
				}
				@include laptop{
					width: 300px;
				}
				@include phone{
					position: relative;
					top: -40px;
				    width: 50%;
				}
			}
			.st-judes-info{
			    position: absolute;
			    bottom: 15px;
			    left: 10vw;
			    text-align: left;
			    opacity: 0;
				@include tablet{
					bottom: 20px;
					p{
						line-height: 35px;
					}
				}
				@include phone{
					bottom: 88px;
					p.type-subtitle-lg{
						line-height: 25px;
						font-size: 18px;
					}
					p.type-md{
						line-height: 25px;
						font-size: 13px;
					}
					p.type-title-xs{
						font-size: 11px;
						line-height: 30px;
					}
				}
			}
			.anchor-wrap{
				padding: 0;
			}
			a{
				color: $red;
				flex-direction: row-reverse;
				position: relative;
				margin-top: 10px;
				margin-bottom: 50px;
				@include phone{
					top: -20px;
				}
				&:hover{
					color: white;
					svg{
						@include svgcolor(white);
					}
				}
			}
			&.active .st-judes-info{
				@include transition(opacity .3s ease-in .7s);
				opacity: 1;
			}
		}

		@extend %square-indicator-right;

		#carousel-cta{
			position: absolute;
			top: 50%;
			width: 100%;
			z-index: 100;
			text-align: center;
			@include desktop{
				transform: translateY(-50%);
			}
			@include tablet{
				transform: translateY(-50%);
			}
			@include phone{
				transform: translateY(-50%);
			}
		}

		#carousel-heading{
			color: white;
			margin-top: 15px;
			margin-bottom: 30px;
			text-transform: uppercase;
		}

		.cta-dropdown{
			min-width: 635px;
			color: black;
			
			@include desktop{max-width: 800px;}
			@include phone{
				width: 25vw;
				min-width: 215px;
				font-size: 14px;
			}

			span{
				background: black;
			}
			&:hover,
			&:focus{
				span{
					background-color: #ba1f31;
				}
			}

		}

		#carousel-dropdown{
			> ul{
				min-height: fit-content;
				min-width: initial;	
				justify-content: space-evenly;	
				padding: 10px 20px;
				gap: 30px;

				@include phone{
					height: calc(35vh - 30px);
					padding: 0;
				}

				&.dropdown-gray-to-red{
					display: flex;
					width: 100%;
					min-width: fit-content;
				}

				.mobile-column{
					> li > a{
						&:hover,
						&:active,
						&:focus{
							@include phone{
								background-color: white;
							}
						}
					}
					> li > a,
					.sub-dropdown > li > a{
						color: black;
						text-transform: uppercase;
					}
				}

				.mobile-column > li > a,
				.column > li > a{
					font-weight: 600;
				}

				.mobile-column > li > a,
				.column > li > a,
				.column .sub-dropdown > li > a{
					background-color: white;
				}

				.mobile-column > li > a.super-region{
					font-weight: 600;
				}

				.mobile-column{
					width: 100%;
					max-height: calc(30vh - 30px);
					.sub-dropdown{
						list-style: disc;
						padding-left: 45px;
						background-color: white;

						li {
							color: black;
						}

						li > a{
							padding-left: 10px;
							font-weight: 400;
						}
					}
				}

				.mobile-column > li > a,
				.mobile-column .sub-dropdown > li > a{
					font-size: 14px;
				}

				.mobile-column .sub-dropdown > li > a{
					&:hover,
					&:focus,
					&:active{
						background-color: transparent !important;
					}
				}

				.column{
					// height: 100%;
					break-inside: avoid;
					display: flex;
					flex-direction: column;
					color: black;

					> li > a,
					.super-region{
						font-weight: 600;
					}

					.sub-dropdown > li > a{
						font-weight: 400;
					}

					> li > a,
					.sub-dropdown > li > a{
						font-size: 18px;
						text-transform: uppercase;
						padding: 15px 0px;
						line-height: unset;
						color: black;

						&:hover,
						&:focus,
						&:active{
							background-color: white !important;
							color: $red;
						}
					}

					.sub-dropdown{
						padding-left: 35px;
						background-color: white;
					}

					.sub-dropdown > li{
						list-style-type: disc;
					}

					.sub-dropdown > li > a{

						&:hover,
						&:focus,
						&:active{
							background-color: white !important;
							color: $red;
						}
					}

					.super-region{
						padding-left: 0px;
						padding-right: 0px;
						&:hover,
						&:focus,
						&:active{
							background-color: white !important;
							color: $red;
						}
					}

				}

				.column1{
					width: fit-content;
				}

				.column2,
				.column3{
					justify-content: flex-start;
				}
			}

			@media screen and (max-height: 775px){
				> ul{
					max-height: calc(30vh - 30px);
				}
			}
		}
	}

    section#fftf-promo{
        background-image: url('/images/site/homepage/fftf-promo-bg.jpg');
        background-size: cover;
        background-position: center;
        min-height: 400px;
        display: flex;
        align-items: stretch;
        justify-content: center;
    

        #promo-container{
            display: flex;
            align-items: center;
            justify-content: center;
        
            h2,
            p{
                color: white;
                line-height: normal;
            }

            h2{
                letter-spacing: normal;
                font-weight: 600;
                font-size: 3rem;
            }

            .col-left{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                gap: 24px;
                padding: 24px;
            }

            a.btn-white-black{
                color: black;
                border-radius: 8px;
                text-transform: uppercase;
                letter-spacing: 1px;
                padding: 12px 30px;
                margin: 16px 0;
            }

            a.btn-white-black:active,
            a.btn-white-black:focus,
            a.btn-white-black:hover{
                color: white;
            }

            .col-right{
                display: flex;
                align-items: flex-end;
                justify-content: center;
                align-self: flex-end;
            }
            @include tablet{
                h2{
                    font-size: 2rem;
                }
            }
            @include phone{
                flex-direction: column;

                .col-right{
                    align-items: center;
                    align-self: center;

                    img{
                        max-width: 350px;
                    }
                }
                .col-left{
                    align-items: center;
                    text-align: center;
                }
            }
        }
    }
    

	%vertical-padding{
		padding: 135px 0;
		@include tablet{padding: 70px 0;}
		@include phone{padding: 70px 0;}
	}

	.st-judes-info p{
		line-height: 48px;
		text-align: left;
		&:nth-of-type(1){font-weight: 700;}
		&:nth-of-type(2){@extend %open-sans;}
		&:last-child{font-weight: 600; line-height: 38px;}
	}

	section{
		position: relative;
		overflow: hidden;
	}

	section#welcome-home{
		background-color: $lightest-gray;
		z-index: 1;
		@include inview(".col-left", left, 0s);
		@include inview(".map-sm", right, 0s);
		&.in{
			overflow: visible;
		}
		.col-left{	
			@extend %vertical-padding;
			
			p,h1,h2{
				margin-bottom: 50px;
			}

			h1{
				line-height: 28px;
			}

			p.type-md{
				line-height: 48px;
				margin-bottom: 18px;
			}

			button{
				min-width: 215px;
			}
			.dropdown-menu {
			  @include phone {
			    min-width: initial;
			  }
			}

			@include tablet{
				padding: 70px 15px;
				p.type-md{
					line-height: 35px;
				}
			}

			@include phone{
				padding: 30px 15px;
				text-align: center;
				p,h1,h2{
					margin-bottom: 25px;
				}
				p.type-title-xl{
				    font-size: 35px;
				    letter-spacing: 0px;
				}
				p.type-md{
					line-height: 30px;
				}
			}
		}
		.map-sm{
		    position: absolute;
			overflow: hidden;
		    width: calc(50% - 150px);
		    background-repeat: no-repeat;
		    background-size: auto 100%;
		    background-position: center;
		    height: 100%;
		    top: 0;
		    background-color: white;
		    @include desktop{
		    	width: calc(50% - 100px);
		    }
		    @include tablet{
		    	width: calc(50% - 50px);
		    }
		    .svg-map{
			    position: absolute;
			    top: 0;
			    height: 100%;
			    left: -18%;
			    width: 705px;
			    @include desktop-hd {
			      left: 0;
                  width: 700px;
                }
			    @include desktop {
			      left: -12%;
                  width: 700px;
                }
			    @include tablet {
			      left: -18%;
				  width: 546px;
			    }
			    .pin {
				    width: 20px;
				    position: absolute;
				    z-index: 1;
				    @include tablet{
				    	margin-top: -15px;
				    }
				    &:after{
					    content: '';
					    width: 30px;
					    height: 3px;
					    position: absolute;
					    background-color: rgba(black, 0.14);
					    left: -5px;
					    bottom: 0px;
					    border-radius: 100%;
				    }
				    + .popover{
				    	border-radius: 0;
				    	border: none;
				    	padding: 0px;
				    	.arrow{
				    		bottom: -10px;
				    	}
					    .popover-title{
					    	text-align: center;
					    	background-color: transparent;
					    	border: none;
					    	color: $red;
					    	text-transform: uppercase;
					    	letter-spacing: 1px;
					    	padding-bottom: 2px;
					    	@extend .type-md;
					    }
					    .popover-content{
					    	padding-top: 0;
					    	text-align: center;
							a{
								color: black;
							}
						    i.fa{
						    	margin-right: 5px;
						    }
					    }
				    }
				    .icon-red-pin {
				      max-height: 41px;
				    }
			    }
			    #icon-us-map{
			    	height: 100%;
			    }

		    }
		}

	}

	section#be-inspired{
		@extend %vertical-padding;
		background-image: url('https://d1k0mxjwfbpkpb.cloudfront.net/resources/293272/original/be-inspired-bg.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		@include inview("p", bottom, 0s);
		@include inview("span", "static", .6s);
		p{
			color: white;
			margin-bottom: 50px;
			@include phone{margin-bottom: 25px;}
		}
		@include flex-param(column, center, center);
		a.btn{
			margin-top: 30px;
			background-color: white;
			color: black;
			transition: background-color .2s ease-in, color .2s ease-in;
			@include phone{margin-top: 15px;}

			svg path{
				fill: black;
				transition: fill .2s ease-in;
			}

			&:hover,
			&:active,
			&:focus{
				background-color: black;
				color: white;

				svg path{
					fill: white;
				}
			}
		}
	}

	section#fischer-gives-back{
		background-image: url(https://d1k0mxjwfbpkpb.cloudfront.net/resources/270373/original/Charles_2022%20%281%29%20%281%29.jpg);
		background-position: center;
		background-size: cover;
		height: calc(100vh - 110px);
		&:before {
		  background-color: rgba(0,0,0,0.45);
		  content: '';
		  position: absolute;
		  top: 0;
		  bottom: 0;
		  left: 0;
		  right: 0;
		}
		@include inview(".col-left", left, 0s);
		@include inview(".col-right", right, 0s);
		@include tablet {
		  height: 75vh;
		}
		@include phone {
		  height: auto;
		}
		> .container-fluid,
		> .container-fluid > .flex-wrap {
		  height: 100%;
		}
		> .container-fluid > .flex-wrap {
		  @include phone {
		    flex-direction: column;
      }
    }
		p {
			color: white;
		}
		.col-left{
			@include flex-param(column, center, flex-start);
      @include desktop-hd {
        margin-left: 4.66666667%;
      }
      @include desktop {
        margin-left: 4.66666667%;
      }
      @include tablet {
        margin-left: 4.66666667%;
      }
      @include phone {
        height: 45vh;
      }
      p {
        &:nth-of-type(1) {
          font-size: 60px;
          font-weight: 600;
          @include desktop {
            font-size: 42px;
          }
          @include tablet {
            font-size: 28.5px;
          }
          @include phone {
            font-size: 28.5px;
          }
        }
        &:nth-of-type(2) {
          font-size: 120px;
          font-weight: 700;
          margin-top: 7.5px;
          margin-bottom: 75px;
          @include desktop {
            font-size: 82.5px;
            margin-bottom: 60px;
          }
          @include tablet {
            font-size: 57px;
            margin-bottom: 45px;
          }
          @include phone {
            font-size: 15vw;
            margin-bottom: 30px;
          }
        }
        &:nth-of-type(3) {
          font-size: 27px;
          line-height: 45px;
          max-width: 690px;
          padding-right: 30px;
          @include desktop {
            font-size: 21px;
            line-height: 37.5px;
          }
          @include tablet {
            font-size: 18px;
            line-height: 30px;
            padding-right: 0;
          }
          @include phone {
            font-size: 5.1vw;
            line-height: 7.5vw;
            padding-right: 0;
          }
        }
      }
		}
		.col-right{
		  @include flex-param(column);
		  padding-left: 0;
		  padding-right: 0;
      @include desktop-hd {
        min-width: 450px;
      }
      @include desktop {
        min-width: 450px;
      }
      @include tablet {
        min-width: 360px;
      }
      > div {
        @include flex-param(column, center);
        width: 100%;
        &:nth-of-type(1) {
          background-color: rgba(0,0,0,0.45);
          @include desktop-hd {
            height: calc(100% - 180px);
            padding-left: 60px;
            padding-right: 60px;
          }
          @include desktop {
            height: calc(100% - 150px);
            padding-left: 60px;
            padding-right: 60px;
          }
          @include tablet {
            height: calc(100% - 105px);
            padding-left: 30px;
            padding-right: 30px;
          }
          @include phone {
            padding-left: 30px;
            padding-right: 30px;
          }
        }
        &:nth-of-type(2) {
          background-color: #C5203E;
          @include desktop-hd {
            height: 180px;
            padding-left: 30px;
            padding-right: 30px;
          }
          @include desktop {
            height: 150px;
            padding-left: 30px;
            padding-right: 30px;
          }
          @include tablet {
            height: 105px;
            padding-left: 15px;
            padding-right: 15px;
          }
          @include phone {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        img {
          max-width: 100%;
        }
      }
      a {
        margin-top: 12px;
        margin-bottom: 12px;
        width: 100%;
        position: relative;
        @include desktop {
          margin-top: 12px;
          margin-bottom: 12px;
        }
        @include tablet {
          margin-top: 12px;
          margin-bottom: 12px;
          padding-left: 15px;
          padding-right: 15px;
        }
        @include phone {
        }
        &:hover {
          color: white;
          svg{
            @include svgcolor(white);
          }
        }
        &.btn.btn-white {
          color: $red;
          svg {
            @include svgfilter(left, $red);
            &.icon-circle-arrow-right {
              @include size(20px);
            }
          }
          &:hover {
            color: white;
            svg{
              @include svgcolor(white);
            }
          }
        }
      }
		}
	}

	section#testimonials{
		@extend %vertical-padding;
		@include inview(".carousel", bottom, 0s);
		@include inview("span.link", "static", .6s);
		@include phone{
			padding-top: 0;
			padding-bottom: 150px;
		}
		.carousel{
			height: 300px;
			margin-bottom: 50px;
			background-color: white;
			@include phone{
				height: 330px;
			}
			.carousel-control{display: none;}
			.item .flex-caption{
				@include flex-param(column, center, center);
				@extend %testimonials-formatting;
				@include phone{
					.info p{
						font-size: 15px;
					}
				}
			}
			.item .quote:before,
			.item .quote:after{
				content: '"';
			}
			.item:nth-of-type(3) .quote{
				padding: 0 100px;
				@include desktop{
					padding: 0 150px;
				}
				@include phone{
					padding: 0 10px;
				}
			}
			.carousel-indicators{
				@extend %carousel-indicator-style;
				li:not(.active){
					background-color: $medium-gray;
				}
				li.active{
					background-color: black;
				}
			}
		}
		p{
			color: $gray;
		}
		
	}

}