#vendor-request {
	section#hero{
		@extend %theme-large-heading;
		@include background-image('/images/site/general-contact/hero-bkg.jpg', cover, center);
		color: white;
		@include phone{
			p{
				text-align: center;
			}
			.type-prequalify-lg{
			    font-size: 10vw;
			    line-height: 12vw;
			}
		}
	}

	section#content{
		background-color: $lightest-gray;
		padding-top: 90px;
		padding-bottom: 90px;

		@extend %modal-them-base;

		@include form-results-color(white);
		
		color: $gray;
		padding-left: 0;
		padding-right: 0;

    a {
      color: $red;
    }

    form .form-component {
      height: auto;
    }
    .form-group {
      margin-bottom: 30px;
    }
	.btn.btn-red{
		float: right;
	}
    textarea {
      height: 120px;
      resize: none;
    }
	}
}