.disclaimer {
  padding-top: 3em;
  padding-bottom: 3em;
  font-size: 16px;
  line-height: 1.6667;
  color: #444;
  strong, b, em {
    font-weight: 600;
  }
  em, i {
    font-style: italic;
  }
  p, li, a {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
  p, li {
    color: inherit;
  }
  ul, ol, p {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul, ol {
    padding-left: 1.5em;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  a {
    color: $blue !important;
    transition: color .2s ease-out;
    &:hover, &:focus {
      color: darken($blue, 15%) !important;
    }
  }
}