#image-gallery{
	#hero{
		height: 425px;
		padding-top: 100px;
		color: white;
		@include flex-param(column, center, center);
		@include background-image('/images/site/homepage/gallery-hero-bkg.webp', cover, center);
		h1{
			font-weight: 300;
		}
		@include phone{
			height: 285px;
			padding-top: var(--headerHeight);
		}
	}

	#content{
		background-color: $lightest-gray;
		padding: 80px 0;
		min-height: 500px;
		.accordian-thumbnail{
			width: calc(25% - 20px);
			float: left;
			margin: 20px 13px;
		}
		.filter{
			margin-bottom: 50px;
		}

		.btn.expand{
			display: inline-block;
			text-align: center;
			margin: 30px 0;
		}
		.btn-primary.btn-dropdown{
			span{
				background-color: $light-gray;
			}
		}
		.btn-primary.btn-dropdown:hover,
		.btn-primary.btn-dropdown:active,
		.btn-primary.btn-dropdown:focus
		{
			span{
				background-color: $red;

				svg:hover > path,
				svg:active > path,
				svg:focus > path{
					fill: white;
				}
			}
		}
		a > svg:hover,
		a > svg:active,
		a > svg:focus,
		span > svg:hover,
		span > svg:active,
		span > svg:focus{
			path{
				fill: $red;
			}
		}
		@mixin marginParam(){			
			.accordian-thumbnail:nth-of-type(4n - 3){
				margin-left: 0;
			}
			.accordian-thumbnail:nth-of-type(4n){
				margin-right: 0;
			}
		}

		@include desktop-hd{@include marginParam();}
		@include desktop{@include marginParam();}

		@include tablet{
			@include marginParam();
			button{
				width: 35vw;
			}
		}

		@include phone{
			padding: 80px 0 140px;
			accordian-thumbnail{
				@include flex-param(column, center, center);
			}
			button{
				width: 85vw;
			}
			.accordian-thumbnail{
				width: 90vw;
			}
		}
	}
	.modal-content{
		background-color: $red;
		.btn-close{
			background-color: darken($red, 25%);
			color: white;
		}
		svg path {
			fill: white;
		}
		.image {
			position: relative;
		}
		.caption {
			background-color: rgba(0, 0, 0, .75);
			color: #fff;
			padding: 7.5px;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
}