.custom-mortgage-calculator{
	color: white;
	form{
		background-color: $blue;
		padding: 50px;
		label{
			margin-bottom: 10px;
		}
		input:not(.btn-white),
		p{
			margin-bottom: 30px;
		}
		input:not(.btn-white){
			font-size: 16px;
			font-weight: 600;
		}
		input.btn-white{
			margin-top: 20px;
			float: right;
		}

		@extend %clearfix;
		@include phone{
			padding: 20px;
			input:not(.btn-white),
			p{
				margin-bottom: 20px;
			}
			.col-sm-12{
				text-align: center;
				input{
					float: none;
				}
			}
		}
	}
	.middle{
		background-color: darken($blue,5%);
		padding: 25px 50px;
		@include flex-param(row, space-between, center);
		@include phone{
		    padding: 20px;
		    flex-direction: column;
		}
	}
	.bottom{
		background-color: darken($blue,10%);
		padding: 30px;
		line-height: 20px;
	}
}