section#model-home {
  .container-fluid{
    @include phone{ padding: 0; }
  }
  .container{
    max-width: 960px;
  }
  .card-wide.featured{
    a:not(.view-details){
      color: $red;
    }
  }
  article.card > .card-wide > .top{
    background-color: white;
  }
  @extend %section-padding;
  @extend %microsite-title;
  background-color: black;
  @include phone {
  	padding-bottom: 60px;
  	p.type-subtitle-xl{
  		margin-bottom: 30px;
  	}
  	.card-wide .cta-link{
  		margin-bottom: 5px;
  	}

    .card .card-wide {
      padding-bottom: 15px;
      .info .info-top{
        padding: 15px;
        .info-left{
          width: 100%;
        }
      }
      .top .info .info-top .info-left p{
        margin-bottom: 10px;
      }
      .snapshot{
        li{
          padding: 0;
          margin: 0;
        }
        li:nth-child(-n+2){margin-bottom: 5px;}
        li:nth-child(odd){width: 60%;}
        li:nth-child(even){width: 40%}
      }

    }
  }
  .card {
    border: none !important;
    width: 1000px;
    max-width: 95vw;
    margin: 0 auto;
    cursor: default;
    margin-bottom: 15px;
    .top{
      height: 250px;
      @include phone{
        min-height: 250px;
        height: fit-content;
      }
    }
    .card-wide .top{ 
      border: none !important;
      .preloader,
      .thumb{
        width: 100%;
        max-width: 400px;
      }
  }
    .card-wide .top .info {
      display: grid;
      justify-content: flex-start;
      @include phone{
        flex: none;
        height: unset;
      }
      .info-top {
        padding: 0 0 0 30px;
        align-items: flex-end;
        @include phone{
          width: 90%;
          padding: 0;
          margin: 25px auto;
          align-items: flex-start;
        }
        @include tablet {
          padding: 15px;
        }
        .info-right a {
          @include tablet {
            font-size: 13.5px;
          }
        }
        .info-left{
          width: fit-content;
          p{
            color: black;
            font-weight: 400;
            letter-spacing: 2px;
            margin-bottom: 0;
          }
        }


      }
      .info-left::after{
        content: "";
        height: 1px;
        background-color: #999999;
        display: block;
        position: relative;
        top: 15px;
      }
      .info-bottom {
        border:none;
        margin-bottom: 15px;
        @include tablet {
          padding-left: 15px;
        }
        .snapshot{
          padding: 0;
          margin-top: 5px;
          li {
            color: black !important;

            > svg path{
              fill: black;
            }
            &:not(:first-child){
              margin-left: 0;
              &::before{
                content: " | ";
                display: block;
                margin: 0 15px;

                @include tablet{
                  display: none;
                }

                @include phone{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .card-wide-cta{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 30px;
      padding-right: 30px;
      gap: 25px;

      @include phone{
        padding-left: 0px;
        padding-right: 0px;
        gap: 1px;

        >a{
          text-align: center;
          font-size: 16px;
        }
      }

      .view-details{
        height: 50px;
        font-weight: 600;
        font-size: 1.1rem;
        letter-spacing: 2px;
      }
    }
    .snapshot {
      @include tablet {
        flex-wrap: wrap;
        gap: 7.5px;
        justify-content: space-between;
      }
      li {
        @include tablet {
          justify-content: flex-start;
          margin-left: 0;
          width: 45%;
        }
      }
    }
  }

  h2 {
    margin-bottom: 20px;
  }
}
