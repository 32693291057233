#model-detail {
	&.has-announcements #hero .row-bottom {
		margin-top: calc(var(--headerHeight) - 2px);
	}
	#hero .row-top{
		display: none;
	}

	#hero .row-bottom{
		margin-top: calc(var(--headerHeight) - 2px);
		@include dropshadow(0px,2px,10px,0,0.1,inset);
	}

	#hero .row-bottom .col-right {

        padding: 0 60px 30px 30px;

        @include phone{
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

		> *:not(.form-wrap, .form-schedule-my-tour-wrap),
        .form-results-container,
		form .input-group {
			max-width: 540px;
		}

		.detail {
			padding: 20px 0px 0px;

			.title {
				width: 450px;
				max-width: 100%;

				@include flex-param(column, space-between, left);
				.choose {
				  font-weight: 600;
				  margin-top: 30px;
				  margin-bottom: 30px;
				}
				> .list-unstyled {
				  > li {
				    display: flex;
				    align-items: center;
				    justify-content: space-between;
				    margin-bottom: 15px;
				    @include phone {
				      text-align: left;
				    }
				    .btn.btn-orange {
				      margin-left: 15px;
				      padding-top: 7.5px;
				      padding-bottom: 7.5px;
				      width: 180px;
				    }

					>a {
						color: $red;
					}
				  }
				}
				>.title-cell:nth-child(1){
					order: 1;
				}
				>.title-cell:nth-child(2){
					order: 0;
				}
				>.title-cell:nth-child(3){
					order: 2;
				}
				>.title-cell:nth-child(4){
					order: 3;
				}

				&::after{
					content: "";
					height: 1px;
					background-color: $light-gray;
					width: 100%;
					display: block;
					order: 4;
					margin: 25px 0;
				}
			}

			.address p{
				color: black;
			}

			.title .type-model-detail-lg{
				margin: 20px 0;
				text-transform: uppercase;
			}

			.title p:last-child{
				margin-bottom: 0;
			}

			.black {
				letter-spacing: 2px;
				margin-bottom: 10px;
			}

			h1.title-cell,
			h1.title-cell>span,
			h1.title-cell>span>a{
				color: black;
				line-height: 25px;
				font-weight: 600;
				letter-spacing: normal;
				margin-bottom: 0;
			}
			h1.title-cell>span>a{
				font-weight: 700;
			}

			.snapshot-info.title-cell{
				margin: 25px 0 15px;
				font-size: 1rem;
				color: black;
			}

			a.red {
				font-weight: 300;
				text-indent: -5px;
				font-size: 40px;
			}
			a.btn{
				padding: 15px;
				color: white;
			}
			a.btn svg{@include size(25px);}

			.address{
				@include flex-param(row, space-between, center);
			}

			@include phone{
				padding: 20px 24px 0;
				.title{
					width: 100%;
				}
				.address{
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
				}
			}
		}

    .guided-tour {
      margin-bottom: 30px;
      padding-left: 20px;
      @include phone {
        padding-left: 15px;
      }
    }

    .form-schedule-my-tour-wrap {
		background-color: unset;
		padding: 0px 30px 30px 0px;

        @include phone{
            padding: 0 15px 25px;
            margin: 0;
        }

		.btn-wrap.model-tour{
			display: flex;
			flex-direction: column;
			gap: 15px;
			max-width: 340px;

			@include phone{
				margin: 0 auto;
				a{
					font-size: 16px;
				}
			}
			
			.hours-detail{
				margin-top: 10px;
				padding: 0px;
				width: 100%;
				position: relative;
				padding: 15px 30px;

				@include phone{
					padding: 7.5px 15px;
				}

				svg{@include size(25px);}

				&::after{
					content: "";
					position: absolute;
					top: calc(50% - 8px);
					right: 20px;
					display: inline-block;
					width: 12px;
				  	height: 12px;
				  	border-left: 2px solid white;
				  	border-bottom: 2px solid white;
				  	transform: rotate(-45deg);
				}
				
			}

			.confirmation-page{
				background-color: $light-gray;
				padding: 15px;
			}

		}
		form{
			padding: 15px;
			background-color: $light-gray;

			p.type-lg{
				color: white;
				font-weight: 600;
				text-align: center;
				padding-bottom: 15px;
				margin-bottom: 0px;
				font-size: 16px;

				@include phone{
					font-size: 16px;
				}
			}

			.form-components .scheduled{
				margin-top: 5px;
			}

			.form-control{
				font-size: 12px;
				color: black;

				&::placeholder{
					color: black;
				}
			}

			.input-group.button-flex{
				align-items: center;
				justify-content: center;
			}

			.btn-wrap > button,
			.btn-wrap > input{
				padding: 10px 25px;
				border-radius: 5px;
				font-size: 14px;

				@include phone{
					font-size: 12px;
				}
			}
		}
		.type-lg {
		  color: $red;
		  font-weight: 700;
		  margin-bottom: 25px;

		  @include phone{
			font-size: 18px;
			text-align: center;
		  }
		}
		.date-time {
		  margin-top: 0;
		}
    }

    .sp1.form-schedule-my-tour-wrap {
      width: 450px;
      max-width: 100%;
      padding: 0;
      margin-top: 10px;

			h2,
			h3{
				margin-top: 25px;
				margin-bottom: 10px;
			}

			> h2:first-child{
				margin-top: 0;
			}

			p{
				line-height: normal !important;
			}

			@include phone{
				text-align: center;
				padding: 0 24px;
				margin-bottom: 24px;
			}

			.btn{
				border-radius: 8px;
				width: 100%;
				max-width: 300px;
				padding: 10px;
				font-size: .9rem;
				margin: 10px 0;
				line-height: 1.2rem;
			}

			.utour-disclosure{
				font-size: .8rem;
				font-style: italic;
				margin-top: 10px;
			}
			
			td.uib-day button.btn{
				padding: 5px 7px;
				margin: 0;

				&.active{
					background-color: $red;

					>span{
							color: white;
					}
				}
			}

			.datepicker-wrapper > input[type=text],
			select{
				cursor: pointer;
			}

			.type-sm{
				line-height: 1.2rem;
			}

			.strong{
				font-weight: 700;
			}

			.next-tour{
				padding-top: 10px;
				color: $red;
			}

			.next-tour > .tour-time{
				font-weight: 600;
				text-decoration: underline;

				i{
					font-size: 1.25rem;

					@include phone{
						font-size: 1rem;
					}
				}
			}

			.btn-wrap.model-tour{

				.btn{
					margin: 0;
				}

				.tour-form-container.hidden {
					max-height: 0; 
				}
				
				.tour-form-container:not(.hidden) {
					max-height: 1000px;
				}

				@include phone{
					a.btn{
						font-size: 0.875rem;
					}
				}
			}		

			.tour-form-container{
				margin: 10px 0 25px;
				background-color: $red;
				border-radius: 8px;
				width: 100%;
				max-width: 300px;

				@include phone{
					margin-left: auto;
					margin-right: auto;
				}

				form{
					background-color: unset;
				}

				.btn.btn-red-black{
					background-color: black;
					transition: all .2s ease-in-out;
					margin: 0;

					&:hover,
					&:active,
					&:focus{
						background-color: white;
						color: black;
					}
				}

				p.type-lg:first-child{
					display: none;
				}

				.form-components .scheduled{
					margin-top: 5px;
					float: unset;

					>li:first-child{
						display: none;
					}
					>li:nth-child(2){
						font-size:1.2rem;
						text-align: center;
						display: block;
						>span.edit-btn{
								display: inline-block;
								font-size: .9rem;
						}
					}
				}

				.btn-group button.btn-dropdown{
					background-color: $lighter-gray;
					color: black;
				}

				.dropdown button svg g path{
					fill: #000 !important;
				}

				.btn-wrap > p:last-child > a{
					margin-top: 15px;
				}
				.hs-form-booleancheckbox span{
					color: black;
					font-size: .67rem;
				}
			}
			.tour-form-container form::before{
				content: "Schedule a Tour";
				display: block;
				color: white;
				font-weight: 600;
				text-align: center;
				padding-bottom: 15px;
				margin-bottom: 0px;
				font-size: 16px;

				@include phone{
					font-size: 16px;
				}
			}            
			.hs-form-qsu{
				width: 100%;
				max-width: 300px;
				margin-top: 15px;

				@include phone{
					margin-top: unset;
					margin: 15px auto 0;
				}

				hubspot-form form{
					background-color: white;
					padding: 0;
				}

				hubspot-form input{
					border-radius: 8px;
					background-color: white;
					color: black;
					text-align: center;
					border: 1px $gray solid;
					font-size: .9rem;
					padding: 10px;
					line-height: normal;
					margin: 5px 0;

					&::placeholder{
						letter-spacing: normal;
						text-transform: capitalize;
						font-weight: 400;
					}

					&[type=submit]{
						width: 100%;
						background-color: $red;
						color: white;
						transition: all .2s ease-in-out;
						border-width: 0;
						margin: 10px 0 15px;
						line-height: 1.2rem;
						font-weight: 600;
						letter-spacing: normal;
						text-transform: capitalize;

						&:hover{
								background-color: black;
						}
					}
				}
			}
			.btn-wrap.model-tour{
				@include phone{
					align-items: center;
				}
			}
    }
		.info{
			padding: 20px 0 20px 20px;

			p{
				clear: both;
				line-height: 40px;
			}

            a {
                color: $red;
                text-transform: uppercase;
                @include flex-param(row, flex-start, center);
                svg {
                    @include size(20px);
                    @include svgcolor($red);
                    margin-right: 15px;
                }
            }

			snapshot-info {
			  @include phone {
			    padding: 0;
        }
        ul {
          padding: 20px 20px 10px;
          li{
            width: 50%;
            &:nth-child(even){
              width: 50%;
            }
          }
          &:nth-child(even){
            padding: 10px 20px 20px;
            margin-bottom: 20px;
          }
        }
      }
			@include desktop{
				snapshot-info .snapshot{font-size: 18px;}
			}
			@include tablet{
				snapshot-info .snapshot{font-size: 16px;}
				snapshot-info .snapshot li:first-child{width:55%}
				snapshot-info .snapshot li:last-child{width:45%}
			}
			@include phone{
				padding: 20px;
				snapshot-info .snapshot{font-size: 12px;}
				snapshot-info .snapshot li:first-child{width:60%}
				snapshot-info .snapshot li:last-child{width:40%}
				snapshot-info .snapshot li svg{
				    width: 25px;
				    height: 25px;
				    margin-right: 10px;
				}
				a{
					line-height: 20px;
					margin-bottom: 15px;
					img {
					  max-width: 100%;
					}
				}
			}
		}

	}

	#content .container .col-left .flex-wrap .icon-clock2 path {
		fill: $red;
	}

}
