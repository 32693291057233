#st-louis{
	#hero{
		height: 420px;
	    background-position: center;
	    background-repeat: no-repeat;
	    background-size: cover;
	    padding-top: 100px;
	    color: white;
	    @include flex-param(row, center, center);
    &::before {
      background-color: rgba(0,0,0,0.3);
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
		.type-subtitle-xl{
			font-weight: 300;
			position: relative;
		}
		@include phone{
			padding-top: 60px;
			height: 300px;
			text-align: center;
		}
	}

	#content{
		background-color: $lightest-gray;
		padding: 80px 0;
		p {
      line-height: 36px;
      margin-bottom: 30px;
      &.disclaimer {
        font-style: italic;
        margin-top: 75px;
      }
    }
	}
}