#our-collections{
	#our-collections-carousel .carousel{
		@extend %theme-small-heading;

		@include phone{
			max-height: 360px;
			.fill{
			    background-attachment: inherit;
			    background-size: cover;
			    background-position: 0px 50px;
			}
			.flex-caption{
				padding-top: 60px;
			}
		}
	}

	#collections-grid{
		padding: 65px 0;
		background-color: $lightest-gray;
		a.thumbnail{
			@include flex-param(column, center, center);
			@include size(350px);
			font-weight: 300;
			color: white;
			text-align: center;
			padding: 40px;
			margin-bottom: 65px;
			margin-left: 50px;
			background-color: $red;
			overflow: hidden;
			&:nth-child(3n - 2){
				margin-left: 0;
			}
			> *{
				position: relative;
				z-index:1;
				@include transition(all .3s ease-out);
			}
			.image{
				position: absolute;
				top: 0;
				left: 0;
				z-index: 0;
				@include size(100%);
				@include scale(1);
				opacity: 1;
		        background-position: center;
		        background-size: cover;
			}
			span{
				margin-top: 55px;
				text-shadow: 2px 2px black;
			}
			svg{
				margin-top: 30px;
				@include svgcolor(white);
				@include size(55px);
				@include rotateY(90);
				opacity: 0;
			}
			&:hover{
				.image{
					@include transition(all .3s ease-in);
					@include scale(1.05);
					opacity: .2;
				}
				span{
					@include transition(all .3s ease-in);
					margin-top: 0;
				}
				svg{
					@include transition(all .2s ease-in .2s);
					@include rotateY(0);
					opacity: 1;

				}
			}
		}

		@include desktop{
			a.thumbnail{
			    @include size(290px);
			}
		}

		@include tablet{
			a.thumbnail{
				@include size(215px);
			}
		}

		@include phone{
			padding: 30px 15px 80px 15px;
			a.thumbnail{
			    width: 100%;
			    height: 75vw;
			    margin-left: 0;
			    margin-bottom: 30px;
			}
		}
	}
}
