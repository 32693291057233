.ff__hero {
  padding-top: var(--headerHeight);
  background-image: url('/images/site/floorplan-finder/ff_hero.jpg');
  background-size: cover;
  background-position: center center;
  position: relative;
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  gap: 25px;

  @media screen and (min-width: 320px) {
    min-height: 70vh;
  }
}

.ff__container__hero {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 700px) minmax(0, 700px);
  width: 100%;
  max-width: 1200px;

  @media screen and (min-width: 360px) {
    grid-auto-rows: 250px auto;
    grid-auto-flow: row;
    justify-content: center;
  }

  @media screen and (min-width: 900px) {
    grid-auto-flow: column;
    grid-auto-rows: unset;
    justify-content: unset;
  }

  @media screen and (min-width: 1800px) {
    max-width: 1400px;
  }
}

.ff__hero-copy,
.ff__hero-img {
  display: flex;
  position: relative;
  z-index: 1;
  flex: 1;
}

.ff__hero-copy {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  // padding: 50px 0;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 25px;
  padding-right: 0;

  @media screen and (min-width: 360px) {
    padding: 50px 20px;
    text-align: center;
    align-items: center;
  }

  @media screen and (min-width: 900px) {
    padding-left: 50px;
    text-align: left;
    align-items: flex-start;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 25px;
    padding-right: 0;
  }
}

.ff__hero-img {
  justify-content: center;
  background-image: url('/images/site/floorplan-finder/ff-flooplan-updated.png');
  background-size: 250px;
  background-repeat: no-repeat;
  background-position: right bottom;
  margin-right: 50px;
  position: relative;
  // bottom: -28px;
  right: 0;

  @media screen and (min-width: 360px) {
    background-size: contain;
    background-position: center bottom;
    margin-right: 0;
  }

  @media screen and (min-width: 1190px) {
    background-size: 550px;
  }

  @media screen and (min-width: 1200px) {
    background-position: right bottom;
  }
}

.ff__hero-copy h1 {
  color: white;
  font-weight: 600;
}

@media screen and (min-width: 1200px) {
  .ff__hero-copy h1 {
    font-size: 3.5rem;
  }
}

.ff__hero-copy p {
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
}

.ff__hero-copy .btn.btn-white-black {
  border-radius: 5px;
  padding: 7px 25px;
  color: black;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1rem;

  @media screen and (min-width: 360px) {
    width: 220px;
  }

  @media screen and (min-width: 991px) {
    letter-spacing: 1.5px;
    font-size: 1.25rem;
    width: unset  ;

  }
}

.ff__hero::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(186, 31, 49, .88);
}

.ff__instructions,
.ff__tour .ff__tour-copy {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  padding: 50px 25px;

  @media screen and (min-width: 768px) {
    padding: 50px;
    gap: 50px;
  }

  @media screen and (min-width: 1200px) {
    padding: 75px 50px;
  }
}

.ff__tour {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.ff__instructions>h2,
.ff__tour .ff__tour-copy>h2 {
  color: black;
  text-transform: uppercase;
  font-weight: 400;
  width: 95%;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
  }
}

.ff__instructions .ff__instructions-container {
  display: grid;
  align-items: start;
  justify-content: center;
  grid-auto-flow: column;
  gap: 25px;
  margin: 25px 0;

  @media screen and (max-width: 991px) {
    grid-auto-flow: row;
    gap: 50px;
  }
}

.ff__instructions .ff__instructions-copy {
  padding: 0 25px;

  @media screen and (min-width: 768px) {
    padding: 0 50px;
  }
}

.ff__instructions .ff__instructions-copy h3>span.ff__copy-heading-numeral,
.ff__instructions .ff__instructions-copy h3>span.ff__copy-heading {
  display: block;
  font-weight: 700;
  width: 100%;
  text-align: center;
  color: black;
  margin-bottom: 25px;
}

.ff__instructions .ff__instructions-copy h3>span.ff__copy-heading-numeral {
  font-size: 2rem;
  color: #ba1f31;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.ff__instructions .ff__instructions-copy p,
.ff__tour .ff__tour-copy {
  color: black;
  text-align: center;
  line-height: normal;
}

.ff__instructions .btn.btn-red-black,
.ff__tour .ff__tour-copy .btn.btn-red-black,
.ff__schedule-tour hubspot-form input.hs-button {
  display: block;
  margin: 0 auto;
  border-radius: 5px;
  padding: 7px 25px;
  text-transform: uppercase;
  font-size: 1.15rem;
  width: fit-content;
  letter-spacing: .5px;
  font-weight: 600;
  line-height: 26px;
}

.ff__instructions a {
  min-width: 218px;

  @media screen and (max-width: 992px) {
    font-size: 16px;
    min-width: 182px;
  }
}

.ff__tour .ff__tour-hero {
  flex: 1;
  background-image: url('/images/site/floorplan-finder/ff_tour-hero.jpg');
  background-size: cover;
  background-position: center;
  align-self: stretch;
  min-height: 250px;
}

.ff__tour .ff__tour-copy {
  background-color: #d9d9d9;
  width: 50%;
}

@media screen and (max-width: 768px) {
  .ff__tour .ff__tour-copy {
    width: 100%;
  }
}

.ff__tour .ff__tour-copy .ff__tour-copy-seo p {
  margin-bottom: 15px;
}

.ff__schedule-tour {
  background-color: black;
  padding: 50px;
}

.ff__schedule-tour hubspot-form {
  display: block;
  margin: 0 auto;
  max-width: 95%;

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1200px;
    padding: 0 100px;
  }
}

.ff__schedule-tour hubspot-form label.hs-form-booleancheckbox-display,
.ff__schedule-tour hubspot-form .hs-richtext span {
  color: white;
}

.ff__schedule-tour h3,
.ff__schedule-tour>a {
  text-align: center;
  display: block;
  text-transform: uppercase;
  color: white;
  margin-bottom: 15px;
}

.ff__schedule-tour h3 {
  letter-spacing: 1px;
}

.ff__schedule-tour>a {
  font-style: italic;
  font-weight: 700;
}

.ff__schedule-tour hubspot-form .hs_submit {
  margin-top: 25px;
}

.ff__schedule-tour hubspot-form input {
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.ff__schedule-tour .sales-agent {
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
    width: 200px;
    background-size: 200px;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.ff__get-started {
  min-width: 100%;
  padding-top: var(--headerHeight);
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - var(--headerHeight) - var(--footerHeight));

  @include phone {
    padding-top: var(--headerHeight);
    min-height: calc(100vh - var(--headerHeight));
  }
}

.ff__get-started-form {
  opacity: 1;
  transition: opacity .2s ease-in;
}

.ff__get-started-form.disabled {
  opacity: 0;
}

.slick-slide>.ff__form-page {
  min-height: calc(100vh - var(--headerHeight));
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;

}

.ff__get-started #page-region {
  background-image: url('/images/site/floorplan-finder/page-1.jpg');
}

.ff__get-started #page-2 {
  background-image: url('/images/site/floorplan-finder/page-2.jpg');
}

.ff__get-started #page-3 {
  background-image: url('/images/site/floorplan-finder/page-3.jpg');
}

.ff__get-started #page-4 {
  background-image: url('/images/site/floorplan-finder/page-4.jpg');
}

.ff__get-started #page-5 {
  background-image: url('/images/site/floorplan-finder/page-5.jpg');
}

.ff__get-started #page-6 {
  background-image: url('/images/site/floorplan-finder/page-6.jpg');
}

.ff__get-started #page-7 {
  background-image: url('/images/site/floorplan-finder/page-7.jpg');
}

.ff__get-started #page-8 {
  background-image: url('/images/site/floorplan-finder/page-8.jpg');
}

.ff__get-started #page-9 {
  background-image: url('/images/site/floorplan-finder/page-9.jpg');
}

.ff__get-started #page-10 {
  background-image: url('/images/site/floorplan-finder/page-10.jpg');
}

.ff__get-started #page-11 {
  background-image: url('/images/site/floorplan-finder/page-2.jpg');
}

.ff__get-started #page-12 {
  background-image: url('/images/site/floorplan-finder/page-3.jpg');
}

.ff__get-started #page-99 {
  background-image: url('/images/site/floorplan-finder/page-11.jpg');
}

.ff__get-started .ff__form-page {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ff__get-started .ff__form-page>.ff__form-page-section {
  height: 100%;
  min-height: calc(100vh - var(--headerHeight));
  width: 50%;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  background-color: rgba(0, 0, 0, .5);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include phone {
    width: 100%;
    backdrop-filter: blur(2px);
  }
}

.ff__logo-container {
  background-image: url('/images/site/floorplan-finder/fff--logo-light.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 150px;
  min-height: 150px;
  max-height: 150px;
}

.ff__form-question-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.ff__form-question {
  font-size: 2rem;
  font-weight: 600;
  color: white;
  text-align: center;
  padding-bottom: 15px;
  line-height: 30px;

  @media screen and (min-width: 992px) {
    line-height: 40px;
  }

  @include phone {
    font-size: 1.25rem;
  }
}

.ff__form-page #regional-dropdown {
  width: 100%;
  max-width: 400px;

  .btn-dropdown {
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
  }
}

ul#regional-menu.dropdown-menu {
  @include phone {
    max-height: 20vh;
  }
}

.ff__form-page #regional-button svg.icon-angle-down {
  height: 22px;
}

.ff__form-page .dropdown-gray-to-red {
  width: 100%;
  max-height: 30vh;
  min-width: fit-content;

  @include dropshadow(15px, 15px, 0, 0, .15);

  li {
    cursor: pointer;

    >ul {

      background-color: $lightest-gray;
    }

    font-weight: 300;
    padding: 10px 50px;
    display: block;
    color: black;
    @extend .type-xl;
    @include hoverstate($red, white, .2s);

    @include laptop {
      font-size: 20px;
      line-height: 30px;
      padding: 5px 50px;
    }

    @include phone {
      padding: 10px 20px;
      font-size: 18px;
      line-height: 20px;

      .sub-dropdown li {
        padding-left: 35px;
      }
    }
  }

  @include phone {
    max-height: calc(35vh - 30px);
    padding: 0;
  }

  >li.super-region {
    font-weight: 600;
  }

  >li.sub-region {
    padding-left: 25px;
    list-style-type: disc;
  }

  >li {
    font-size: 18px;
    text-transform: uppercase;
    padding: 10px;
    line-height: unset;
    color: black;

    &:hover,
    &:focus,
    &:active {
      background-color: $red;
      color: white;
    }
  }
}

.ff__slick-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
  width: 100%;
  max-width: 500px;
  padding: 15px;

  button.btn.btn-red-black.slick-btn {
    border-radius: 15px;
    flex: .5;
    text-transform: uppercase;
    letter-spacing: 1px;

    @include phone {
      border-radius: 8px;
    }
  }

  .slick-btn {
    opacity: 1;
    transition: opacity .2s ease-in-out;

    &:disabled {
      opacity: 0;
    }

    .slick-prev {
      justify-self: flex-start;
    }

    .slick-next {
      justify-self: flex-end;
    }
  }
}

.ff__form-page-section .question-btn-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 300px;
  max-width: 90%;

  button.btn-white-black {
    width: 100%;
    border-radius: 15px;
    color: black;

    &:hover {
      color: white;
    }

    &.selected-answer {
      background-color: black;
      color: white;
    }

    @include phone {
      border-radius: 8px;
    }
  }
}

.ff__form-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  p {
    color: white;
    padding-bottom: 15px;
    text-align: center;
  }

  form p {
    color: black;
  }
}

.ff__loading-indicator {
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 5px;
  padding: 0 40px;

  p {
    padding-bottom: 0px;
  }

  .spinner {
    border: 4px solid rgba(1, 1, 1, 0.5);
    border-left-color: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }

  .spinner:not(.active) {
    display: none;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .checkmark {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid green;
    position: relative;
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
  }

  .checkmark.active {
    transform: scale(1);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 50%;
    width: 8px;
    height: 14px;
    border: solid green;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(-50%);
  }
}

.overlay-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .loader {
    border: 12px solid #f3f3f3;
    border-top: 12px solid #BA1F31;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  &.show {
    display: flex !important;
  }
}

.restart__modal {
  background-color: #fefefe;
  margin: auto;
  padding: 20px 40px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // gap: 15px;
  border-radius: 10px;

  h2 {
    color: black;
    text-align: center;
    margin-bottom: 0;
  }

  .body {
    padding: 20px 10px 30px 10px;

    p {
      text-align: center;
    }
  }

  .footer {
    display: block;
    text-align: center;

    a {
      border-radius: 10px;
      padding: 10px 25px;
      margin-top: 10px;

      &:hover {
        color: white;
      }
    }
  }



}

#continue-save-answers {
  width: 300px;
  height: 56px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  align-items: center;

  &:hover {
    color: white;
  }
}

.btn-option-asnwer {
  white-space: normal;
}