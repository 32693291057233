#collection-details{
	#hero{
		@include flex-param(column, center, center);
		@include background-image('/images/site/our-collections/hero-detail.jpg', cover, center);
    	background-color: blacks;
		text-align: center;
		color: white;
		height: 100%;

		&:before{
			opacity: 0;
			@include size(100%);
			@include psuedo-block(black);
			@include transition(all .3s ease-out);
		}

		.caption{
			max-width: 900px;
			opacity: 1;
			@include transition(all .3s ease-out);
			p:first-child{
				margin-bottom: 80px;
				font-weight: 300;
				b{
					text-transform: capitalize;
					font-weight: 600;
				}
			}
			p:last-child{
				line-height: 36px;
			}
		}

		.switch-collections{
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: rgba($red, 0.5);
			@include size(100%, 105px);
			@include flex-param(column, center, center);
			@include transition(all .3s ease-out);
			p{
				cursor: pointer;
				margin-bottom: 0px;
				@include transition(all .3s ease-out);
				@include flex-param(row, center, center);
				font-weight: 300;
				svg{
					margin-left: 30px;
					@include size(30px);
					@include svgcolor(white);
				}
			}
			#slick-collections{
				width: calc(100% - 185px);
				max-width: 1080px;
				position: relative;
				height: 0;
				@include transition(all .3s ease-out);
				.item{
					top: 50px;
				    height: 130px;
				    background-size: cover;
				    margin: 0 25px;
				    opacity: 0;
				    cursor: pointer;
				    line-height: 20px;
				    .inner{
						background-color: rgba($red, 0);
						@include size(100%);
						@include hoverstate(rgba($red, .7), white, .3s);
						@include flex-param(column, center, center);
						&.active{
							background-color: rgba($red, .7);
						}
				    }
				    @include flex-param(column, center, center);
				    @include transition(all .3s ease-out);
				}
			}
		}

		&.toggle{
			&:before{
				opacity: .7;
				@include transition(all .3s ease-in);
			}
			.switch-collections{
				height: 250px;
				@include transition(all .3s ease-in);
				p{
					margin-bottom: 30px;
					@include transition(all .3s ease-in);
				}
				#slick-collections{
					height: 130px;
					@include transition(all .3s ease-in);
					.item{
						top: 0px;
						opacity: 1;
						@include transition(all .3s ease-in .3s);
					}
				}
			}
		}


		@include phone{
			height: calc(100% - 80px);
			.switch-collections{
				height: 55px;
			}
			.switch-collections p{
				font-size: 6vw;
			}
			.switch-collections #slick-collections{
				width: 100%;
			}
			.switch-collections #slick-collections .item{
				background-position: center;
				height: 100%;
				margin: 0 15px;
			}
			&.toggle .switch-collections{
				height: 205px;
			}
			&.toggle .switch-collections #slick-collections{
				height: 100px;
			}
		}
	}

	#content{
		padding: 75px 0;

		inline-form,
		.card-group .card{
			margin-bottom: 60px;
		}

		.card-group{
			@include flex-param(row, space-between, flex-start);
			flex-wrap: wrap;

			.card{
	    		width: calc(50% - 15px);
			}

		}
		@include phone {
			padding: 0 0 80px;
			.card-group .card{
				width: 100%;
				margin-bottom: 15px;
			}
			inline-form{
				margin-bottom: 0
			}
		}
	}
}
.form-container{
	width: 100%;
	margin: 0;
	background-color: #e0e0e0;
	position: relative;
	padding: 24px 48px;

	@include phone{
		padding: 24px;
	}
	.submitted-message{
		padding: 100px 0;
		text-align: center;
	}

	form{
		display: flex;
		flex-wrap: wrap;

		fieldset,
		.hs_submit{
			flex-basis: 100%;
			.hs-button{
				border-radius: 5px;
			}
		}

		textarea{
			height: auto;
			max-height: 2.625rem;
			overflow: hidden;
			resize: none;
			padding: 13px 15px 5px;
			white-space: nowrap;

			&::placeholder{
				color: black;
				font-weight: 300;
				letter-spacing: 1.5px;
				white-space: nowrap;
			}
		}

		input[type="checkbox"]{
			min-width: 25px;
		}

		@media screen and (min-width: 769px) and (max-width: 1024px){
			input::placeholder,
			textarea::placeholder,
			select{
				font-size: .75rem;
			}

			textarea{
				padding: 15px 15px 5px;
			}
		}

		@media screen and (max-width: 768px) {
			.input > input.hs-input {
				width: 100% !important;
			}
		}
	}
	.hs-form{
		width: 80%;
		max-width: 1200px;
		margin: 0 auto;

		h3{
			font-size: 1.75rem;
			font-weight: 600;
			&> a{
				font-size: 1.5rem;
				display: block;
			}
		}

		@media screen and (max-width: 768px){
			width: 100%;
		}
	}
	.sales-agent{
		height: 220px;
		width: 200px;
		position:absolute;
		bottom: 0;
		right: 0;
		&:not(.show-agent){
			display: none;
		}
		@media screen and (min-width: 301px) and (max-width: 425px){
			background-size: contain;
			background-repeat: no-repeat;
			width: 180px;
			height: 180px;
		}
		@media screen and (max-width: 300px){
			display: none;
		}
	}
	h3{
		font-size: 1.5rem;
		font-weight: 400;
		text-align: center;
		margin-bottom: 10px;
		color: black;

		a{
			font-size: 1.5rem;
			color: #ba1f31;
			font-weight: 700;
			font-style: italic;
		}
	}
			
	@media screen and (min-width: 451px) and (max-width: 768px){
		padding-top: 32px;
		padding-bottom: 48px;
	}

	@media screen and (max-width: 450px){
		padding-top: 32px;
		padding-bottom: 32px;
		padding-left: 24px;
		padding-right: 24px;
		width: 100%;
	}

	&.show-agent .hs-form form{
		@media screen and (max-width: 768px) {
			.hs_opt_in{
				width: calc(100% - 125px);
				padding-top: 32px;

				.input{
					margin: 0 15px;
				}
			}
			.hs_submit .actions{
				width: calc(100% - 125px);
			}
		}
		@media screen and (max-width: 425px){
			.hs_opt_in{
				width: calc(100% - 150px);
				padding-top: 15px;

			}
		}
		@media screen and (max-width: 300px){
			.sales-agent{
				display: none;
			}
			.hs_opt_in{
				width: 100%;
			}
			.hs_submit .actions{
				width: 100%;
			}
		}
	}
}
.our-collections-subheader {
	font-size: 3em;
	color: $red;
	margin: 1em;
	text-align: center;
}