#virtual-tours {
	#virtual-tour-carousel .carousel {
		@extend %theme-small-heading;

    .fill {
      background-size: cover;
      &::before {
        background-color: rgba(0,0,0,0.6);
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .sub-caption {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      > .container > .row > div[class^="col-"] > div {
        background-color: rgba($red,0.75);
        padding: 15px 150px;
        @include desktop {
          padding-left: 15px;
          padding-right: 15px;
        }
        @include tablet {
          padding-left: 15px;
          padding-right: 15px;
        }
        @include phone {
          padding-left: 15px;
          padding-right: 15px;
        }
        p {
          line-height: 36px;
          text-transform: none;
          @include phone {
            font-size: 15px;
            line-height: 18px;
          }
        }
      }
    }

    @include tablet {
      .fill {
        background-size: cover;
      }
    }

		@include phone {
			max-height: 300px;
			.fill {
			    background-attachment: inherit;
			    background-size: cover;
			    background-position: 0px 50px;
			}
			.flex-caption {
				padding-top: 60px;
			}
		}
	}

	#collections-grid {
		padding: 65px 0;
		background-color: $lightest-gray;

    .collection-subheader {
      font-size: 2.4em;
      color: $red;
      margin-top: .9em;
      margin-bottom: .9em;
      text-align: center;
      
      &:hover a,
      &:active a,
      &:hover a{
        color: $red;
        text-decoration: underline;
        border-bottom: 0px solid transparent;
      }

      @include tablet {
        font-size: 1.8em;
      }
      @include phone {
        font-size: 1.5em;
      }
    }

		.virtual-tour {
		  @include size(100%, 219px);
      background-size: cover;
      display: block;
      margin-bottom: 30px;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      @include desktop {
        @include size(100%, 174px);
      }
      @include tablet {
        @include size(100%, 159px);
      }
      @include phone {
        @include size(100%, 219px);
      }

		  p {
		    background-color: rgba($red,0.75);
        color: white;
        position: absolute;
        top: calc(100% - 52px);
        padding-top: 18px;
        @include size(100%);
        @include flex-param(column, flex-start, center);
        @include transition(all .2s ease-in);
        &:after {
          margin-top: 5px;
          font-size: 50px;
          content: "+";
          opacity: 0;
          @include transition(all 5s ease-out, .2s);
          @include rotateY(90);
        }
        @include desktop {
          padding-top: 9px;
          top: calc(100% - 34px);
        }
        @include tablet {
          padding-top: 9px;
          top: calc(100% - 34px);
        }
        @include phone {
          font-size: 13px;
          padding-top: 9px;
          top: calc(100% - 34px);
        }
		  }

		  &:hover {
		    p {            
          top: 0;
          padding-top: calc(50% - 25px);
          @include transition(all .2s ease-out);
          &:after {
            opacity: 1;
            @include transition(all .1s ease-out);
            @include rotateY(0);
          }
        }
      }
		}

		@include phone{
			padding: 30px 15px 80px 15px;
		}
	}

	#like-what-you-see {
	  @include background-image('/images/site/virtual-tours/like-what-you-see.jpg', cover, center);
	  padding-top: 120px;
	  padding-bottom: 120px;

	  &::before {
      background-color: rgba(0,0,0,0.6);
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

	  p {
	    color: #fff;
	    margin-bottom: 30px;
	  }
	}
}
