form {

  input.form-control,
  select.form-control {
    border-radius: 5px !important;
    height: 35px;
    color: $gray;
  }

  .form-component {
    position: relative;
    height: 35px;

    .help-block {
      &::before {
        display: block;
        position: absolute;
        content: "";
        top: -10px;
        left: 8px;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-bottom-color: #ba1f31;
      }

      font-size: 12px;
      position: absolute;
      bottom: -20px;
      background-color: #ba1f31;
      text-transform: capitalize;
      z-index: 10;
      padding: 3px 5px;
      color: #fff;
    }

    input:disabled,
    select:disabled {
      opacity: 0.5;
    }

    i.fa {
      color: $light-gray;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 5;

      &.fa-times {
        top: 0;
        right: -20px;
        display: none;
      }
    }
  }

  .uib-datepicker {
    .btn-default {
      border-color: transparent;
    }

    thead tr th {
      vertical-align: middle;
    }
  }

  @include invalid-form-fields;
  @include uppercase-placeholders;
}

div.form-results-container {
  padding: 20px 0;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #7b8a8b;
  background-color: #cecece;
  font-weight: bold;
  border-radius: 8px;
  margin-bottom: 30px;
}

div.form-results-loader {
  text-align: center;

  i.fa {
    font-size: 40px;
    color: $gray;
  }
}

.filter-select {
  -webkit-appearance: menulist;
  cursor: pointer;
}

range-select,
category-select {
  padding-left: 0.5em;
}

.checkbox-opt-in {
  label {
    padding-left: 16.5px;

    input[type="checkbox"] {
      margin-left: -16.5px;
    }
  }
}

.form-schedule-my-tour-wrap {
  background-color: $light-gray;
  width: 100%;

  @include desktop-hd {
    margin-bottom: 60px;
    padding: 30px;
  }

  @include desktop {
    margin-bottom: 60px;
    padding: 30px;
  }

  @include tablet {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 30px 15px;
  }

  @include phone {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px 15px;
  }

  .type-lg {
    color: white;
    margin-bottom: 30px;

    @include tablet {
      font-size: 16.5px;
    }

    @include phone {
      font-size: 6.9vw;
    }
  }

  button[disabled="disabled"] span {
    color: #cecece;
  }

  .date-time {
    margin-top: 30px;
    margin-bottom: 7.5px;
  }

  form {
    .button-flex {
      .btn-wrap {
        .btn.btn-orange {
          margin-bottom: 0;
        }
      }
    }

    .form-components {
      width: 100%;

      .scheduled {
        @include desktop-hd {
          float: right;
          margin-top: -60px;
        }

        @include desktop {
          float: right;
          margin-top: -60px;
        }

        @include tablet {
          float: right;
          margin-top: -60px;
        }

        >li {
          color: white;
          font-size: 12px;

          span.edit-btn {
            cursor: pointer;
            padding-left: 5px;
            text-decoration: underline;
          }

          &:first-child,
          &:nth-child(2) {
            line-height: 18px;
          }

          &:last-child {
            line-height: 30px;
          }

          @include desktop-hd {
            text-align: right;
          }
        }
      }
    }

    .form-component {
      background-color: white;
      border-radius: 5px;
      float: left;
      width: calc(50% - 10px);
      margin-bottom: 10px;
      border: none;

      @include phone {
        width: 100%;
      }

      &:nth-child(even) {
        float: right;
      }

      &:after {
        @extend %clearfix;
      }

      i.fa.fa-angle-down {
        font-size: 30px;
        top: 3px;
        z-index: 1;
      }

      .form-control {
        background-color: transparent;
      }
    }

    .button-flex {
      color: white;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-pack: end;
      justify-content: flex-start;
      -ms-flex-align: center;
      align-items: center;

      @include phone {
        justify-content: center;
      }

      .btn-wrap {
        color: white;
        flex-wrap: wrap;

        @include desktop-hd {
          @include flex-param(row, space-between, flex-end);
        }

        @include desktop {
          @include flex-param(row, space-between, flex-end);
        }

        @include tablet {
          @include flex-param(row, space-between, flex-end);
        }

        @include phone {
          @include flex-param(column, space-between, flex-start);
        }

        .checkbox-opt-in {
          label {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 15px;

            @include desktop {
              font-size: 12px;
            }
          }
        }

        .btn.btn-orange {
          margin-bottom: -51px;

          @include desktop-hd {
            padding: 9px 21px;
          }

          @include desktop {
            padding: 9px 21px;
          }

          @include tablet {
            font-size: 13.5px;
            padding: 9px 21px;
          }

          @include phone {
            font-size: 16px;
            padding: 9px 21px;
            margin-bottom: 0;
          }
        }

        p {
          @include desktop-hd {
            margin-bottom: 7.5px;
          }

          @include desktop {
            margin-bottom: 7.5px;
          }

          @include tablet {
            margin-bottom: 7.5px;
          }

          @include phone {
            margin-top: 15px;
          }

          a {
            color: #fff;

            @include phone {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  h5 {
    color: #fff;
  }

  .tour-confirmation {
    line-height: 24px;
    color: #fff;
    text-align: center;

    p {
      margin-bottom: 15px;
    }
  }

  div.form-results-loader i.fa {
    color: #fff;
  }

  .dropdown {
    margin: 10px 0 15px 0;
    text-align: center;
    width: 100%;
  }

  button.btn-dropdown.dropdown-toggle,
  button.btn-dropdown.dropdown-toggle:focus {
    width: auto !important;
    background-color: transparent;
    color: #fff;
    border-radius: 4px;
    border: 1px solid #fff;
    display: inline-block;
    float: none;
    padding: 5px 8px !important;
  }

  .dropdown button {
    font-size: 12px;
    box-shadow: none !important;
    padding: 10px;
    margin-bottom: 0px !important;
    @include flex-param(row, space-between, center);

    svg {
      width: 14px;
      margin-left: 8px;

      @include rotate(0);
      @include svgcolor($medium-gray);
      @include transition(all .2s ease-out);

      g path {
        fill: #fff !important;
      }
    }
  }

  .dropdown.open button svg {
    @include rotate(-180);
    @include transition(all .2s ease-in);
  }

  .dropdown-menu {
    background-color: $lightest-gray;
    border-radius: 0;

    a {
      border: none;
      font-size: 14px;
      padding: 10px 15px;
      white-space: normal;
      font-weight: 400;

      @include hoverstate($red, white, .2s);

      @include desktop-hd {
        padding: 10px 20px;
      }
    }
  }
}

.form-exposed-schedule-my-tour-wrap {
  background-color: $light-gray;
  flex-grow: 1;

  @include desktop-hd {
    padding: 30px;
  }

  @include desktop {
    padding: 30px 24px;
  }

  @include tablet {
    padding: 30px 15px;
  }

  @include phone {
    padding: 20px 15px;
  }

  .type-lg {
    color: white;

    @include desktop-hd {
      margin-bottom: 24px;
    }

    @include tablet {
      font-size: 16.5px;
    }

    @include phone {
      font-size: 6.9vw;
    }
  }

  button[disabled="disabled"] span {
    color: #cecece;
  }

  .date-time {
    margin-top: 30px;
    margin-bottom: 7.5px;
  }

  form {
    >.row>div[class^="col-"] {
      &:first-child {
        >div {
          @include desktop-hd {
            padding-right: 15px;
          }
        }
      }

      &:last-child {
        >div {
          @include desktop-hd {
            padding-left: 15px;
          }
        }
      }
    }

    .button-flex {
      .btn-wrap {
        .btn.btn-orange {
          margin-bottom: 0;
        }
      }
    }

    .form-components {
      width: 100%;

      .scheduled {
        >li {
          color: white;
          font-size: 12px;

          span.edit-btn {
            cursor: pointer;
            padding-left: 5px;
            text-decoration: underline;
          }

          &:first-child,
          &:nth-child(2) {
            line-height: 18px;
          }

          &:last-child {
            line-height: 30px;
          }

          @include desktop-hd {
            text-align: right;
          }

          @include phone {
            display: block;
          }
        }
      }
    }

    .form-component {
      margin-bottom: 10px;
      height: 40px;
      border: none;

      @include phone {
        width: 100%;
      }

      &:after {
        @extend %clearfix;
      }

      i.fa.fa-angle-down {
        font-size: 30px;
        top: 3px;
      }
    }

    .checkbox-opt-in {
      label {
        color: white;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 15px;
      }
    }

    .btn-wrap {
      flex-wrap: wrap;

      @include desktop-hd {
        @include flex-param(row, space-between, flex-end);
      }

      @include desktop {
        @include flex-param(row, space-between, flex-end);
      }

      @include tablet {
        @include flex-param(row, space-between, flex-end);
      }

      @include phone {
        @include flex-param(column, space-between, flex-start);
      }

      .btn.btn-orange {
        @include desktop-hd {
          padding: 9px 21px;
        }

        @include desktop {
          padding: 9px 21px;
        }

        @include tablet {
          font-size: 13.5px;
          padding: 9px 21px;
        }

        @include phone {
          font-size: 16px;
          padding: 9px 21px;
          margin-bottom: 0;
        }
      }

      p {
        @include desktop-hd {
          margin-bottom: 7.5px;
        }

        @include desktop {
          margin-bottom: 7.5px;
        }

        @include tablet {
          margin-bottom: 7.5px;
        }

        @include phone {
          margin-top: 15px;
        }

        a {
          color: #fff;

          @include phone {
            font-size: 15px;
          }
        }
      }
    }
  }

  h5 {
    color: #fff;
  }

  .tour-confirmation {
    line-height: 24px;
    color: #fff;
    margin-bottom: 15px;
  }

  div.form-results-loader i.fa {
    color: #fff;
  }

  .dropdown {
    margin-bottom: 15px;

    @include desktop {
      margin-top: 10px;
    }

    @include tablet {
      margin-top: 10px;
    }

    @include phone {
      margin-top: 10px;
    }
  }

  button.btn-dropdown.dropdown-toggle,
  button.btn-dropdown.dropdown-toggle:focus {
    width: auto !important;
    background-color: transparent;
    color: #fff;
    border-radius: 4px;
    border: 1px solid #fff;
    padding: 5px 8px !important;
  }

  .dropdown button {
    font-size: 12px;
    box-shadow: none !important;
    padding: 10px;
    margin-bottom: 0px !important;
    @include flex-param(row, space-between, center);

    svg {
      width: 14px;
      margin-left: 8px;

      @include rotate(0);
      @include svgcolor($medium-gray);
      @include transition(all .2s ease-out);

      g path {
        fill: #fff !important;
      }
    }
  }

  .dropdown.open button svg {
    @include rotate(-180);
    @include transition(all .2s ease-in);
  }

  .dropdown-menu {
    background-color: $lightest-gray;
    border-radius: 0;

    a {
      border: none;
      font-size: 14px;
      padding: 10px 15px;
      white-space: normal;
      font-weight: 400;

      @include hoverstate($red, white, .2s);

      @include desktop-hd {
        padding: 10px 20px;
      }
    }
  }

  .form-disclaimer {
    color: #fff;
    margin-top: 15px;
  }
}

.form-disclaimer {
  font-size: 13.5px;
  line-height: 19.5px;

  a {
    color: white;
    text-decoration: underline !important;
    display: inline-block !important;
  }
}


form.myFavoritesLoginForm {
  .form-group {
    // margin-bottom: 22.5px;


  }



}

tool-tip {
  position: absolute;
  z-index: 1;
  // width: 80%;
  pointer-events: none;
  user-select: text;
  box-shadow: 0 0 2px #000;
  padding: .5rem;
  border-radius: 4px;
  font-size: .8rem;
  top: 100%;
  left: 0;
  background-color: #ba1f31;
  color: white;

  &::before {
    content: '';
    position: absolute;
    top: -40%;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #ba1f31;
  }
}

:has(> tool-tip) {
  position: relative;
}

.form__error-request--message,
.form__success-request--message {
  margin-top: 10px;
  padding: 20px;
  border-radius: 4px;

  text-align: center;
}

.form__error-request--message {
  border: 1px solid #ba1f31;
  background-color: rgba($color: #ba1f31, $alpha: 0.1);

}

.form__success-request--message {
  border: 1px solid #008f39;
  background-color: rgba($color: #008f39, $alpha: 0.1);
}

.input-password-box {
  position: relative;
  display: flex;
  align-items: center;

  input.form-control {
    padding-right: 2.5rem;
  }

  .icons--append {
    position: absolute;
    right: 0.75rem;
    display: flex;
    align-items: center;
    height: 100%;

    svg {
      width: 1.2rem;
      height: 1.2rem;
      cursor: pointer;
    }

  }
}