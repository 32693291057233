section#residences-homes{
  .collection-features-page{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-direction: row;
    position: relative;
    z-index: 100;

    margin-top: -25px;

    @include phone{
      flex-direction: column;
      margin-top: -40px;
    }

    a.btn-gray-black{
      flex: 1;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 10px 30px;
      max-width: 250px;

      @include phone{
        width: 90%;
        max-width: unset;
      }
    }
  }
  >.container-fluid{
    width: 95%;
    max-width: 1400px;
    margin: 0 auto;

    @media screen and (max-width: 768px){
      width: 100%;
      padding: 0;
    }
  }
  padding: 75px 0;

  @include phone{
    padding: 15px 0 45px 0;
  }

  .section-heading{
    display: flex;
    align-items: center;
    justify-content: center;

    div{
      margin: 0 15px;

      &.active{
        border-bottom: 2px $red solid;
      }
  
      p{
        font-size: 36px;
        color: black;
        font-weight: 300;
        cursor: pointer;
        text-align: center;

        @include phone{
          font-size: 20px;
        }
      }
      .count{
        font-weight: 700;
        line-height: 2.25rem;
      }
    }
  }

  // for section#floorplans + section#move-in-ready
  .filtered-count{
    text-align: center;
    color: black;
    font-size: 24px;
    margin-bottom: 25px;
    font-weight: 300;
    letter-spacing: 1px;

    p{
      font-size: 22px;
      font-style: italic;
      color: $red;

      @include phone{
        font-size: 18px;
      }
    }
  }
  .filter {
    text-align: center;
    margin-bottom: 17px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .dropdown-menu{
      width: 450px;
      @include phone{
        width: 90vw;
        position: absolute;
        left: 50%;
        top: 2rem;
        @media screen and (max-width: 765px){
          top: 3rem;
        }
        transform: translateX(-50%);
        z-index: 2;
      }
      .filter-heading{
        font-size: 22px;
      }
      li{
        &:not(:last-child)::after{
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background-color: #CDCDCD;
        }
        a{
          padding: 15px 20px;
          .collection-grid{
            display: flex;
            gap: 15px;
            p{
              font-weight: 700;
            }
          }
        }
      }
    }

    .btn-group {
      border: 1px solid $lighter-gray;

      @include desktop-hd {
        flex: 1;
        margin-left: 15px;
        margin-right: 15px;
      }
      @include desktop {
        flex: 1;
        margin-left: 15px;
        margin-right: 15px;
      }
      @include tablet {
        flex: 1;
        margin-left: 15px;
        margin-right: 15px;
      }

      button {
        width: 100%;
        padding: 10px 15px;
        float: none;

        span {
          width: 40px;
        }

        svg {
          height: 16px;
        }

      }

    }

    @include phone{
      flex-wrap: wrap;
      .btn-group{
        margin-bottom: 20px;
        &:nth-child(1),
        &:nth-child(2) {
          width: 46.5%;
        }
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          width: 100%;
        }
      }
    }

  }
  .sorts{
    color: black;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 37.5px;

    @include phone{
      margin: 7.5px 15px;
    }

    .dropdown-filter::after{
      content: "";
      position: relative;
      top: -3px;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-left: 3px solid #ba1f31;
      border-bottom: 3px solid #ba1f31;
      transition: transform .5s ease-in-out, top .5s ease-in-out;
      transform: rotate(-45deg);
      margin-left: 10px;
    }

    &.open .dropdown-filter::after{
      transform: rotate(135deg);
      top: 0px;
    }

    ul.dropdown-menu{
      text-transform: capitalize;
      width: fit-content;
      min-width: 200px;
      left: 50%;
      transform: translateX(-50%)
    }
  }
  .card-group{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media screen and (min-width: 769px) and (max-width: 1024px){
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 768px){
      grid-template-columns: 1fr;
    }
  }
  section#floorplans,
  section#move-in-ready {
    padding: 25px 0 75px;
  }
  // for section#floorplans + section#move-in-ready

  .btn-filter{
    color: black;
    text-transform: uppercase;
    border: 2.5px solid $gray;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    margin: 0 15px;
    cursor: pointer;
    transition: border-color .2s ease-in;

    &.active{
      border-color: $red;
    }
    @include tablet {
      white-space: nowrap;
    }
    @include phone {
      &.dropdown-toggle{
        width: 75vw;
        min-width: fit-content;
      }
    }


    @include phone{
      margin: 7.5px 15px;
    }
  }

  .btn-red-black{
    width: 100%;
  }

  .btn-red-black.load-btn{
    width: fit-content;
    display: block;
    margin: 0 auto;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.1rem;
  }
    section#floorplans {
        @extend %section-padding;
        @extend %microsite-title;
        @include inview(".type-subtitle-xl", static, 0s);
        @include inview(".filter", bottom, .6s);
        @include inview(".sort", bottom, .8s);
        @include inview(".list", bottom, 1s);

        &:not(.active){
          display: none;
        }

        &.active{
          display: block;
        }
      
        .list {
          clear: both;
        }

        .expand-plus{
          color: $red;
          font-weight: 700;
        }
      
        .no-results {
          color: $red;
          font-size: 25px;
          text-align: center;
          margin: 50px 30px;
        }
      
        .btn-primary.btn-dropdown.dropdown-toggle{
          span{
            background-color: $light-gray;
      
          
          }
        }
        .btn-primary.btn-dropdown.dropdown-toggle:hover,
        .btn-primary.btn-dropdown.dropdown-toggle:focus,
        .btn-primary.btn-dropdown.dropdown-toggle:active{
          span{
            background-color: $red;
          }
        }
      
        .btn-filter-home-designs {
          background-color: $light-gray;
          border-top-color: $light-gray;
          border-bottom-color: $light-gray;
          border-left-color: white;
          border-right-color: white;
          border-radius: 0;
          color: white;
          font-size: 18px;
          margin-bottom: 20px;
          padding: 11.25px 30px;
          text-align: left;
          width: 100%;
          -webkit-box-shadow: none;
          box-shadow: none;
      
          &.active {
            background-color: white;
            color: $light-gray;
          }
      
          .fa {
            float: right;
            font-size: 24px;
          }
        }

        .sort-by-list {
          display: flex;
          margin-bottom: 7.5px;
          @include desktop-hd {
            justify-content: flex-end;
          }
          @include desktop {
            justify-content: flex-end;
          }
          @include tablet {
            justify-content: flex-end;
          }
          @include phone {
            justify-content: space-between;
          }
          li {
            cursor: pointer;
            @include desktop-hd {
              padding-left: 15px;
              padding-right: 15px;
            }
            @include desktop {
              padding-left: 15px;
              padding-right: 15px;
            }
            @include tablet {
              padding-left: 15px;
              padding-right: 15px;
            }
            &.active {
              span {
                .fa.active {
                  color: $red;
                }
              }
            }
            span {
              display: flex;
              flex-direction: column;
              float: right;
              margin-left: 7.5px;
              .fa {
                color: $gray;
                font-size: 15px;
                line-height: 9px;
              }
            }
          }
        }
      
        .pagination {
          float: right;
          margin: 40px 0px;
          > li {
            &:first-child {
              > a, span {
                border-radius: 0px;
              }
            }
      
            &.active {
              > a, span, a:hover, span:hover, a:focus, span:focus {
                z-index: 3;
                color: #fff;
                cursor: default;
                background-color: $blue;
                border-color: $blue;
              }
            }
            > a, span {
              padding: 10px 15px;
            }
          }
        }
      
        .inline-get-fast-answers{
          margin-top: 17px;
          margin-bottom: 17px;
          clear: both;
          @include phone{
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }

  section#floorplans {
    @extend %section-padding;
    @extend %microsite-title;
    @include inview(".type-subtitle-xl", static, 0s);
    @include inview(".filter", bottom, .6s);
    @include inview(".sort", bottom, .8s);
    @include inview(".list", bottom, 1s);
    .view-more-container > .btn-filter {
      background-color: white;
      transition: border-color .2s ease-in-out;
      display: block;
      margin: 0 auto;
      &:hover{
        border-color: $red;
      }
    }
    .list {
      clear: both;
    }

    .cta-link{
      background-color: #ba1f31;
      box-shadow: inset 0 0 20px transparent;
      color: #fff;
      text-shadow: 1px 1px 1px rgba(0,0,0,.3);
    }
    
    .no-results {
      color: $red;
      font-size: 25px;
      text-align: center;
      margin: 50px 30px;
    }

    .btn-primary.btn-dropdown.dropdown-toggle{
      span{
        background-color: $light-gray;

      
      }
    }
    .btn-primary.btn-dropdown.dropdown-toggle:hover,
    .btn-primary.btn-dropdown.dropdown-toggle:focus,
    .btn-primary.btn-dropdown.dropdown-toggle:active{
      span{
        background-color: $red;
      }
    }

    .btn-filter-home-designs {
      background-color: $light-gray;
      border-top-color: $light-gray;
      border-bottom-color: $light-gray;
      border-left-color: white;
      border-right-color: white;
      border-radius: 0;
      color: white;
      font-size: 18px;
      margin-bottom: 20px;
      padding: 11.25px 30px;
      text-align: left;
      width: 100%;
      -webkit-box-shadow: none;
      box-shadow: none;

      &.active {
        background-color: white;
        color: $light-gray;
      }

      .fa {
        float: right;
        font-size: 24px;
      }
    }

    .sort-by-list {
      display: flex;
      margin-bottom: 7.5px;
      @include desktop-hd {
        justify-content: flex-end;
      }
      @include desktop {
        justify-content: flex-end;
      }
      @include tablet {
        justify-content: flex-end;
      }
      @include phone {
        justify-content: space-between;
      }
      li {
        cursor: pointer;
        @include desktop-hd {
          padding-left: 15px;
          padding-right: 15px;
        }
        @include desktop {
          padding-left: 15px;
          padding-right: 15px;
        }
        @include tablet {
          padding-left: 15px;
          padding-right: 15px;
        }
        &.active {
          span {
            .fa.active {
              color: $red;
            }
          }
        }
        span {
          display: flex;
          flex-direction: column;
          float: right;
          margin-left: 7.5px;
          .fa {
            color: $gray;
            font-size: 15px;
            line-height: 9px;
          }
        }
      }
    }

    .pagination {
      float: right;
      margin: 40px 0px;
      > li {
        &:first-child {
          > a, span {
            border-radius: 0px;
          }
        }

        &.active {
          > a, span, a:hover, span:hover, a:focus, span:focus {
            z-index: 3;
            color: #fff;
            cursor: default;
            background-color: $blue;
            border-color: $blue;
          }
        }
        > a, span {
          padding: 10px 15px;
        }
      }
    }

    .inline-get-fast-answers{
      margin-top: 17px;
      margin-bottom: 17px;
      clear: both;
      @include phone{
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
  section#move-in-ready {
    @extend %section-padding;
    @extend %microsite-title;
    @include inview(".type-subtitle-xl", static, 0s);
    @include inview(".carousel", bottom, .6s);

    &:not(.active){
      display: none;
    }

    &.active{
      display: block;
    }

		.flex-wrap {
			@include flex-param(row, center, stretch);
      // justify-content: space-evenly;

			.card {
				opacity: 1;
				@include transition(all .2s ease-out);
				@include scale(1);
        background: white;
        // width: calc(33% - 30px);
        width: 33%;
        margin-left: 15px;
        margin-right: 15px;
        max-width: 400px;

        div.reg__card div.reg__card-content div.reg__card-body ul.list-unstyled.snapshot{
            flex-wrap: wrap;
            align-items: center;
            justify-content: start;
            border: none;
            li {
              margin: 0;
              font-size: 11px;
              svg {
                width: auto;
                height: 15px;
              }
            }
        }
			}

			.card .card .img{
				height: 185px;
			}

			.card .card .title{
				min-height: 0;
			}

			&.hovered .card.active,
			.card.active {
				@include transition(all .2s ease-out);
				@include scale(1.05);
			}

			&.hovered .card:not(.active) {
				@include transition(all .2s ease-out);
				opacity: .3;
			}

			@include tablet{
				.card .card .img{
					height: 125px;
				}
				.card .title .type-md,
				.card .availability.type-lg,
				.card snapshot-info li{
					font-size: 14px;
				}
				.card snapshot-info svg{
					@include size(20px);
				}
				.card .title,
				.card .availability,
				.card .cta-link-group .cta-link{
					padding: 20px;
				}
			}

			@include phone{
				flex-direction: column;
				.card{
				    width: 100%;
				    max-width: 300px;
					margin-bottom: 20px;
				}
			}

		}

		.carousel-control:after {
			background-color: transparent;
		}

    .controls-wrap{
      svg{
        background-color: $gray;
        transition: background-color .2s ease-in-out, transform 50ms ease-in;
        padding: 7.5px;

        &:hover,
        &:active{
          background-color: $red;
        }

        &:active{
          transform: scale(.95)
        }
      }
    }

		ol.carousel-indicators {
			@extend %carousel-indicator-style;
			bottom: -60px;

      li{
        background-color: $gray;
      }

			li.active {
				background-color: $red;
			}

		}

  }
  div.reg__card div.reg__card-content div.reg__card-body ul.list-unstyled.snapshot{
    border: none;
    display: flex;
    align-items: center;
    text-align: start;
    margin: 0;
    padding: 0;

    li {
      color: black;
      font-size: 13px;
      letter-spacing: 0;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      gap: 5px;

      &:not(:first-child)::before{
        content: "|";
      }

      svg {
        width: auto;
        height: 15px;
        margin: 0;
      }
    }
  }

  h2 {
    color: black;
  }
}