div#my-matches-view {
	margin-top: var(--headerHeight);

	.container-fluid {
		@media screen and (max-width: 768px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.slider-welcome {
		@media screen and (max-width: 768px) {
			display: none !important;
		}

		&.slider__my-matches {
			margin: 0 80px;
		}

	}
}

#my-favorites.my-matches {
	section#hero {
		padding-left: 0;
		padding-right: 0;

		.title-matches {
			padding: 30px 20px;
			text-align: center;
			margin-top: 0 !important;

			.title-matches__text {
				margin-bottom: 0;
				font-size: 32px;
				color: #000000;
				font-weight: bold;
			}
		}

		.content-welcome {
			display: flex;
			align-items: center;
			margin-left: 10vw;
			margin-bottom: 15px;

			.content-welcome__icon {
				margin-right: 10px;

				svg {
					width: 32px;
					height: 32px;
				}
			}

			.content-welcome__title {
				display: flex;
				font-size: 20px;
				color: #000000;
				// font-weight: bold;
				// margin-bottom: 5px;
			}
		}

		@media screen and (max-width: 768px) {
			display: none;
		}
	}

	.hero--img {
		height: 300px;
		background-position: 50% 20%;
		background-size: cover;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		padding: 0 120px;
		gap: 20px;

		@media screen and (min-width: 768px) {
			display: none;
		}

		div.text {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			max-height: 300px;
			padding: 3rem;

			h1 {
				font-size: 60px;
				color: #ffffff;
			}

			h5 {
				font-size: 16px;
				color: #ffffff;
				margin-top: 30px;
			}
		}

		div.img {

			padding-top: 20px;

			img {
				width: 100%;
			}
		}

	}

	.img-responsive {
		max-height: 350px;
		object-fit: cover;
		object-position: 50% 20%;
	}

	section#hero-mobile {
		margin-top: 110px;

		@media screen and (min-width: 769px) {
			display: none;
		}

		.content-header {
			display: flex;
			width: 100%;
			padding-top: 40px;
			padding-bottom: 10px;
		}

		.content-welcome {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			width: 100%;

			.content-welcome__icon {

				margin-right: 10px;

				svg {
					width: 32px;
					height: 32px;
				}
			}

			.content-welcome__hero {
				text-align: center;
				flex-grow: 1;

				.content-welcome__title {
					font-size: 24px;
					color: #000000;
					font-weight: bold;
				}

				.content-welcome__subtitle {
					font-size: 20px;
					color: #000000;
					margin-top: 10px;
				}
			}
		}

		.hero-text__box {
			padding: 12px 20px;
			border-radius: 10px;
			background: var(--Red, #BA1F31);

			h1 {
				color: var(--White, #FFF);
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: 150%;
				/* 30px */
				letter-spacing: 0.4px;
			}

			p {
				color: var(--White, #FFF);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				/* 24px */
				letter-spacing: 0.32px;
			}
		}
	}

	section#content {
		padding: 50px 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;

		@media screen and (max-width: 768px) {
			padding-top: 0;
		}



		.incomplete-match {
			display: flex;
			justify-content: center;

			padding: 20px;

			.button {
				color: var(--red, #BA1F31);
				text-align: center;
				font-weight: bold;

				@media screen and (min-width: 992px) {
					display: flex;
					align-items: center;
					height: 56px;
					background-color: var(--red, #BA1F31);
					border-radius: 10px;
					color: white;
					padding: 10px 20px;
				}
			}
		}

		section.discover-your-perfect-pairings,
		section.close-call-candidates {

			width: 100%;
			padding-left: 0;
			padding-right: 0;
			padding-top: 50px;

			.title {
				text-align: center;

				@media screen and (max-width: 480px) {
					padding: 0 20px;
				}
			}

			.cards.active {
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
			}

			@media screen and (max-width: 768px) {
				padding-top: 10px;
			}

			@media screen and (max-width: 1024px) {
				padding-top: 25px;
			}
		}

		section.discover-your-perfect-pairings .title {
			@media screen and (max-width: 768px) {
				display: none;
			}
		}

		// section.discover-your-perfect-pairings .reg__cards .reg__card {
		section.discover-your-perfect-pairings .reg__cards,
		section.close-call-candidates .reg__cards {
			display: grid !important;
			grid-template-columns: repeat(4, minmax(250px, 1fr));
			gap: 20px;

			@media screen and (max-width: 1024px) {
				grid-template-columns: repeat(2, minmax(250px, 1fr));
			}

			@media screen and (max-width: 768px) {
				grid-template-columns: repeat(1, minmax(250px, 1fr));
			}

			.reg__card .reg__card-image button.slick-next,
			.reg__card .reg__card-image button.slick-prev {
				display: none !important;
			}

			.view-all {
				width: 100%;
				height: 100%;
				position: absolute;
				z-index: 10;
				color: #FFFFFF;
				border: 1px solid #C5C5C5;
				backdrop-filter: blur(1.5rem) brightness(75%);
				-webkit-backdrop-filter: blur(1.5rem) brightness(75%);
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 20px;

				a {
					text-decoration: none;
					// margin-top: 60px;
					font-size: 24px;
					font-weight: bold;
					color: #ffffff;

					&:hover {
						cursor: pointer;
					}
				}
			}

			.reg__card .reg__card-content {
				.reg__card-heading .reg__card-box-title {
					display: flex;
					justify-content: space-between;
				}

				.list-unstyled.snapshot {
					display: flex;
					align-items: center;
					min-height: 40px;

					li {
						display: flex;
						align-items: center;
					}

					li svg {
						width: 25px;
						height: 25px;
					}

					li span {
						color: #000000;
						margin-left: 5px;
						margin-right: 5px;
					}

					li b {
						margin-left: 10px;
						margin-right: 5px;
						color: #000000;
					}
				}
			}
		}

		section.discover-your-perfect-pairings,
		section.close-call-candidates {
			.reg__cards--communities {

				grid-template-columns: repeat(1, minmax(250px, 1fr));

				@media screen and (min-width: 748px) {
					grid-template-columns: repeat(2, minmax(250px, 1fr));
				}

				@media screen and (min-width: 992px) {
					grid-template-columns: repeat(3, minmax(250px, 1fr));
				}

				@media screen and (min-width: 1560px) {
					grid-template-columns: repeat(4, minmax(250px, 1fr));
				}



			}
		}
	}

	section.take-relationship-next-level {
		position: relative;
		width: 100%;
		padding: 0;
		height: 325px;
		background-image: url('/images/site/my-favorites/Oakley_Market_Interior_Living_Room2.jpg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.8);
			z-index: 1;
		}

		.next-level__container {
			display: flex;
			width: 100%;
			height: 100%;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 2;

			h3 {
				font-size: 24px;
				color: #ffffff;
				font-weight: 400;
				line-height: 32px;
				letter-spacing: 0.4px;
				margin-bottom: 40px;
			}

			@media screen and (max-width: 480px) {
				padding: 0 20px;
				text-align: center;
			}

			.button-box {
				display: flex;
				width: 500px;
				justify-content: space-between;

				@media screen and (max-width: 480px) {
					width: 100%;
				}

				button {
					width: 100%;
				}

				button:nth-child(1) {
					margin-right: 30px;
				}

				@media screen and (max-width: 768px) {
					flex-direction: column;

					button {
						margin-bottom: 20px;
					}

					button:nth-child(1) {
						margin-right: 0;
					}
				}
			}
		}
	}

	section.ready-for-fresh-start {
		padding: 20px;

		@media screen and (min-width: 992px) {
			padding: 40px;
		}

		h4 {
			font-size: 24px;
			color: #BA1F31;
			text-align: center;
			margin: 5px 0;
		}

		p {
			color: #000000;
			text-align: center;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 32px;
			margin: 5px 0;
		}

		.box--buton {
			display: flex;
			justify-content: center;
			margin: 20px 0;

			button {
				font-weight: bold;
				height: 56px;
				min-width: 300px;
			}
		}
	}

	section.is-love-still-on-horizon__container {
		display: flex;
		justify-content: center;
		padding: 75px 8vw;
		width: 100%;

		@media screen and (max-width: 480px) {
			padding: 50px 20px;
		}

		div.is-love-still-on-horizon__box {
			width: 100%;
			display: grid;
			max-height: 380px;
			grid-template-columns: 35vw 2fr;
			margin: 0;
			padding: 0;
			box-sizing: border-box;

			@media screen and (max-width: 768px) {
				display: flex;
				flex-direction: column-reverse;
				width: 100%;
				max-height: 100%;
			}

			div.is-love-still-on-horizon__info,
			div.is-love-still-on-horizon__image {
				max-height: 380px;
			}

			div.is-love-still-on-horizon__info {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 50px 40px;
				background: #C2C2C2;
				color: #000000;

				h3 {
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: 150%;
					letter-spacing: 0.48px;
				}

				p {
					color: #000;
					text-align: center;
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: 32px;
					letter-spacing: 0.4px;
				}

				button.btn-primary {
					height: 56px;
					padding: 10px 20px;
					background: var(--primary-bg, #BA1F31);
					color: #ffffff;
					border: none;
				}
			}

			div.is-love-still-on-horizon__image {
				img {
					object-fit: cover;
					height: 380px;
					width: 100%;
				}
			}
		}
	}

	i {
		font-style: italic;
	}

}

.reg__cards--communities {

	.list-unstyled.snapshot {
		li {

			.text-snapshot {
				white-space: nowrap !important;
			}
		}

	}

}

.reg__cards--communities,
.section__floorplans {
	.list-unstyled.snapshot {
		li {

			svg.icon-bed,
			svg.icon-bath,
			svg.icon-sqft,
			svg.icon-stairs {
				width: 15px !important;
				height: 15px !important;
			}

			span {
				margin: 0 !important;
				// font-size: 12px !important;
			}

		}

	}
}