/* Slider */

.slick-slider {
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

//CUSTOM
%slick-control{
    .slick-control{
        cursor: pointer;
        position: absolute;
        z-index: 1;
        top: 0;
        height: 100%;
        @include flex-param(column,center,center);
        &.prev{
            left: 0;
        }
        &.next{
            right: 0;
        }
    }
}

%slick{
    position: relative;
    slick{
        height: 100%;
    }

    @extend %slick-control;

    .slick-list,
    .slick-track{
        height: 100%;
    }
    .item{
        position: relative;
        height: 100%;
        .fill{
            background-size: cover;
            position: absolute;
            background-position: center;
            @include size(100%);
        }
    }
    .caption{
        position: absolute;
        bottom: 0;
        height: 55px;
        width: 100%;
        background-color: rgba($red,.8);
        color: white;
        padding: 20px 40px;
        @extend .type-sm;
        @include phone{
            height: auto;
            padding: 15px;
        }
    }
}

.slick-tabs{
    margin-left: 0;
    padding-top: 30px;
    background-color: $lightest-gray;
    text-align: center;
    li{
        display: inline-block;
        padding-right: 5px;
        padding-left: 5px;
        background-color: $light-gray;
        width: calc(35% - 5px);
        text-align: center;
        padding: 20px;
        border-radius: 10px 10px 0 0;
        color: white;
        @include hoverstate(white, $gray, .2s);
        @include phone{
            @include hoverstate($gray, white, .2s);
        }
        &.active{
            background-color: white;
            color: $gray;
        }
        @include phone{
            font-size: 12px;
        }
    }
}

#slick-main{
    @extend %slick;
    height: 100%;
    opacity: 1;
    @include transition(opacity .3s ease-in);
    &.fade-out{
        opacity: 0;
    }
    @mixin param($dir){
        margin-top: -18.5px;
        position: absolute;
        top: 50%;
        #{$dir}: 10px;
        z-index: 10;
        svg{
            @include size(35px);
        }
        @include phone{
            // display: none;
        }
    }
    .slick-arrow-left{@include param(left);}
    .slick-arrow-right{@include param(right);}
    button{
        position: absolute;
        height: 100%;
        width: 50px;
        top: 0;
        opacity: 0;
        z-index: 11;
        overflow: hidden;
        &.slick-prev{left: 0;}
        &.slick-next{right: 0;}
    }
}


#slick-thumb{
    @extend %slick;
    height: 90px;
    padding: 0 30px;
    margin: 30px;
    top: 0;
    @include desktop-hd{
        height: 7vw;
    }
    slick{
        opacity: 1;
    }
    &.fade-out slick{
        opacity: 0;
    }

    .slick-control{
        width: 30px;
        background-color: $light-gray;
        font-size: 50px;
        color: white;
        @include hoverstate($red, white, .2s);
    }

    .item{
        margin: 0 5px;
        border: 5px solid transparent;
        cursor: pointer;
        &.slick-current{
            border-color: $red;
        }
    }
}

#slick-strip{
    @extend %slick;
    @include slick-control(50px, 50px);
    .item{
        margin: 0 20px;
        cursor: pointer;
        @include size(250px, 175px);
        .caption{
            background-color: rgba($red, 0.9);
            padding: 10px 15px;
            line-height: 28px;
            height: 28%;
            @include transition(all .3s ease-out);
            p:last-child{
                margin-top: 5px;
                @include transition(all .3s ease-out);
                opacity: 0;
            }
        }
        &:hover .caption{
            height: 100%;
            @include transition(all .3s ease-in);
            p:last-child{
                @include transition(all .3s ease-in .3s);
                opacity: 1;
            }
        }
    }

}

#slick-floorplan{
    @extend %slick;
    @include slick-control(100px, 20px);
    .item{
        padding: 0 60px;
        img{
            width: 100%;
        }
    }
    @include phone{
        .item{
            padding: 0 30px;
        }
        @include slick-control(30px, 0px);
    }
}

#slick-collections{
    @extend %slick;
    @include slick-control(100px, 90px);
    svg{
        @include svgcolor(white);
    }
}

#slick-modal .slick-track{
    height: 550px;
    @include phone{
        height: 30vh;
    }
    .image{
        background-size: cover;
        background-position: center;
        height: 100%;
    }
}

.modal{
    $offset: 50px;
    svg{@include size(35px);}
    @extend %slick-control;
    .slick-control{@include svghover(rgba(white,.6),white,.2s);}
    .slick-control.prev{left: -$offset;}
    .slick-control.next{right: -$offset;}
}
