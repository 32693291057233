/* .customCheckbox */
div[class^="custom-checkbox-"] {

  @mixin checkboxSize($size, $margin){
    width: $size + 2;
    height: $size + 2;
    label {
      width: $size;
      height: $size;
    }
    input[type=checkbox] {
      margin-right:  $margin;
    }
  }

  @include checkboxSize(25px, 45px);
  @include phone{
    @include checkboxSize(20px, 30px);
  }
  
  position: relative;
  margin: 0 15px 0 0;
  border: 1px solid $light-gray;
  display: flex;
  align-items: center;
  label {
    cursor: pointer;
    position: absolute;
    left: 0px;
    top: 0px;
    background: transparent;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $blue;
      opacity: 0;
      @include transition(all .2s ease-in);
      @include scale(0);
    }
    &.checkmark:after{
      background: transparent;
    }
    &:hover::after {
      opacity: 0;
    }
  }
  input[type=checkbox] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
      @include transition(all .2s ease-out);
      @include scale(1);
    }    
    &:checked + label:after {
      background: url('/images/site/global/checkmark.svg') no-repeat;
      background-size: 75%;
      background-position: 5px 5px;
    }
  }
}
/* end .customCheckbox */