#move-in-detail {
  #hero .row-top {
    height: auto;
    @include phone {
      padding-top: 110px;
    }
	
    .container-fluid {
      padding-top: 30px;
      padding-bottom: 30px;
      @include phone {
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 0;
      }
      a.type-xl {
		color: $red;
        @include phone {
          text-align: right;
        }
        span {
          @include phone {
            display: none;
          }
        }
      }
    }
  }
	#hero .row-bottom .col-right {
		> *:not(.form-wrap, .form-schedule-my-tour-wrap),
		.form-results-container,
        form .input-group {
			max-width: 570px;
		}

		.detail {
			padding: 36px 36px 0 36px;

			.title {
				@include flex-param(row, space-between, center);
			}

			.red {
				font-weight: 300;
				text-indent: -5px;
				margin-bottom: 20px;
			}

			a.btn{padding: 15px;}
			a.btn svg{@include size(25px);}

			.sub-title {
				@include flex-param(row, space-between, center);
				margin-bottom: 25px;
			}

			.type-md{
				line-height: 1.3em;
			}

			.availability{
				@include flex-param(row, space-between, center);
				margin-top: 25px;
				margin-bottom: 25px;
				font-weight: 600;
				a.video-link{
					@include flex-param(row, center, center);
					svg{
						@include svgcolor($blue);
						@include size(20px);
						margin-right: 10px;
					}
				}
			}

		}

    .sp1.detail{
      width: 540px;
      max-width: 100%;

      &::after{
        content: "";
        height: 1px;
        background-color: #a6a7a7;
        width: 100%;
        display: block;
        margin: 25px 0;
      }

      p,
      span{
        color: black;
        line-height: normal;
      }

      .title{
        padding-top: 36px;

        h1{
          text-transform: uppercase;
          font-weight: 400;
          letter-spacing: normal;
        }
      }

      .list-price{
        margin: 0;
        color: black;
        font-weight: 600;
        font-size: 1.2rem;
      }

      .sale-price{
        margin: 0;

        span{
          font-size: 1.25rem;
          margin-bottom: 0;
        }

        p{
          font-size: 1.38rem;
        }

        button.btn-buy-now{
          display: none;
        }
      }

      .availability{
        margin: 0;
        margin-top: 15px;

        .red{
            font-weight: 600;
        }
      }
    
      .snapshot-info{
          margin: 15px 0;
      }
    }

    .sp1.tour{
      width: 540px;
      max-width: 100%;
      h2,
      h3{
        font-weight: 700;
        margin-top: 15px;
      }
      h2{
        margin-bottom: 25px;
      }
      h3{
        margin-bottom: 10px;
      }
      p{
        line-height: normal;
      }
      .btn{
        border-radius: 8px;
        width: 100%;
        max-width: 300px;
        padding: 10px;
        font-size: .9rem;
        margin: 10px 0;
        line-height: 1.2rem;
        font-weight: 600;
      }
      .btn-red-outline{
        color: $red;
        border-color: $red;
        border-width: 2px;
        background-color: white;
        transition: all .2s ease-in-out;
        margin-bottom: 10px;

        &:hover,
        &:active,
        &:focus{
            color: white;
            background-color: $red;
        }
      }
      .utour-disclosure{
				font-size: .8rem;
				font-style: italic;
				margin-top: 10px;
			}
      .form-schedule-my-tour-wrap{
        width: 100%;
        max-width: 300px;
        background-color: $red;
        color: white;
        padding: 10px 15px;
        margin: 10px 0;
        border-radius: 8px;

        form{
          .form-components{
            margin: 15px 0;
          }
          td.uib-day button.btn{
            padding: 5px 7px;
            margin: 0;

            &.active{
                background-color: $red;

                >span{
                    color: white;
                }
            }
          }
  
          .datepicker-wrapper > input[type=text],
          select{
            cursor: pointer;
          }            

          .form-components .scheduled{
            margin-top: 5px;
            float: unset;

            >li:first-child{
              display: none;
            }
          
            >li:nth-child(2){
              font-size:1.2rem;
              text-align: center;
              display: block;
              >span.edit-btn{
                display: inline-block;
                font-size: .9rem;
              }
            }
          }

          .input-group.button-flex > div:nth-child(3) > p:last-child{
            margin-top: 8px;
          }

          .btn-group button.btn-dropdown{
            background-color: $lighter-gray;
            color: black;
          }
          
          .dropdown button svg g path{
            fill: #000 !important;
          }

          .btn-wrap > p:last-child > a{
            margin-top: 15px;
          }
        }

        p.type-lg{
          display: none;
        }

        &::before{
          content: "Schedule a Tour";
          display: block;
          margin: 0 auto;
          font-size: 1rem;
          font-weight: 600;
          text-align: center;
        }

        .btn-wrap{
          width: 100%;

          .btn{
            background-color: black;
            color: white;
            transition: all .2s ease-in-out;
            margin: 0 auto;
            width: fit-content;
            display: block;

            &:active,
            &:hover,
            &:focus{
              background-color: white;
              color: black;
            }
          }
        }

        .form-results-container{
          background-color: unset;
          border: none;
        }
      }

      .btn-wrap.qmi-tour{
        margin-bottom: 50px;
        h3{
            margin-bottom: 15px;
            margin-top: 25px;
        }
        a{
            margin: 8px 0;
        }
      }

      .hs-form-qmi{
        width: 100%;
        max-width: 300px;
        margin-top: 15px;

        hubspot-form input{
          border-radius: 8px;
          background-color: white;
          color: black;
          text-align: center;
          border: 1px $gray solid;
          font-size: .9rem;
          padding: 10px;
          line-height: normal;
          margin: 5px 0;

          &::placeholder{
            letter-spacing: normal;
            text-transform: capitalize;
            font-weight: 400;
          }

          &[type=submit]{
            width: 100%;
            background-color: $red;
            color: white;
            transition: all .2s ease-in-out;
            border-width: 0;
            margin: 10px 0 15px;
            line-height: 1.2rem;
            font-weight: 600;
            letter-spacing: normal;
            text-transform: capitalize;

            &:hover{
              background-color: black;
            }
          }
        }
        hubspot-form .hs-form-booleancheckbox span{
          color: black;
          font-size: .65rem;
        }
      }

      @include phone{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;

        p{
          text-align: center;
        }
      }

      .next-tour{
        padding-top: 10px;
        line-height: 1.5rem;
        color: $red;
      }

      .next-tour > .tour-time{
        font-weight: 600;
        text-decoration: underline;

        i{
          font-size: 1.25rem;
        }
      } 

      .btn-wrap,
      .btn-container{
        @include phone{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &.qmi-tour{
            flex-direction: column;
          }
        }
      }
    }

		.info{
			padding: 0 36px 43px 36px;

			p{
				clear: both;
				line-height: 36px;
			}

      snapshot-info {
        @include phone {
          padding-top: 0;
          padding-bottom: 0;
        }
      }

			.snapshot{
				justify-content: space-between;
				padding: 20px;
				margin-bottom: 10px;
				li{
					width: auto;
					@include desktop-hd {
					  font-size: 18px;
					}
					@include desktop {
					  font-size: 18px;
					}
					@include tablet {
					  font-size: 18px;
					}
				}
				small{
            font-size: 16px;
				    margin-top: 5px;
				    letter-spacing: 0.5px;
				    @include desktop-hd {
              display: block;
            }
            @include desktop {
              display: block;
            }
            @include tablet {
              display: block;
            }
				}
			}

			.desc-wrap .desc{
				padding-top: 15px;
				line-height: 30px;
				.summary + br{
					display: none;
				}
				&.show{
					.dots{
						display: none;
					}
					.read-more-toggle{
						display: inherit;
						padding-left: 8px;
					}
					+ i.read-more:after{
						content: "Read Less";
					}
				}
			}
      .desc-wrap{
        .text-container {
          display: block;
          position: relative;
        }

        .text-container .truncated-text,
        .read-more,
        .read-more:active,
        .read-more:focus{
          color: black;
          line-height: normal;
        }

        .text-container.collapsed .truncated-text {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden; 
        }

        .text-container.expanded .truncated-text {
          display: block;
          overflow: visible;
        }

        .read-more {
          text-decoration: none;
          font-weight: 700;
          margin: 15px auto;
          cursor: pointer;
        }

        .text-container.expanded .read-more {
          display: none;
        }

        .text-container.no-clamp .truncated-text{
          display: block;
          -webkit-line-clamp: unset;
          -webkit-box-orient: unset;
          overflow: auto;
        }

        .text-container.no-clamp .read-more{
          display: none;
        }
      }
			i.read-more{
				float: right;
				color: $blue;
				cursor: pointer;
				line-height: 30px;
				&:after{
					content: "Read More";
				}
			}
		}

    .sp1.info{
      width: 540px;
      max-width: 100%;
        &::after{
            content: "";
            height: 1px;
            background-color: #a6a7a7;
            width: 100%;
            display: block;
            margin: 25px 0;
        }
        .desc-wrap p{
            color: black;
            line-height: normal;
        }
		}

    .slick-tabs,
		#slick-main .caption{
			display: none;
		}

		@mixin param(){
			.detail,
			.info{
			  padding-top: 20px;
				padding-left: 20px;
				padding-right: 20px;
			}
			.info .snapshot{
				flex-wrap: wrap;
			}
		}

		@include tablet{
			@include param();
			p.black.type-lg{
				font-size: 17px;
			}
			.info .snapshot li{
				width: 50%;
			}
		}
		@include phone{
			@include param();
			.info .snapshot li.snapshot__beds,
			.info .snapshot li.snapshot__baths,
			.info .snapshot li.snapshot__sqft{
				width: 33.33333333%;
			}
			.info .snapshot li.snapshot__levels{
			  justify-content: center;
			  margin-top: 20px;
				width: 100%;
			}
		}

	}

  .col-right .sp1{
    padding: 0 60px 0 30px!important;

    @include phone{
      padding: 0 24px !important;
      margin: 0 auto;
    }
  }

	.type-model-detail-xl,
	.sub-title .type-sm,
	.sub-title .type-md {
		text-transform: uppercase;
		display: block;
	}

	.address,
	.address-city-state-zip {
		white-space: nowrap;
	}

	.asset-group {
		@include flex-param(row, space-between, center);
	}

	.asset-container {
		.popover {
			padding: 0;
			margin: 0;
			width: 114px;
			border: none;
			border-radius: 0;
			font-size: 14px !important;
			text-align: center;
			color: $blue;
		}
	
		a {
			display: inline-block;
			width: 36px;
			height: 36px;
			padding: 6px;
			cursor: pointer;
			background: rgba(0, 0, 0, 0.3);
			border-radius: 100%;
			border: 1px solid transparent;
			transition: all 0.25s ease-in-out;
	
			&.video {
				padding: 7px;
			}
	
			&:hover {
				background-color: $blue;
				border-color: $blue;
			}
	
			svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	.asset-container + .asset-container {
		margin-left: 8px;
	}
  .disclaimer {
    background-color: #fff;
    margin-top: 3em;
    @include phone {
      margin-top: 0;
    }
    p {
      font-size: 13.5px;
    }
  }
}

.sale-price{
	@include flex-param(row, space-between, center);
	font-weight: 600;
	margin-top: 30px;
	margin-bottom: 30px;
	p{
		margin-right: 15px;
	}
}

.list-price{
	display: inline-block;
	position: relative;
	&.rate-and-savings {
	  font-weight: 600;
	  margin-bottom: 30px;
	}
}

.list-price s {
	position: relative;
  	text-decoration: none;
}

.list-price s:after{
	content: "";
	position: absolute;
	background-color: red;
	width: 100%;
	height: 2px;
	right: 0;
	top: 50%;
	@include rotate(-5);
}

.btn.btn-buy-now {
  border-radius: 7.5px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 15px;
  .fa {
    margin-left: 30px;
    margin-right: 0;
    @include desktop-hd {
      font-size: 36px;
      margin-top: -4.5px;
    }
    @include desktop {
      font-size: 24px;
      margin-top: -1.5px;
    }
    @include tablet {
      font-size: 21px;
      margin-top: -1.5px;
    }
    @include phone{
      font-size: 21px;
      margin-top: -1.5px;
    }
  }
}
