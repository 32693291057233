#building-a-better-community{
	section#hero{
		@include inview("p.type-title-xl", static, 2s);
		@include inview("p.type-sm", bottom, 2.2s);
		@include background-image('/images/site/building-a-better-community/hero-bkg.jpg',cover,center);
		@extend %theme-large-heading;
		text-align: center;
		building-animation{
			margin-top: 80px;
			@include phone{
				margin-top: 15px;
			}
		}
		h1.type-title-xl{
		  color: $red;
		  font-weight: 600;
			margin-top: 30px;
			margin-bottom: 60px;
			@include phone{
				font-size: 20px;
			}
		}
		p.type-md{
		  color: $dark-gray;
		  font-weight: 600;
		  margin-bottom: 120px;
			max-width: 855px;
			line-height: 39px;
			@include desktop{
				max-width: 765px;
			}
			@include tablet{
				max-width: 650px;
			}
			@include phone{
				line-height: 30px;
			}
		}
		@include phone{
			padding-top: calc(40px + var(--headerHeight));
		}
	}

	#content{
		color: white;
		@include phone{
			margin-bottom: 80px;
		}
		> div{
			padding: 110px 0;
			position: relative;

			p,h2{
				margin-bottom: 50px;
				line-height: 50px;
			}

			p.type-md{
				line-height: 36px;
			}

			.row{
				display:flex;
				align-items: flex-end;
			}

			.col-left{
				padding-right: 100px;
			}

			.col-right{
				padding-left: 100px;
			}

			#st-jude-cta{
				color: white;
				text-decoration: underline;
			}

			a{
				@include flex-param(row, center, center);
				display: inline-flex;
				svg{
					margin-left: 15px;
					margin-right: 0;
					@include size(20px);
				}
			}

			@include tablet{
				p,h2{
					margin-bottom: 30px;
				}
			}

			@include phone{
				padding: 40px 0;
				padding-bottom: 80px;
				
				p,h2{
					margin-bottom: 25px;
				}

				.row{
					flex-direction: column;
				}
				
				.col-left{
					padding-right: 15px;
					p:first-child{
						font-size: 20px;
					}
				}

				.col-right{
					padding-left: 15px;
				}
			}
		}
	}

	#content #st-jude,
	#content #great-american-ballpark{
		&:before{
			@include psuedo-block($red);
			@include gradient(linear, (to right, $red, $red, transparent));
			width: 80%;
			@include phone{
				width: 100%;
				opacity: .8;
				@include gradient(linear, (to right, $red, $red));
			}
		}
	}


	#content #st-jude{
		@include inview(".col-left", left, 0s);
		@include inview("img", static, .5s);
		@include background-image('/images/site/building-a-better-community/st-jude-bkg.jpg',cover,right);

		img{
			max-width: 390px;
		}
		@include tablet{
			&.in img{
				width: 100%;
			}
		}
		@include phone{
			text-align: center;
			.container .row{
				flex-direction: column-reverse;
			}
			&.in img{
				width: 100%;
				margin-bottom: 25px;
			}
		}


		.btn-black-white:hover{
			color: black;

			svg > path{
				fill: black;
			}
		}

	}

	#content #kings-island{
		@include inview(".col-right", right, 0s);
		@include inview("img", static, .5s);
		@include background-image('/images/site/building-a-better-community/kings-bkg.jpg',cover,center);
		text-align: right;
		img{
			max-width: 391px;
		}
		&:before{
			@include psuedo-block($blue);
			@include gradient(linear, (to left, $medium-blue, $medium-blue, transparent));
			left: auto;
			right: 0;
			width: 80%;
		}
		@include tablet{
			&.in img{
				width: 135%;
			}
		}
		@include phone{
			text-align: center;
			&.in img{
				margin-bottom: 30px;
				width: 100%;
			}
			&:before{	
				width: 100%;
				opacity: .8;
				@include gradient(linear, (to right, $blue, $blue));
			}
		}
	}

	#content #great-american-ballpark{
		@include inview(".col-left", left, 0s);
		@include inview("img", static, .5s);
		@include background-image('/images/site/building-a-better-community/ballpark-bkg.jpg',cover,center);
		img.reds-logo{
			max-width: 391px;
		}
		@include tablet{
			&.in img{
				width: 135%;
			}
		}
		@include phone{
			text-align: center;
			.container .row{
				flex-direction: column-reverse;
			}
			&.in img{
				margin-bottom: 30px;
				width: 100%;
			}
		}
	}

	#content #cincinnati-museum-center{
		@include inview(".col-right", right, 0s);
		@include inview("img", static, .5s);
		@include background-image('/images/site/building-a-better-community/cmc-bkg.jpg',cover,center);
		text-align: right;
		a.mobile-cta{
			display: none;
		}
		&:before{
			@include psuedo-block(#000000);
			@include gradient(linear, (to left, #000000, #000000, transparent));
			left: auto;
			right: 0;
			width: 80%;
		}
		@include tablet{
			&.in img{
				width: 135%;
			}
		}
		@include phone{
			text-align: center;
			&.in img{
				margin-bottom: 30px;
				width: 100%;
				max-width: 253px;
			}
			&:before{	
				width: 100%;
				opacity: .8;
				@include gradient(linear, (to right, #000000, #000000));
			}
			a{
				display: none;
			}
			a.mobile-cta{
				display: inline-flex;
			}
		}
	}
}