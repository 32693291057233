#why-choose-fischer{

	#hero{
		@include background-image('/images/site/why-choose-fischer/hero-bkg.jpg', cover, center);
		@include inview("p:first-child", right, 0s);
		@include inview("p:last-child", right, .6s);
		.row > div{
			min-height: 545px;
			padding-top: 100px;
			@include flex-param(column, center, flex-start);
			h1{
				font-weight: 300;
			}
			h1 .type-why-choose-fischer-md{
				display: block;
				margin-bottom: 30px;
			}
		}
		@include phone{
			background-position: top right -255px;
			background-size: auto 115%;
			.row > div{
				min-height: 0;
				height: 60vh;
			}
			.row > div h1 .type-why-choose-fischer-md{
				color: white;
				margin-bottom: 10px;
				text-indent: 0;
				@include text-shadow(2px 2px rgba(black,.5));
			}
			.row > div h1 .type-subtitle-xl.red{
				font-size: 12vw;
			}
		}
	}

	#americas-top-builder{
		background-color: $dark-gray;
		color: white;
		background-repeat: no-repeat;
		overflow: visible;
		z-index: 1;
		@include inview(".col-left", left, 0s);
		@include inview(".col-center p", 'static', .6s);
		@include inview(".col-right img:first-child", bottom, .8s, absolute, calc(50% + 20px));
		@include inview(".col-right img:last-child", top, .8s, absolute, calc(50% + 20px));
		@include flex-param(row, center, stretch);
		a{
			color: $blue;
		}
		.col-left{
			flex: 1.5;
			@include background-image('/images/site/why-choose-fischer/fireplace.jpg', cover, center);
		}
		.col-center{
			max-width: 800px;
			padding: 85px;
			h2.type-subtitle-lg{
				font-weight: 300;
				margin-bottom: 30px;
			}
			p.type-md{
				line-height: 48px;
			}
			@include desktop{
				max-width: 500px;
				padding: 85px 30px;		
				h2.type-subtitle-lg{
					line-height: 40px;
				}
				p.type-md{
					line-height: 40px;
				}	
			}
			@include phone{
				padding: 50px 30px;
				h2.type-subtitle-lg{
					line-height: 35px;
					text-align: center;
				}
				p.type-md{
					line-height: 35px;
				}	
			}
		}
		.col-right{
			flex: 1.5 0 0;
			@include flex-param(column, center, space-between);
			img{
				@include dropshadow(10px, 10px, 20px, 0, 0.2);
				@include desktop{
					width: 25%;
				}
			}
		}
	}

	#invest-in-design{
		padding: 165px 0;
		background-color: $cool-gray;
		background-repeat: no-repeat;
		@include background-image('/images/site/why-choose-fischer/work-bkg.jpg', auto 100%, right -30px center);
		@include inview("p:first-child", right, 0s);
		@include inview("p:last-child", right, .6s);
		a{
			color: $blue;
		}

		&:before{
			content: "";
			background-color: $cool-gray;
			position: absolute;
			top: 0;
			opacity: 1;
			@include size(100%);
		}

		&.in{
			@include transition(all 3s ease-out 1s);
			@include desktop-hd{
				background-position:right 0 center;
			}
			@include desktop{
				background-position: center left 200px;
			}
			@include tablet{
				background-position: center left -20px;
			}
		}

		&.in:before{
			opacity: 0;
			@include transition(all 2s ease-out 1s);
		}

		h2:first-child{
			line-height: 75px;
			margin-bottom: 85px;
			font-weight: 300;
		}

		p:last-child{
			line-height: 48px;
		}

		@include desktop{
			padding: 80px 0;
		}

		@include tablet{
			padding: 100px 0;
			h2:first-child{
				line-height: 60px;
				margin-bottom: 35px;
			}
			p:last-child{
				line-height: 45px;
			}
		}

		@include phone{
			padding: 35px 0 230px;	
    		background-size: 100% auto;
    		background-position: left bottom;
    		background-image: url('/images/site/why-choose-fischer/work-bkg-mobile.jpg');
			h2:first-child{
			    line-height: 45px;
			    font-size: 34px;
			    margin-bottom: 20px;
			}
			p:last-child{
				line-height: 35px;
			}
		}

	}

	#our-lifestyle{
		padding: 115px 0;
		overflow: visible;
		z-index: 1;
		@include tablet{
			padding: 85px 0;
		}
		@include phone{
			padding: 50px 0;
		}
		@include inview("img", bottom, 0s);
		@include inview("p:nth-of-type(1)", bottom, .6s);
		@include inview("p:nth-of-type(2)", bottom, 1.2s);
		@include inview("a", bottom, 1.8s);
		.col-left{
			img{
				width: 100%;
				margin-top: -50%;
				@include dropshadow(10px, 10px, 20px, 0, 0.2);
				@include phone{
					width: 200px;
					margin-top: 0;
					position: relative;
					left: calc(50% - 100px);
					margin-bottom: 30px;
				}
			}
		}
		.col-right{
			padding-left: 125px;
			> *{
				margin-bottom: 60px;
			}
			p,h2{
				line-height: 48px;
				&:first-child{font-weight: 300;}
			}
			a{
				display: inline-flex;
				align-items: center;
				svg{
					@include size(25px);
				}
			}
			@include tablet{
				padding-left: 85px;
			}
			@include phone{
				padding-left: 15px;
				p,h2{
				    line-height: 40px;
				    &:first-child{
					    font-size: 20px;
					    text-align: center;
					    margin-bottom: 20px;
				    }
				}
				a{
					margin-bottom: 15px;
    				left: calc(50% - 140px);
				}
			}

		}
	}

	@include phone{
		#inline-form.inline-stay-updated{
			padding-bottom: 80px;
		}
	}
}