:root {
	// Main navigation
	--navbarHeight: 70px;

	// Announcement (header hat)
	--announcementHeight: 36px;

	// Full header height (navbar + announcement + filters, if applicable)
	--headerHeight: 106px;

	// Filter Height for Region Page
	--filterbarHeight: 70px;
}

header {
	position: fixed;
	width: 100%;
	z-index: 950;
	// padding-left: 5vw;
	top: 0;
	height: 0;
	@include transition(all .5s ease-out);

	&.slideUp #top-nav {
		.navbar-header:before {
			height: 100%;
		}

		.navbar-header a {
			position: relative;
		}

		.navbar-header img {
			opacity: 1;
			// margin: 5px 0;
			// height: calc(100% - 10px);
			// width: auto;
			@include transition(all .5s ease-out);
		}
	}

	&.slideUp #main-nav {
		@include transition(all .5s ease-out);
		// top: -150px;
	}

	&:not(.isloaded) {
		@include animation(navOnLoad 1.5s ease-in);

		@include keyframes(navOnLoad) {

			0%,
			50% {
				top: -150px;
			}

			100% {
				top: 0;
			}
		}
	}

	@include phone {
		padding: 0;

		&.slideUp #main-nav {
			top: 0px;
		}

		&:not(.isloaded) {
			@include keyframes(navOnLoad) {

				0%,
				50% {
					top: 0px;
				}

				100% {
					top: 0;
				}
			}
		}
	}
}

header nav.navbar {
	border-radius: 0;
	border: none;
	margin: 0;
}

header #top-nav {
	$height: 50px;
	$bkg-color: black;
	z-index: 1;

	@include navbar-header($height, 237px, $bkg-color);

	@include tablet {
		.navbar-header {
			width: 0;
		}

		.navbar-header:after {
			right: 0;
		}
	}

	>.container-fluid {
		padding: 0;
	}

	.navbar-header img {
		opacity: 0;
		@include transition(all .5s ease-out);
	}

	.navbar-header:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 25px;
	}

	@media only screen and (min-width: 1170px) and (max-width: 1330px) {

		.elem-hide-md,
		.elem-visible-lg {
			display: none;
		}
	}

	@mixin param {

		.elem-hide-sm,
		.elem-visible-lg,
		#top-nav-toggle>ul>li>a svg {
			display: none;
		}
	}

	@include desktop {
		@include param;
	}

	@include tablet {
		@include param;
	}

	#top-nav-toggle {
		@include navbar-flex-override($height, $bkg-color, flex-end);
		padding: 0;

		@include tablet {
			justify-content: flex-start;
		}

		.search {
			background-color: white;
			background-image: url(/images/site/global/search.jpg);
			background-repeat: no-repeat;
			background-position: center right 5px;
			background-size: 25px;
			padding: 0;
			margin-left: 7.5px;
			margin-right: 7.5px;
			border-radius: 5px;

			input,
			button {
				border: none;
			}

			button {
				color: $blue;
			}

		}

		form.search,
		ul.nav.navbar-nav {
			position: relative;
			z-index: 1;
		}

		ul.nav.navbar-nav li {
			cursor: pointer;
			border-right: 1px solid white;

			&:after {
				content: "";
				background-color: $red;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 0;
				z-index: -1;
				@include transition(.2s all ease-in);
			}

			&:hover:after {
				height: 100%;
				@include transition(.2s all ease-out);
			}

			&:first-child {
				border-left: 1px solid white;
			}

			>a,
			>.dd-trigger {
				color: white;
				display: flex;
				align-items: center;
				background-color: transparent;
				font-weight: 600;
				@extend .type-xs;

				@include desktop {
					font-size: 13px;
					padding-left: 7.5px;
					padding-right: 7.5px;

					.elem-hide-sm {
						display: none;
					}
				}

				svg {
					margin-right: 10px;

					&.icon-blog {
						width: 16px;
						height: 16px;

						path {
							fill: white;
						}
					}
				}

			}

			&.dd-homeowner-login {
				@include topnav-dropdown(70vw, -20vw);

				&:after {
					background-color: $red;
				}
			}

			&.dd-social {
				@include topnav-dropdown(455px, -5vw);

				&:after {
					background-color: $red;
				}

				#nav-social.dropdown-content {
					@include flex-param(row, space-between, center);

					@include tablet {
						right: -25vw;
					}

					.title {
						padding: 40px 50px;
					}

					a {
						&:not(:last-child) svg {
							margin-right: 20px;
						}

						svg {
							@include size(30px, 30px);
							@include svgcolor(white);
							@include svgtransition(.2s all ease-out);

							&.icon-pinterest {
								@include scale(1.7);
							}
						}

						&:hover svg {
							@include svgcolor($blue);
							@include svgtransition(.2s all ease-in);
						}
					}
				}


			}

		}

	}

}

header #main-nav {
	$height: 90px;
	$bkg-color: black;
	z-index: 0;
	height: 0;
	// padding-right: 45px;
	position: relative;
	top: -1px;

	@include phone {
		top: 0;
	}

	@include transition(all .5s ease-in);
	@include navbar-header(90px, 200px, $bkg-color);

	.container-fluid {
		padding-right: 0;
	}

	@include tablet {
		.container-fluid {
			padding: 0px;
		}
	}

	.navbar-header {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: black;

		.mobile-nav {
			display: none;
			align-items: center;
			justify-content: space-between;
			width: fit-content;
			min-width: 75px;
			max-width: 50vw;

			>a {
				transform: rotate(270deg) scale(1.5);
				position: relative;
				bottom: 1px;
			}

			@include phone {
				display: flex;
			}
		}
	}

	.navbar-brand {
		float: none;
		height: 90px;
		margin: 0 15px;
		display: flex;
		align-items: center;

		@include tablet {
			margin: 0 10px;
			height: 90px;
		}

		@include phone {
			margin: 0 5px;
		}
	}

	// @include desktop{padding-right:105px;}
	@media screen and (min-width: 768px) and (max-width: 1095px) {
		.navbar-header {
			width: 140px;
			height: 90px;

			.navbar-brand {
				width: 100%;
				height: fit-content;
				margin: 0;
				bottom: 0;
			}
		}
	}

	@include tablet {
		.navbar-header {
			width: 125px;
		}

		.navbar-header:after {
			right: 165px;
		}

		.navbar-header .navbar-brand {
			margin-left: 10px;
		}
	}

	@include phone {
		padding-right: 0;

		.navbar-header {
			height: 65px;
			width: 100%;
			background-color: black;
			margin: 0;
			padding: 0 15px;
			@include flex-param(row-reverse, space-between, center);
		}

		@include navtogglestyle(white);

		.navbar-header:before,
		.navbar-header:after {
			display: none;
		}

		.navbar-header .navbar-brand {
			bottom: inherit;
			// width: 175px;
			height: auto;

			@include phone {
				width: 105px;
			}
		}

		.container-fluid {
			padding: 0;
			height: 85px;
		}
	}

	#main-nav-toggle {
		@include navbar-flex-override($height, $bkg-color, center);
		display: flex;
		justify-content: space-between;

		padding: 0;

		&:after {
			width: 100%;
		}

		@include tablet {
			&:before {
				background-color: black;
				content: "";
				z-index: 1;
				@include absolute(top, left, 40px);
			}
		}

		@include phone {
			margin: 0px;
			display: block !important;
			height: auto !important;
			top: 0;
			padding: 0;
			opacity: 0;
			overflow-x: hidden;
			@include rotateX(90, top);
			@include transition(all .3s ease-out);
			@include dropshadow($x: 0px, $y: 0px, $blur: 15px, $spread: 0px, $opacity: 0.1);

			&.collapsing,
			&.in {
				opacity: 1;
				@include rotateX(0, top);
				@include transition(all .3s ease-in);
			}
		}

		#mobile-fix {
			height: 0;

			&:after {
				display: none;
			}

			@include phone {
				&:after {
					content: "";
					display: block;
					width: 100%;
					height: 10vh;
					background-color: transparent;
				}

			}
		}

		#nav-wrapper {
			display: flex;
			justify-content: flex-end;
			width: 100%;
			flex-direction: row;

			@include phone {
				flex-direction: column;
				justify-content: flex-start;
				height: auto;
				max-height: calc(100vh - var(--headerHeight));
				overflow-y: scroll;
			}

			#choose-region-list-item {
				background-color: white;
				padding-top: 5px;

				&:before {
					content: '';
					width: 90%;
					background-color: #d9d9d9;
					height: 1px;
					display: block;
					margin: 0 auto;
				}
			}

			#choose-region-link {
				padding-top: 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 7px;

				#region-icon {
					width: 16px;
					position: relative;
					top: 2px;
					left: -2px;
				}

			}

			ul.nav.navbar-nav {
				float: none;
				display: flex;
				position: relative;
				z-index: 1;
				width: 100%;
				justify-content: flex-end;

				// max-width: 800px;
				// @include desktop{right: -40px;}
				@include tablet {
					right: 0;
				}

				@include phone {
					margin: 0px;
					display: block !important;
					height: auto !important;
					top: 0;
					right: 0;
					padding: 0;
					background-color: black;
				}

				.saved-option {
					margin-left: 0 !important;
					margin-right: -25px;
					background-color: #BA1F31 !important;
					transition: all .5s ease-in-out;

					@media screen and (max-width: 1200px) {
						min-width: 100px;
					}

					.heart {
						display: flex;
						width: 50px;
						height: 100%;
						align-items: center;
						padding: 0 10px;

						svg {
							fill: #ffffff !important;
							color: #ffffff !important;
							width: 30px;
							height: 30px;

							&:hover {
								fill: #BA1F31 !important;
								color: #BA1F31 !important;
							}
						}
					}
				}

				.saved-option:hover {
					background-color: #ffffff !important;

					.heart svg {
						fill: #BA1F31 !important;
						color: #BA1F31 !important;
					}
				}

				.saved-option:has(.heart svg:hover) {
					background-color: #ffffff !important;
				}


				.dd-get-fast-answers {
					overflow: hidden;
					z-index: 100;

					&.open {
						overflow: visible;
					}

					#menu-region-phone {
						color: $red;
					}

					@include topnav-dropdown(645px, 0vw);
					background-color: white;
					margin-left: 25px;
					padding: 0 15px;
					border-bottom: 1px black solid;

					.dd-trigger {
						padding: 5px 7.5px;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
						color: black;
						font-weight: 600;

						a {
							color: black;
							display: block;
						}
					}

					// @include hoverstate($green, white, .2s);

					#qsu-form.dropdown-content {

						.title,
						.input-group {
							padding: 30px 50px 0;
						}

						.title {

							p {
								&:first-child {
									font-weight: 300;
									letter-spacing: 0.8px;
									margin-bottom: 30px;
								}

								&:nth-child(2) {
									display: none;
								}
							}

						}

						.input-group {

							>input,
							.form-component {
								width: calc(50% - 15px);
								float: left;
								margin: 10px 0;

								&:nth-of-type(even) {
									float: right;
								}

								@include validation-white;
							}

							.form-component {
								background-color: white;
								border-radius: 5px;

								>i.fa {
									z-index: 0;
								}
							}

							.form-component select.form-control {
								background-color: transparent;
								position: relative;
								z-index: 1;
							}

							.checkbox-opt-in {
								label {
									line-height: 24px;
								}
							}
						}

						.bottom-content {
							text-align: right;
							@extend %clearfix;

							button[type="submit"] {
								margin: 30px 50px 50px;

								.fa {
									margin-left: 15px;
									margin-right: 0;
								}
							}

							img {
								position: absolute;
								left: 0;
								bottom: 0;
								z-index: 1;
							}

							p,
							a {
								height: 75px;
								padding-right: 50px;
								text-align: right;
								@include flex-param(row, flex-end, center);
							}

							p {
								font-weight: 300;
								background-color: $light-gray;

								&.phone-number {
									background-color: white;
									color: $red;
									font-weight: 400;

									svg {
										@include size(25px);
										margin-right: 10px;
									}
								}
							}

							a {
								font-weight: 600;
								background-color: $blue;
								position: relative;
								color: white;
								@include hoverstate(darken($blue, 10%), white, .3s);

								&:after {
									content: "";
									position: absolute;
									left: 0;
									bottom: 0;
									width: 100px;
									height: 100%;
									background-color: $light-gray;
								}

								svg {
									width: 30px;
									height: 22px;
									margin-left: 15px;
								}

							}

						}

						@include form-results-color(white);

						.form-disclaimer {
							margin-top: 30px;
						}
					}
				}

				#dropdown-search {
					display: none;

					@include tablet {
						display: block;
					}
				}

				>li:not(.homefest-link) {
					overflow: hidden;
					cursor: pointer;

					@include phone {
						&:after {
							display: none;
						}

						&:not(.open):not(:nth-child(5)):not(.ready-to-get-started):after {
							content: "";
							width: calc(100% - 40px);
							display: block;
							margin: 0 auto;
							height: 1px;
							background-color: white;
						}
					}

					#mobile-get-started {
						background-color: #d9d9d9;

						.title {
							p {
								display: none;

								&:first-child {
									display: block;
									color: black;
									font-size: 1.3rem;
									letter-spacing: 1px;
									font-weight: 400;
									text-align: center;
									padding-top: 25px;
									padding-bottom: 15px;
								}
							}
						}

						.input-group {
							width: 85%;
							margin: 0 auto;

							input,
							select {
								color: black;
								border-radius: 0px !important;
								margin: 5px auto;

							}

							input::placeholder {
								color: black;
							}
						}

						.form-component {
							height: 45px;

							i.fa {
								top: 15px;
							}
						}

						.checkbox-opt-in {
							color: black;
							font-size: .625rem;
							margin-top: 15px;
							line-height: normal;

							label {
								display: flex;
								align-items: flex-start;
								padding-left: 0;

								input {
									margin: 5px;
								}
							}
						}

						.form-disclaimer {
							margin: 7.5px 0 0 15px;
							font-size: .562rem;
							color: black;
							line-height: normal;
						}

						.bottom-content {
							display: flex;
							flex-direction: column;
							margin: 7.5px 0;
							align-items: center;

							img {
								display: none;
							}

							button {
								margin: 10px 0;
								font-size: 14px;

								i {
									margin-left: 15px;
									margin-right: 0;
								}
							}

							p {
								display: none;
							}
						}

						.contact-info {
							display: flex !important;
							align-items: center;
							justify-content: space-between;
							gap: 10px;

							@include phone {
								padding-bottom: 50px;
							}

							a {
								width: 50%;
								color: white;
								background-color: black;
								transition: background-color .2 ease-in, color .2s ease-in;

								&:hover {
									color: black;
									background-color: white;
								}
							}
						}

						.contact-info-dark {
							background-color: black;

							a {
								background-color: $red;

								&:hover {
									background-color: white;
									color: $red;
								}
							}
						}
					}

					@mixin param($px) {
						&:not(:first-child) {
							margin-left: $px;
						}
					}

					@include desktop {
						@include param(0);
					}

					@include tablet {
						@include param(0);
					}

					@include phone {
						// border-bottom: 1px solid $lighter-gray;
						@include param(0);
					}

					>a:before {
						content: "";
						position: absolute;
						top: 0px;
						left: -15px;
						@include size(calc(100% + 105px), 100%);
						background-color: black;
						z-index: -1;

						@include desktop-hd {
							left: -10px;
						}

						@media screen and (min-width: 768px) and (max-width: 1285px) {
							left: -5px;
						}
					}

					>a {
						height: $height;
						position: relative;
						display: flex;
						align-items: center;
						background-color: black;
						cursor: pointer;
						z-index: 1;
						font-weight: 400;
						color: white;
						transition: backround-color .3s ease-in;
						padding: 10px;

						@mixin param($font, $padding, $justify) {
							font-size: $font;
							padding: $padding;
							justify-content: $justify;
						}

						@include desktop-hd {
							@include param(16px, 10px 7.5px, initial);
							letter-spacing: 3px;
						}

						@media screen and (min-width: 1200px) and (max-width: 1285px) {
							@include param(16px, 15px 10px, initial);
							letter-spacing: 1.5px;
						}

						@include desktop {
							@include param(14px, 10px, initial);
							letter-spacing: .5px;
						}

						@include tablet {
							@include param(16px, 0 9.5px, center)
						}

						@include phone {
							@include param(18px, 0, space-between);
							height: 45px;
							padding-left: 20px;
							padding-right: 20px;
						}

						span.fa {
							margin-left: 15px;
							color: $red;

							@include desktop {
								margin-left: 7.5px;
							}

							@include tablet {
								margin-left: 7.5px;
							}
						}

						&:after {
							content: "";
							position: absolute;
							top: calc(100% - 12px);
							left: 50%;
							margin-left: -30px;
							z-index: 9999;
							opacity: 0;
							@include triangle("down", 45px, 12px, black);
							@include transition(.2s all ease-in);

							@include phone {
								@include triangle("down", 35px, 8px, black);
								margin-left: -15px;
							}
						}

						>a:hover {
							background-color: $red;
							color: white;
						}

						>a:focus,
						>a:active {
							background-color: $red;
							color: white;
						}

					}


					.dropdown-social-icons {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-around;
						width: 100%;
						background: white;
						padding: 0 10px 10px;

						a {
							padding: 0px !important;
							flex: 1;
							max-width: calc(100% / 7);
							box-sizing: border-box;
						}

						a:hover {
							background-color: white !important;
						}

						a:hover svg {

							path,
							circle {
								fill: $red;
							}
						}

						svg {
							width: 25px;
							height: 25px;
							position: relative;
							bottom: -3px;

							path,
							circle {
								fill: black;
								transition: fill 0.2s ease-in;
							}

						}

						svg.icon-pinterest {
							transform-origin: center;
							transform: scale(1.6);
						}
					}

					.dropdown-menu {
						background-color: transparent;
						border-radius: 0;
						padding: 0;
						margin-top: -75px;
						opacity: 0;
						display: block;
						z-index: 0;
						left: 50%;

						@include desktop-hd {
							width: 250px;
							margin-left: -135px;
						}

						@media screen and (min-width: 1200px) and (max-width: 1285px) {
							width: 250px;
							margin-left: -135px;
						}

						@include desktop {
							width: 205px;
							margin-left: -112.5px;
						}

						@include tablet {
							width: 200px;
							margin-left: -100px;
						}

						@include phone {
							margin-left: 0;
							width: 100%;
						}

					}

					.dropdown-menu>li:first-child>a {
						padding-top: 15px !important;
					}

					.dropdown-menu>li:last-child>a {
						padding-bottom: 15px !important;
					}

					#follow-us-cta-text {
						display: block;
						width: 100%;
						text-transform: uppercase;
						background: white;
						color: black;
						text-align: center;
						padding: 10px 10px 5px;
					}


					.dropdown-menu.search-dropdown {
						background-color: white;
					}

					&.ready-to-get-started {

						>a {
							background-color: $gray;
							color: white;
						}

						.btn {
							background-color: $red;
							color: white;
						}

						&:hover {
							>a {
								background-color: $red;

								&:after {
									display: none;
								}
							}
						}

						.dropdown-content {
							max-height: calc(100vh - var(--headerHeight) + 2px);
							overflow-y: auto;

							@include phone {
								max-height: unset;
								overflow-y: auto;
							}

							.qsu-form {
								width: 100%;

								padding: 25px 50px 25px;

								@include phone {
									padding: 25px !important;
								}

								div.hs-richtext:first-child {

									>p {
										padding-bottom: 15px;
									}

									>p:first-child {
										font-weight: 700;
										font-size: 2rem;
									}

									>p:last-child {
										font-size: 1rem;
									}

									@include phone {
										>p:first-child {
											font-weight: 700;
											font-size: 1.4rem;
										}

										>p:last-child {
											font-size: .75rem;
										}
									}

								}

								a {
									color: $red;
								}

								.hs_opt_in {
									span {
										text-align: center;

										@include phone {
											font-size: .7rem;
										}
									}
								}

								.disclaimers {
									padding: 0 50px 25px;
									margin: 0 auto;
									font-size: 12px;
									text-align: center;

									@include phone {
										padding: 0 20px 25px !important;
									}
								}

								.submitted-message {
									margin: 25px;
									font-size: 1.7rem;
									text-align: center;
								}
							}

							.qsu-form-dark {
								background-color: #d9d9d9;
								color: black;

							}

							.qsu-form-black {
								background-color: black;
								color: white;
							}
						}
					}

					&.careers {
						>a {
							background-color: $gray;
							color: #fff;

							&:before {
								display: none;
							}
						}

						&:hover {
							>a {
								background-color: $blue;

								&:after {
									display: none;
								}
							}
						}
					}

					// &.about {
					//   .dropdown-menu{
					//     @include tablet {
					//       margin-left: -165px;
					//     }
					//   }
					// }

					&.mobile-sub-group,
					&.mobile-sub-group a:before {
						display: none;
						background-color: $light-gray;
					}

					&.mobile-sub-group a {
						height: auto;
						justify-content: center;
						letter-spacing: 1px;
						width: 35%;
						color: white;
						font-size: 14px;
						background-color: transparent;
						padding: 15px 20px;
					}

					&.mobile-sub-group a:last-child {
						width: 65%;
						border-left: 1px solid white;
						justify-content: center;
					}

					&.mobile-sub-group.social-links {
						background-color: white;
						justify-content: space-between;
						padding: 0 15px;

						a {
							flex: initial;
							padding: 20px 0;
						}
					}

					&.mobile-sub-group.social-links svg {
						@include size(25px);
						@include svgcolor($blue);

						&.icon-pinterest {
							@include scale(1.6);
						}
					}

					// @include tablet{
					// 	&:last-child .dropdown-menu{
					// 		margin-left: -130px;
					// 	}
					// }

					@include phone {
						&.mobile-sub-group {
							@include flex-param(row, space-between, center);
						}
					}

				}

				>li:nth-child(1) {
					>a:before {
						left: -90px;
						max-width: 100vw;

						@media screen and (min-width: 1200px) and (max-width: 1285px) {
							left: -110px;
						}

						@include desktop {
							left: -80px;
						}

						@include tablet {
							left: -75px;
						}
					}
				}

				>li.homefest-link {
					padding-left: 10px;

					&:hover {
						background-color: transparent;
					}

					@include phone {
						// border-top: 1px solid lightgray;
						padding: 0;
						background-color: white;
					}

					a {
						border: 1px solid $blue;
						font-size: 20px;
						color: white;
						padding: 20px;
						letter-spacing: 1px;
						box-shadow: 2px 2px 0px 1px rgba(black, 0.2);
						@include flex-param(row, space-between);
						display: inline-flex;
						@include transition(all .2s ease-in);

						@include phone {
							display: flex;
							border: none;
							color: $blue;
						}

						&:before {
							display: none;
						}

						&:after {
							content: "";
							width: 15px;
							height: 15px;
							border-top: thin solid white;
							border-right: thin solid white;
							transform: rotate(45deg);
							margin-left: 30px;
							@include transition(all .2s ease-in);

							@include phone {
								border-color: $blue;
							}
						}

						&:hover {
							background-color: $blue;
							color: white;
							@include transition(all .2s ease-out);

							&:after {
								border-color: white;
								@include transition(all .2s ease-out);
							}
						}

					}
				}

				>li.open,
				&.debug>li {
					@include main-dropdown-menu;
				}

				@mixin param() {

					>li:not(.touch):hover,
					>li:not(.touch):focus,
					>li:not(.touch).active #dropdown-search:focus {
						@include main-dropdown-menu;
					}
				}

				@include desktop-hd {
					@include param();
				}

				@media screen and (min-width: 1200px) and (max-width: 1285px) {
					@include param();
				}

				@include desktop {
					@include param();
				}

				@include tablet {
					@include param();
				}



			}
		}

	}

	.nav-addenda {
		display: none;
		position: relative;
		z-index: 10;
		display: flex;
		align-items: center;
		padding: 0 15px;
		box-sizing: border-box;
		background-color: black;

		@include desktop {
			margin: 0 5px;
		}

		@include tablet {
			margin: 0 5px;
		}
	}

	.nav-search {
		@include tablet {
			display: none;
		}
	}

	.header-cta {
		color: white;
		width: 100%;
		box-sizing: border-box;
		white-space: nowrap;
		transition: background-color .3s ease-in-out;
		margin: 5px;

		&:hover {
			background: $gray;
		}


		@mixin param($font, $padding, $justify) {
			font-size: $font;
			padding: $padding;
			text-align: $justify;
		}

		@include desktop-hd {
			@include param(16px, 15px, center);
			letter-spacing: 1px;
			font-weight: 600;
		}

		@media screen and (min-width: 1200px) and (max-width: 1285px) {
			@include param(14px, 12px, center);
			letter-spacing: 1px;
			font-weight: 600;
		}

		@include desktop {
			@include param(13px, 7.5px, center);
			letter-spacing: .5px;
			font-weight: 600;
		}

		@include tablet {
			@include param(12px, 5px, center);
			letter-spacing: .5px;
			font-weight: 600;
		}
	}

	.dropdown-wide {
		background-color: rgba($gray, .8);
		position: relative;
		left: -10vw;
		width: 135%;
		z-index: -1;
		opacity: 0;
		top: -1000em;

		@include transition(.3s all ease-in);

		.touch-btn {
			color: white;
			position: absolute;
			bottom: 20px;
			height: 55px;
			left: calc(50% - 20px);
			display: none;
			@include svgcolor(white);

			&.touch {
				@include flex-param(column, center, center);
			}

			svg {
				position: relative;
				top: 10px;
				@include rotate(180);
			}
		}

		&.open {
			@include transition(.3s all ease-in);
			opacity: 1;
			top: 0;
		}

		.col-left,
		.col-right {
			hr {
				border-color: $faded-white;
			}

			p {
				color: white;
			}
		}

		.col-left {
			padding: 50px 80px 50px 0;

			@include tablet {
				padding: 90px 20px 50px 0;
			}

			form {
				@include flex-param(row, space-between, center);
			}

			form .form-group {
				width: calc(100% - 115px);
				margin-bottom: 0;
			}

			form .form-group .btn-group {
				flex: 1;

				button {
					font-weight: 300;
					padding: 10px 20px;
				}
			}

			form span.search-lg {
				position: relative;
				width: 95px;

				svg {
					position: absolute;
					top: 20px;
					left: 15px;
				}

				input {
					text-indent: 20px;
					width: 100%;
				}
			}

			.dropdown-gray-to-red {
				li {
					a {
						font-size: 21px;
						padding-top: 1.875px;
						padding-bottom: 1.875px;

						@include desktop {
							font-size: 18px;
						}
					}
				}
			}
		}

		.col-right {
			padding: 50px 0 50px 80px;
			border-left: 1px solid $faded-white;

			@include tablet {
				padding-left: 20px;
			}

			li {
				text-transform: uppercase;
				color: white;
				@extend .type-md;
				padding: 20px 10px;
				display: block;
				@include hoverstate($blue, white, .2s);
			}

			&:not(:last-child) {
				border-bottom: 1px solid $faded-white;
			}
		}
	}

}

body>*:not(header) {
	@include animation(fadeBodyIn 1s ease-in);

	@include keyframes(fadeBodyIn) {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}
}

//google search override
.google-search {
	height: 50px;
	padding-top: 4px;
}

#___gcse_0 {
	width: 12.5vw;
	max-width: 200px;

	@include desktop {
		width: 10.5vw;
	}

	@include tablet {
		width: calc(100% - 30px);
		margin: 15px;
		border-radius: 15px;
		border: 1px gray solid;
	}
}

#___gcse_1.gsc-input.active {
	background: transparent !important;
}

#___gcse_0 input.gsc-input:not(:active),
#___gcse_1 input.gsc-input:not(:active) {
	background: url('/images/site/global/search-icon.png') !important;
	background-size: 80px !important;
	background-position: left center !important;
	background-repeat: no-repeat !important;
	position: relative;
	top: 1px;
}

input.gsc-input::placeholder {
	color: transparent !important;
}

#___gcse_1 input.gsc-input:active,
#___gcse_1 input.gsc-input:focus {
	background: transparent !important;
}

#___gcse_0 input.gsc-input.active,
#___gcse_1 input.gsc-input.active {
	background: transparent !important;
}


#___gcse_1 {
	width: 10.5vw;
	max-width: 200px;

	@include desktop {
		width: 10.5vw;
	}

	@include tablet {
		width: calc(100% - 30px);
		margin: 15px;
		border-radius: 15px;
		border: 1px gray solid;
		display: none;
	}

	@include phone {
		width: 100%;
		max-width: unset;
		margin: 15px;
	}

	.gsc-control-cse-en {
		background: white;
		border-radius: 15px;
	}

	.gsc-results-wrapper-visible {

		@include phone {
			max-height: 80vh;
			overflow-y: scroll;
		}
	}

	.gsc-resultsbox-invisible {
		height: 200px;
		max-height: 25vh;
		overflow-y: scroll;
	}
}

.gsc-input-box {
	border: none !important;
	background: transparent !important;
}

.gsc-input-box-hover {
	box-shadow: none !important;
}

form.gsc-search-box {
	margin-bottom: 0px !important;
}

.gsc-search-button,
input.gsc-search-button-v2 {
	opacity: 0;
	max-width: 17px;
	overflow: hidden;
	height: 20px !important;
}

.gsc-search-box-tools .gsc-clear-button,
.gsib_b {
	display: none !important;
}

.gsc-search-box-tools .gsc-search-box .gsc-input,
.cse .gsc-control-cse,
.gsc-control-cse {
	padding: 0 !important;
	text-indent: 0 !important;
}

.announcements {
	display: grid;
	grid-template-columns: auto;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 15px;
	color: #fff;
	background: #ba1f31;
	overflow: hidden;
	transition: height 0.2s ease-out;

	@media screen and (min-width: 768px) {
		width: calc(100% + 5vw);
		margin-left: -5vw;
		margin-right: -5vw;
		padding-left: calc(5vw + 7.5px);
		padding-right: calc(5vw + 7.5px);
	}

	@include phone {
		grid-template-columns: auto;
		padding-left: 7.5px;
		padding-right: 7.5px;
	}

	>img {
		&.banner-red-bow-left {
			@include phone {
				height: 93px;
				position: absolute;
				right: 0;
				-webkit-transform: scaleX(-1);
				transform: scaleX(-1);
			}
		}
	}
	sup {
		font-size: .75em;
	}
}

.announcements__content {
	display: flex;
	align-items: center;
	gap: 7.5px;
	padding-top: 7.5px;
	padding-bottom: 7.5px;
	font-size: 16px;
	line-height: 1.3;
	color: white;
	transition: color .2s ease-out;

	// span {
	// 	text-decoration: underline;
	// }

	p {
		font-size: inherit;
		font-style: italic;
		line-height: inherit;

		span {
			display: block;
			font-size: 12px;
		}
	}

	&:hover,
	&:focus-visible {
		& em::after {
			background: white;
		}
	}

	@include tablet {
		font-size: 13.5px;
	}

	@include phone {
		font-size: 12px;
	}

	&:hover,
	&:focus {
		color: white;
	}
}

.announcements__actions {
	align-self: flex-start;

	@media screen and (min-width: 768px) {
		align-self: stretch;
	}

	@include phone {
		align-self: center;
		position: relative;
	}
}

.announcements__button {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 12px 15px;
	font-size: 12px;
	text-transform: uppercase;
	color: #000;
	border-top: 15px solid #000;
	border-bottom: 15px solid #000;
	background: #fff;
	transition: color .15s ease-out;

	&:hover,
	&:focus {
		background: $red;
		color: white;
	}

	@include phone {
		border-width: 0;
		padding-top: 7.5px;
		padding-bottom: 7.5px;
	}
}

.has-announcements header.slideUp {
	@media screen and (min-width: 768px) {
		// .announcements {
		// 	height: 0;
		// }
	}
}

/* These styles are applied to this modal instance to prevent conflicts with other modal styles.
   	They will be removed and integrated into the global modal styling when we revisit and update all modal styles across the site, removing the teal color. */

.modal-header.nav-modal,
.modal-body.nav-modal {
	position: relative;
	width: 675px;
	max-width: calc(100vw - 20px);
	left: 50%;
	transform: translateX(-50%);
}

.modal-header.nav-modal,
.modal-header.nav-modal>.btn-close,
.modal-body.nav-modal {
	background-color: white !important;
}

.modal-header.text-phone,
.modal-header.text-phone>.btn-close,
.modal-body.text-phone {
	background-color: #d9d9d9 !important;
}

.modal-body.text-phone {
	.title {
		p {
			display: none;

			&:first-child {
				display: block !important;
				color: black;
				font-size: 1.3rem !important;
				letter-spacing: 1px !important;
				font-weight: 400;
				text-align: center;
				padding-top: 25px;
				padding-bottom: 15px;
				margin-bottom: 0px !important;
			}
		}
	}

	.input-group {

		input,
		select {
			border-radius: 0px !important;
			color: black;

			&::placeholder {
				color: black;
			}
		}

		.checkbox-opt-in {
			label {
				display: flex;
				align-items: center;
				font-size: 0.625rem;
				line-height: normal;
			}
		}

		.form-disclaimer {
			font-size: 0.563rem;
			line-height: normal;
		}
	}

	.bottom-content {
		.btn {
			background-color: $red;
			color: white;
			transition: background-color .2s ease-in, color .2s ease-in;

			&:hover,
			&:focus,
			&:active {
				background-color: white;
				color: $red;
			}
		}
	}

	.contact-info {
		a.btn {
			background-color: black;
			color: white;
			transition: background-color .2s ease-in, color .2s ease-in;

			&:hover,
			&:focus,
			&:active {
				color: black;
				background-color: white;
			}
		}
	}
}

.modal-header.nav-modal,
.modal-header.text-phone {
	.btn-close {
		color: gray !important;
	}
}

.modal-body.text-phone {
	form {
		display: none;
	}

	.contact-info {
		flex-direction: column;

		a {
			background-color: black !important;
			color: white !important;
			transition: background-color .2s ease-in, color .2s ease-in;

			&:focus,
			&:hover {
				background-color: white !important;
				color: black !important;
			}
		}

		p {
			display: none;
		}
	}

	.contact-info>a {
		margin: 15px 0;
	}

}

.modal-body.nav-modal,
.modal-body.text-phone {
	color: black !important;

	.dropdown-toggle>span {
		background-color: black !important;
		transition: background-color .3s ease-in-out !important;

		&:hover,
		&:active {
			background-color: $red !important;
		}

		svg path {
			fill: white !important;
		}

	}

	.dropdown {
		width: 100% !important;

		.dropdown-toggle {
			color: black;
			border-radius: 5px 0 0 5px !important;
			border: 1px #7f7f7f solid !important;
			font-size: 18px;

			&:focus {
				color: black;
			}
		}

		.dropdown-menu {
			width: 100%;
			min-width: fit-content !important;

			@include desktop {
				max-width: 800px;
			}

			@include phone {
				width: 25vw;
				min-width: 215px;
				font-size: 14px;
			}

			span {
				background: black;
			}

			&:hover,
			&:focus {
				span {
					background-color: #ba1f31;
				}
			}
		}

		&.open>.dropdown-menu {
			display: flex;
		}

		>ul {
			min-height: fit-content;
			min-width: initial;
			justify-content: space-evenly;
			height: auto;
			padding: 10px 20px;
			gap: 30px;

			@include tablet {
				height: auto;
				min-height: fit-content;
			}

			@include phone {
				height: auto;
				max-height: calc(35vh - 30px);
			}


			.mobile-column>li>a,
			.mobile-column .sub-dropdown>li>a {
				// background-color: white !important;
				color: black !important;
				font-size: 14px;
				font-weight: 400;
				text-transform: uppercase;
			}

			.mobile-column>li>a {
				font-weight: 600;
			}

			.mobile-column>li>a.super-region {
				font-weight: 600;
			}

			.mobile-column {
				width: 100%;
				max-height: calc(30vh - 30px);
			}

			.mobile-column .sub-dropdown {
				background-color: white;
				color: black;
				list-style: disc;
				padding-left: 50px;

				li>a {
					padding-left: 10px;
				}
			}

			.column {
				// height: 100%;
				break-inside: avoid;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				>li>a,
				.super-region {
					font-weight: 600;
					padding: 10px 0;
				}

				.sub-dropdown>li>a {
					font-weight: 400;
					padding: 10px 0;
				}

				>li>a,
				.sub-dropdown>li>a {
					color: black !important;
					font-size: 16px;
					line-height: initial;
					text-transform: uppercase;
					padding: 10px 0px;

					&:hover {
						background-color: white !important;
						color: $red !important;
					}

					&:focus,
					&:active {
						background-color: white !important;
						color: $red !important;
					}

					@include phone {
						font-size: 14px;
					}
				}

				.sub-dropdown {
					padding-left: 25px !important;
					background-color: white !important;
				}

				.sub-dropdown>li {
					list-style-type: disc;
				}

				.sub-dropdown>li>a {

					&:hover {
						background-color: white !important;
						color: $red !important;
					}

					&:focus,
					&:active {
						background-color: white !important;
						color: $red !important;
					}
				}

				.super-region {

					&:focus,
					&:active {
						color: $red;
					}
				}
			}

			.column1 {
				width: fit-content;
			}

			.column2,
			.column3 {
				justify-content: flex-start;
			}
		}

		@media screen and (max-height: 775px) {
			>ul {
				max-height: calc(30vh - 30px);
			}
		}
	}
}

.announcements {
	.stl-msg__text {
		display: none;
	}

	&.stl-msg {
		.stl-msg__text {
			display: block;
		}

		a.announcements__content {
			display: none;
		}
	}

}

.mobile-heart {
	display: flex;
	gap: 20px;
	background-color: transparent !important;

	@media screen and (min-width: 480px) {
		display: none !important;

	}

	&::after {
		background-color: transparent !important;
	}

	.heart {
		display: flex !important;
		width: 100%;
		justify-content: flex-start !important;
		gap: 20px;


		&::before {
			background-color: #BA1F31 !important;
		}

		svg {
			fill: #ffffff !important;
			color: #ffffff !important;
			width: 20px !important;
			height: 20px !important;

		}
	}
}