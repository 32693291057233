.cookie-banner {
  background-color: $dark-gray;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1000;

  &.is-open {
    visibility: visible;
    opacity: 1;
  }

  > .row > div[class^="col-"] {
    p {
      color: #fff;
      font-size: 15px;
      line-height: 24px;
      margin-top: 15px;
      margin-bottom: 15px;
      &:nth-child(2) {
        font-size: 18px;
      }
      a {
        color: #fff;
        text-decoration: underline;
      }
    }
    .btn-close {
      color: white;
      cursor: pointer;
      position: absolute;
      top: 7.5px;
      right: 15px;
    }
    .btn-i-accept {
      padding-top: 7.5px;
      padding-bottom: 7.5px;
    }
  }
}