
.btn.btn-primary.btn-dropdown,
.btn.btn-primary.btn-dropdown:focus{
	border-radius: 0;
	background-color: white;
	border: none;
	color: $gray;
    width: 25vw;
    text-align: left;
    padding: 20px;
    position: relative;
    cursor: pointer;
    @include laptop{
    	padding: 10px 20px;
    }
    span{
    	background-color: $blue;
    	position: absolute;
    	right: 0;
    	top: 0;
		width: 65px;
		height: 100%;
		@include flex-param(row, center, center);
		@include transition(all .2s ease-out);
		svg{
			width: auto;
			height: 25px;
		}
    }
    &:hover span{
    	@include transition(all .2s ease-in);
		background-color: $red;
    }
    + .dropdown-menu{
    	width: 100%;
    	overflow: auto;
    	font-size: 16px;
    }
	&.arrow{
		width: 100%;
		span{
			margin-right: 0;
			background-color: transparent;
			svg{
				@include svgcolor($blue);
			}
		}
	}

}

.dropdown-menu > li > a {
	white-space: initial;
}

.btn-group.dropdown .btn-dropdown-lg {
	box-shadow: none;
	border: none;
	background-color: transparent;
	padding: 0;
	cursor: pointer;
	text-align: left;

	@include flex-param(row, center, center);

	> span {
		white-space:nowrap;
		margin-right: 15px;
	}

	svg {
		@include transition(all .2s ease-out);
		@include rotate(0);
	}
}

.btn-group.dropdown.open .btn-dropdown-lg{
	svg{
		@include transition(all .2s ease-in);
		@include rotate(180);
	}
}

.scroll-down-arrow{
	$radius: 175px;
	height: 70px;
	width: $radius;
	bottom: 0;
	left: calc(50% - #{$radius} / 2);
	position: absolute;
	overflow: hidden;
	z-index: 10;
	cursor: pointer;
	text-align: center;
	color: white;
	@include phone{
		@include scale(.5, bottom);
	}
	.fa{
	    font-size: 50px;
	    margin-top: 15px;
	    position: relative;
	    z-index: 1;
		@include keyframes(pulse) {
			0%,
			100%{
				top: -5px;
			}
			50%{
				top: 5px;
			}
		}
		@include animation('pulse 1.5s ease-in-out infinite');
	}
	&.gray:before{
		background: rgba($dark-gray, .8);
	}
	&:before{
		content: "";
		background: rgba(black, .2);
		border-radius: $radius;
		width: $radius;
		height: $radius;
		position: absolute;
		top: 0;
		left: 0;
		@include transition(background .2s ease-out);
	}
	&:hover{
		&:before{
			background: rgba(black, .5);
			@include transition(background .2s ease-in);
		}
	}
}

.button-hover-alt {
	color: $blue;
	&.red{color: $red;}
	@include hoverstate(transparent, $green, .2s);
}

.dropdown-gray-to-red{
	min-width: 385px;
	padding: 0;
	@include dropshadow(15px, 15px, 0, 0, .15);
	li{
		cursor: pointer;
		> ul{
			background-color: $lightest-gray;
		}
		a{
			font-weight: 300;
			line-height: 40px;
			padding: 10px 50px;
			display: block;
			color: $gray;
			@extend .type-xl;
			@include hoverstate($red, white, .2s);
		}
		.sub-dropdown li a{
			padding-left: 70px;
		}
		@include laptop{
			a{
				font-size: 20px;
				line-height: 30px;
				padding: 5px 50px;
			}
		}
		@include phone{
			a{
				padding: 10px 20px;
				font-size: 18px;
				line-height: 20px;
			}
			.sub-dropdown li a{
				padding-left: 35px;
			}
		}
	}
}

.btn.btn-blue{@include btn-link($blue);}
.btn.btn-red{@include btn-link($red);}
.btn.btn-red-svg{@include btn-link(white, $red);}
.btn.btn-white{@include btn-link(white, $blue);}
.btn.btn-gray{@include btn-link($light-gray);}
.btn.btn-orange{@include btn-link($orange);}
.btn.btn-black{@include btn-link(rgba(black,.7));}
.btn.btn-green{@include btn-link($green);}
.btn.btn-dark-green{@include btn-link(darken($green, 6%));}
.btn.btn-white-red{@include btn-link(white, $red, $red, white);}
.btn.btn-black-red{@include btn-link(black, white, $red, white);}
.btn.btn-black-gray{@include btn-link(black, white, $light-gray, white);}
.btn.btn-red-black{@include btn-link($red, white, black, white);}
.btn.btn-red-white{@include btn-link($red, white, white, black);}
.btn.btn-black-white{@include btn-link(black, white, white, black);}
.btn.btn-white-black{@include btn-link(white, $red, black, white);}
.btn.btn-gray-red{@include btn-link($light-gray, white, $red, white);}
.btn.btn-gray-black{@include btn-link($light-gray, white, black, white);}
