#floorplan-detail,
#microsite-floorplan-detail {
    .nav-tabs > li > a{
        color: $red;
    }
    .nav-tabs > li.active > a{
        color: $light-gray;
    }
    #hero {
        overflow: initial;
        .col-right {
            padding: 50px;
            > * {
                max-width: 540px;
            }
            .detail p,
            .detail h1 {
                margin-bottom: 20px;
            }
            .detail .red {
                font-weight: 300;
            }
            .detail .black {
                letter-spacing: 0.6px;
            }
            .detail .collection-name {
                text-transform: uppercase;
                display: block;
                margin: 1em 0;
            }
            .info button.calculator {
                margin-top: 30px;
                @include flex-param(row, center, center);
                svg {
                    @include size(20px);
                }
            }
            .info .dropdown {
                margin: 15px 0;
            }
            .info .dropdown button {
                box-shadow: none !important;
                padding: 15px 0;
                margin-bottom: 0px !important;
                @include flex-param(row, space-between, center);
                svg {
                    @include rotate(0);
                    @include svgcolor($medium-gray);
                    @include transition(all .2s ease-out);
                    @include phone{
                        height: 50px;
                        width: 25px;
                        margin-left: 10px;
                    }
                }
            }
            .info .dropdown.open button svg {
                @include rotate(-180);
                @include transition(all .2s ease-in);
            }
            .info .dropdown-menu {
                background-color: $lightest-gray;
                border-radius: 0;
                @include dropshadow(10px,10px,0,0,0.2);
                a {
                    color: $red;
                    text-transform: uppercase;
                    padding: 10px 20px;
                    white-space: normal;
                    font-weight: 300;
                    @extend .type-lg;
                    @include hoverstate($red, white, .2s);
                    @include desktop-hd{
                        padding: 15px 30px;
                    }
                }
            }
            .btn-pick-a-different-region {
              color: $red;
              font-size: 16.5px;
              font-weight: 600;
            }
            .info > .type-lg {
                line-height: 25px;
                span {
                    color: $light-gray;
                }
            }
            .info .type-md {
                line-height: 48px;
                @include phone {
                  line-height: 30px;
                }
                &:not(:last-child) {
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
            }
            .info a {
                color: $red;
                text-transform: uppercase;
                @include flex-param(row, flex-start, center);
                svg {
                    @include size(20px);
                    @include svgcolor($red);
                    margin-right: 15px;
                }
            }
            @mixin param(){
                .detail .black{
                    line-height: 22px;
                }

                .info snapshot-info .snapshot li{
                    letter-spacing: 0px;
                    font-size: 14px;
                }
                .info .dropdown{                    
                    margin: 0;
                    width: 100%;
                }
                .info .dropdown button{
                    padding: 0;
                    width: 100%;
                }
                .info .dropdown button i{
                    margin-left: 20px;
                }

                .info a.type-md.blue{
                    font-size: 14px;
                    line-height: 20px;
                    margin-top: 20px;
                }
            }
            @include tablet{
              @include param();
              padding: 30px 15px;
            }
            @include phone{
              @include param();
              padding: 15px;
              .detail {
                padding-left: 15px;
                padding-right: 15px;
              }
              .img-responsive {
                max-width: 100%;
              }
              .info .dropdown {
                padding-left: 15px;
                padding-right: 15px;
              }
              .info a.type-md.blue {
                margin-left: 15px;
                margin-right: 15px;
              }
              .info .description {
                margin-left: 15px;
                margin-right: 15px;
              }
            }
        }
    }
    #content {
        .container.flex-wrap{
            @include phone{
                flex-direction: column;
                align-items: center;
                .col-left{
                    flex: initial;
                    padding: 0;
                }
            }
        }
        > .gray-bkg {
            background-color: $lightest-gray;
            padding: 30px 0;
            .col-left {
                padding-right: 50px;
                p:first-child {
                    font-weight: 300;
                    margin-bottom: 20px;
                    line-height: 40px;
                }
                p:last-child {
                    line-height: 35px;
                }
            }
        }
    }
}
