body#login {
    height: auto;
}

div#login-view {
    margin-top: var(--headerHeight);
}

section#container-login {
    position: relative;
    background-image: url('/images/site/login/login-background.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 79vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(50, 50, 50, 0.8);
        z-index: 1;
    }



}

.forgot-password__modal-overlay {

    position: fixed;
    display: flex !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: center;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out;

    &.show {
        visibility: visible;
        opacity: 1;

        .forgot-password__card {
            transform: translateY(50px);
        }
    }

    .forgot-password__card {
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        transform: translateY(-60px);
        transition: transform 0.3s ease-out;

        .close-forgot-password {
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }
    }
}

.login__card {
    position: relative;
    width: 350px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 40px 30px;
    z-index: 500;

    button {
        width: 100%;
    }

    h3 {
        color: #000000;
        font-size: 20px;
        text-align: center;
    }

    h6 {
        text-align: center;
        padding: 10px 0;
    }

    .subnav {
        display: grid !important;
        grid-template-columns: repeat(2, minmax(100px, 1fr));

        li {
            text-align: center;
            padding: 20px;
            border-bottom: 2px solid var(--lighter-gray, #cacaca);
            font-size: 16px;
            color: #000000;

            &.active {
                border-bottom: 2px solid #000000;
            }
        }
    }

    .login-form__section {
        padding-top: 20px;
        padding-bottom: 20px;

        .forgot-password {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-top: 20px;
            padding-bottom: 40px;

            a {
                font-size: 16px;
                text-decoration: underline;
                color: var(--Black, #0C0C0C);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 0.32px;
            }
        }
    }

    .sing-up-form__section {
        padding-top: 20px;
        padding-bottom: 20px;

        .terms {
            display: flex;
            align-items: stretch;
            gap: 10px;

            .terms-text {
                flex: 1;
                color: #000000;
                padding-top: 12px;
                font-size: 13px;
            }
        }

        .information {
            color: var(--Black, #0C0C0C);
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: 0.2px;
            margin-top: 20px;
        }

        input[type="checkbox"] {
            height: 13px;
            margin-top: 18px;
        }
    }
}

@import "reset-password";