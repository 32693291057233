.inline-new-phase {
	background-color: $lighter-gray;
	height: 140px;

	@include flex-param(row, center, center);

	@include phone{
		display: block;
		text-align: center;
		height: auto;
		padding: 10px 20px;
	}

	.image {
		@include background-image('/images/site/microsite/tiny-tree.jpg', cover, bottom center);
		@include size(255px, 100%);
		flex: 1.5 0 0;
		@include tablet{
			display: none;
		}
	}

	.copy {
		padding-left: 25px;
		flex: 2;

		p.type-lg {
			font-style: italic;
			margin-bottom: 5px;
		}

		p.type-xs {
			line-height: 22px;
		}

		@include phone{
			padding-left: 0;
			margin-bottom: 20px;
		}

	}

	.flex-wrap {
		@include flex-param(row, center, center);
		flex: 4;

        .form-results-container {
            div.form-results-loader {
                margin-top: 50px;
            }

            h5 {
                margin-top: 80px;
            }
        }
        @include phone{
        	flex-direction: column;
        	align-items: stretch;
        }
	}

	.flex-wrap form {
		@include flex-param(row, center, center);

        padding: 20px;
        > .input-group{
            width: 60%;
        }

        > .input-group:last-child{
        	width: 40%;
        }


        > .input-group > .form-component{
            width: calc(50% - 10px);
            &:nth-child(odd){float:left}
            &:nth-child(even){float:right}
            &:nth-child(n + 1){margin-bottom: 20px;}
        }

        .input-group.form-components {
            margin-top: 20px;
        }

        .checkbox-opt-in {
          label {
            font-size: 12px;
            line-height: 15px;
            input[type="checkbox"] {
              position: relative;
              top: 3px;
            }
          }
        }

        .btn-wrap {margin-left: 20px;}
        .btn-wrap input[type="submit"],
        .btn-wrap button[type="submit"] {
          display: block;
          margin: 15px auto;
          padding: 10px 45px;
          position: relative;
          z-index: 1;
          .fa {
            margin-left: 15px;
            margin-right: 0;
          }
        }

        a{
        	color: white;
        	display: block;
        	text-align: center;
        }

        @include tablet{
          .btn-wrap {margin-bottom: 7.5px;}
	        .btn-wrap input[type="submit"]{
	            font-size: 13px;
	            padding: 10px;
	        }
        }

        @include phone{
        	padding: 0;
        	display: block;
        	> .input-group,
        	> .input-group:last-child{
        		width: 100%;
        	}
        	> .input-group > .form-component,
        	> .input-group > .form-component:nth-child(n + 1){
				width: 100%;
				float: none;
				margin-bottom: 15px;
        	}

	        .input-group.form-components {
	            margin-top: 0px;
	        }
	        .btn-wrap {
	        	margin-left: 0px;
	        	margin-bottom: 0px;
	        }
	        .btn-wrap input[type="submit"]{
	        	font-size: 16px;
	        	margin-bottom: 10px;
	        }
        }
	}

}

.inline-get-fast-answers{
	@extend .inline-new-phase;
	height: 195px;
	@include phone {
	  height: auto;
	}
	.image{
		width: 145px;
		flex: initial !important;
		@include phone {
			display: none;
		}
	}
	.copy{
		flex: 1 0 0;
		text-align: left;
		padding: 25px 5px;
		p.type-lg{
		    line-height: 25px;
		    font-size: 14px;
		    text-align: left;
		}
		p.type-xl{
			color: $gray;
			font-style: initial;
			@extend .type-sm;
			line-height: 25px;
		}
		p.type-xs,
		p > a{
			color: inherit;
			font-style: initial;
			font-size: 22px;
			font-weight: 600;
			line-height: 30px;
			margin-top: 5px;
		}
	}
	.flex-wrap {
    flex: 5;
    form {
      flex-wrap: wrap;
      @include desktop-hd {
        padding: 20px 5px;
      }
      @include desktop {
        padding: 20px 5px;
      }
      @include tablet {
        padding: 20px 5px;
      }

      > .input-group {
        @include desktop-hd {
          width: 49.5%;
        }
        @include desktop {
          width: 49.5%;
        }
        @include tablet {
          width: 49.5%;
        }
        &:last-child {
          @include desktop-hd {
            width: 46.5%;
          }
          @include desktop {
            width: 46.5%;
          }
          @include tablet {
            width: 46.5%;
          }
        }
      }
      .form-disclaimer {
        flex-basis: 100%;
        margin-top: 7.5px;
        margin-bottom: 7.5px;
        text-align: left;
      }
    }
  }
	@include desktop{
		.copy{		
		    flex: 1.4 0 0;
		    padding: 0;
		}
		p.type-lg{
		    line-height: 20px;
		    font-size: 13px;
		}
		.flex-wrap{
			flex: 5 0 0;
		}
	}
	@include tablet{
		.copy{		
		    flex: 1.3 0 0;
		    padding: 10px;
		}
		.copy p.type-lg{
		    line-height: 18px;
		    font-size: 13px;
		}
		.copy p.type-xs{
			font-size: 16px;
			line-height: 20px;
		}
	}
	@include phone{
		background-color: $gray;
		color: white;
		.copy{
			padding: 0;
		}
		.copy,
		.copy p.type-lg{
			text-align: center;
		}
		.copy p.type-xs {
		  color: #fff;
      font-size: 9vw;
      line-height: 12vw;
    }
    .flex-wrap form {
      .checkbox-opt-in {
        label {
          color: #fff;
        }
      }
      .btn-wrap input[type="submit"] {
        font-size: 6vw;
        margin-left: auto;
        margin-right: auto;
        // padding-left: 18vw;
        // padding-right: 18vw;
      }
    }
	}
	&.md .copy p.type-xl{
		@extend .type-md;
		line-height: 22px;
	}
}

.inline-stay-updated,
.inline-energywyse{
	background-color: $lighter-gray;
	inline-form{
		@extend .inline-new-phase;
		height: 210px;
		@include desktop {
		  height: 240px;
		}
		@include tablet {
		  height: 285px;
		}
		@include phone {
		  height: auto;
		}
		.image{
			display: none;
		}
		.copy{
			display: flex;
			justify-content: flex-end;
			padding: 25px;
			@include tablet{
			  padding-left: 0;
				padding-right: 0;
			}
			@include phone{
				padding: 0;
				justify-content: center;
			}
			p{
			    max-width: 240px;
			    line-height: 40px;
			    color: white;
				@include desktop{					
				    font-size: 18px;
				    line-height: 30px;
				}
				@include tablet{
				    font-size: 16px;
				    line-height: 20px;
				}
				@include phone{
					font-size: 22px;
					max-width: 500px;
				}
			}
		}
		.flex-wrap{
			flex: 7 0 0;
			@include tablet{
				flex: 5 0 0;
			}
			form {
        flex-wrap: wrap;
        @include tablet {
          padding-left: 0;
          padding-right: 0;
        }
        > .input-group {
          @include desktop-hd {
            width: 49.5%;
          }
          @include desktop {
            width: 49.5%;
          }
          @include tablet {
            width: 49.5%;
          }
          &.form-components {
            margin-top: 0;
          }
        }
      }
			.btn-wrap{
				svg{
					@include svgcolor(white);
				}
				span.icon{
				    position: absolute;
				    top: 20px;
				    left: 20px;
				}
				input.btn{
					@include transition(all .2s ease-out);
					background-color: $blue;
					width: 100%;
				}
				&:hover input.btn{
					@include transition(all .2s ease-in);
					background-color: $green;
				}
			}
		}
	}
	.form-disclaimer {
    flex-basis: 100%;
    font-size: 12px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
    text-align: left;
  }
}
.inline-energywyse{
	inline-form .flex-wrap form .input-group:nth-of-type(2) {
		.btn-group.dropdown,
        .form-component {
			width: 100%;
		}
		.btn-group.dropdown button.btn,
        .form-component {
		    padding: 0px 0px 0px 20px;
		    border-radius: 3px;
		    font-size: 12px;
    		margin-bottom: 10px;
    		@include tablet{
			    margin-bottom: 20px;
			    margin-top: 10px;
    		}
    		@include phone{
    			padding: 0;
			    margin-top: 10px;
			    margin-bottom: 15px;
    		}
		}
		.btn-group.dropdown button span svg{
			@include size(20px);
			@include svgcolor($gray);
		}


		.btn-wrap{
		    display: inline-block;
		    float: right;
		    @include phone{
		    	float: none;
		    }
		}
		.btn-wrap input.btn{
		    padding: 7px 20px;
		    font-size: 13px;
		    margin-bottom: 0;
		}
	}
}

.inline-exposed-schedule-my-tour {
  background-color: $lighter-gray;
  margin-top: 45px;
  margin-bottom: 60px;
  width: 100%;
  @include desktop-hd {
    @include flex-param(row, center, center);
  }
  @include desktop {
    @include flex-param(row, center, center);
  }
  @include tablet {
    @include flex-param(row, center, center);
  }
  @include phone {
    background-color: $blue;
    color: white;
  }

  .image {
    @include background-image('/images/site/microsite/tiny-tree.jpg', contain, left bottom);
    @include size(15vw, 100%);
    background-repeat: no-repeat;
    flex: initial;
    max-width: 210px;
    @include tablet{
      display: none;
    }
    @include phone {
      display: none;
    }
  }

  .copy {
    @include desktop-hd {
      height: 100%;
      padding: 25px 15px;
    }
    @include desktop {
      flex: 1.4 0 0;
      height: 100%;
      padding: 25px 15px;
    }
    @include tablet {
      flex: 1.3 0 0;
      padding: 10px;
    }
    @include phone {
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
    }

    p {
      @include desktop-hd {
        margin-left: -1.5vw;
      }
      @include desktop {
        margin-left: -1.5vw;
      }
    }

    p.type-lg {
      font-size: 14px;
      font-style: italic;
      line-height: 25px;
      margin-bottom: 5px;

      @include desktop {
        line-height: 20px;
        font-size: 13px;
      }
      @include tablet {
        line-height: 18px;
        font-size: 13px;
      }
      @include phone {
        text-align: center;
      }
    }

    p.type-xs,
    p > a {
      color: inherit;
      font-style: initial;
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      margin-top: 5px;
    }

    p.type-xs {
      @include tablet {
        font-size: 16px;
        line-height: 20px;
      }
      @include phone {
        color: #fff;
        font-size: 9vw;
        line-height: 12vw;
      }
    }
  }

  .flex-wrap {
    @include flex-param(row, center, center);
    flex: 5;

    .form-results-container {
      div.form-results-loader {
        margin-top: 50px;
      }

      h5 {
        margin-top: 80px;
      }
    }
    @include desktop {
      flex: 6 0 0;
    }
    @include phone {
      flex-direction: column;
      align-items: stretch;
    }
  }
}

//ie 11 fix
html.is-ie{
	.inline-get-fast-answers .copy{
		width: 50%;
		flex: 1.5;
		@include desktop{
			flex: 1;
		}
		@include tablet{
			flex: 1;
		}
	}
	inline-form fh-form-inline{
		display: flex;
	}
}