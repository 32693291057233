show-more{
	span{
		color: black;
		@include flex-param(row, center, center);
		margin-top: 80px;
		cursor: pointer;
		svg{
			margin: 0 10px;
			@include svgcolor(black);
		}
	}
}