&.home-show section#overview {

	h2.type-title-xl {
		text-transform: uppercase;
	}

	p{
      	margin-bottom: 45px;
		font-size: 18px;
		line-height: 30px;
		@include desktop-hd{line-height: 45px}
		@include desktop{font-size: 16px}
		@include tablet{font-size: 15px}
		@include phone{
			font-size: 15px;	
        	margin-bottom: 30px;
		}
	}

}