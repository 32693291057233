#podcast {
	#hero{
    height: 425px;
    padding-top: 100px;
    color: white;
    @include flex-param(column, center, center);
    @include background-image('/images/site/homepage/grid-bkg.jpg', cover, center);
    h1{
      font-weight: 300;
      @include phone {
        font-size: 7.5vw;
      }
    }
    @include phone{
      height: 225px;
      padding-top: 65px;
    }
  }

  #content {
    background-color: $lightest-gray;
    padding-bottom: 45px;
  }

  .episode {
    padding-top: 15px;
    padding-bottom: 15px;
    &:not(:last-child) {
      border-bottom: 1px solid $blue;
    }
    > div {
      &:first-child {
        background-position: center; 
        background-repeat: no-repeat;
        background-size: cover;
        display: table;
        width: 100%;
        text-align: center;

        @include desktop-hd {
          height: 300px;
        }
        @include desktop {
          height: 240px;
        }
        @include tablet {
          height: 240px;
        }
        @include phone {
          height: 180px;
        }
        > .fa {
          color: #fff;
          display: table-cell;
          font-size: 120px;
          text-shadow: 7.5px 7.5px 7.5px rgba(0,0,0,.375);
          vertical-align: middle;
        }
      }
    }
    p {
      color: $dark-gray;
      margin-bottom: 7.5px;
    }
    .title {
      font-size: 27px;
      line-height: 36px;
      margin-top: 7.5px;
      @include desktop {
        font-size: 22.5px;
      }
    }
    .blurb {
      font-size: 13.5px;
      line-height: 18px;
    }
    .btn.btn-blue {
      margin-top: 15px;
    }
  }

	#like-what-you-see {
	  @include background-image('/images/site/virtual-tours/like-what-you-see-v2.jpg', cover, center);
	  padding-top: 120px;
	  padding-bottom: 120px;

	  &::before {
      background-color: rgba(0,0,0,0.6);
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

	  p {
	    color: #fff;
	    margin-bottom: 30px;
	  }
	}
}
