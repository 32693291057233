#floorplan-gallery{
	padding: 100px 0;
	background-color: white;
	p, ul, h2{
		margin-bottom: 60px;
		display:  block;
	}
	a svg{
		@include svgcolor($blue);
		margin-right: 15px;
	}
	p, h2{
		font-weight: 300;
		letter-spacing: 2px;
	}

	.nav-tabs > li {
	  padding-left: 15px;
	  padding-right: 15px;
	}

	li.type-sm{
		@extend %tab-list-style;
	    padding: 15px;

	    &:last-child:after {
	      content: '|';
	      color: transparent;
	    }
	}

	.slick-arrow{
		display: none !important;
	}

	@include phone{
		padding: 30px 0 50px;

		p, ul, h2{
			margin-bottom: 30px;
		}

		li{
		    &:after {
		      display: none;
		    }
		}
	}
}
