/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
   reset
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
a,
a:hover,
a:focus{
    outline: none;
    text-decoration: none;
    color: $red;
}

//use this rule to find empty or hashed links by adding ?debug
#debug a[href="#"],
#debug a[href=""]{
    color: red !important;
}

//custom reset
html,
body {
    height: 100%;
    color: $gray;
    position: relative;
    -webkit-animation-delay: 0.1s;
    -webkit-animation-name: fontfix;
    -webkit-animation-duration: 0.1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
    [ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
      display: none !important;
    }
}

*{
    outline: none;
}


section{
    position: relative;
    overflow: hidden;
}

//IE FIX FOR FIXED POSITION SCROLLING
html.is-ie { overflow:hidden; }
html.is-ie body { overflow:auto; height:100%; }

/* bootstrap slickslideshow embedded in tab fix */
.tab-content > .tab-pane,
.pill-content > .pill-pane {
    display: block;
    height: 0;
    overflow: hidden;
}
.tab-content > .active,
.pill-content > .active {
    height: auto;
} /* end */


.ta-hidden-input {
    display: none;
}

.clear-fix {
    clear: both;
}

.clear-item{
    @extend %clearfix;
}

.navbar-default .navbar-toggle,
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover{
    border: none;
    background-color: transparent;
    margin-right: 0;
    border-radius: 0;
    .icon-bar{
        background-color: white;
        width: 25px;
        height: 5px;
        + .icon-bar{margin-top: 3px;}
    }
}

//forms
select{
    -webkit-appearance: none;
}
select::-ms-expand {
    display: none;
}