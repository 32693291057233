#join-our-team {
	#hero-carousel{
	    h1 span{
	    	display: block;
	    	margin-bottom: 40px;
	    	font-weight: 300;
	    	text-align: center;
	    }

		button svg{
			@include size(20px);
		}

		.fill iframe{
			opacity: 0;
		}

		.fill.active iframe{
			opacity: 1;
			@include transition(all .4s ease-in);
		}
		.carousel .fill.video, .carousel .carousel-inner .fill.video,
		.carousel .carousel-inner .item .fill.video,
		.carousel .carousel-inner .item .flex-caption .fill.video{
		    height: calc(100% - 260px);
		    top: 170px;
		    @include tablet{
			    top: 0;
			    width: 100%;
			    height: 100%;
		    }
		    @include phone{
			    top: 70px;
			    width: 100%;
			    position: relative;
			    transform-origin: center;
			    @include scale(1.15);
			    height: 205px;
		    }
		}

	    @include phone{
	    	.carousel{
	    		top: 7px;
	    		height: 375px;
	    	}
	    	span:nth-of-type(1){
	    		font-size: 25px;
	    		margin-bottom: 20px;
	    	}
	    	span:nth-of-type(2){
	    		font-size: 25px;
	    		text-align: center;
	    		margin-bottom: 15px;
	    	}
	    	button{
	    		margin-bottom: 55px;
	    	}

	    	.video-wrap{
	    		background-color: black;
	    		height: 100%;
	    	}
	    }
	}

	section:not(#inline-form) .container{
		padding: 70px 0;
		> .row{
			padding: 0 15px;
			p,h1,h2{
				line-height: 52px;
				&:not(.type-subtitle-lg){
					margin-bottom: 50px;
				}
			}

			p.type-sm,
			p.type-md,
			h1.type-sm{
				line-height: 35px;
				margin-bottom: 30px;
			}

			p.type-title-xs{
				letter-spacing: 0;
				font-weight: 600;
			}

			p.type-subtitle-xl.red{
				font-weight: 300;
			}

			p.type-xxl,
			h2.type-xxl{
				text-transform: uppercase;
				letter-spacing: 2px;
			}

			p.no-margin,
			h1.no-margin,
			h2.no-margin{
				margin: 0;
			}
		}
		@include desktop-hd{
			> .row{			
				width: 100%;
				padding: 0;
			}
		}
		@include tablet{
			padding: 70px 15px;
			> .row{			
				p,
				h1{
					line-height: 45px;
				}
				br{
					display: none;
				}
			}
		}

		@include phone{
			padding: 30px 15px;
		}
	}

	#overview{
		.row .col-left,
		.row .col-right{
			padding: 0;
			.row{
				width: 100%;
				margin: 0;
			}
		}

		.row:nth-of-type(1) .flex-wrap{
			background-color: white;
			padding: 35px;
			margin-bottom: 70px;
			@include flex-param(row, space-between, center);
			p,
			h1,
			h2{
				line-height: 35px;
				margin-bottom: 0;
			}
			@include tablet{
				p:nth-of-type(1),
				h1:nth-of-type(1){
					padding-right: 20px;
				}
			}
			@include phone{
				flex-direction: column;
				padding: 25px;
				margin-bottom: 30px;
				p:nth-of-type(1),
				h1:nth-of-type(1){
					margin-bottom: 20px;
				}
			}
		}

		.row:nth-of-type(2){
			margin-bottom: 100px;
			.col-left{
				padding-right: 100px;
				p:first-child{
					padding-right: 80px;
				}
			}
			@include desktop{
				margin-bottom: 50px;
				.col-left{
					padding-right: 50px;
				}
			}
			@include tablet{
				margin-bottom: 40px;
				.col-left{
					padding-right: 30px;
					p:first-child,
					h1:first-child{
						padding-right: 30px;
					}
				}
			}
			@include phone{
				margin-bottom: 30px;
				.col-left{
					padding-right: 0;
					h2.type-xxl{
						margin-bottom: 30px;
						line-height: 35px;
					}
				}
			}
			.col-right{
				.row{
					background-color: white;
					padding: 40px;
				}
				.row .top{
					@include flex-param(row, flex-end, stretch);
					margin-bottom: 55px;
					.logo-wrap{
						@include flex-param(column, space-around, flex-end);
						margin-right: 30px;
						p{
							margin: 0;
							line-height: 30px;
							text-align: right;
						}
					}
				}
				@include tablet{
					.row .top .logo-wrap{
						margin-right: 15px;
					}
					.row .top .logo-wrap > img{
						width: 100%;
					}
					.row .top > img{
					    width: 160px;
					    height: 230px;
					}
				}
				@include phone{
					.row .top{					
						flex-direction: column-reverse;
						margin-bottom: 30px;
					}
					.row .top .logo-wrap{
						@include flex-param(row, center, center);
						margin-right: 0;
					}
					.row .top .logo-wrap > img{
						width: 40px;
						margin-right: 10px;
					}
					.row .top .logo-wrap p{
						text-align: left;
					}
					p.type-sm{
						margin-bottom: 0;
					}
				}
			}
		}

		.row:nth-of-type(3){
			margin-bottom: 70px;
			@include phone{
				margin-bottom: 0;
			}
			.col-left{
				padding-top: 15px;
			}
			.col-left .flex-wrap{
				padding: 40px;
				background-color: white;
				@include flex-param(row, flex-start, flex-start);
				.info{
					padding-left: 30px;
					p{
						margin-bottom: 0;
						&:nth-of-type(2){
							margin-bottom: 20px;
						}
						&:not(:last-child){
							line-height: 30px;
						}
					}
				}
				@include tablet{
					flex-direction: column;
					align-items: center;
					.info{
						padding-left: 0;
					}
					.info p:not(:last-child){
						text-align: center;
					}
				}
				@include phone{
					flex-direction: column;
					margin-bottom: 30px;
					.info{
						padding-left: 0;
						p.type-title-xs,
						p.type-xs{
							text-align: center;
						}
					}
				}
			}

			.col-right{
				padding-left: 50px;
				padding-right: 0;
				p:first-child{
					padding-right: 40px;
				}
				@include tablet{
					p:first-child{
						padding-right: 0px;
					}
				}
				@include phone{
					padding-left: 0;
					h2.type-xxl{
						margin-bottom: 30px;
						line-height: 35px;
					}
				}
			}
		}
	}

	section#career-opportunities{
		p,a,h1{
			font-weight: 300;
		}
		> .container > .row {
		  width: auto;
		  @include desktop {
        padding-left: 0;
        padding-right: 0;
      }
		  @include tablet {
        padding-left: 0;
        padding-right: 0;
      }
		  @include phone {
		    padding-left: 0;
		    padding-right: 0;
		  }
		  > .col-left {
		    > .title{
          > h1 {
            margin-bottom: 15px;
            @include phone {
              line-height: 35px;
            }
          }
          > p {
            margin-bottom: 30px;
          }
        }
        .flex-wrap{
          padding: 15px 20px;
          @include transition(all .2s ease-in);
          @include flex-param(row, space-between, center);
          @include phone {
            position: relative;
            @include flex-param(column, flex-start, flex-start);
          }
          &:nth-child(odd){background-color: white;}
          &:hover{
            @include transition(all .2s ease-in);
            background-color: $blue;
            cursor: pointer;
            p,
            h1,
            a{
              @include transition(all .2s ease-in);
              color: white;
            }
          }
          p,a,h1{
            margin: 0;
            line-height: initial;
            @include transition(all .2s ease-in);
          }
          p,h1{
            width: 12.5%;
            &:nth-of-type(1){
              width: 25%;
            }
            @include phone {
              width: 100%;
              &:nth-of-type(1){
                width: 100%;
              }
            }
          }
          p:last-child {
            @include phone {
              position: absolute;
              top: 15px;
              right: 20px;
              display: inline-block;
              width: initial;
            }
          }
          a{
            color: $blue;
            width: 50%;
            @include phone {
              width: 65%;
            }
          }
        }
      }
      > .col-right {
        p {
          line-height: 36px;
          margin-bottom: 0;
        }
        .form-wrap {
          background-color: $blue;
          padding: 30px;
          @include tablet {
            margin-top: 30px;
          }
          @include phone {
            margin-top: 30px;
          }
          .copy p {
            &.type-lg {
              margin-bottom: 30px;
              text-align: center;
            }
            &.type-xs {
              margin-bottom: 0;
            }
          }
          div.form-results-container h5 {
            color: #fff;
            line-height: 36px;
          }
          form {
            a {
              color: #fff;
            }
            .form-component {
              margin-bottom: 20px;
              border: none;

              @include validation-white;
            }

            input.btn-orange {
              margin-top: 15px;
              margin-bottom: 15px;
              width: 100%;
            }
          }
        }
      }
		}
	}

	section#benefits{

		.row >div{
			text-align: center;
		}

		.benefit-offerings{
			.image-right{
				position: absolute;
				top: 0;
				right: 0;
				@include size(40%, 100%);
				@include background-image('/images/site/join-our-team/benefits.jpg', cover, center);
			}
			position: relative;
			p,h1,h2{
				text-align: left;
			}
		}


		.title-section,
		.icon-wrap{
			margin-bottom: 70px;
			@include flex-param(column, center, center);
		}

		ul li{
			text-align: left;
			margin-bottom: 25px;
			padding-left: 30px;
			line-height: 30px;
			@extend .type-sm;
			@extend %li-circle;
			&:after{
				top: 10px;
				background-color: $red;
				@include size(8px);
			}
			@include tablet{
				line-height: 25px;
				&:after{
					top: 8px;
				}
			}
		}

		.career-advancement{
			background-color: white;
			.icon-wrap .icon{
				background-color:$lightest-gray;
				border-radius: 195px;
				margin-bottom: 30px;
				@include size(195px);
				@include flex-param(row, center, center);
				@include transition(all .6s ease-in);
				svg{
					width: 150px;
					@include transition(all .6s ease-in);
					@include svghover($blue,$lightest-gray,.6s);
				}
			}
			.icon-wrap:hover .icon{
				background-color:$blue;
				@include svgcolor(white);
				@include rotateY(360);
				@include transition(all .6s ease-out);
			}
			.icon-wrap p{
				line-height: 45px;
				&:nth-of-type(1){
					font-weight: 600;
					margin-bottom: 10px;
				}
			}
		}


		@include phone{
			.image-right{
				display: none;
			}
			.title-section,
			.icon-wrap{
				margin-bottom: 30px;
			}
			.container .row h2{
				line-height: 40px;
			}
			.container .row .icon-wrap p{
				line-height: 35px;
				margin-bottom: 30px;
			}
			ul li{
				line-height: 18px;
			}
			ul li:after{
				top: 5px;
			}
		}
	}

	section#meet-and-greet{
		overflow: hidden;
		strong{
			font-weight: 800;
		}
		address{
			line-height: 40px;
		}
		.container{
			padding: 0;
		}
		.col-left{
			padding: 115px 115px 115px 0;
		}
		.col-right{
			background-color: $blue;
			position: relative;
			color: white;
			padding: 0;
			width: 520px;
			left: 15px;

			img{
				width: 100%;
			}
			.form-wrap {
				.top{
					padding: 25px 25px 25px 55px;
				}
				.top p,
				.top h1,
				.top h2{
					margin: 0;
					line-height: initial;
					&:first-child{
						font-weight: 300;
						margin-bottom: 30px;
					}
					&.type-lg,
					span{
						font-weight: 600;
					}
					&.type-md{
						margin-bottom: 15px;
					}
					&:last-child{
						line-height: 25px;
					}
				}
				hr{
					position: relative;
					width: 400%;
					opacity: 0.7;
				}
				.bottom{
					padding: 25px 55px;
				}
				.bottom p,
				.bottom h1,
				.bottom h2{
					line-height: 25px;
				}

                @include form-results-color(white);

                form {
                    .form-component {
                        margin-bottom: 20px;
                        border: none;

                        @include validation-white;
                    }

                    input.btn-orange{
                        float: right;
                        margin: 15px 0 50px;
                    }
                }
			}
		}
		@include tablet{
			.col-left{
				padding: 50px 0;
			}
		}
		@include phone{
			.container > .row.flex-wrap{
				flex-direction: column;
			}
			.container > .row h2{
				line-height: 35px;
			}
			.container > .row h1:not(.type-subtitle-lg){
				margin-bottom: 30px;
			}
			.col-left{
				padding: 30px 15px 115px 15px;
			}
			.col-right{
				width: 100%;
				left: 0;
				margin-bottom: 80px;
			}
		}
	}

	#career-fairs{
		.image-left{
			top: 0;
			left: 0;
			position: absolute;
			@include background-image('https://www.fischerhomes.com/cf-imgs/resources/223386/large/career-fairs.png', contain, top);
			@include size(40%, 100%);
		}
		b{
			display: inline-block;
			margin-bottom: 30px;
		}
		ul{
			margin-bottom: 50px;
			li{
				margin-bottom: 15px;
				line-height: 25px;
				clear: both;
				span{
				    height: 30px;
				    float: left;
				    margin-right: 10px;
				    display: inline-block;
				}
			}
		}
		@include phone{
			.image-left{
				display: none;
			}
			ul li span{
				height: 60px;
			}
		}
	}

	section#inline-form{
		.copy{
			flex: 2.4 0 0;
		}
		p{
			max-width: initial;
		}
		@include phone{
			padding-bottom: 80px;
		}
	}

}