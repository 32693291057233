#privacy-policy{
	.text-bold{
		font-weight: 700;
	}
	#privacy_policy_section{
        h2{
            margin: 50px 0 25px;
            text-transform: uppercase;
        }
		padding-top: 250px;
		padding-bottom: 100px;
	    min-height: calc(100% - 75px);
	    background-color: $lightest-gray;

	    .type-title-xl{
	    	margin-bottom: 140px;
	    	text-align: center;
	    	font-weight: 300;
	    }
	    .type-lg{
	    	font-weight: 600;
	    }
	    p:not(:first-child){
	    	line-height: 36px;
	    	margin-bottom: 30px;
	    	padding-right: 20px;
	    }

	    @include phone{
	    	padding-top: 130px;
		    .type-title-xl{
		    	margin-bottom: 50px;
		    }
		    p:not(:first-child){
		    	margin-bottom: 20px;
		    	padding-right: 0;
		    }
	    }

		a{
			color: $red;
		}

		ul{
			padding-left: 35px;
			margin-bottom: 50px;
			li{
				list-style-type: disc;
				line-height: 36px;
			}
		}
		#purposes-grid.privacy-grid,
		.privacy-grid {
			p {
				line-height: normal;
				margin-bottom: 15px;
			}
			ul {
				margin-bottom: 25px;

				li{
					line-height: normal;
				}
			}
			.privacy-row{
				display: grid;
				grid-template-columns: 1fr 3fr;

				@include phone{
					grid-template-columns: 120px 1fr;
				}

				.privacy-col-left,
				.privacy-col-right {
					padding: 15px;
				}	

				.privacy-col-left{
					border-left: 1px $gray solid;
					border-top: 1px $gray solid;
				}

				.privacy-col-right{
					border-right: 1px $gray solid;
					border-top: 1px $gray solid;
					border-left: 1px $gray solid;
				}
			}
			.privacy-row:last-child{
				.privacy-col-left,
				.privacy-col-right {
					border-bottom: 1px $gray solid;
				}	
			}
		}
		#purposes-grid.privacy-grid{
			.privacy-row{
				grid-template-columns: 1fr 1fr;

				.privacy-col-half:first-child{
					border-left: 1px $gray solid;
					border-top: 1px $gray solid;
					padding: 15px;
				}
				.privacy-col-half:last-child{
					border-left: 1px $gray solid;
					border-right: 1px $gray solid;
					border-top: 1px $gray solid;
					padding: 15px;
				}
			}
			.privacy-row:last-child{
				.privacy-col-half{
					border-bottom: 1px $gray solid;
				}
			}
		}
	}
	p.type-lg{
		margin-top: 30px;
	}

	address{
		padding-left: 50px;
		line-height: 24px;
	}
}