section#content #block-links {
    background-color: $lightest-gray;
    padding: 90px 0;
    .block-wrap{
        flex-wrap: wrap;
        @include flex-param(row, center, center);
        &:first-child{
            margin-bottom: 70px;
        }
    }


    .block.prequalify .block-link{
        @include background-image('/images/site/contact-us/thumb-001.jpg',cover,center);
    }

    .block.subcontractors .block-link{
        @include background-image('/images/site/contact-us/thumb-002.jpg',cover,center);
    }

    .block.land-to-sell .block-link{
        @include background-image('/images/site/contact-us/thumb-003.jpg',cover,center);
    }

    .block.warranty .block-link{
        @include background-image('/images/site/contact-us/thumb-004.jpg',cover,center);
    }

    .block.general-inquiries .block-link{
        @include background-image('/images/site/contact-us/thumb-005.jpg',cover,center);
    }

    @include tablet{
        padding: 40px 0;
        .block{
            padding: 30px;
        }
    }

    .block .block-link{
        color: white;
        text-align: center;
        position: relative;
        display: inline-block;
        line-height: 35px;
        cursor: pointer;
        margin-bottom: 28px;

        span{
            position: relative;
            &:last-child{
                opacity: 0;
                margin-bottom: -40px;
                @include transition(all .2s ease-out);
                @include flex-param(row, center, center);
                svg{
                    margin-right: 10px;
                    @include svgcolor(white);
                    @include size(20px);
                }
            }
        }

        &:before{
            opacity: 0;
            @include transition(all .3s ease-out);
            @include psuedo-block($red);
        }
        
        font-weight: 300;
        height: 260px;
        @include desktop{height: 210px;}
        @include tablet{
            height: 310px; 
            font-size: 25px;
        }
        @include phone{
            margin-bottom: 20px;
            font-size: 25px;
        }
        @include flex-param(column, center, center);

        &:hover{
            &:before{
                opacity: .8;
                @include transition(all .3s ease-in);
            }
            span:last-child{
                opacity: 1;
                margin-bottom: 0px;
                height: 40px;
                @include transition(
                    margin-bottom .2s ease-out,
                    opacity .3s ease-in .3s
                );
            }
        }
    }
}
