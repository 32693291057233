section#hours-and-directions {
	@extend %section-padding;
	@include inview(".type-title-xl", static, 0s);
	background-color: $lightest-gray;

	.type-title-xl{
		@extend %section-title-margin;
	}

	.driving-directions,
	.address{	
		p{
			line-height: 40px;
			text-align: left;
		}

		p:not(.sub-title){
			margin-bottom: 30px;
		}
	}

	.address{
		position: relative;
		background-color: white;
		margin-bottom: 25px;
		@include phone {
			flex-direction: column;
		}
		
		> .col-left{
			padding: 50px;
			flex: 1 0 0;
			p{
				font-size: 20px;
				text-align: left;
				@include tablet {
					font-size: 16px;
				}
			}
		}
		> .col-center{
			@include flex-param(column, space-between, center);
			padding: 25px;
			background-color: $blue;
			color: white;
			flex: 1 0 0;
			@include phone {
				display: block;
			}
			p{
				font-size: 20px;
				text-align: center;
				margin: 0;
				@include tablet {
					font-size: 16px;
				}
			}
			.flex-wrap:nth-of-type(1){
				flex-direction: column;
				width: 100%;
				padding: 0 20px;
				.dates{
					width: 100%;
					margin-bottom: 10px;
					font-weight: 300;
					@include flex-param(row, space-between, center);
				}
			}
			.flex-wrap:nth-of-type(2){
				width: 100%;
				justify-content: space-between;
				p{
					font-weight: 300;
					margin-bottom: 15px;
				}
			}
		}
		> .col-right{
			flex: 1.5;		
			position: relative;
			.mini-map,
			.mini-map .map{
				@include size(100%);
			}
			@include phone {
          flex: auto;
			    height: 70vw;
			    margin-top: 1px;
			}
		}
	}

	.driving-directions{
		background-color: white;
		padding: 50px;
		margin-top: 25px;
		@include phone {
			padding: 30px;
			margin-bottom: 80px;
		}
	}
}