#content.sp1-landing-page{
    section{
        padding: 64px 32px;
        width: 100%;
    }
    section > div{
        max-width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        gap: 16px;
    }
    section.contrast{
        background-color: #dfdfdf;
    }
    a.btn{
        border-radius: 7px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: .5px;
        padding: 10px 30px;
        line-height: 2rem;
    }
    section.hero{
        background-image: url('/images/site/sales-priority-one/tour1.jpg');
        background-size: cover;
        background-position: top center;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 300px;
        height: 67vh;
        max-height: 600px;
        padding-top: calc(var(--headerHeight) + 64px);
    }
    .hero::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        z-index: 0;
    }
    .hero h1.type-title-xxl{
        letter-spacing: 0;
        font-weight: 700;
        text-transform: uppercase;
        color: white;
        z-index: 1;
        text-align: center;
        font-size: 3rem;
    
    }
    .form-container{
        background-image: url('/images/site/sales-priority-one/form-image.jpg');
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    p.type-md,
    h2.type-lg,
    h3.type-title-lg{
        color: black;
    }
    p.type-md{
        line-height: normal;
    }
    h2.type-lg,
    h3.type-title-lg {
        text-transform: uppercase;
        letter-spacing: 0;
        text-align: center;
    }
    h3.type-title-lg{
        font-size: 2.1rem;
        font-weight: 400;
    }
    h2 .accent{
        font-weight: 700;
    }
    p.type-md small{
        font-size: .8rem;
        font-style: italic;
    }
    p.type-sm.next-tour,
    span.tour-time{
        color: #ba1f31;
    }
    .showcase-cards{
        display: flex;
        align-items: stretch;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 16px;
    }
    .showcase-cards .showcase-card{
        flex: 1;
        width: 300px;
        border: 2px solid #d4d4d4;
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        transition: border-color .2s ease-in-out;
    }
    
    .showcase-cards .showcase-card:hover,
    .showcase-cards .showcase-card:active,
    .showcase-cards .showcase-card:focus{
        border-color: #a6a6a6;
    }
    
    .showcase-cards .showcase-card .showcase-image{
        background-position: center;
        background-size: cover;
        height: 150px;
    }
    .showcase-cards .showcase-card .showcase-card-content{
        display: grid;
        grid-auto-flow: row dense;
        grid-auto-rows: auto;
        -ms-flex-line-pack: justify;
        align-content: space-between;
        gap: 15px;
        padding: 15px;
    }
    .showcase-cards .showcase-card .showcase-card-community{
        font-size: .7rem;
        line-height: 1.3;
        letter-spacing: .5px;
        text-transform: uppercase;
        color: black;
        font-weight: 300;
        text-align: start;
    }
    .showcase-cards .showcase-card .showcase-card-body{
        font-size: .9rem;
        line-height: 1.5;
        color: black;
        padding-left: 2px;
        padding-right: 2px;
    }
    .showcase-cards .showcase-card .showcase-link{
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
    }

    .showcase-community-name,
    .showcase-name{
        display: block;
    }

    .showcase-community-name{
        text-transform: capitalize;
    }
    
    h3.showcase-card-title{
        font-size: 1.3rem;
        font-weight: normal;
        text-transform: uppercase;
        line-height: 1.4rem;
        letter-spacing: normal;
        color: black;
        text-align: center;
        margin: 16px auto;
    }

    .showcase-card-body .showcase-name,
    .showcase-card-body .showcase-community-name{
        text-align: center;
        font-size: 1rem;
    }

    .showcase-directions-link{
        position: relative;
        z-index: 11;
        display: block;
        margin-top: 16px;
        text-align: center;
        text-decoration: underline;
    }

    .form-container > .form-wrapper{
        display: flex;
        flex-direction: row;
        gap: 0;
        align-items: stretch;
        justify-content: center;
    }
    .form-container > .form-wrapper .agent-img-wrapper{
        background-color: white;
        max-width: 300px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        gap: 16px;
    }
    .form-container > .form-wrapper .hs-form{
        background-color: black;
        padding: 24px;
    }
    .form-container .agent-img-wrapper .agent-img{
        width: 125px;
        border-radius: 65px;
    }
    .form-container .agent-img-wrapper h2{
        color: black;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 1.3rem;
    }
    .form-container .agent-img-wrapper p{
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: normal;
        line-height: 1.5rem;
        text-align: center;
    }
    .form-container .agent-img-wrapper .cta{
        font-weight: 400;
    }
    .hs-form:not(form){
        display: flex;
        align-items: center;
        justify-content: center;
    }
    input[type="submit"].hs-button{
        border-radius: 7px;
        font-size: .875rem;
    }
    .next-tour .tour-time{
        text-decoration: underline;
    }
    @media screen and (max-width: 768px){
        section{
            padding: 48px 24px;
        }
        .showcase-cards{
            flex-direction: column;
        }
        .form-container > .form-wrapper{
            flex-direction: column;
        }
        .form-container > .form-wrapper .agent-img-wrapper{
            max-width: 100%;
        }
    
        p.type-md{
            text-align: center;
        }
    }
}
