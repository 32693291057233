#general-contact{
	section#hero{
		@extend %theme-large-heading;
		@include background-image('/images/site/general-contact/hero-bkg.jpg', cover, center);
		color: white;
		.type-md{
			margin: 0;
			b{
				font-weight: 700;
				font-style: italic;
			}
		}
		@include phone{
			p{
				text-align: center;
			}
			.type-prequalify-lg{
			    font-size: 10vw;
			    line-height: 12vw;
			}
			p.type-md{
				padding: 0 3vw;
				max-width: 350px;
			}
		}
	}

	section#content{
		background-color: $lightest-gray;
		// padding: 90px 0 0 0;
		label{
			margin-bottom: 10px;
			@extend .type-xs;
		}
		input{
			border: none;
		}
		.col-right{
			padding-left: 50px;
			p.type-xs{
				line-height: 26px;
			}
			input.btn{
				float: right;
				margin-top: 40px;
			}
			custom-checkbox{
				padding-top: 8px;
			}
		}

        .form-component {
            margin-bottom: 50px;

            .help-block {
                bottom: -54px;
            }
        }

        .help-block.form-helper-text {
        	margin-top: 20px;
        }

		@include phone{
			padding: 0 0 90px;
			.col-right{
				padding-left: 15px;
			}

			.col-right input.btn{
			    margin-top: 40px;
			    float: none;
			    width: 150px;
			    padding: 15px;
			    font-size: 16px;
			    margin-left: calc(50% - 75px);
			}
		}
	}
	.form-container{
		width: 100%;
		margin: 0;
		background-color: #e0e0e0;
		position: relative;
		padding: 24px 48px;
	
		@include phone{
			padding: 24px;
		}
		.submitted-message{
			padding: 100px 0;
			text-align: center;
		}
	
		form{
			display: flex;
			flex-wrap: wrap;
	
			fieldset,
			.hs_submit{
				flex-basis: 100%;
				.hs-button{
					border-radius: 5px;
				}
			}
	
			textarea{
				height: auto;
				max-height: 2.625rem;
				overflow: hidden;
				resize: none;
				padding: 13px 15px 5px;
				white-space: nowrap;
	
				&::placeholder{
					color: black;
					font-weight: 300;
					letter-spacing: 1.5px;
					white-space: nowrap;
				}
			}
	
			input[type="checkbox"]{
				min-width: 25px;
			}
	
			@media screen and (min-width: 769px) and (max-width: 1024px){
				input::placeholder,
				textarea::placeholder,
				select{
					font-size: .75rem;
				}
	
				textarea{
					padding: 15px 15px 5px;
				}
			}
	
			@media screen and (max-width: 768px) {
				.input > input.hs-input {
					width: 100% !important;
				}
			}
		}
		.hs-form{
			width: 80%;
			max-width: 1200px;
			margin: 0 auto;

			fieldset.form-columns-1 .hs-input{
				width: 100%;
			}
	
			@media screen and (max-width: 768px){
				width: 100%;
			}
		}
		.sales-agent{
			height: 220px;
			width: 200px;
			position:absolute;
			bottom: 0;
			right: 0;
			&:not(.show-agent){
				display: none;
			}
			@media screen and (min-width: 301px) and (max-width: 425px){
				background-size: contain;
				background-repeat: no-repeat;
				width: 180px;
				height: 180px;
			}
			@media screen and (max-width: 300px){
				display: none;
			}
		}
		h3{
			font-size: 1.5rem;
			font-weight: 400;
			text-align: center;
			margin-bottom: 10px;
			color: black;
	
			a{
				font-size: 1.5rem;
				color: #ba1f31;
				font-weight: 700;
				font-style: italic;
			}
		}
				
		@media screen and (min-width: 451px) and (max-width: 768px){
			padding-top: 32px;
			padding-bottom: 48px;
		}
	
		@media screen and (max-width: 450px){
			padding-top: 32px;
			padding-bottom: 32px;
			padding-left: 24px;
			padding-right: 24px;
			width: 100%;
		}
	
		&.show-agent .hs-form form{
			@media screen and (max-width: 768px) {
				.hs_opt_in{
					width: calc(100% - 125px);
					padding-top: 32px;
	
					.input{
						margin: 0 15px;
					}
				}
				.hs_submit .actions{
					width: calc(100% - 125px);
				}
			}
			@media screen and (max-width: 425px){
				.hs_opt_in{
					width: calc(100% - 150px);
					padding-top: 15px;
	
				}
			}
			@media screen and (max-width: 300px){
				.sales-agent{
					display: none;
				}
				.hs_opt_in{
					width: 100%;
				}
				.hs_submit .actions{
					width: 100%;
				}
			}
		}
	}
}