section#our-partners {
	@extend %section-padding;
	@include inview(".type-title-xl", static, 0s);
	@include inview("img", bottom, .3s);
	.type-title-xl{
		@extend %section-title-margin;
	}
	img{
		width: 100%;
		max-width: 1100px;
	}

}