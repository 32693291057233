#sales-center {
	section#hero {
	  @extend %theme-large-heading;
    @include background-image('/images/site/sales-center/hero-bkg.jpg', cover, center);
    color: white;
    display: block;
    padding-top: 0;

		> .container-fluid > .row {
		  > div[class^="col-"] {
		    height: 100vh;
  		  &:first-child {
  		    > div {
            height: calc(100vh - 30px);
            padding-top: 51px;
  		      padding-left: 36px;
  		      padding-right: 36px;

  		      @include tablet {
  		        padding-left: 0;
              padding-right: 0;
  		      }

            .logo > img {
              width: 180px;
            }
            .model-home-name {
              color: white;
              font-size: 36px;
              font-weight: 600;
              line-height: 45px;
              margin-top: 30px;
              margin-bottom: 60px;
            }
  		      h1, p, li {
  		        @include text-shadow(1.5px 1.5px 7.5px rgba(black,.75));
  		      }
    		    h1, p {
    		      margin-bottom: 30px;
    		    }
    		    h1 {
              font-weight: 600;
    		      @include tablet {
    		        font-size: 36px;
    		      }
    		    }
    		    p, li {
    		      font-size: 27px;
              font-weight: 400;

    		      @include tablet {
                font-size: 21px;
              }
    		    }
    		    .list-unstyled {
    		      margin-bottom: 30px;
    		      li {
    		        background-image: url('/images/site/sales-center/icon-checkbox.png');
    		        background-repeat: no-repeat;
    		        background-position: left center;
                font-weight: 400;
                line-height: 52.5px;
                padding-left: 45px;
              }
    		    }
    		    i {
    		      font-style: italic;
    		    }
  		    }
  		    .list-inline {
              li {
                @extend .type-xxs;
                &:first-child {
                  font-size: 3px;
                  text-align: center;
                  vertical-align: middle;
                }
              }
            }
            .equal-housing {
              display: inline-block;
              margin-bottom: 3px;
        
              svg {
                height: 20px;
                margin-right: 2px;
                top: 2px;
                position: relative;
              }
            }
  		  }
  		  &:last-child {
  		    background-color: rgba($light-gray, .8);
  		    .form-wrap {
            padding: 48px 12px;

  		      @include tablet {
  		        padding: 30px 15px;
  		      }

            .form-components {
              > .row:nth-child(3) > div[class^="col-"] {
                &:first-child {
                  padding-right: 5px;
                }
                &:last-child {
                  padding-left: 5px;
                }
              }
            }
  		      .form-component {
  		        height: auto;
  		        margin-bottom: 10px;

              .help-block {
                display: none;
              }
            }
            label {
              font-size: 13.5px;
              font-weight: 600;
              line-height: 21px;
              margin-bottom: 5px;

              @include tablet {
                font-size: 12px;
              }
            }
            .form-control {
              font-size: 10.5px;
            }
            .list-inline {
              li {
                font-size: 13.5px;
                font-weight: 600;

                @include tablet {
                  font-size: 12px;
                }

                .form-component {
                  display: inline-block;
                  margin-bottom: 0;
                  margin-left: 7.5px;
                  width: 210px;

                  input.form-control {
                    font-weight: 300;
                  }
                }
              }
            }
            .row {
              margin-top: 12px;
            }
            .icon {
              position: absolute;
              top: 21px;
              left: 48px;

              @include tablet {
                left: 33px;
              }

              svg path {
                fill: white;
              }
            }
            .btn.btn-blue {
              padding-left: 45px;
              width: 100%;
            }
            p {
              margin-top: 7.5px;
              margin-bottom: 0;
              a {
                color: white;
                font-weight: 600;
                line-height: 18px;

                @include tablet {
                  font-size: 12px;
                }
              }
            }
            h5 {
              font-size: 30px;
              line-height: 45px;
            }
  		    }
  		  }
  		}
		}
	}
}