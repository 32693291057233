section#floorplans {
  @extend %section-padding;
  @extend %microsite-title;
  @include inview(".type-subtitle-xl", static, 0s);
  @include inview(".filter", bottom, .6s);
  @include inview(".sort", bottom, .8s);
  @include inview(".list", bottom, 1s);

  .list {
    clear: both;
  }

  .no-results {
    color: $red;
    font-size: 25px;
    text-align: center;
    margin: 50px 30px;
  }

  .btn-primary.btn-dropdown.dropdown-toggle{
    span{
      background-color: $light-gray;

    
    }
  }
  .btn-primary.btn-dropdown.dropdown-toggle:hover,
  .btn-primary.btn-dropdown.dropdown-toggle:focus,
  .btn-primary.btn-dropdown.dropdown-toggle:active{
    span{
      background-color: $red;
    }
  }

  .btn-filter-home-designs {
    background-color: $light-gray;
    border-top-color: $light-gray;
    border-bottom-color: $light-gray;
    border-left-color: white;
    border-right-color: white;
    border-radius: 0;
    color: white;
    font-size: 18px;
    margin-bottom: 20px;
    padding: 11.25px 30px;
    text-align: left;
    width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: none;

    &.active {
      background-color: white;
      color: $light-gray;
    }

    .fa {
      float: right;
      font-size: 24px;
    }
  }

  .sort-by-list {
    display: flex;
    margin-bottom: 7.5px;
    @include desktop-hd {
      justify-content: flex-end;
    }
    @include desktop {
      justify-content: flex-end;
    }
    @include tablet {
      justify-content: flex-end;
    }
    @include phone {
      justify-content: space-between;
    }
    li {
      cursor: pointer;
      @include desktop-hd {
        padding-left: 15px;
        padding-right: 15px;
      }
      @include desktop {
        padding-left: 15px;
        padding-right: 15px;
      }
      @include tablet {
        padding-left: 15px;
        padding-right: 15px;
      }
      &.active {
        span {
          .fa.active {
            color: $red;
          }
        }
      }
      span {
        display: flex;
        flex-direction: column;
        float: right;
        margin-left: 7.5px;
        .fa {
          color: $gray;
          font-size: 15px;
          line-height: 9px;
        }
      }
    }
  }

  .pagination {
    float: right;
    margin: 40px 0px;
    > li {
      &:first-child {
        > a, span {
          border-radius: 0px;
        }
      }

      &.active {
        > a, span, a:hover, span:hover, a:focus, span:focus {
          z-index: 3;
          color: #fff;
          cursor: default;
          background-color: $blue;
          border-color: $blue;
        }
      }
      > a, span {
        padding: 10px 15px;
      }
    }
  }

  .inline-get-fast-answers{
    margin-top: 17px;
    margin-bottom: 17px;
    clear: both;
    @include phone{
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
