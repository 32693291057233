section#contact {
  @extend %section-padding;
  padding-bottom: 0px;
  @extend %microsite-title;
  // background-color: $lightest-gray;
  // @include inview(".type-subtitle-xl", static, 0s);
  // @include inview(".contact-info", bottom, .6s);
  // @include inview(".inline-get-fast-answers", static, .8s);
  // @include inview(".driving-directions", bottom, 1s);

  a.email{
    cursor: pointer;
  }

  > .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    justify-items: center;
    margin-bottom: 50px;
  }

  .schedule-form-container{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    flex-direction: column;
    padding: 65px 0;
    min-height: 250px;

    @media screen and (max-width: 768px){
      padding: 40px 0;
    }
    position: relative;

    h3.type-xl{
      text-transform: uppercase;
      color: white;
      font-size: 1.75rem;
      letter-spacing: 2px;
      margin: 10px 0;

      @include phone{
        text-align: center;
        margin: 10px 15px;
        font-size: 1.5rem;
      }
    }

    a.type-lg{
      color: white;
      font-size: 1.4rem;
      letter-spacing: 1px;  
      font-style: italic;
      text-transform: uppercase;
      font-weight: 700;
      @include phone{
        text-align: center;
        margin: 10px 15px;
        font-size: 1.25rem;
      }
    }

    .sales-agent{
      width: 225px;
      height: 250px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: cover;

      @include phone{
        display: none;
      }

    }
    hubspot-form{
      width: calc(100% - 500px);
      @include phone{
        width: 80%;

        select,
        input,
        .input{
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }

  .disclaimer {
    padding-top: 60px;
    max-width: 1170px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.66667;
    background-color: transparent;
    ul, ol, li, p {
      &:empty {
        display: none;
      }
    }
    ul, ol, p {
      margin-bottom: 1em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul, ol {
      padding-left: 1.5em;
    }
    ul {
      list-style: disc;
    }
    ol {
      list-style: decimal;
    }
    p, span, a, li {
      font-family: inherit !important;
      font-size: inherit !important;
      font-weight: inherit;
      line-height: inherit !important;
    }
    li {
      margin-bottom: 0.25em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    em, strong, b {
      font-weight: 600 !important;
    }
    i, em {
      font-style: italic !important;
    }
    @include tablet {
      width: 750px;
    }
    @include desktop {
      width: 970px;
    }
  }

  .directions {
    border-left: 1px black solid;
    width: 66.66666667%;
    height: 100%;
    @include desktop-hd {
      @include flex-param(row, space-between, center);
    }
    @include desktop {
      @include flex-param(row, space-between, center);
    }
    @include tablet {
      @include flex-param(row, space-between, center);
    }
    @include phone {
      @include flex-param(column, space-between, center);
      border: none;
      width: 100%;
    }
    .btn-view-on-map {
      font-weight: 400;
      display: block;
    }
    .driving-directions,
    #directions-form {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 3em;
      @include phone {
        padding: .75em 1.5em;
      }
      p {
        line-height: 2.2rem;
      }
    }

    .driving-directions {
      height: 100%;
      color: black;
      .type-subtitle-md {
        color: black;
        font-size: 24px;
        @include phone {
          font-size: 18px;
        }
      }
    }

    #directions-form {
      background: #dedede;

      .directions-form {
        width: 100%;
      }

      .directions-form__title {
        color: #00a5b8;
        font-size: 2em;
        text-transform: uppercase;
        font-weight: 100;
      }

      input {
        flex-grow: 1;
        display: block;
        margin: 1em 0;
        font-size: 1.1em;
      }
      a {
        font-size: 1.1em;
      }
    }
  }
  .inline-exposed-schedule-my-tour {
    margin-bottom: 0;
    background-color: black;
    flex-direction: column;

    > .copy{
      text-align: center;
    }

    > .copy > .type-lg{
      &::after{
        content: " SCHEDULE A TOUR";
      }
    }
  }
  .form-exposed-schedule-my-tour-wrap{
    background-color: black;
  }
  .inline-get-fast-answers {

    img {
      @include phone {
        display: none;
      }
    }
  }

  .type-subtitle-xl,
  .microsite-title {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 0.4em;
  }

  .contact-map {
    @include flex-param($justify: space-between);
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    @include phone {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
    }
    .contact {
      @include flex-param($dir: column, $align: flex-start);
      padding: 0 1.5em;
      justify-content: flex-start;
      gap: 30px;
      width: 33.33333333%;
      height: 100%;
      @include tablet {
        width: 41.66666667%;
      }
      @include phone {
        padding: .75em 1.5em;
        width: 100%;
        height: auto;
      }
    }
  }

  .model-home,
  .sales-contact,
  mini-map {
    p {
      color: black;
      line-height: 2.2rem;
    }
    a {
      color: $red;
      &:hover {
        text-decoration: underline;
        svg {
          path {
            fill: $red;
          }
        }
      }
    }
  }

  .model-home,
  .sales-contact {
    min-width: 300px;
    @include tablet {
      min-width: 270px;
    }
  }

  mini-map {
    padding: 0;
    @include phone {
      order: 10;
    }
  }

  .phone,
  .email {
    display: inline-block;
    svg {
      float: left;
      margin-right: 0.5em;
      margin-top: 0.1em;
      path {
        fill: $red;
      }
    }
  }
}
