@function calc-min($num){
	@return calc(100% - #{$num}px);
}

@function calc-max($num){
	@return calc(100% + #{$num}px);
}

@function splitwidth($num,$offset:0){
	$width: 100% / $num;
	@return calc(#{$width} - #{$offset}px);
}