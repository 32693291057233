#lifestyle {
	
	p.type-md,
	h1.type-md,
	h2.type-md {
		line-height: 52px;
		@Include desktop{
			line-height: 45px;
		}
		@include tablet{
			line-height: 40px;
		}
		@include phone{
			line-height: 35px;
		}
	}

	p.type-subtitle-lg,
	h1.type-subtitle-lg,
	h2.type-subtitle-lg {
		margin-bottom: 25px;
		@include phone{
			margin-bottom: 15px;
		}
	}

	p.type-subtitle-xl,
	h1.type-subtitle-xl,
	h2.type-subtitle-xl {
		font-weight: 300;
		letter-spacing: 2px;
		margin-bottom: 75px;
		@include tablet{
			margin-bottom: 45px;
		}
		@include phone{
			font-size: 35px;
			margin-bottom: 30px;
		}
	}

	#hero-carousel{
		.carousel{
			min-height: 500px;
		}
		.flex-caption img{
			@include phone{
				width: 65%;
				margin-bottom: 50px;
			}
		} 
	}

	#overview {
		a:not(.btn){
			color: $red;
		}
		padding: 90px 0;
		.row {
			margin-bottom: 140px;
			@include inview("p", left, 0s);
			@include inview("img", static, 0s);
			p,img{
				@include transition(all .2s ease-out);
			}
		}

		.row:nth-child(1) .col-left{
			padding: 40px 140px 40px 0;
		}

		.row:nth-child(2) .col-right{
			padding-top: 40px;
		}

		.row:nth-child(3) {
			@include flex-param(row, center, flex-start);
			@include phone {
        margin-bottom: 0;
      }
			img {
				width: 100%;
			}
			p.type-subtitle-xl{
				margin-bottom: 0;
			}
			p.type-md {
        padding: 0 90px 30px 0;
      }

		}

		@include desktop{
			.row img{
				width: 100%;
			}
			.row{
				margin-bottom: 100px;
			}
			.row:nth-child(1) .col-left{
				padding: 40px 100px 40px 0;
			}
			.row:nth-child(3) p.type-md {
				padding: 0 60px 30px 0;
			}
		}

		@include tablet{
			padding: 45px 0;

			.row img{
				width: 100%;
			}
			.row{
				margin-bottom: 40px;
			}
			.row:nth-child(1) .col-left{
				padding: 40px 40px 40px 20px;
			}
			.row:nth-child(3) p.type-md {
				padding: 0 60px 30px 0;
			}
		}

		@include phone{
			margin-bottom: 0px;
			padding: 30px 15px;
			.row:nth-child(1) .col-left{
				padding: 0 15px;
				margin-bottom: 15px;
			}
			.row:nth-child(3) {
			  flex-direction: column;
			  p.type-md {
			    padding-top: 0;
			    padding-right: 0;
        }
			}
			.row.in img{
				width: 100%;
			}
			.row{
				margin-bottom: 35px;
			}
		}

		.btn-click-to-take-a-360-tour {
      margin-top: 30px;
      .fa {
        margin-left: 15px;
        margin-right: 0;
      }
    }
	}

	#the-design-process{

		.col-left{
			background-color: $lightest-gray;
			.top{
				overflow: hidden;
				padding-top: 150px;
				.nav.nav-tabs{
					justify-content: flex-start;
					li a{
						padding: 15px 20px;
						height: auto;
					}
					li a:after{
						display: none;
					}
					li.active a,
					li a:hover{
						color: white;
						background-color: $red;
						@include dropshadow(0,0,0,0,0);
					}
				}
			}
			.divider{
				@include dropshadow(0px,-10px,15px,0,0.1);
				height: 95px;
			}
			.bottom{
				padding-bottom: 125px;
				> div{
					opacity: 1;
					@include transition(all .3s ease-out);
					&.fade{
						opacity: 0;
						@include transition(all .3s ease-in);
					}
				}
				p.type-xl{
					margin-bottom: 20px;
				}
			}
		}

		.col-right{
			background-size: cover;
			background-color: white;
			position: absolute;
			top: 0;
			right: 0;
			@include size(calc(50% - 100px),100%);
		}
		
		.col-right .fill{
			@extend .col-right;
			opacity: 0;
			z-index: 0;
    		@include size(100%,1000px);
    		@include transition(all .6s ease-out);
			&.active{
				opacity: 1;
				z-index: 1;
				@include transition(all .6s ease-in);
			}
		}

		@include desktop{
			.col-left .top{
				padding-top: 100px;
			}
			.col-left .divider{
				height: 75px;
			}
			.col-left .bottom{
				padding-bottom: 90px;
			}
			.col-right{
				@include size(calc(50% - 80px),100%);
			}
			
			.col-right .fill{
	    		@include size(100%,850px);
			}
		}

		@include tablet{
			.col-left .top .nav.nav-tabs li a{
				padding: 10px 15px;
				font-size: 14px;
			}
			.col-left .top{
				padding-top: 50px;
			}
			.col-left .divider{
				height: 45px;
			}
			.col-left .bottom{
				padding-bottom: 60px;
			}
			.col-right{
				@include size(calc(50% - 40px),100%);
			}
			
			.col-right .fill{
	    		@include size(100%,650px);
			}
		}

		@include phone{
			.col-left .top{
				padding-top: 30px;
			}
			.col-left .top .nav.nav-tabs{
				flex-direction: row;
			}
			.col-left .top .nav.nav-tabs li{
				height: auto;
			}
			.col-left .top .nav.nav-tabs li a{
				padding: 5px 10px;
				font-size: 12px;
			}
			.col-left .bottom{
				padding-top: 30px;
				padding-bottom: 30px;
			}
			.col-left .bottom p.type-xl{
				font-size: 18px;
			}
			.col-right,
			.divider{
				display: none;
			}
		}
	}

	#visit-a-design-center{
		padding: 95px 0;

		a.accordion-toggle:hover{
			color: $red;
		}
		@include phone{
			padding: 30px 15px;
		}
		.panel {
		  background-color: transparent;
		  -webkit-box-shadow: none;
      box-shadow: none;
		}
		.panel-heading {
		  background-color: #fff;
		  padding-top: 15px;
		  padding-bottom: 15px;
		}
		.panel-title > a {
		  font-size: 30px;
		  @include phone {
		    font-size: 15px;
		  }
		  .fa {
		    color: $red;
		    font-size: 60px;
		    margin-top: -15px;
		    @include phone {
		      font-size: 30px;
		      margin-top: -6px;
		    }
		  }
		}
		.panel-body {
		  border-top-color: transparent;
		  padding: 30px 0;
		}
		svg{
			@include size(30px);
			@include svgcolor($red);
			&.icon-phone{
				@include size(20px);
			}

		}
		hr{
			border-color: $red;
		}
		.title{
			min-height: 65px;
			padding-bottom: 5px;
			@include item-count('*',2){
				margin: 0;
				line-height: 30px;
			}
			p,h1{
				font-weight: 300;
			}
		}
		h2.sub-title,
		p.sub-title{
			font-weight: 600;
		}
		p.sub-title{
			line-height: 25px;
			margin-bottom: 20px;
		}
		.map{
			margin-bottom: 35px;
			border: 1px solid $light-gray;
			position: relative;
			@include background-image('/images/site/global/map-template.jpg', cover, center);
			@include size(100%, 390px);
			@include flex-param(row, center, center);
			a{
				display: inline-block;
				margin-left: -131.5px;
				position: absolute;
				bottom: -17px;
				left: 50%;
				background-color:$red;
				color: white;
				padding: 10px 15px;
				@extend .type-xs;
			}
		}
		.info{
			line-height: 30px;
			margin-bottom: 20px;
			p:not(:last-child){
				margin-bottom: 20px;
			}
		}

		p.type-sm.red{
			@include flex-param(row, flex-start, center);
			font-weight: 600;
			margin-bottom: 20px;
			svg{
				margin-right: 10px;
			}
		}

		a.phone{
			@extend p.type-sm.red;
			margin-bottom: 0;
			color: $gray;
		}

		@include tablet{
			> .container > .row{
			    display: flex;
			    flex-wrap: wrap;
			}
			> .container > .row > div{
				margin-bottom: 30px;
			}
		}
	}

	#our-team{
		.fill{
			position: absolute;
			top: 0;
			left: 0;
			@include background-image('/images/site/lifestyle/team-img.jpg', cover, center);
			@include size(calc(50% - 250px), 100%);
			@include tablet{
				@include size(calc(50% - 150px), 100%);
			}
		}
		.row{
			padding: 125px 0;
			.info p:first-child{
				margin-bottom: 20px;
			}
			@include phone{
				padding: 30px 15px;
			}
		}
	}

	#inline-form.inline-stay-updated{
		@include phone{
			padding-bottom: 80px;
		}
	}

	.form-container{
		width: 100%;
		margin: 0;
		background-color: #e0e0e0;
		position: relative;
		padding: 24px 48px;

		.submitted-message{
			padding: 100px 0;
			text-align: center;
		}

		form{
			display: flex;
			flex-wrap: wrap;

			fieldset,
			.hs_submit{
				flex-basis: 100%;
				.hs-button{
					border-radius: 5px;
				}
			}

			textarea{
				height: auto;
				max-height: 2.625rem;
				overflow: hidden;
				resize: none;
				padding: 13px 15px 5px;
				white-space: nowrap;

				&::placeholder{
					color: black;
					font-weight: 300;
					letter-spacing: 1.5px;
					white-space: nowrap;
				}
			}

			input[type="checkbox"]{
				min-width: 25px;
			}

			@media screen and (min-width: 769px) and (max-width: 1024px){
				input::placeholder,
				textarea::placeholder,
				select{
					font-size: .75rem;
				}

				textarea{
					padding: 15px 15px 5px;
				}
			}

			@media screen and (max-width: 768px) {
				.input > input.hs-input {
					width: 100% !important;
				}
			}
		}
		.hs-form{
			width: 80%;
			max-width: 1200px;
			margin: 0 auto;

			@media screen and (max-width: 768px){
				width: 100%;
			}
		}
		.sales-agent{
			height: 220px;
			width: 200px;
			position:absolute;
			bottom: 0;
			right: 0;
			&:not(.show-agent){
				display: none;
			}
			@media screen and(min-width: 301px) and (max-width: 450px){
				background-size: contain;
				background-repeat: no-repeat;
				width: 180px;
				height: 180px;
			}
			@media screen and (max-width: 300px){
				display: none;
			}
		}
		h3{
			font-size: 1.75rem;
    		font-weight: 600;
    		text-align: center;
    		margin-bottom: 10px;
			color: black;

			a{
				font-size: 1.5rem;
				color: #ba1f31;
				font-weight: 700;
				font-style: italic;
				display: block;
			}
		}
		        
        @media screen and (min-width: 451px) and (max-width: 768px){
            padding-top: 32px;
            padding-bottom: 48px;
        }

        @media screen and (max-width: 450px){
            padding-top: 32px;
            padding-bottom: 32px;
			padding-left: 24px;
			padding-right: 24px;
        }

		&.show-agent .hs-form form{
			@media screen and (max-width: 768px) {
				.hs_opt_in{
					width: calc(100% - 125px);
					padding-top: 32px;
	
					.input{
						margin: 0 15px;
					}
				}
				.hs_submit .actions{
					width: calc(100% - 125px);
				}
			}
			@media screen and (max-width: 425px){
				.hs_opt_in{
					width: calc(100% - 150px);
					padding-top: 15px;
	
				}
			}
			@media screen and (max-width: 300px){
				.hs_opt_in{
					width: 100%;
				}
				.hs_submit .actions{
					width: 100%;
				}
			}
		}
	}
}