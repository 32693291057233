#discover-the-difference{
	#hero{
	    background: $lightest-gray;
	    padding: 200px 0 100px;
	    text-align: center;
	    > h1, > h2{
			margin-bottom: 45px;
	    }
	    > h1{
	    	font-weight: 300;
	    }
	    @include phone{
	    	padding-top: calc(40px + var(--headerHeight));
	    	h2{
	    		margin-bottom: 0;
	    	}
	    }

	}

	#slick-slideshow{
		position: relative;
		i{
			position: absolute;
			top: 305px;
			color: black;
			font-size: 130px;
			z-index: 5;
			@include hoverstate(transparent,$red,.2s);
			&.fa-angle-left{
				left: 40px;
			}
			&.fa-angle-right{
				right: 40px;
			}
			@include tablet{
				top: 40%;
			}
			@include tablet{
				&.fa-angle-left{
					left: 20px;
				}
				&.fa-angle-right{
					right: 20px;
				}
			}
		}
		.item{
		  &:first-child {
		    .bottom {
		      position: relative;
		      &:before {
		        border-top: 5px solid black;
		        content: '';
		        position: absolute;
		        width: 36vw;
		        top: -5px;
		        left: -36vw;
          }
        }
      }
		  &:last-child {
        .bottom {
          position: relative;
          &:after {
            border-top: 5px solid black;
            content: '';
            position: absolute;
            width: 36vw;
            top: -5px;
            right: -36vw;
          }
        }
      }
			&.start,
			&.end{
				border-bottom: 5px solid black;
				@include tablet{
					height: 14vh;
				}
			}
			&.start{
				@include phone{
					display: none;
				}
			}
			.top,
			.top .gray{
				background-size: 100%;
				background-repeat: no-repeat;
				background-position: bottom;
			}
			.top{			
				position: relative;
				@include tablet{
					height: 14vh;
				}

				&:after{
					opacity: 0.8;
					@include psuedo-block($lightest-gray);
					@include transition(all .5s ease-out);
				}
				.gray{
					position: absolute;
					top: 0;
					left: 0;
					opacity: 1;
					@include size(100%);
					@include transition(all .5s ease-out);
				}
			}
			.bottom{
				border-top: 5px solid black;
				position: relative;
				@include flex-param(column, center, center);
				.year{
					color: black;
					position: relative;
					margin-top: -37.5px;
					margin-bottom: 55px;
					@extend .type-xs;
					@include circle(75px, black);
					@include transition(all .5s ease-out);
					@include flex-param(column, center, center);
					&:before{
						content: "";
						position: absolute;
						top: 5px;
						left: 5px;
						border: 5px solid white;
						@include transition(all .5s ease-out);
						@include circle(65px, white);
					}
					span{
						position: relative;
						z-index: 1;
					}
				}
				p{
					width: 85%;
					line-height: 42px;
					opacity: 0;
					@include transition(all .2s ease-out);
					@include desktop{
						width: 160%;
					}
					@include tablet{
						width: 160%;
					}
				}

			}

			&.active-slide{
				.top:after,
				.top .gray{
					opacity: 0;
					@include transition(all .5s ease-in);
				}

				.bottom .year{
					color: white;
					@include transition(all .5s ease-in);
				}
				.bottom .year:before{
					background-color: $red;
					@include transition(all .5s ease-in);
				}
				.bottom p{
					opacity: 1;
					@include transition(all .5s ease-in .3s);
				}
			}
		}
	}
}