
.accordian-thumbnail {

	$width: 300px;
	$height: 200px;
	cursor: pointer;

	%psuedo {
		content: "";
		position: absolute;
		height: 100%;
		top: 0;
	}

	@mixin col($id, $left, $left2, $rotate) {
		> div:nth-child(#{$id}) {
			position: absolute;
			top: 0;
			left: $left;
			z-index: 1;
			overflow: hidden;
			@include size(20%, 100%);
			@include transition(all .15s ease-out);

			.col {
				left: -100% * $id;
				width: $width;
				background-size: $width $height;
				background-position: center;
				@extend %psuedo;
				@include transition(all .15s ease-out);
				@include tablet{
					background-size: $width ($height * 0.75);
				}
				@include phone{
					width: 90vw;
					background-size: auto 100%;
				}
			}

			&:after {
				left: 0;
				width: 100%;
				background-color: black;
				opacity: 0;
				@extend %psuedo;
				@include transition(all .15s ease-out);
			}

		}

		&:hover > div:nth-child(#{$id}),
		&.active > div:nth-child(#{$id}),
		&.debug > div:nth-child(#{$id}) {
			
			left: $left2;
			@include transition(all .15s ease-in);

			@if($rotate == left) {
				transform-origin: left;
				transform: rotateY(-45deg);
			}@elseif($rotate == right) {
				transform-origin: right;
				transform: rotateY(45deg);
				&:after {
					opacity: 0.6;
					@include transition(all .15s ease-in);
				}

			}

		}

	}

	position: relative;
	perspective: 500px;
	border: 10px solid white;
	background-size: $width $height;
	background-position: left center;

	@include tablet{
		height: $height * 0.75;
		background-size: $width ($height * 0.75);
	}

	@include phone{
		height: 65vw;
		background-size: cover;
	}

	@include dropshadow(0px,5px,10px,0,0.2);
	@include size($width, $height);
	@include col(1, 20%, 20%, 'left');
	@include col(2, 40%, 28%, 'right');
	@include col(3, 60%, 47%, 'left');
	@include col(4, 80%, 55%, 'right');

	> div.last {
		@include size(25.5%, 100%);
		background-color: $gray;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 0;
		padding: 13px 0;
		@include phone{
			padding: 15px 0;
		}
		@include flex-param(column, space-between, center);
		> div{
			@include flex-param(column, space-around, center);
		}
		svg{
			cursor: pointer;
			@include svghover(white, $blue, .2s);
			@include size(20px);
			&.icon-pinterest{
				@include size(40px);
			}
			@include phone{
				@include size(7vw);
			}
		}
		.top a:last-child{
			margin-top: 8px;
		}
	}

}