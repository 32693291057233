hubspot-form{
    fieldset{
        max-width: 100% !important;
    }
    textarea{
      width: 100% !important;
      resize: none;
      border: none;
      border-radius: 0;
      height: 150px;
      margin: 10px 0;
      padding: 10px;
    }
    select{
      width: 100% !important;
      line-height: 2.8rem;
      text-transform: uppercase;
      font-weight: 300;
      background-color: white;
      border: none;
      padding: 0 15px;
      letter-spacing: 1.5px;
      color:black;
    }
    input,
    textarea,
    select{
      border-radius: 0px;
    }
    .hs-fieldtype-select .input{
        position: relative;
        margin-right: 0px !important;
    }
    .hs-fieldtype-select .input::after{
      content: "";
      position: absolute;
      top: calc(50% - 6px);
      right: 15px;
      display: block;
      width: 10px;
      height: 10px;
      border-left: 1px solid black;
      border-bottom: 1px solid black;
      transition: transform .2s ease-in-out, top .2s ease-in-out;
      transform: rotate(-45deg);
      margin-left: 5px;
    }

    .hs_pick_a_date{
      legend{
        color: white;
        font-family: 'Open Sans', 'Arial', sans-serif;
        letter-spacing: 1px;
        font-weight: 300px;
        text-transform: uppercase;
        margin-left: 15px;

        @include phone{
          margin-left: 0;
          text-align: center;
        }
      }
    }
  .hs-error-msgs{
    margin-top: 0 !important;
    label{
      color: red;
      font-size: small;
      display: inline-block;
      margin: 0 auto;
    }
  }
  .hs-form-booleancheckbox-display{
    margin-bottom: 15px;
  }  

  .hs-form-booleancheckbox-display{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    span{
      text-align: left;
    }
    input[type="checkbox"]{
      accent-color: $red;
      display: inline-block;
      width: fit-content;
      margin: 0 10px 0 0 !important;
      width: 20px !important;
  }
  }
  
  input{
    width: 100%;
    color: black;
    border: none;
    margin: 10px auto;
    padding: 5px 15px;
    line-height: 2rem;
    &::placeholder{
      color: black;
      font-weight:300;
      letter-spacing: 1.5px;
    }
  }

  .input{
    margin-right: 0px !important;
  }

  .form-columns-2,
  .form-columns-3{
    display: flex;
    gap: 15px;
    flex-flow: row nowrap;

    @include phone{
      flex-flow: column nowrap;
      gap: 0px;

      .field{
        width: 100% !important;
      }
    }
  }

  .inputs-list,
  .hs-richtext{
    display: block;
    text-align: center;

    a{
      color: #000000;
      font-weight: 700;
      text-decoration: none;
    }
  }
  
  .inputs-list{
    margin: 10px auto 0px;

    span{
      font-size: .85rem;
    }
  }



input[type="submit"]{
  background-color: $red;
  padding: 10px 30px;
  letter-spacing: 1.5px;
  width: fit-content;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  border:none;
  display: block;
  margin: 15px auto;
}
  .hs-form{
    select{
      margin: 10px 0;
      padding: 5px 15px;
      line-height: 2rem;
      width: 100% !important;
    }
  }
  .hs-richtext{
    margin: 10px auto;
    font-size: 10px;
  }
}
.modal-header.hubspot-form,
.modal-header.hubspot-form > button{
	background-color: $lighter-gray !important;
}
.modal-footer.hubspot-form{
	display: none !important;
}
#modal-body.hubspot-form{
	padding: 0;

	.form-container{
		background-color: $lighter-gray;
		color: black;
		padding: 40px 25px;

		.submitted-message{
			p{
				text-align: center;
			}
		}

		.form-header{
			font-size: 1.375rem;
			letter-spacing: 1px;
		}

		.form-instructions{
			margin-bottom: 15px;
		}

		.form-header,
		.form-instructions{
			text-align: center;
			line-height: normal;
		}


		form{
			max-width: 100%;

			ul > li{
				line-height: normal;
				&::after{
				display: none;
				}
			}

			input,
			select{
				width: 100%;
				color: black;
				border: none;
				margin: 10px auto;
				padding: 5px 15px;
				line-height: 2rem;
				&::placeholder{
				color: black;
				font-weight:300;
				letter-spacing: 1.5px;
				}
			}

			.inputs-list{
				margin-top: 10px;
				margin: 10px auto 0px;

				span{
				font-size: .85rem;
				}
			}
			
			input[type="submit"]{
			background-color: $red;
			padding: 10px 30px;
			letter-spacing: 1.5px;
			width: fit-content;
			color: white;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 1rem;
			border:none;
			display: block;
			margin: 15px auto;
			}
		}

    .submitted-message{
      margin: 15px 0;
      font-size: 1.3rem;
      display: block;
      text-align: center;
      p{
        font-size: 1.3rem;
        display: block;
        text-align: center;
      }
    }

		.inline-get-fast-answers{
			height: fit-content;
			background-color: $lighter-gray;

			.image{
			display: none;
			}

			.copy{
			display: none;
			}

			.flex-wrap form{
			flex-direction: column;
			padding-bottom: 0;

			.input-group{
				width: 100%;

				input{
				border-radius: 0 !important;
				border: none;
				&::placeholder{
					color: black;
					text-transform: uppercase;
					font-weight: 300;
					letter-spacing: .5px;
					font-size: 14px;
				}
				}
			}
				.form-disclaimer{
				font-size: 10.5px;
				line-height: normal;
				text-align: justify;
				a{
					color: black !important;
				}
				}
			

			.btn-wrap{
				margin-left: 0;

				p:last-child{
				text-align: center;

				.privacy-link{
					color: black;
					text-align: center;
				}
				}
				button{
				padding: 0 15px;
				}
			}
			}
		}
	}
}

